import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import CustomerOrderDrawer from "./CustomerOrderDrawer";
import CustomerInvoiceDrawer from "./CustomerInvoiceDrawer";

class CustomerAllEventsDrawer extends React.Component {
  state = {
    fulfillmentDate: moment().day(6).toDate(),
    order: [],
    loading: false,
  };

  getAllEvents(customer) {
    this.setState({
      loading: true,
    });

    let query = {
      customerID: customer?._id,
    };

    if (this.props.lead) {
      query = {
        leadID: customer?._id,
      };
    }

    let sortBy = { dueOn: -1 };

    APIV2.getCustomerEventsForStore(customer?.storeID, 1, 10000, query, sortBy)
      .then(
        (data) => {
          this.setState({ events: data?.data?.events ?? [] });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.open != this.props.open &&
      this.props.open &&
      (this.props.customer || this.props.lead)
    ) {
      if (this.props.customer) {
        this.getAllEvents(this.props.customer);
      } else {
        this.getAllEvents(this.props.lead);
      }
    }
  }

  componentDidMount() {
    if (this.props.open && (this.props.customer || this.props.lead)) {
      if (this.props.customer) {
        this.getAllEvents(this.props.customer);
      } else {
        this.getAllEvents(this.props.lead);
      }
    }
  }

  typeFromString(type) {
    return (
      <Badge color="medium" className="text-dark">
        {type}
      </Badge>
    );
  }

  invoiceRow(c) {
    return (
      <Row className="align-items-center">
        <Col className="mb-2 mb-md-0" xs="12" sm="12" md="3">
          <h3 className="mb-0">{this.typeFromString(c.type)}</h3>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {moment(c.eventTimestamp).format("MM/DD/YY hh:mm A")}
          </p>
        </Col>
        <Col className="mb-3 mb-md-0 text-left" xs="12" sm="12" md="7">
          {c.title}
        </Col>
        <Col xs="12" sm="12" md="2" className="text-left text-md-right">
          <Button
            size="sm"
            outline
            color="dark"
            onClick={() => {
              this.setState({
                viewData: this.state.viewData == c?._id ? "" : c._id,
              });
            }}
          >
            {this.state.viewData == c?._id ? "Hide" : "View"} Data
          </Button>
        </Col>
        {this.state.viewData == c?._id ? (
          <Col xs="12">
            <div
              className="mt-3 p-2 border bg-white"
              dangerouslySetInnerHTML={{
                __html: StringUtils.jsonToHTML(c),
              }}
            ></div>
          </Col>
        ) : null}
      </Row>
    );
  }

  toggleModal() {
    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              All {this.props.customer ? "Member" : "Lead"} Events
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            {this.state?.loading && (
              <div className="px-3 py-5 text-center">
                <Spinner size="sm" color="dark"></Spinner>
              </div>
            )}
            {!this.state?.events?.length && !this.state.loading && (
              <div className="px-3 py-5">
                <Row>
                  <Col xs="12">
                    <h3 className="m-0">No events available.</h3>
                    {this.props.customer ? (
                      <p>
                        {this.props?.customer?.name
                          ? StringUtils.getFirstName(this.props?.customer?.name)
                          : `This customer`}{" "}
                        does not have any events.
                      </p>
                    ) : (
                      <p>This lead does not have any events.</p>
                    )}
                  </Col>
                </Row>
              </div>
            )}

            {this.state?.events?.length > 0 && (
              <div className="px-4 py-2 bg-superlight border-bottom">
                <Row>
                  <Col xs="12" sm="6" md="4">
                    <h4 className="m-0">Type</h4>
                  </Col>
                  <Col xs="12" sm="6" md="6" className="d-none d-sm-flex">
                    <h4 className="m-0">Subject</h4>
                  </Col>

                  <Col xs="12" sm="6" md="2" className="d-none d-sm-flex"></Col>
                </Row>
              </div>
            )}
            {this.state?.events?.map((invoice, i) => (
              <div key={invoice?._id}>
                <div className="px-4 py-3 cursor-pointer bg-superlight--hover">
                  {this.invoiceRow(invoice)}
                </div>
                {i != this.state?.invoices?.length - 1 && (
                  <hr className="m-0"></hr>
                )}
              </div>
            ))}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CustomerAllEventsDrawer);
