import React from "react";
import { Link, withRouter } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import Badge from "reactstrap/lib/Badge";
import moment from "moment";
import NSOUtils from "lib/NSOUtils";

class StoreRow extends React.Component {
  state = {
    showSecret: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    modify: false,
  };

  view(id) {
    this.props.history.push("/frandev/stores/details/" + id + "/about");
  }

  render() {
    return (
      <>
        <tr
          className="cursor-pointer"
          onClick={() => this.view(this.props.store._id)}
        >
          <td style={{ whiteSpace: "pre-wrap" }}>
            <h4 className="mb-1">
              {this.props.store.name?.replace("Project LeanNation", "PLN")}
            </h4>
          </td>
          <td className="">{this.props?.store?.nsoStage ?? "--"}</td>
          <td className="">
            {this.props.store?.type == "retail" ? (
              <small
                className="m-0 font-weight-400"
                style={{ fontSize: "14px" }}
              >
                {this.props.store?.location?.address?.address_1}&nbsp;
                {this.props.store?.location?.address?.address_2
                  ? this.props.store.address_2
                  : ""}
                {this.props.store?.location?.address?.address_1 && <br></br>}
                {this.props.store?.location?.address?.city},&nbsp;
                {this.props.store?.location?.address?.state}{" "}
                {this.props.store?.location?.address?.zip}
              </small>
            ) : (
              <p className="m-0">
                <Badge color="medium">No Retail Store</Badge>
              </p>
            )}
          </td>

          <td className="">
            {this.props.store?.operations?.storeOpening?.websiteLaunchDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(
                    this.props.store?.operations?.storeOpening
                      ?.websiteLaunchDate
                  ).format("MM/DD/YYYY")}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="">
            {this.props.store?.operations?.storeOpening?.prelaunchStartDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(
                    this.props.store?.operations?.storeOpening
                      ?.prelaunchStartDate
                  ).format("MM/DD/YYYY")}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="">
            {this.props.store?.operations?.storeOpening?.firstBillDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(
                    this.props.store?.operations?.storeOpening?.firstBillDate
                  ).format("MM/DD/YYYY")}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="">
            {this.props.store?.operations?.storeOpening?.openingDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(
                    this.props.store?.operations?.storeOpening?.openingDate
                  ).format("MM/DD/YYYY")}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="text-center">
            {this.props.store?.nsoStage == "Site Selection" ||
            this.props.store?.nsoStage == "Buildout" ||
            this.props.store?.nsoStage == "Prelaunch" ? (
              <div>
                <Badge
                  className={`text-white ${
                    NSOUtils.getPrelaunchCompletion(this.props.store) < 1
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                >
                  {NSOUtils.getPrelaunchCompletion(this.props.store) < 1
                    ? "NO"
                    : "YES"}
                </Badge>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="text-center">
            {this.props.store?.nsoStage == "Site Selection" ||
            this.props.store?.nsoStage == "Buildout" ||
            this.props.store?.nsoStage == "Prelaunch" ? (
              <div>
                <Badge
                  className={`text-white ${
                    NSOUtils.getRetailCompletion(this.props.store) < 1
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                >
                  {NSOUtils.getRetailCompletion(this.props.store) < 1
                    ? "NO"
                    : "YES"}
                </Badge>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="text-center">
            {this.props.store?.nsoStage == "Site Selection" ||
            this.props.store?.nsoStage == "Buildout" ||
            this.props.store?.nsoStage == "Prelaunch" ? (
              <div>
                <Badge
                  className={`text-white ${
                    NSOUtils.getFirstBillDateCompletion(this.props.store) < 1
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                >
                  {NSOUtils.getFirstBillDateCompletion(this.props.store) < 1
                    ? "NO"
                    : "YES"}
                </Badge>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="">
            {this.props.store?.franchisePartners?.length ? (
              <div>
                {this.props.store?.franchisePartners?.map((partner, i) => (
                  <div key={i}>
                    <small
                      className="m-0 font-weight-400"
                      style={{ fontSize: "14px" }}
                    >
                      {partner?.name}
                    </small>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
        </tr>
      </>
    );
  }
}

export default withRouter(StoreRow);
