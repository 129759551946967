import ArrowText from "components/ArrowText";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import React from "react";
import { Link, withRouter } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Collapse,
  CardBody,
  Badge,
  Button,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardFooter from "reactstrap/lib/CardFooter";
import _ from "underscore";

class SelectedMealCheckoutRow extends React.Component {
  state = {};

  incrementMeal(meal, quantity) {
    this.props.incrementMeal(meal, quantity);
  }

  render() {
    return (
      <>
        <Row className="align-items-center">
          <Col xs="auto" className="col-auto py-3">
            <div
              onClick={() => {
                PubSub.publish(Event.MEAL.OPEN_INFO, this.props.meal);
              }}
              className="p-1 border cursor-pointer bg-white border-lighter"
              style={{ borderRadius: "6px" }}
            >
              <img
                src={this.props.meal.imageURL}
                alt={this.props.meal.name}
                style={{
                  width: "45px",
                }}
              ></img>
              {this.props.selected ? (
                <div
                  className="text-white bg-medium shadow"
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "5px",
                    borderRadius: "100%",
                    width: "28px",
                    height: "28px",
                    textAlign: "center",
                    paddingTop: "1px",
                    border: "1px solid var(--medium)",
                  }}
                >
                  {this.props.selected}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col xs="" className="py-3 pl-3 meal-name">
            <h4
              onClick={() => {
                PubSub.publish(Event.MEAL.OPEN_INFO, this.props.meal);
              }}
              className="mb-0 text-dark cursor-pointer"
              style={{ lineHeight: 1.3 }}
            >
              {this.props.meal.name}
            </h4>
            <div
              onClick={() => {
                PubSub.publish(Event.MEAL.OPEN_INFO, this.props.meal);
              }}
              className="meal-checkoutrow-description cursor-pointer mt-1"
              dangerouslySetInnerHTML={{ __html: this.props.meal.description }}
            ></div>
            <hr className="my-2"></hr>
            <div className="mt-0">
              <Row className="align-items-center">
                <Col xs="6" sm="auto" className="pr-sm-0">
                  <small className="font-weight-500">
                    {this.props.meal?.nutrition?.calories} CAL
                  </small>
                </Col>
                <Col xs="6" sm="auto" className="pr-sm-0">
                  <small className="font-weight-500">
                    {this.props.meal?.nutrition?.carbs}g CARBS
                  </small>
                </Col>
                <Col xs="6" sm="auto" className="pr-sm-0">
                  <small className="font-weight-500">
                    {this.props.meal?.nutrition?.protein}g PROTEIN
                  </small>
                </Col>
                <Col xs="6" sm="auto">
                  <small className="font-weight-500">
                    {this.props.meal?.nutrition?.fat}g FAT
                  </small>
                </Col>
              </Row>
            </div>
            <div className="mt-0">
              <small>
                {this.props.meal?.categories
                  ?.map((c) => {
                    return c?.name;
                  })
                  ?.join(", ")}
              </small>
            </div>
          </Col>
          {this.props.modifiable ? (
            <Col xs="auto">
              <div className="mt-2">
                <Button
                  disabled={this.props.disableAdd}
                  size="sm"
                  color=""
                  className="btn-link px-y px-0"
                  onClick={() => this.incrementMeal(this.props.meal, 1)}
                >
                  <span
                    style={{
                      fontSize: "25px",
                      lineHeight: 1,
                    }}
                  >
                    <i className="mdi mdi-plus"></i>
                  </span>
                </Button>
              </div>
              <div>
                <Button
                  disabled={this.props.selected <= 0 || !this.props.selected}
                  size="sm"
                  color=""
                  className={`btn-link px-y px-0 ${
                    this.props.selected > 0 ? "text-dark" : ""
                  }`}
                  onClick={() => this.incrementMeal(this.props.meal, -1)}
                >
                  <span
                    style={{
                      fontSize: "25px",
                      lineHeight: 1,
                    }}
                  >
                    <i className="mdi mdi-minus"></i>
                  </span>
                </Button>
              </div>
            </Col>
          ) : null}
        </Row>
      </>
    );
  }
}

export default withRouter(SelectedMealCheckoutRow);
