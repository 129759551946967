import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class ConsultationDetailChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchDataByStore(cubejsApi, stores, dateRange, metricNames = []) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity,
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: metricNames,
            },
          ],
          dimensions: ["Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let storeNames = [];

          data = data.map((item) => {
            let mom = moment(item[`Metrics.createdat.${granularity}`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(0);
              granularityString = "Week " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            if (!storeNames?.includes(item["Stores.name"])) {
              storeNames.push(item["Stores.name"]);
            }

            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              dateObj: moment(
                item[`Metrics.createdat.${granularity}`]
              ).toDate(),
              date: granularityString,
              count: item["Metrics.count"],
            };
          });

          let endMoment = moment(dateRange[1]);

          let tempDate = moment(dateRange[0])
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0);

          if (granularity == "week") {
            tempDate.day(0);
          }

          if (granularity == "month") {
            tempDate.startOf("month");
          }

          while (tempDate.toDate() <= endMoment.toDate()) {
            let granularityString = tempDate.format("MM/DD/YYYY");

            if (granularity == "week") {
              tempDate.day(0);
              granularityString = "Week " + granularityString;
            }

            if (granularity == "month") {
              tempDate.startOf("month");
              granularityString = tempDate.format("MMMM YY");
            }

            for (let i = 0; i < storeNames?.length; i++) {
              const storeName = storeNames[i]
                ?.replace("Project LeanNation", "PLN")
                ?.trim();

              if (!_.findWhere(data, { date: granularityString, storeName })) {
                data.push({
                  dateObj: tempDate.toDate(),
                  date: granularityString,
                  count: 0,
                  storeName,
                });
              }
            }

            if (granularity == "day") {
              tempDate.add(1, "day");
            } else if (granularity == "week") {
              tempDate.add(1, "week");
            } else if (granularity == "month") {
              tempDate.add(1, "month");
            }
          }

          data = _.sortBy(data, "dateObj");

          let outByStore2 = data.map((item) => {
            return {
              storeName: item?.storeName,
              dateObj: item?.dateObj,
              date: item?.date,
              count: item?.count,
            };
          });

          let outByStore = [];

          let outOverall = [];

          let total = 0;

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            const storeIDX = _.findIndex(outByStore, {
              date: item.date,
            });
            const overallIDX = _.findIndex(outOverall, {
              date: item.date,
            });

            if (storeIDX == -1) {
              outByStore.push({
                date: item?.date,
                [item.storeName]: item.count,
                dateObj: item?.dateObj,
              });
            } else {
              outByStore[storeIDX][item?.storeName] = item.count;
            }

            if (overallIDX == -1) {
              outOverall.push({
                date: item?.date,
                count: item.count,
                dateObj: item?.dateObj,
              });
            } else {
              outOverall[overallIDX].count += item.count;
            }

            total += item?.count;
          }

          outByStore = _.sortBy(outByStore, "dateObj");
          outByStore2 = _.sortBy(outByStore2, "dateObj");

          return resolve({
            byStore: outByStore,
            byStore2: outByStore2,
            total,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(
    cubejsApi,
    stores,
    dateRange,
    metricNames = [],
    dimensions = []
  ) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity,
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: metricNames,
            },
          ],
          dimensions,
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["Metrics.count"];

            let mom = moment(item[`Metrics.createdat`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(0);

              granularityString = "Week " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            return {
              count: item["Metrics.count"],
              dateObj: mom.toDate(),
              date: granularityString,
            };
          });

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    // Fetching current counts for both types
    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange, [
        "member_consultation",
        "member_consultation_client",
      ]);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantities by week.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let previousCount = null;

    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    let startMoment = moment(dateRange[0].toISOString());
    let endMoment = moment(dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    try {
      previousCount = await this._fetchData(
        cubejsApi,
        stores,
        [startMoment.toDate(), endMoment.toDate()],
        ["member_consultation", "member_consultation_client"]
      );
    } catch (e) {}

    if (previousCount !== null) {
      let percentChange =
        (currentCount?.total - previousCount?.total) / previousCount?.total;

      let declineMode = "";
      let isNegative = false;

      if (percentChange > 0) {
        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateIncrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "increase";
        }
      } else {
        isNegative = true;

        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateDecrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "decrease";
        }
      }

      this.setState({
        previousCount: previousCount,
        changeIsNegative: isNegative,
        deltaType: declineMode,
        percentChange: percentChange,
        percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
      });
    }

    let performanceTrend = [];

    for (let i = 0; i < currentCount?.history?.length; i++) {
      const item = currentCount?.history[i];

      performanceTrend.push({
        dateObj: item?.dateObj,
        date: item?.date,
        Consultations: item?.count,
        Previous: null,
      });
    }

    for (let i = 0; i < previousCount?.history?.length; i++) {
      const item = previousCount?.history[i];

      const dateObj = moment(item?.dateObj?.toISOString())
        .add(secondDifference, "seconds")
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      const realDate = moment(item?.dateObj?.toISOString())
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      let granularityString = dateObj.format("MM/DD/YYYY");
      let realDateString = realDate.format("MM/DD/YYYY");

      if (granularity == "week") {
        dateObj.day(0);
        realDate.day(0);
        granularityString = "Week " + dateObj.format("MM/DD/YYYY");

        realDateString = "Week " + realDate.format("MM/DD/YYYY");
      }

      if (granularity == "month") {
        dateObj.startOf("month");
        realDate.startOf("month");

        granularityString = dateObj.format("MMMM YY");
        realDateString = realDate.format("MMMM YY");
      }

      const idx = _.findIndex(performanceTrend, {
        date: granularityString,
      });

      if (idx >= 0) {
        performanceTrend[idx].Previous = item?.count;
        performanceTrend[idx].date += ` vs. ${realDateString}`;
      } else if (
        dateObj?.toDate() >= dateRange[0] &&
        dateObj.toDate() <= dateRange[1]
      ) {
        performanceTrend.push({
          Previous: item?.count,
          Consultations: null,
          dateObj: dateObj?.toDate(),
          date: granularityString + ` vs. ${realDateString}`,
        });
      }
    }

    performanceTrend = _.sortBy(performanceTrend, "dateObj");

    // Fetch by Store data
    let storeNames = [];

    let byStoreData = null;

    // Fetching current counts for both types
    try {
      byStoreData = await this._fetchDataByStore(cubejsApi, stores, dateRange, [
        "member_consultation",
        "member_consultation_client",
      ]);
    } catch (e) {}

    for (let i = 0; i < byStoreData?.byStore2?.length; i++) {
      let keys = _.keys(byStoreData?.byStore2[i]);

      let names = _.filter(keys, (name) => {
        return name.includes("PLN");
      });

      storeNames = storeNames.concat(names);
    }

    storeNames = _.uniq(storeNames);

    storeNames.sort();

    let colors = StringUtils.randomChartColorSet(storeNames?.length);

    // Fetch client type data
    let newHistory = [];
    let reactivateHistory = [];

    try {
      let res = await this._fetchData(cubejsApi, stores, dateRange, [
        "member_consultation",
      ]);

      newHistory = res?.history;
    } catch (e) {}

    try {
      let res = await this._fetchData(cubejsApi, stores, dateRange, [
        "member_consultation_client",
      ]);

      reactivateHistory = res?.history;
    } catch (e) {}

    let typeHistory = [];

    for (let i = 0; i < newHistory?.length; i++) {
      const cur = newHistory[i];

      let out = {
        dateObj: cur?.dateObj,
        date: cur?.date,
        "LEAN Consultations": cur?.count,
        "Member Check-Ins": 0,
      };

      typeHistory.push(out);
    }

    for (let i = 0; i < reactivateHistory?.length; i++) {
      const cur = reactivateHistory[i];

      const idx = _.findIndex(typeHistory, { date: cur?.date });

      if (idx >= 0) {
        typeHistory[idx]["Member Check-Ins"] = cur?.count;
      } else {
        let out = {
          dateObj: cur?.dateObj,
          date: cur?.date,
          "LEAN Consultations": 0,
          "Member Check-Ins": cur?.count,
        };

        typeHistory.push(out);
      }
    }

    startMoment = moment(dateRange[0]);
    endMoment = moment(dateRange[1]);

    let tempDate = moment(dateRange[0])
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    if (granularity == "week") {
      tempDate.day(0);
    }

    if (granularity == "month") {
      tempDate.startOf("month");
    }

    while (tempDate.toDate() <= endMoment.toDate()) {
      let granularityString = tempDate.format("MM/DD/YYYY");

      if (granularity == "week") {
        tempDate.day(0);
        granularityString = "Week " + granularityString;
      }

      if (granularity == "month") {
        tempDate.startOf("month");
        granularityString = tempDate.format("MMMM YY");
      }

      if (!_.findWhere(typeHistory, { date: granularityString })) {
        typeHistory.push({
          dateObj: tempDate.toDate(),
          date: granularityString,
          "LEAN Consultations": 0,
          "Member Check-Ins": 0,
        });
      }

      if (granularity == "day") {
        tempDate.add(1, "day");
      } else if (granularity == "week") {
        tempDate.add(1, "week");
      } else if (granularity == "month") {
        tempDate.add(1, "month");
      }
    }

    typeHistory = _.sortBy(typeHistory, "dateObj");

    // Fetch referral data

    this.setState({
      currentCount: currentCount,
      loading: false,
      performanceTrend,
      byStore: byStoreData?.byStore,
      byStore2: byStoreData?.byStore2,
      storeNames: storeNames,
      byStoreColors: colors,
      typeHistory,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Consultation Summary"
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadDetailedReport(
                      this.props.stores,
                      this.props.dateRange
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
                {/*<Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading || !this.state.dataAvailable}
            >
                <i className="mdi mdi-download"></i>
                </Button>*/}
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={StringUtils.numberFormat(this.state.currentCount?.total)}
            comparisonMetric={
              this.state.previousCount !== null
                ? StringUtils.numberFormat(this.state.previousCount?.total)
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          <TabList
            color="orange"
            defaultValue="performanceTrend"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });

              if (value == "byStore") {
                this.setState({
                  chartAdjust: [{ type: "stack" }],
                });
              } else {
                this.setState({
                  chartAdjust: [{ type: "dodge", marginRatio: 1 / 32 }],
                });
              }
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="performanceTrend" text="Overall Trend" />
            <Tab value="byType" text="Consultation Type" />
            <Tab value="byStore" text="Store Summary" />
            <Tab value="byStoreBar" text="Store Comparison" />
          </TabList>
          {this.state.loading ? (
            <div
              className="skeleton"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  {this.state.tab == "performanceTrend" && (
                    <div className="mt-3">
                      <AreaChart
                        data={this.state.performanceTrend}
                        categories={["Consultations", "Previous"]}
                        dataKey="date"
                        colors={["orange", "gray"]}
                        valueFormatter={(number) => {
                          return StringUtils.numberFormat(number);
                        }}
                        height="h-80"
                      />
                    </div>
                  )}
                  {this.state.tab == "byType" && (
                    <div className="mt-3">
                      <BarChart
                        data={this.state.typeHistory}
                        dataKey="date"
                        categories={["LEAN Consultations", "Member Check-Ins"]}
                        colors={["orange", "green"]}
                        stack={true}
                        valueFormatter={(number) => {
                          return StringUtils.numberFormat(number) + " Members";
                        }}
                        height="h-80"
                        yAxisWidth="w-14"
                      />
                    </div>
                  )}

                  {this.state.tab == "byStore" && (
                    <>
                      <div className="mt-3">
                        <Chart
                          height={320}
                          appendPadding={[20, 0, 10, 30]}
                          data={this.state.byStore2}
                          autoFit
                        >
                          <Coordinate transposed></Coordinate>
                          <Tooltip
                            itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Members</span></li>`}
                            shared
                          ></Tooltip>
                          <Axis name="date" label={{ offset: 12 }}></Axis>
                          <Axis
                            name="count"
                            grid={{
                              line: {
                                style: {
                                  stroke: "#CCC",
                                  lineDash: [3, 3],
                                },
                              },
                            }}
                          ></Axis>
                          <Legend
                            marker={{ symbol: "circle" }}
                            label={{}}
                            position="top-right"
                            layout={"horizontal"}
                            offsetY={0}
                          ></Legend>
                          <Interval
                            adjust={this.state.chartAdjust}
                            color="storeName"
                            position="date*count"
                          ></Interval>
                          <Interaction type="active-region"></Interaction>
                        </Chart>
                      </div>
                    </>
                  )}
                  {this.state.tab == "byStoreBar" && (
                    <>
                      <div className="mt-3">
                        <Chart
                          height={320}
                          appendPadding={[20, 0, 10, 30]}
                          data={this.state.byStore2}
                          autoFit
                        >
                          <Coordinate transposed></Coordinate>
                          <Tooltip
                            itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Members</span></li>`}
                            shared
                          ></Tooltip>
                          <Axis name="date" label={{ offset: 12 }}></Axis>
                          <Axis
                            name="count"
                            grid={{
                              line: {
                                style: {
                                  stroke: "#CCC",
                                  lineDash: [3, 3],
                                },
                              },
                            }}
                          ></Axis>
                          <Legend
                            marker={{ symbol: "circle" }}
                            label={{}}
                            position="top-right"
                            layout={"horizontal"}
                            offsetY={0}
                          ></Legend>
                          <Interval
                            adjust={this.state.chartAdjust}
                            color="storeName"
                            position="date*count"
                          ></Interval>
                          <Interaction type="active-region"></Interaction>
                        </Chart>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default ConsultationDetailChartCard;
