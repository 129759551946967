import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import { Sparklines, SparklinesBars } from "react-sparklines";
import cubejs from "@cubejs-client/core";
import DataAPI from "../../lib/DataAPI";
import StoreUpcomingAppointmentsWidget from "../../components/Widgets/StoreUpcomingAppointmentsWidget";
import NewConsultationDrawer from "../../components/Drawers/NewConsultationDrawer";

class StorefrontHomeView extends React.Component {
  state = {
    active: {
      id: "All Upcoming & Overdue Tasks",
      name: "All Upcoming & Overdue Tasks",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 15,
    filters: null,
    orderBy: null,
    memberActivity: "This Week (Sun-Sat)",
    leadActivity: "This Week (Sun-Sat)",
    activeView: "member",
  };

  async loadCharts() {
    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    this.setState({
      newMemberResultsLoading: true,
      hfnResultsLoading: true,
      totalActiveResultsLoading: true,
      newLeadResultsLoading: true,
      convertedLeadResultsLoading: true,
      conversionRateResultsLoading: true,
      referralsLoading: true,
      consultationsLoading: true,
      reactivationsLoading: true,
      clientConsultations: true,
    });

    // Load New Member Results
    cubejsApi
      .load({
        measures: ["Customers.count"],
        timeDimensions: [
          {
            dimension: "Customers.signupdate",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Customers.createdat": "asc",
        },
        dimensions: [],
        filters: [
          {
            member: "Customers.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["ACTIVE"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];

          byDay.push(data[i]["Customers.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          newMemberResults: StringUtils.numberFormat(total),
          newMemberResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ newMemberResults: "--", newMemberResultsByDay: [] });
      })
      .finally(() => {
        this.setState({
          newMemberResultsLoading: false,
        });
      });

    // Load Active this week Results
    cubejsApi
      .load({
        measures: ["Customers.count"],
        timeDimensions: [],
        order: {},
        dimensions: [],
        filters: [
          {
            member: "Customers.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["ACTIVE"],
          },
          {
            member: "CustomerSubscriptions.nextbilldate",
            operator: "equals",
            values: [moment().day(6).format("YYYY-MM-DD")],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];
        }

        this.setState({
          totalActiveResults: StringUtils.numberFormat(total),
        });
      })
      .catch(() => {
        this.setState({ totalActiveResults: "--" });
      })
      .finally(() => {
        this.setState({
          totalActiveResultsLoading: false,
        });
      });

    // Load HFNs
    cubejsApi
      .load({
        measures: ["Customers.count"],
        order: {
          "Customers.createdat": "asc",
        },
        dimensions: [],
        timeDimensions: [
          {
            dimension: "CustomerSubscriptions.holdDate",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        filters: [
          {
            member: "Customers.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["HOLD"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];

          byDay.push(data[i]["Customers.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          hfnResults: StringUtils.numberFormat(total),
          hfnResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ hfnResults: "--", hfnResultsByDay: [] });
      })
      .finally(() => {
        this.setState({
          hfnResultsLoading: false,
        });
      });

    // Load New Leads
    cubejsApi
      .load({
        measures: ["CustomerLeads.totalWithInformation"],
        timeDimensions: [
          {
            dimension: "CustomerLeads.createdAt",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "CustomerLeads.createdat": "asc",
        },
        dimensions: [],
        timeDimensions: [
          {
            dimension: "CustomerLeads.createdat",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        filters: [
          {
            member: "CustomerLeads.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["CustomerLeads.totalWithInformation"];

          byDay.push(data[i]["CustomerLeads.totalWithInformation"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          newLeadResults: StringUtils.numberFormat(total),
          newLeadResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ newLeadResults: "--", newLeadResultsByDay: [] });
      })
      .finally(() => {
        this.setState({
          newLeadResultsLoading: false,
        });
      });

    // Load Converted Leads
    cubejsApi
      .load({
        measures: ["CustomerLeads.converted"],
        timeDimensions: [
          {
            dimension: "CustomerLeads.convertedat",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "CustomerLeads.createdat": "asc",
        },
        dimensions: [],
        filters: [
          {
            member: "CustomerLeads.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        console.log(data);

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["CustomerLeads.converted"];

          byDay.push(data[i]["CustomerLeads.converted"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          convertedLeadResults: StringUtils.numberFormat(total),
          convertedLeadResultsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({
          convertedLeadResults: "--",
          convertedLeadResultsByDay: [],
        });
      })
      .finally(() => {
        this.setState({
          convertedLeadResultsLoading: false,
        });
      });

    // Load conversion rate
    cubejsApi
      .load({
        measures: ["CustomerLeads.closeRate"],
        timeDimensions: [
          {
            dimension: "CustomerLeads.createdat",
            dateRange: "Last 30 days",
          },
        ],
        order: {
          "CustomerLeads.createdat": "asc",
        },
        dimensions: [],
        filters: [
          {
            member: "CustomerLeads.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        console.log(data);

        let total = data?.length ? data[0]["CustomerLeads.closeRate"] : "--";

        if (total != "--") {
          total = total.toFixed(1) + "%";
        }

        this.setState({
          conversionRateResults: total,
        });
      })
      .catch(() => {
        this.setState({
          conversionRateResults: "--",
          conversionRateResultsByDay: [],
        });
      })
      .finally(() => {
        this.setState({
          conversionRateResultsLoading: false,
        });
      });

    // Consultations
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_consultation"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Metrics.totalEvents"];
          byDay.push(data[i]["Metrics.totalEvents"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          consultations: StringUtils.numberFormat(total),
          consultationsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ consultations: "--" });
      })
      .finally(() => {
        this.setState({
          consultationsLoading: false,
        });
      });

    // Client Consultations
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            granularity: "day",
            dateRange: [
              moment()
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                .milliseconds(0)
                ?.format("YYYY-MM-DD"),
              moment()
                ?.day(6)
                ?.hour(23)
                .minute(59)
                .second(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_consultation_client"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Metrics.totalEvents"];
          byDay.push(data[i]["Metrics.totalEvents"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          clientConsultations: StringUtils.numberFormat(total),
          clientConsultationsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ clientConsultations: "--" });
      })
      .finally(() => {
        this.setState({
          clientConsultationsLoading: false,
        });
      });

    // Referrals
    cubejsApi
      .load({
        measures: ["Metrics.totalEvents"],
        timeDimensions: [
          {
            dimension: "Metrics.createdat",
            granularity: "day",
            dateRange: [
              moment()
                ?.day(0)
                .hour(0)
                .minute(0)
                .second(0)
                .milliseconds(0)
                ?.format("YYYY-MM-DD"),
              moment()
                ?.hour(23)
                .minute(59)
                .second(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        order: {
          "Metrics.createdat": "asc",
        },
        filters: [
          {
            member: "Metrics.metadatastoreid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "Metrics.name",
            operator: "equals",
            values: ["member_referral"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Metrics.totalEvents"];
          byDay.push(data[i]["Metrics.totalEvents"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          referrals: StringUtils.numberFormat(total),
          referralsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ referrals: "--" });
      })
      .finally(() => {
        this.setState({
          referralsLoading: false,
        });
      });

    // Reactivations
    cubejsApi
      .load({
        measures: ["Customers.count"],
        order: {
          "Customers.createdat": "asc",
        },
        dimensions: [],
        timeDimensions: [
          {
            dimension: "CustomerSubscriptions.reactivateDate",
            granularity: "day",
            dateRange: [
              moment()
                .day(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .day(6)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .format("YYYY-MM-DD"),
            ],
          },
        ],
        filters: [
          {
            member: "CustomerSubscriptions.storeid",
            operator: "equals",
            values: [this.props.match?.params?.storeID],
          },
          {
            member: "CustomerSubscriptions.status",
            operator: "equals",
            values: ["ACTIVE"],
          },
        ],
      })
      .then((res) => {
        let data = res?.loadResponse?.results?.length
          ? res?.loadResponse?.results[0]?.data
          : [];

        let total = 0;
        let byDay = [];

        for (let i = 0; i < data?.length; i++) {
          total += data[i]["Customers.count"];
          byDay.push(data[i]["Customers.count"]);
        }

        while (byDay?.length < 7) {
          byDay.push(null);
        }

        this.setState({
          reactivations: StringUtils.numberFormat(total),
          reactivationsByDay: byDay,
        });
      })
      .catch(() => {
        this.setState({ reactivations: "--" });
      })
      .finally(() => {
        this.setState({
          reactivationsLoading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID
    ) {
      console.log("HERE");

      if (this.state.stores) {
        let store = _.findWhere(this.state.stores, {
          _id: this.props?.match?.params?.storeID,
        });

        this.setState(
          {
            store,
          },
          () => {
            this.handleTabChange(this.state.active, true);
          }
        );
      }

      this.loadCharts();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    this.loadStores();
    this.loadCharts();

    PubSub.subscribe(Event.TASK.CREATED, (m) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailTask?._id == a?._id) {
        this.setState({
          detailTask: a,
        });
      }
    });

    PubSub.subscribe(Event.TASK.DELETED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadTasks(page = 1, count = 15, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    APIV2.getTasksForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      {
        dueDate: 1,
      }
    )
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tasks.length) {
              tasks = data.data.tasks;
            }

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleTabChange(option, force = false) {
    let filters = {
      storeTask: true,
      type: "Franchise Partner",
      storeID: this.props?.match?.params?.storeID,
    };
    let orderBy = {
      dueDate: 1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All Upcoming & Overdue Tasks") {
      let mom = moment();

      mom.add(14, "days");

      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        dueDate: {
          $lte: { $date: mom.toDate().toISOString() },
        },
        storeTask: true,
        type: "Franchise Partner",
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "All Incomplete Tasks") {
      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: true,
        type: "Franchise Partner",
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "All Completed Tasks") {
      filters = {
        status: { $in: ["Completed"] },
        storeTask: true,
        type: "Franchise Partner",
        storeID: this.props?.match?.params?.storeID,
      };
    }

    console.log(filters);

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTasks(1, this.state.count, filters);
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            let store = _.findWhere(data.data.stores, {
              _id: this.props?.match?.params?.storeID,
            });

            console.log(localStorage.getItem("cache_user"));

            this.setState({ stores: data.data.stores, store }, () => {
              this.handleTabChange(this.state.active, true);
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(page, this.state.count, this.state.filters);
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    let now = moment();
    let due = moment(dateString);

    if (now.toDate() > due.toDate() && status != "Completed") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {due.format("MMM DD, YYYY")}
        </Badge>
      );
    }

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return <Badge color="info">{status}</Badge>;
    }

    if (status == "Completed") {
      return <Badge color="light">{status}</Badge>;
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}

          <div className="mb-4">
            <h3 className="text-dark font-weight-500">
              {this.state.store?.name ? this.state.store?.name : "Loading..."}
            </h3>
            <h1>Welcome back. Here’s your business at a glance.</h1>
          </div>
          <Row>
            <div className="col">
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Upcoming Consultations</h3>
                </Col>
                <Col xs="auto">
                  <Button
                    disabled={!this.state.store?.twilioNumber}
                    size="sm"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        newConsult: true,
                      });
                    }}
                  >
                    New
                  </Button>
                </Col>
              </Row>
              <hr className="mt-2 mb-3"></hr>
              <StoreUpcomingAppointmentsWidget
                store={this.state.store}
              ></StoreUpcomingAppointmentsWidget>
              <NewConsultationDrawer
                open={this.state.newConsult}
                store={this.state.store}
                onClose={() => {
                  this.setState({ newConsult: false });
                }}
              ></NewConsultationDrawer>
            </div>
          </Row>
          <div
            className={`mb-5 ${this.state.activeView != "member" && "d-none"}`}
          >
            <div>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Member Activity</h3>
                </Col>
                <Col xs="auto">
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    size="sm"
                    placement="auto"
                    value={this.state.memberActivity}
                    data={[
                      {
                        label: "This Week (Sun-Sat)",
                        value: "This Week (Sun-Sat)",
                      },
                    ]}
                  ></SelectPicker>
                </Col>
              </Row>
            </div>
            <hr className="mt-2 mb-3"></hr>
            <Row>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">New Members</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of members who signed up during the
                              selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.newMemberResultsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.newMemberResults
                      )}
                    </h1>
                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state?.newMemberResultsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">Active This Week</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of active members with a next bill
                              date on:{" "}
                              {moment().day(6).format("dddd MMM DD, YYYY")}
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-3 display-4">
                      {this.state.totalActiveResultsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.totalActiveResults
                      )}
                    </h1>
                    <p className="mb-0">
                      For {moment().day(6).format("MMMM DD, YYYY")}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">HFNs</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of members who were placed on HOLD
                              during the selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.hfnResultsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.hfnResults
                      )}
                    </h1>
                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state?.hfnResultsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">Reactivations</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of members who came off HOLD were
                              during the selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.reactivationsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.reactivations
                      )}
                    </h1>
                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state?.reactivationsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">Member Check-Ins</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of Member Check-Ins performed during
                              the selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.clientConsultationsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.clientConsultations
                      )}
                    </h1>

                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state.clientConsultationsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">Referrals</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of new &amp; reactivated members who
                              were referred by another PLN member during the
                              selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.referralsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.referrals
                      )}
                    </h1>
                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state?.referralsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div
            className={`mb-5 ${this.state.activeView != "lead" && "d-none"}`}
          >
            <div>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Lead Activity</h3>
                </Col>
                <Col xs="auto">
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    size="sm"
                    value={this.state.leadActivity}
                    placement="auto"
                    data={[
                      {
                        label: "This Week (Sun-Sat)",
                        value: "This Week (Sun-Sat)",
                      },
                    ]}
                  ></SelectPicker>
                </Col>
              </Row>
            </div>
            <hr className="mt-2 mb-3"></hr>
            <Row>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">New Leads</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of leads created with a name, email,
                              and phone during the selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.newLeadResultsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.newLeadResults
                      )}
                    </h1>

                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state.newLeadResultsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">Converted Leads</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of leads converted within the
                              selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.convertedLeadResultsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.convertedLeadResults
                      )}
                    </h1>

                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state.convertedLeadResultsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4" className="mb-3">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">Avg. Conversion Rate</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Average percentage of leads (with name, email,
                              phone) that were created and converted within the
                              last 30 days.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.conversionRateResultsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.conversionRateResults
                      )}
                    </h1>
                    <p className="mb-0">Last 30 Days</p>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4" className="mt-3 mb-3 mb-md-0">
                <Card style={{ height: "100%" }} className="border shadow-sm">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="m-0">LEAN Consultations</h4>
                      </Col>
                      <Col xs="auto">
                        <Whisper
                          placement="autoHorizontal"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Total number of LEAN consultations performed
                              during the selected time range.
                            </Tooltip>
                          }
                        >
                          <div
                            className="cursor-pointer"
                            style={{ lineHeight: 1, fontSize: 18 }}
                          >
                            <i className="mdi mdi-information-outline"></i>
                          </div>
                        </Whisper>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h1 className="mb-4 display-4">
                      {this.state.consultationsLoading ? (
                        <>
                          <Spinner size="sm" color="medium"></Spinner>
                        </>
                      ) : (
                        this.state.consultations
                      )}
                    </h1>

                    <Sparklines
                      style={{ maxHeight: 20, width: "100%" }}
                      data={this.state.consultationsByDay}
                    >
                      <SparklinesBars
                        style={{
                          fill: "var(--primary)",
                        }}
                      />
                    </Sparklines>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mt--4 mb-5">
            <Row className="justify-content-center">
              <Col xs="12" sm="5" md="4" lg="4">
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Button
                      size="sm"
                      outline
                      color="dark"
                      className="btn-icon-only"
                      onClick={() => {
                        if (this.state.activeView == "member") {
                          return this.setState({
                            activeView: "lead",
                          });
                        }

                        return this.setState({
                          activeView: "member",
                        });
                      }}
                    >
                      <i className="mdi mdi-chevron-left"></i>
                    </Button>
                  </Col>
                  <Col xs="">
                    <div className="text-center">
                      <div
                        className={`rounded d-inline-block mr-2 ${
                          this.state.activeView == "member"
                            ? "bg-primary"
                            : "bg-light"
                        }`}
                        style={{ width: 10, height: 10 }}
                      ></div>
                      <div
                        className={`rounded d-inline-block ${
                          this.state.activeView == "lead"
                            ? "bg-primary"
                            : "bg-light"
                        }`}
                        style={{ width: 10, height: 10 }}
                      ></div>
                    </div>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      outline
                      color="dark"
                      className="btn-icon-only"
                      onClick={() => {
                        if (this.state.activeView == "member") {
                          return this.setState({
                            activeView: "lead",
                          });
                        }

                        return this.setState({
                          activeView: "member",
                        });
                      }}
                    >
                      <i className="mdi mdi-chevron-right"></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <div className="col">
              <h3 className="mb-0">Franchise Development Tasks</h3>
              <hr className="mt-2 mb-3"></hr>
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All Upcoming & Overdue Tasks",
                      id: "All Upcoming & Overdue Tasks",
                    },
                    {
                      name: "All Incomplete Tasks",
                      id: "All Incomplete Tasks",
                    },
                    {
                      name: "All Completed Tasks",
                      id: "All Completed Tasks",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center ">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      <div>
                        {this.state.result?.start && this.state.result?.end ? (
                          <>
                            Showing{" "}
                            {StringUtils.numberFormat(this.state.result?.start)}
                            -{StringUtils.numberFormat(this.state.result?.end)}
                            {this.state.result?.total ? (
                              <>
                                &nbsp;of{" "}
                                {StringUtils.numberFormat(
                                  this.state.result?.total
                                )}{" "}
                                tasks
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>---</>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">Subject</h4>
                      </Col>

                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Due Date</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Priority</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Status</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingTasks || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultTasks?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No tasks found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultTasks?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.searchResultTasks.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.setState({
                                detailTask: c,
                                detailOpen: true,
                              });
                            }}
                          >
                            <Row className="align-items-center">
                              <Col xs="6" sm="6" md="4">
                                <h4 className="mb-0">{c.subject}</h4>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.type}
                                  {c?.customer
                                    ? ` For ${c?.customer?.name}`
                                    : ""}
                                  {c?.lead ? ` For ${c?.lead?.name}` : ""}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {this.formatDueDate(c.dueDate, c.status)}
                              </Col>
                              <Col xs="6" sm="6" md="3">
                                {c.priority == "High" ? (
                                  <i className="mdi mdi-exclamation-thick text-danger pr-2"></i>
                                ) : null}
                                {c.priority}
                              </Col>
                              <Col xs="6" sm="6" md="3">
                                {this.formatStatus(c.status)}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.tasks?.length &&
                        !this.state.loadingTasks ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No franchise development tasks match your
                                search.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.tasks?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.tasks.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.setState({
                                detailTask: c,
                                detailOpen: true,
                              });
                            }}
                          >
                            <Row className="align-items-center">
                              <Col xs="6" sm="6" md="4">
                                <h4 className="mb-0">{c.subject}</h4>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.type}
                                  {c?.customer
                                    ? ` for ${c?.customer?.name}`
                                    : ""}
                                  {c?.lead ? ` for ${c?.lead?.name}` : ""}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {this.formatDueDate(c.dueDate, c.status)}
                              </Col>
                              <Col xs="6" sm="6" md="3">
                                {c.priority == "High" ? (
                                  <i className="mdi mdi-exclamation-thick text-danger pr-2"></i>
                                ) : null}
                                {c.priority}
                              </Col>
                              <Col xs="6" sm="6" md="3">
                                {this.formatStatus(c.status)}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

        <TaskDetailDrawer
          preventModify={true}
          preventDelete={true}
          store={this.state.store}
          open={this.state.detailOpen}
          task={this.state.detailTask}
          onClose={() => {
            this.setState({
              detailOpen: false,
              detailTask: null,
            });
          }}
        ></TaskDetailDrawer>
      </>
    );
  }
  f;
}

export default StorefrontHomeView;
