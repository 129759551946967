import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { DatePicker, SelectPicker, Modal, Drawer } from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import EditBoxDrawer from "components/Drawers/EditBoxDrawer";

class CustomerBoxWidget extends React.Component {
  state = {
    selectedMeals: [],
    loadingMeals: true,
    modifySelectedMeals: [],
    modifySelectedQty: 0,
    allMeals: [],
    filteredAllMeals: [],
  };

  handleSearchChange(e) {
    const { name, value } = e.target;

    let filteredAll = [];

    if (!value) {
      filteredAll = this.state.allMeals;
    } else {
      filteredAll = _.filter(this.state.allMeals, (m) => {
        return m.name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
    }

    this.setState({
      [name]: value,
      filteredAllMeals: filteredAll,
    });
  }

  toggleModal() {
    this.setState({
      open: !this.state.open,
    });
  }

  incrementMeal(meal, quantity) {
    let i = _.findIndex(this.state.modifySelectedMeals, { _id: meal._id });

    if (i < 0) {
      let m = _.clone(meal);
      let cats = [];

      for (let i = 0; i < m.categories.length; i++) {
        let c = _.clone(m.categories[i]);

        c.meals = [];

        cats.push(c);
      }

      m.quantity = 1;
      m.categories = cats;

      let meals = this.state.modifySelectedMeals;

      meals.push(m);

      meals = _.sortBy(meals, "name");

      this.setState({ modifySelectedMeals: meals }, () => {
        this.updateModifyQty();
      });

      return;
    }

    let meals = this.state.modifySelectedMeals;

    meals[i].quantity = meals[i].quantity + quantity;

    if (meals[i].quantity <= 0) {
      meals = _.filter(meals, (m) => {
        return m._id != meal._id;
      });
    }

    meals = _.sortBy(meals, "name");

    this.setState({ modifySelectedMeals: meals }, () => {
      this.updateModifyQty();
    });
  }

  updateModifyQty() {
    let qty = 0;

    for (let i = 0; i < this.state.modifySelectedMeals.length; i++) {
      qty += this.state.modifySelectedMeals[i].quantity;
    }

    console.log(this.props.customer?.subscription?.plan);

    this.setState({ modifySelectedQty: qty });
  }

  openModal() {
    let selected = this.state.selectedMeals.map((m) => {
      return _.clone(m);
    });

    selected = _.filter(selected, (m) => {
      return _.findWhere(this.state.allMeals, { _id: m._id });
    });

    selected = _.sortBy(selected, "name");

    this.setState(
      {
        open: !this.state.open,
        modifySelectedMeals: selected,
      },
      () => {
        this.updateModifyQty();
      }
    );
  }

  clearAll() {
    this.setState({
      modifySelectedMeals: [],
      modifySelectedQty: 0,
    });
  }

  getSelectedQuantity(meal) {
    if (!_.findWhere(this.state.modifySelectedMeals, { _id: meal?._id })) {
      return 0;
    }

    let m = _.findWhere(this.state.modifySelectedMeals, {
      _id: meal?._id,
    });

    console.log(m.quantity);

    return m.quantity ? m.quantity : 0;
  }

  loadMeals(customer) {
    console.log("here");

    if (
      !customer ||
      !customer?.subscription?.bom ||
      !customer?.subscription?.bom.length
    ) {
      this.setState({
        loadingMeals: false,
      });

      return;
    }

    console.log("here");

    this.setState({ loadingMeals: true });

    APIV2.getMeals().then(
      (data) => {
        let selectedMeals = [];
        let selectedQuantity = 0;

        for (let i = 0; i < customer?.subscription?.bom.length; i++) {
          let meal = _.findWhere(data.data.meals, {
            _id: customer?.subscription?.bom[i].mealID,
          });

          if (meal) {
            meal.quantity = customer?.subscription?.bom[i].quantity;

            selectedQuantity = selectedQuantity + meal.quantity;

            selectedMeals.push(meal);
          }
        }

        console.log(selectedMeals);

        this.setState({ loadingMeals: false, selectedMeals, selectedQuantity });
      },
      (e) => {
        this.setState({ loadingMeals: false });
      }
    );

    APIV2.getStoreByID(customer.storeID).then(
      (data) => {
        let store = data.data.store;

        if (
          store?.menus?.meals?.hasOwnProperty(
            customer?.subscription?.plan?.planTypeID
          )
        ) {
          let menu =
            store?.menus?.meals[customer?.subscription?.plan?.planTypeID]
              ?.active;

          let meals = _.sortBy(menu?.meals, "name");

          if (meals) {
            this.setState({ allMeals: meals, filteredAllMeals: meals });
          }
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.loadMeals(this.props.customer);
    }
  }

  componentDidMount() {
    if (this.props.customer) {
      this.loadMeals(this.props.customer);
    }
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  submit() {
    if (
      this.state.modifySelectedQty !=
      this.props.customer?.subscription?.plan?.quantity
    ) {
      return;
    }

    let bom = this.state.modifySelectedMeals.map((m) => {
      return {
        mealID: m._id,
        quantity: m.quantity,
      };
    });

    this.setState({
      submitting: true,
    });

    APIV2.updateCustomerBOM(
      this.props.customer?.storeID,
      this.props.customer?._id,
      bom
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.toggleModal();
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <>
        <Card
          className={`${this.props.flat ? "border-0" : "border shadow"} mb-4`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">
                  Box{" "}
                  {!this.state.loadingMeals ? (
                    <>
                      {this.state.selectedQuantity ? (
                        <Badge
                          color={
                            this.state.selectedQuantity ==
                            this.props.customer?.subscription?.plan?.quantity
                              ? "success"
                              : ""
                          }
                          className={`ml-2 ${
                            this.state.selectedQuantity !=
                            this.props.customer?.subscription?.plan?.quantity
                              ? "bg-yellow text-dark"
                              : ""
                          }`}
                          style={{ position: "relative", top: "-1px" }}
                        >
                          {this.state.selectedQuantity !=
                          this.props.customer?.subscription?.plan?.quantity ? (
                            <>
                              <i
                                className="mdi mdi-checkbox-blank-circle mr-1"
                                style={{ position: "top", top: "1px" }}
                              />
                            </>
                          ) : null}
                          {this.state.selectedQuantity} Selected
                        </Badge>
                      ) : (
                        <Badge
                          color=""
                          className={`ml-2 bg-yellow text-dark`}
                          style={{ position: "relative", top: "-1px" }}
                        >
                          <i
                            className="mdi mdi-checkbox-blank-circle mr-1"
                            style={{ position: "top", top: "1px" }}
                          />
                          Box Empty
                        </Badge>
                      )}
                    </>
                  ) : null}
                </h3>
              </div>
              <Col xs="auto">
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => {
                    this.openModal();
                  }}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="bg-transparent">
            {this.state.loadingMeals ? (
              <Row className="align-items-center">
                <Col xs="12" className="mb-3 text-center py-4">
                  <Spinner color="medium" size="sm"></Spinner>
                </Col>
              </Row>
            ) : (
              <Row className="align-items-center">
                {!this.state.selectedMeals?.length ? (
                  <Col xs="12">Box is empty</Col>
                ) : null}
                {this.state.selectedMeals.map((meal, i) => (
                  <Col
                    key={i}
                    xs="12"
                    md={this.props.grid === false ? 12 : 6}
                    className={`${
                      (i + 1) % 2 == 0 && this.props.grid !== false
                        ? "border-md-left pl-md-0"
                        : `${this.props.grid !== false ? "pr-md-0" : ""}`
                    }`}
                  >
                    <div
                      className={`${
                        (i + 1) % 2 == 0 && this.props.grid !== false
                          ? "pl-md-3"
                          : "pr-md-3"
                      }`}
                    >
                      <SelectedMealCheckoutRow
                        meal={meal}
                        selected={meal.quantity}
                        modifiable={false}
                      ></SelectedMealCheckoutRow>
                    </div>
                    {i != this.state.selectedMeals.length - 1 ? (
                      <hr
                        className={`my-1 ${
                          i == this.state.selectedMeals.length - 2
                            ? "d-md-none"
                            : ""
                        }`}
                      ></hr>
                    ) : null}
                  </Col>
                ))}
              </Row>
            )}
          </CardBody>
        </Card>
        <EditBoxDrawer
          customer={this.props.customer}
          bom={this.props.customer?.subscription?.bom}
          open={this.state.open}
          quantity={this.props.customer?.subscription?.plan?.quantity}
          planTypeID={this.props.customer?.subscription?.plan?.planTypeID}
          onClose={() => {
            this.toggleModal();
          }}
          onSubmit={(bom) => {
            return new Promise((resolve) => {
              APIV2.updateCustomerBOM(
                this.props.customer?.storeID,
                this.props.customer?._id,
                bom
              )
                .then(
                  (data) => {
                    PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

                    this.toggleModal();
                  },
                  (e) => {}
                )
                .finally(() => {
                  resolve();
                });
            });
          }}
        ></EditBoxDrawer>
      </>
    );
  }
}

export default withRouter(CustomerBoxWidget);
