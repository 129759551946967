import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import _ from "underscore";

class MealList extends React.Component {
  state = {
    active: {
      id: "Lifestyle",
      name: "Lifestyle",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
  };

  componentWillMount() {
    APIV2.getProductGroups().then(
      (data) => {
        if (data.data && data.data.planTypes) {
          let active = data.data.planTypes.length ? data.data.planTypes[0] : {};

          active.id = active.name;

          let options = data.data.planTypes.map((p) => {
            return {
              id: p.name,
              name: p.name,
              _id: p._id,
            };
          });

          if (_.findWhere(options, { id: this.state.active?.id })) {
            active = _.findWhere(options, { id: this.state.active?.id });
          }

          this.setState({
            productGroups: data.data.planTypes,
            options: options,
            loadingGroups: false,
          });

          this.handleTabChange(active, true);
        }
      },
      () => {
        window.alert("Failed to get product groups. Please try again.");
      }
    );
  }

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    PubSub.subscribe(Event.MEAL.DELETED, (data) => {
      let g = filter(this.state.meals, (a) => {
        return a.id != data._id;
      });

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.CREATED, (m) => {
      let g = this.state.meals;

      g.push(m);

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.MODIFIED, (a) => {
      let g = this.state.meals;
      let idx = findIndex(g, { _id: a._id });

      g[idx] = a;

      this.setState({
        meals: g,
      });
    });

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    } else {
      this.handleTabChange(this.state.active);
    }
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  handleTabChange(tab, force = false) {
    if (tab?.id == this.state.active?.id && !force) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(tab.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadMeals(tab);
  }

  loadMeals(group) {
    if (!group) {
      return;
    }

    this.setState({
      meals: [],
      active: group,
      loadingMeals: true,
    });

    APIV2.getMealsByProductGroup(group._id)
      .then(
        (data) => {
          if (data && data.data) {
            let meals = [];

            if (data.data.meals.length) {
              meals = data.data.meals;
            }

            meals = _.sortBy(meals, "name");

            this.setState({
              meals: meals,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading " +
              (group ? group.name : "the") +
              " meals. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingMeals: false,
        });
      });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Meals"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.props.history.push(
                  `/${
                    window.location.href.includes("global")
                      ? "global"
                      : "production"
                  }/meals/new`
                );
              },
            }}
            sticky={true}
            tabs={
              <CardSelectorHeader
                loading={this.state.loadingGroups}
                value={this.state.active}
                options={this.state.options ? this.state.options : []}
                onChange={(option) => {
                  this.handleTabChange(option);
                }}
                compact={true}
              ></CardSelectorHeader>
            }
          />
          <div
            style={{
              marginLeft: -15,
              marginRight: -15,
            }}
            className="mt--4 bg-white border-bottom"
          >
            {this.state.active ? (
              <Table
                className="table-hover mb-0"
                style={{ minHeight: "300px" }}
                responsive={true}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col-auto"></th>
                    <th scope="col">Name</th>
                    <th scope="col" className="d-md-table-cell d-none">
                      Categories
                    </th>
                    <th scope="col" className="d-lg-table-cell d-none">
                      Calories
                    </th>
                    <th scope="col" className="d-lg-table-cell d-none">
                      Carbs
                    </th>
                    <th scope="col" className="d-lg-table-cell d-none">
                      Protein
                    </th>
                    <th scope="col" className="d-xl-table-cell d-none">
                      Fat
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {this.state.loadingMeals ? (
                    <tr>
                      <td colSpan="8">Loading...</td>
                    </tr>
                  ) : null}
                  {!this.state.loadingMeals && !this.state.meals.length ? (
                    <tr>
                      <td colSpan="8">
                        Get started by adding the first{" "}
                        {this.state.active ? this.state.active.name : ""}
                        &nbsp;meal.
                      </td>
                    </tr>
                  ) : null}
                  {this.state.meals.map((meal, i) => (
                    <MealRow key={i} meal={meal}></MealRow>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light"></thead>
                <tbody>
                  <tr>
                    <td>Loading...</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default MealList;
