import React, { useEffect, useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { Button, Col, Row } from "reactstrap";
import moment from "moment-timezone";
import _, { map } from "underscore";

const CalendarWeekView = ({
  timeslotComponent,
  onChange,
  loading,
  data,
  compareKey,
  emptyString,
  onRefresh,
  columnHeight,
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewMode, setViewMode] = useState("calendar");

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    //showDetailsHandle(dayStr);
  };

  useEffect(() => {
    if (typeof onChange == "function") {
      let startDate = currentMonth;
      const endDate = addDays(currentMonth, 6);

      onChange({ startDate, endDate });
    }
  }, [currentMonth]);

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";

    let startDate = currentMonth;
    const endDate = addDays(currentMonth, 6);

    let val = `${moment(startDate?.toISOString()).format(
      "MM/DD/YY"
    )} - ${moment(endDate?.toISOString()).format("MM/DD/YY")}`;

    // console.log("selected day", selectedDate);
    return (
      <div className="p-3 border-bottom">
        <Row className="align-items-center">
          {/*<Col xs="auto">
            <Button
              size="sm"
              onClick={() => setViewMode("calendar")}
              outline={viewMode != "calendar"}
              className="btn-icon-only"
            >
              <i className="mdi mdi-calendar"></i>
    </Button>
          <Button
              size="sm"
              onClick={() => setViewMode("list")}
              outline={viewMode != "list"}
              className="btn-icon-only"
              disabled
            >
              <i className="mdi mdi-format-list-bulleted-square"></i>
    </Button>
  </Col>*/}
          <Col xs="" className="text-left">
            <h3 className="m-0">{val}</h3>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              onClick={() => {
                if (typeof onRefresh == "function") {
                  let startDate = currentMonth;
                  const endDate = addDays(currentMonth, 6);

                  onRefresh({ startDate, endDate });
                }
              }}
              outline
              className="btn-icon-only"
            >
              <i className="mdi mdi-refresh"></i>
            </Button>
            <Button
              size="sm"
              onClick={() => changeWeekHandle("prev")}
              outline
              className="btn-icon-only"
            >
              <i className="mdi mdi-chevron-left"></i>
            </Button>
            <Button
              size="sm"
              onClick={() => changeWeekHandle("next")}
              outline
              className="btn-icon-only"
            >
              <i className="mdi mdi-chevron-right"></i>
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const getDataForDay = (day) => {
    if (!data?.length) {
      return [];
    }

    const dateString = moment(day?.toISOString());

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let filtered = _.filter(data, (val) => {
      return (
        moment.utc(val[compareKey]).tz(timezone)?.format("YYYY-MM-DD") ==
        dateString?.format("YYYY-MM-DD")
      );
    });

    filtered = _.sortBy(filtered, (v) => {
      return moment(v?.startsAt)?.toDate();
    });

    return filtered;
  };

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = currentMonth;
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className="calcol calcol-center bg-superlight text-dark border-bottom font-weight-bold"
          key={i}
        >
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days calrow d-block">{days}</div>;
  };
  const renderCells = () => {
    const startDate = currentMonth;
    const endDate = addDays(startDate, 6);
    const dateFormat = "M/d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`calcol cell pt-5 ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            style={{ height: columnHeight ? columnHeight : "" }}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <div className="number">{formattedDate}</div>

            {loading ? (
              <>
                <div
                  className="m-2 rounded skeleton"
                  style={{ height: 104 }}
                ></div>
                <div
                  className="m-2 rounded skeleton"
                  style={{ height: 104 }}
                ></div>
              </>
            ) : (
              <>
                {/** TODO: render appointments here */}
                {getDataForDay(cloneDay)?.length ? (
                  <>
                    {getDataForDay(cloneDay)?.map((record, i) => (
                      <div key={i}>{timeslotComponent(record)}</div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="m-2 p-2 border rounded text-center">
                      <p
                        className="m-0"
                        style={{ fontSize: 16, whiteSpace: "pre-wrap" }}
                      >
                        {emptyString ?? "Nothing scheduled"}
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="calrow d-block" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return <div></div>;
  };
  return (
    <div className="calendar">
      {renderHeader()}
      <div className="calbody">
        {renderDays()}
        {renderCells()}
      </div>
      {renderFooter()}
    </div>
  );
};

export default CalendarWeekView;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
