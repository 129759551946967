/**
 * Basic Pub/Sub protocol
 *
 * Author: Brandon Hudson
 * Created: 12-15-2019
 */

import StringUtils from "./StringUtils";
import _ from "underscore";

class PubSub {
  static registry = {};

  /**
   * Publish to a topic
   *
   * @param name
   * @param data
   */
  static publish(name, data = null) {
    if (!this.registry[name]) return;
    this.registry[name].forEach((subscriber) => {
      subscriber.fn.call(null, data);
    });
  }

  /**
   * Subscribe to a topic
   *
   * @param name
   * @param fn
   */
  static subscribe(name, fn = null) {
    let subscriber = StringUtils.uuid();

    if (!this.registry[name]) {
      this.registry[name] = [
        {
          id: subscriber,
          fn,
        },
      ];
    } else {
      this.registry[name].push({
        id: subscriber,
        fn,
      });
    }

    return subscriber;
  }

  static removeSubscriber(name, id) {
    const idx = _.findIndex(this.registry[name], { id });

    if (idx >= 0) {
      this.registry[name] = _.filter(this.registry[name], (v) => {
        return v.id != id;
      });
    }
  }
}

export default PubSub;
