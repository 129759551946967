import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Badge,
  Row,
  Col,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import _ from "underscore";
import { Checkbox } from "rsuite";
import StringUtils from "../lib/StringUtils";
import CustomerOrderDrawer from "./Drawers/CustomerOrderDrawer";

class CustomerPickupListRow extends React.Component {
  state = {};

  render() {
    return (
      <>
        <div
          className={`px-3 py-2 ${
            this.props.selected
              ? "bg-primary-ultralight"
              : "bg-superlight--hover"
          } cursor-pointer`}
        >
          <Row className="align-items-center">
            <Col xs="auto">
              <Checkbox
                checked={this.props.selected}
                onChange={(v, c) => {
                  this.props.onSelect(c);
                }}
              ></Checkbox>
            </Col>
            <Col
              xs=""
              className="pl-0"
              onClick={() => {
                PubSub.publish(Event.ORDER.OPEN, this.props.order);
              }}
            >
              <Row className="align-items-center">
                <Col xs="12" sm="6" md="4" className="mb-1 mb-md-0">
                  <h4 className="mb-0 text-capitalize">
                    {this.props.order?.customer?.name}
                  </h4>
                  <p className="mb-0" style={{ fontSize: "13px" }}>
                    {this.props.order?.customer?.phone
                      ? StringUtils.formatPhoneNumber(
                          this.props.order?.customer?.phone.replace("+1", "")
                        )
                      : "--"}
                  </p>
                </Col>
                <Col xs="12" sm="6" md="3" className="mb-1 mb-md-0">
                  <div style={{ fontSize: "15px" }}>
                    {this.props.order?.subscription?.plan?.name
                      ? this.props.order?.subscription?.plan?.name
                      : "--"}
                  </div>
                </Col>
                <Col xs="12" sm="6" md="3" className="mb-1 mb-sm-0">
                  {this.props.order?.subscription?.currentFulfillmentCount ? (
                    <>
                      {this.props.order?.subscription?.currentFulfillmentCount >
                      0
                        ? `Week ${
                            this.props.order?.subscription
                              ?.currentFulfillmentCount + 1
                          }`
                        : "First Fulfillment"}
                    </>
                  ) : (
                    <>--</>
                  )}
                </Col>
                <Col xs="12" sm="6" md="2">
                  {this.props.order?.invoice?.status != "UNPAID" ? (
                    <>
                      <Badge color="medium" className="">
                        {this.props.order?.invoice?.status}
                      </Badge>
                    </>
                  ) : (
                    <>
                      <Badge color="" className="bg-yellow text-dark">
                        {this.props.order?.invoice?.status}
                      </Badge>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(CustomerPickupListRow);
