import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibrarySectionEditDrawer from "components/Drawers/LibrarySectionEditDrawer";
import LibraryTopicEditDrawer from "components/Drawers/LibraryTopicEditDrawer";
import { Link } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Capacitor } from "@capacitor/core";

class LibraryTopic extends React.Component {
  state = {
    loadingModule: true,
    contentState: EditorState.createEmpty(),
    loadingTopic: true,
    openSection: false,
    openTopic: false,
    module: null,
    topic: null,
    content: "",
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  onContentChange(editorState) {
    this.setState({
      contentState: editorState,
    });
  }

  uploadCallback(file) {
    return new Promise((resolve, reject) => {
      if (file) {
        const data = new FormData();

        data.append("file", file);

        APIV2.uploadCDNImage(data).then(
          (data) => {
            let url = data.data.data.url;

            console.log(url);

            resolve({ data: { link: url } });
          },
          (e) => {
            reject(e);
          }
        );
      }
    });
  }

  getPreviousTopic() {
    if (!this.state.module || !this.state.topic) {
      return null;
    }

    let module = this.state.module;
    let allTopics = [];

    for (let i = 0; i < module?.sections?.length; i++) {
      const section = module.sections[i];

      for (let j = 0; j < section?.topics?.length; j++) {
        allTopics.push(section.topics[j].topic);
      }
    }

    const idx = _.findIndex(allTopics, { _id: this.state.topic?._id });

    if (idx <= 0 || allTopics?.length == 1) {
      return null;
    }

    return allTopics[idx - 1];
  }

  getNextTopic() {
    if (!this.state.module || !this.state.topic) {
      return null;
    }

    let module = this.state.module;
    let allTopics = [];

    for (let i = 0; i < module?.sections?.length; i++) {
      const section = module.sections[i];

      for (let j = 0; j < section?.topics?.length; j++) {
        allTopics.push(section.topics[j].topic);
      }
    }

    const idx = _.findIndex(allTopics, { _id: this.state.topic?._id });

    console.log(idx);

    if (idx < 0 || allTopics?.length == 1 || idx == allTopics?.length - 1) {
      return null;
    }

    return allTopics[idx + 1];
  }

  /**
   * Fetches all categories.
   */
  getModule(moduleID) {
    this.setState({
      loadingModule: true,
    });

    APIV2.getLibraryModuleByID(moduleID)
      .then(
        (data) => {
          let mod = data.data.module;

          for (let i = 0; i < mod?.sections?.length; i++) {
            mod.sections[i].open = true;
          }

          this.setState({
            module: mod,
          });
        },
        () => {
          window.alert("Failed to get module. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingModule: false,
        });
      });
  }

  getTopic() {
    this.setState({
      loadingTopic: true,
    });

    APIV2.getLibraryTopicByID(this.props.match?.params?.topicID)
      .then(
        (data) => {
          let mod = data.data.topic;

          if (mod?.content) {
            const contentBlock = htmlToDraft(mod?.content);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              const contentState2 = EditorState.createWithContent(contentState);

              this.setState({ contentState: contentState2 });
            }
          }

          this.setState({
            topic: mod,
          });
        },
        () => {
          window.alert("Failed to get topic. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingTopic: false,
        });
      });
  }

  onModifyTopic(topic) {
    this.setState({ topic, openTopic: false });
  }

  saveContent() {
    this.setState({
      submitting: true,
    });

    let content = draftToHtml(
      convertToRaw(this.state.contentState.getCurrentContent())
    );

    APIV2.modifyLibraryTopicContent(this.state.topic?._id, content)
      .then(
        (data) => {
          this.setState({
            topic: data.data.topic,
            editMode: false,
          });
        },
        (e) => {
          alert("Unable to save topic. Please try again.");
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  componentDidMount() {
    this.getTopic();

    const urlParams = new URLSearchParams(window.location.search);
    const moduleID = urlParams.get("moduleID");
    const sectionID = urlParams.get("sectionID");

    if (moduleID) {
      this.getModule(moduleID);

      this.setState({ sectionID, moduleID });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.topicID != prevProps?.match?.params?.topicID
    ) {
      this.getTopic();

      this.setState({
        editMode: false,
        contentState: EditorState.createEmpty(),
      });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const moduleID = urlParams.get("moduleID");
    const sectionID = urlParams.get("sectionID");

    if (moduleID != this?.state?.moduleID) {
      this.getModule(moduleID);

      this.setState({ moduleID });
    }

    if (sectionID != this.state?.sectionID) {
      this.setState({ sectionID });
    }
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div
          className="bg-white border-bottom px-4 py-4 py-md-6"
          style={{ marginTop: "5rem" }}
        >
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="">
              {this.state.loadingTopic ? (
                <>
                  <div
                    style={{
                      height: 20,
                      width: "100%",
                      maxWidth: 300,
                      marginBottom: "1.5rem",
                      borderRadius: 6,
                    }}
                    className="skeleton"
                  ></div>
                  <div
                    style={{
                      height: 35,
                      width: "100%",
                      maxWidth: 500,
                      borderRadius: 6,
                      marginBottom: "1rem",
                    }}
                    className="skeleton"
                  ></div>
                  <div
                    style={{
                      height: 20,
                      width: "100%",
                      marginBottom: "0.25rem",
                      borderRadius: 6,
                    }}
                    className="skeleton"
                  ></div>
                </>
              ) : (
                <>
                  <p className="text-muted mb-2">
                    <div className="d-inline-block pr-2">
                      <Link to="/university/library">Library</Link>
                    </div>
                    {this.state.module && (
                      <>
                        <span>-</span>
                        <div className="d-inline-block pl-2 pr-2">
                          <Link
                            to={`/university/library/modules/${this.state.module?._id}`}
                          >
                            {this.state.module?.name}
                          </Link>
                        </div>
                      </>
                    )}
                    <span>-</span>
                    <div className="d-inline-block pl-2">
                      {this.state.topic?.name}
                    </div>
                  </p>
                  <h1 className="m-0 display-3 text-dark">
                    {this.state.topic?.name}
                  </h1>
                  {this.state.topic?.description && (
                    <p className="m-0 text-dark font-weight-500">
                      {this.state.topic.description}
                    </p>
                  )}
                </>
              )}
            </Col>
            {this.isGlobal() && this.state.editMode && (
              <Col xs="auto">
                <Button
                  color="secondary"
                  outline
                  className="btn-icon-only"
                  onClick={() => {
                    this.setState({ openTopic: true });
                  }}
                >
                  <i className="mdi mdi-pencil"></i>
                </Button>
              </Col>
            )}
          </Row>
        </div>
        <div className="bg-white pt-4 pb-6 border-bottom">
          <Container fluid>
            {/* Table */}
            {this.isGlobal() && (
              <>
                <div className="text-right mb-4">
                  {!this.state.editMode ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({ editMode: !this.state.editMode });
                        }}
                        color="dark"
                        outline
                        className=""
                      >
                        {this.state.editMode ? "Done Editing" : "Edit"}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({ editMode: !this.state.editMode });
                        }}
                        color="dark"
                        outline
                        className=""
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.saveContent();
                        }}
                        color="primary"
                        disabled={this.state.submitting}
                        className=""
                      >
                        {this.state.submitting ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
            {this.isGlobal() && this.state.editMode ? (
              <>
                <Editor
                  placeholder="Add content here..."
                  toolbarClassName="pln-richtext-toolbar"
                  editorClassName="pln-richtext-editor"
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "embedded",
                      "emoji",
                      "image",
                      "remove",
                      "history",
                    ],
                    image: {
                      uploadEnabled: true,
                      uploadCallback: this.uploadCallback.bind(this),
                      alignmentEnabled: false,
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "60%",
                      },
                    },
                    embedded: {
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      embedCallback: undefined,
                      defaultSize: {
                        height: "auto",
                        width: "60%",
                      },
                    },
                  }}
                  editorState={this.state.contentState}
                  onEditorStateChange={this.onContentChange.bind(this)}
                ></Editor>
              </>
            ) : (
              <>
                {this.state.loadingTopic ? (
                  <>
                    <div style={{ minHeight: 400 }}>
                      <Card className="border">
                        <CardBody>
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <Spinner color="dark"></Spinner>
                            </Col>
                            <Col xs="">
                              <h2 className="m-0">Hang Tight...</h2>
                              <p className="m-0">Fetching the topic now.</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{ minHeight: 400 }}
                      className="plnu-topic"
                      dangerouslySetInnerHTML={{
                        __html: this.state.topic?.content
                          ? this.state.topic?.content
                          : "(empty)",
                      }}
                    ></div>
                  </>
                )}
              </>
            )}
          </Container>
        </div>
        {this.getNextTopic() || this.getPreviousTopic() ? (
          <>
            <div className="bg-white pt-4 pb-4 border-bottom">
              <Container fluid>
                <Row>
                  <Col xs="">
                    {this.getPreviousTopic() && (
                      <>
                        <h3 className="m-0 font-weight-normal">
                          <Link
                            to={`/university/library/topics/${
                              this.getPreviousTopic()?._id
                            }?moduleID=${this.state.module?._id}`}
                          >
                            <span
                              className="mr-1"
                              style={{ position: "relative", top: 1 }}
                            >
                              <i className="mdi mdi-chevron-left"></i>
                            </span>
                            {this.getPreviousTopic()?.name}
                          </Link>
                        </h3>
                      </>
                    )}
                  </Col>
                  <Col xs="" className="text-right">
                    {this.getNextTopic() && (
                      <>
                        <h3 className="m-0 font-weight-normal">
                          <Link
                            to={`/university/library/topics/${
                              this.getNextTopic()?._id
                            }?moduleID=${this.state.module?._id}`}
                          >
                            {this.getNextTopic()?.name}
                            <span
                              className="ml-1"
                              style={{ position: "relative", top: 1 }}
                            >
                              <i className="mdi mdi-chevron-right"></i>
                            </span>
                          </Link>
                        </h3>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        ) : null}

        <LibraryTopicEditDrawer
          open={this.state.openTopic}
          topic={this.state.topic}
          onModify={(topic) => {
            this.onModifyTopic(topic);
          }}
          onClose={() => {
            this.setState({ openTopic: false });
          }}
        ></LibraryTopicEditDrawer>
      </>
    );
  }
}

export default LibraryTopic;
