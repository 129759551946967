import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment, { isMoment } from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link, withRouter } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";

class ProductOrderPalletSheetView extends React.Component {
  state = {};

  loadOrder() {
    this.setState({
      loadingOrder: true,
    });

    APIV2.getProductionOrderByID(this.props?.match?.params?.orderID)
      .then(
        (data) => {
          this.setState({
            order: data?.data?.order,
          });

          console.log(data.data.order);

          this._loadOrderTable(data.data.order);
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the order. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrder: false,
          loading: false,
        });
      });
  }

  _loadOrderTable(order) {
    if (!order) {
      return;
    }

    if (order?.fulfillmentReportID) {
      let planKeys = _.keys(order?.summary?.planAnalysis);
      let plans = [];

      let totalQuantity = 0;

      for (let i = 0; i < planKeys?.length; i++) {
        const plan = planKeys[i];
        const qty = order?.summary?.planAnalysis[plan];

        plans.push({
          name: plan,
          quantity: qty,
        });

        totalQuantity += qty;
      }

      plans = _.sortBy(plans, "name");

      this.setState({
        plans,
        totalQuantity,
      });

      return;
    }

    let vals = _.values(order?.menus?.meals);

    console.log(vals);

    let allMeals = [];

    // Parse all options off the meal menus
    for (let i = 0; i < vals?.length; i++) {
      if (vals[i].hasOwnProperty("active") && vals[i].active?.meals?.length) {
        const menu = vals[i].active;

        let activeMenuMeals = vals[i].active?.meals;

        // Check for an upcoming transition for new meals to be orderable
        if (menu?.menuTransition) {
          let transitionDate = moment(
            menu?.menuTransition?.transitionDate
          ).toDate();

          // Make new meals available or order one week out
          if (moment().add(1, "week").toDate() >= transitionDate) {
            let swaps = menu?.menuTransition?.newMenu?.meals?.map((item) => {
              item.upcoming = true;

              return item;
            });

            let retiring = menu?.menuTransition?.retiringMealSwaps.map(
              (item) => {
                return item?.fromMeal;
              }
            );

            for (let j = 0; j < retiring?.length; j++) {
              const idx = _.findIndex(activeMenuMeals, {
                _id: retiring[j]?._id,
              });

              if (idx >= 0) {
                activeMenuMeals[idx].retiring = true;
              }
            }

            swaps = _.filter(swaps, (m) => {
              return !_.findWhere(activeMenuMeals, { _id: m?._id });
            });

            activeMenuMeals = activeMenuMeals.concat(swaps);
          }
        }

        for (let j = 0; j < activeMenuMeals?.length; j++) {
          const meal = activeMenuMeals[j];

          meal.name = meal?.name?.trim();

          // Set as default until it can be calculated
          if (
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku].caseQuantity
          ) {
            meal.caseQuantity = order?.meals[meal?.sku]?.caseQuantity;
          } else {
            meal.caseQuantity = 0;
          }

          if (
            order?.meals?.hasOwnProperty(meal?.sku) &&
            order?.meals[meal?.sku]?.mealQuantity
          ) {
            meal.mealQuantity = order?.meals[meal?.sku]?.mealQuantity;
          } else {
            meal.mealQuantity = 0;
          }

          if (!_.findWhere(allMeals, { _id: meal?._id })) {
            allMeals.push(meal);
          }
        }
      }
    }

    console.log(allMeals);

    let lifestyle = [];
    let athlete = [];
    let leancheats = [];

    let totalMeals = 0;
    let totalCases = 0;
    let lifestyleTotalCases = 0;
    let lifestyleTotalMeals = 0;
    let athleteTotalCases = 0;
    let athleteTotalMeals = 0;
    let leanCheatTotalPacks = 0;
    let leanCheatTotalMeals = 0;

    // Group by type
    for (let i = 0; i < allMeals?.length; i++) {
      const meal = allMeals[i];

      totalMeals += meal?.mealQuantity;

      if (meal?.planType?.name?.includes("Lifestyle")) {
        // Lifestyle meal
        // Lean cheat or lifestyle check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          leanCheatTotalPacks += meal?.caseQuantity;
          leanCheatTotalMeals += meal?.mealQuantity;

          meal.planType.name = "Lean Cheats";
          meal.caseSize = order?.pricing?.leanCheatOrderMultiple;
          meal.mealQuantity = meal.caseQuantity * meal.caseSize;

          leancheats.push(meal);
        } else if (
          !_.findWhere(lifestyle, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          lifestyleTotalCases += meal?.caseQuantity;
          lifestyleTotalMeals += meal?.mealQuantity;
          totalCases += meal?.caseQuantity;

          meal.caseSize = order?.pricing?.lifestyleMealCaseSize;

          lifestyle.push(meal);
        }
      } else {
        // Athlete meal
        // Lean cheat or athlete check
        // TODO: convert to sku check
        if (
          _.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
          !_.findWhere(leancheats, { name: meal?.name })
        ) {
          meal.caseSize = order?.pricing?.leanCheatOrderMultiple;
          meal.planType.name = "Lean Cheats";
          meal.mealQuantity = meal.caseQuantity * meal.caseSize;

          leancheats.push(meal);

          leanCheatTotalPacks += meal?.caseQuantity;
          leanCheatTotalMeals += meal?.mealQuantity;
        } else if (
          !_.findWhere(athlete, { _id: meal?._id }) &&
          !_.findWhere(meal?.categories, { name: "Lean Cheats" })
        ) {
          meal.caseSize = order?.pricing?.athleteMealCaseSize;

          athlete.push(meal);

          athleteTotalCases += meal?.caseQuantity;
          athleteTotalMeals += meal?.mealQuantity;
          totalCases += meal?.caseQuantity;
        }
      }
    }

    // Sort alphabetically

    lifestyle = _.sortBy(lifestyle, "name");
    athlete = _.sortBy(athlete, "name");
    leancheats = _.sortBy(leancheats, "name");

    for (let i = 0; i < leancheats?.length; i++) {
      leancheats[i].freightCaseQuantity = (
        leancheats[i].mealQuantity / order?.pricing?.leanCheatCaseSize
      ).toFixed(2);
    }

    let leanCheatTotalCases =
      leanCheatTotalMeals / order?.pricing?.leanCheatCaseSize;

    totalCases = totalCases + leanCheatTotalCases;

    let totalBoxes = Math.ceil(totalCases);

    let casesPerPallet = order?.pricing?.logisticsPalletCaseQuantity;

    if (
      this.state.store?._id &&
      this.state.pricing?.logisticsCustomPalletCaseQuantity?.hasOwnProperty(
        this.state.store?._id
      )
    ) {
      casesPerPallet =
        this.state.pricing?.logisticsCustomPalletCaseQuantity[
          this.state.store?._id
        ];
    }

    let palletsShipped = Math.ceil(totalCases / casesPerPallet);

    let pallets = [];
    let currentPallet = {
      meals: [],
      hasBoxes: false,
      _id: StringUtils.uuid(),
      hasLeanCheats: false,
      totalCases: 0,
      totalMeals: 0,
      totalBoxes: 0,
    };

    let all = lifestyle.concat(athlete);

    //all = all.concat(leancheats);

    let palletMax = order?.pricing?.logisticsPalletCaseQuantity;

    if (
      this.state.store?._id &&
      this.state.pricing?.logisticsCustomPalletCaseQuantity?.hasOwnProperty(
        this.state.store?._id
      )
    ) {
      palletMax =
        this.state.pricing?.logisticsCustomPalletCaseQuantity[
          this.state.store?._id
        ];
    }

    let onPallet = 0;

    for (let i = 0; i < all?.length; i++) {
      const item = JSON.parse(JSON.stringify(all[i]));

      const { caseQuantity } = item;

      if (!caseQuantity) {
        continue;
      }

      if (onPallet + caseQuantity <= palletMax) {
        currentPallet.meals.push(item);

        currentPallet.totalCases += caseQuantity;
        currentPallet.totalBoxes += caseQuantity;
        currentPallet.totalMeals += item?.mealQuantity;

        onPallet = onPallet + caseQuantity;
      } else {
        // Split to second pallet

        let lastPalletQuantity = palletMax - onPallet;

        item.caseQuantity = lastPalletQuantity;
        item.mealQuantity = item.caseQuantity * item.caseSize;

        currentPallet.totalCases += item.caseQuantity;
        currentPallet.totalBoxes += item.caseQuantity;
        currentPallet.totalMeals += item?.mealQuantity;

        currentPallet.meals.push(JSON.parse(JSON.stringify(item)));

        let newQuantity = caseQuantity - lastPalletQuantity;

        pallets.push(currentPallet);

        onPallet = 0;
        currentPallet = {
          meals: [],
          hasBoxes: false,
          _id: StringUtils.uuid(),
          totalBoxes: 0,
          totalMeals: 0,
          totalCases: 0,
        };

        item.caseQuantity = newQuantity;
        item.mealQuantity = newQuantity * item?.caseSize;

        currentPallet.totalCases += item.caseQuantity;
        currentPallet.totalBoxes += item.caseQuantity;
        currentPallet.totalMeals += item?.mealQuantity;

        currentPallet.meals.push(JSON.parse(JSON.stringify(item)));

        onPallet = onPallet + newQuantity;
      }

      if (onPallet == palletMax) {
        pallets.push(currentPallet);

        onPallet = 0;
        currentPallet = {
          meals: [],
          hasBoxes: false,
          _id: StringUtils.uuid(),
          totalMeals: 0,
          totalCases: 0,
          hasLeanCheats: false,
          totalBoxes: 0,
        };
      }

      //console.log(item);
    }

    if (currentPallet?.meals?.length) {
      pallets.push(currentPallet);
    }

    // TODO: add lean cheats to pallets
    if (leancheats?.length) {
      currentPallet = pallets[pallets?.length - 1];

      for (let i = 0; i < currentPallet?.meals?.length; i++) {
        if (i == 0) {
          onPallet = 0;
        }

        onPallet = onPallet + currentPallet?.meals[i]?.caseQuantity;
      }

      currentPallet.hasLeanCheats = true;
      currentPallet.leanCheats = [];
      currentPallet.leanCheatCaseQuantity = 0;
      currentPallet.leanCheatPackQuantity = 0;

      for (let i = 0; i < leancheats?.length; i++) {
        const cheat = leancheats[i];

        if (!cheat?.caseQuantity) {
          continue;
        }

        if (onPallet + parseFloat(cheat?.freightCaseQuantity) <= palletMax) {
          currentPallet.leanCheats.push(cheat);

          onPallet = onPallet + parseFloat(cheat?.freightCaseQuantity);

          console.log(parseFloat(cheat?.freightCaseQuantity));

          currentPallet.leanCheatCaseQuantity =
            currentPallet.leanCheatCaseQuantity +
            parseFloat(cheat?.freightCaseQuantity);

          currentPallet.totalBoxes += parseFloat(cheat?.freightCaseQuantity);

          currentPallet.leanCheatPackQuantity =
            currentPallet.leanCheatPackQuantity + cheat.mealQuantity;
        } else {
          // Split to second pallet

          let lastPalletQuantity = palletMax - onPallet;

          console.log("CHEATS LAST QTY", lastPalletQuantity);

          const previousFreightQuantity = parseFloat(cheat.freightCaseQuantity);

          console.log(
            "CHEATS PREVIOUS FREIGHT QUANTITY",
            previousFreightQuantity
          );

          cheat.freightCaseQuantity = lastPalletQuantity;
          cheat.caseQuantity =
            (cheat.freightCaseQuantity * order?.pricing?.leanCheatCaseSize) /
            cheat?.caseSize;
          cheat.mealQuantity = cheat.caseQuantity * cheat.caseSize;

          currentPallet.leanCheats.push(JSON.parse(JSON.stringify(cheat)));

          currentPallet.leanCheatCaseQuantity =
            currentPallet.leanCheatCaseQuantity +
            parseFloat(cheat?.freightCaseQuantity);

          currentPallet.leanCheatPackQuantity =
            currentPallet.leanCheatPackQuantity + cheat?.mealQuantity;

          let newQuantity = previousFreightQuantity - lastPalletQuantity;

          currentPallet.totalBoxes += parseFloat(cheat?.freightCaseQuantity);

          if (!_.findWhere((pallets, { _id: currentPallet?._id }))) {
            pallets.push(JSON.parse(JSON.stringify(currentPallet)));
          }

          onPallet = 0;
          currentPallet = {
            meals: [],
            hasBoxes: false,
            leanCheats: [],
            hasLeanCheats: true,
            leanCheatCaseQuantity: 0,
            leanCheatPackQuantity: 0,
            _id: StringUtils.uuid(),
            totalBoxes: 0,
          };

          cheat.freightCaseQuantity = newQuantity;
          cheat.caseQuantity = Math.floor(
            (newQuantity * order?.pricing?.leanCheatCaseSize) / cheat.caseSize
          );
          cheat.mealQuantity = cheat.caseQuantity * cheat.caseSize;

          console.log(cheat?.sku, newQuantity, cheat?.caseQuantity);

          currentPallet.leanCheats.push(JSON.parse(JSON.stringify(cheat)));

          currentPallet.leanCheatCaseQuantity =
            currentPallet.leanCheatCaseQuantity +
            parseFloat(cheat?.freightCaseQuantity);

          currentPallet.leanCheatPackQuantity += cheat.mealQuantity;

          currentPallet.totalBoxes += parseFloat(cheat?.freightCaseQuantity);

          onPallet = onPallet + newQuantity;
        }

        if (onPallet == palletMax) {
          if (!_.findWhere(pallets, { _id: currentPallet?._id })) {
            pallets.push(JSON.parse(JSON.stringify(currentPallet)));
          }

          onPallet = 0;
          currentPallet = {
            meals: [],
            hasBoxes: false,
            leanCheats: [],
            hasLeanCheats: true,
            leanCheatCaseQuantity: 0,
            _id: StringUtils.uuid(),
          };
        }
      }
    }

    if (
      currentPallet?.leanCheats?.length &&
      !_.findWhere(pallets, { _id: currentPallet?._id })
    ) {
      pallets.push(JSON.parse(JSON.stringify(currentPallet)));
    }

    // TODO: add boxes to all array

    let boxes = [];

    let smallBoxes =
      order?.boxes?.hasOwnProperty("Small Box") &&
      order?.boxes["Small Box"]?.caseQuantity
        ? order?.boxes["Small Box"]
        : null;
    let mediumBoxes =
      order?.boxes?.hasOwnProperty("Medium Box") &&
      order?.boxes["Medium Box"]?.caseQuantity
        ? order?.boxes["Medium Box"]
        : null;
    let largeBoxes =
      order?.boxes?.hasOwnProperty("Large Box") &&
      order?.boxes["Large Box"]?.caseQuantity
        ? order?.boxes["Large Box"]
        : null;

    if (smallBoxes) {
      smallBoxes.freightCaseQuantity = smallBoxes.caseQuantity * 5;
      smallBoxes.sku = "Small Box (12)";

      boxes.push(JSON.parse(JSON.stringify(smallBoxes)));
    }

    if (mediumBoxes) {
      mediumBoxes.freightCaseQuantity = mediumBoxes.caseQuantity * 5;
      mediumBoxes.sku = "Medium Box (18)";

      boxes.push(JSON.parse(JSON.stringify(mediumBoxes)));
    }

    if (largeBoxes) {
      largeBoxes.freightCaseQuantity = largeBoxes.caseQuantity * 5;
      largeBoxes.sku = "Large Box (24/30)";

      boxes.push(JSON.parse(JSON.stringify(largeBoxes)));
    }

    console.log(boxes);

    if (boxes?.length) {
      currentPallet = pallets[pallets?.length - 1];
      onPallet = 0;

      for (let i = 0; i < currentPallet?.meals?.length; i++) {
        onPallet = onPallet + currentPallet?.meals[i]?.caseQuantity;
      }

      if (currentPallet?.leanCheatCaseQuantity) {
        onPallet += Math.ceil(currentPallet?.leanCheatCaseQuantity);
      }

      currentPallet.hasBoxes = true;
      currentPallet.boxes = [];
      currentPallet.boxBundleQuantity = 0;
      currentPallet.boxQuantity = 0;

      for (let i = 0; i < boxes?.length; i++) {
        const cheat = boxes[i];

        if (!cheat?.caseQuantity) {
          continue;
        }

        if (onPallet + cheat?.freightCaseQuantity <= palletMax) {
          currentPallet.boxes.push(cheat);

          onPallet = onPallet + cheat?.freightCaseQuantity;

          currentPallet.boxQuantity += cheat.boxQuantity;
          currentPallet.boxBundleQuantity += cheat.caseQuantity;
        } else {
          // Split to second pallet
          let lastPalletQuantity = palletMax - onPallet; // slots available
          let originalCaseQuantity = cheat?.caseQuantity;

          let bundleToFitOnPallet = Math.floor(lastPalletQuantity / 5);

          cheat.freightCaseQuantity = bundleToFitOnPallet * 5;
          cheat.caseQuantity = bundleToFitOnPallet;
          cheat.boxQuantity =
            cheat.caseQuantity * order?.pricing?.boxBundleSize;

          currentPallet.boxes.push(JSON.parse(JSON.stringify(cheat)));

          let newQuantity = originalCaseQuantity - bundleToFitOnPallet;

          currentPallet.boxQuantity += cheat.boxQuantity;
          currentPallet.boxBundleQuantity += cheat.caseQuantity;

          if (!_.findWhere((pallets, { _id: currentPallet?._id }))) {
            pallets.push(JSON.parse(JSON.stringify(currentPallet)));
          }

          onPallet = 0;
          currentPallet = {
            meals: [],
            hasBoxes: true,
            leanCheats: [],
            hasLeanCheats: true,
            leanCheatCaseQuantity: 0,
            leanCheatPackQuantity: 0,
            boxQuantity: 0,
            boxBundleQuantity: 0,
            _id: StringUtils.uuid(),
            totalBoxes: 0,
            boxes: [],
          };

          cheat.freightCaseQuantity = newQuantity * 5;
          cheat.caseQuantity = newQuantity;
          cheat.boxQuantity = newQuantity * order?.pricing?.boxBundleSize;

          currentPallet.boxQuantity = cheat.boxQuantity;
          currentPallet.boxBundleQuantity = cheat.caseQuantity;

          currentPallet.boxes.push(cheat);

          console.log("PALLET", currentPallet);

          onPallet = onPallet + cheat.freightCaseQuantity;
        }

        if (onPallet == palletMax) {
          console.log("PALLET MAX");

          if (!_.findWhere(pallets, { _id: currentPallet?._id })) {
            pallets.push(JSON.parse(JSON.stringify(currentPallet)));
          }

          onPallet = 0;
          currentPallet = {
            meals: [],
            hasBoxes: true,
            boxes: [],
            boxQuantity: 0,
            boxBundleQuantity: 0,
            leanCheats: [],
            hasLeanCheats: false,
            leanCheatCaseQuantity: 0,
            _id: StringUtils.uuid(),
          };
        }
      }
    }

    if (
      currentPallet?.boxes?.length &&
      !_.findWhere(pallets, { _id: currentPallet?._id })
    ) {
      pallets.push(JSON.parse(JSON.stringify(currentPallet)));
    }

    this.setState({
      lifestyleMeals: lifestyle,
      athleteMeals: athlete,
      leanCheats: leancheats,
      lifestyleTotalCases,
      lifestyleTotalMeals,
      athleteTotalCases,
      athleteTotalMeals,
      leanCheatTotalPacks,
      leanCheatTotalMeals,
      leanCheatTotalCases,
      totalMeals,
      totalCases,
      palletsShipped,
      totalBoxes,
      pallets,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.orderID != prevProps?.match?.params?.orderID &&
      this.props.match?.params?.orderID
    ) {
      this.loadOrder();
    }
  }

  componentDidMount() {
    if (this.props.match?.params?.orderID) {
      this.loadOrder();
    }
  }

  totalBoxes(pallets) {
    if (!pallets) {
      return "--";
    }

    let total = 0;

    for (let i = 0; i < pallets?.length; i++) {
      total += pallets[i].totalBoxes;
    }

    return StringUtils.numberFormat(Math.ceil(total));
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading || this.state.loadingOrder ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Generating Pallet Sheet</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your order information.
                </CardBody>
              </Card>
            </div>
          ) : null}

          {!this.state.order &&
          !this.state.loadingOrder &&
          !this.state.loading ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <span>No Pallet Sheet Available</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  We were unable to generate a pallet sheet for this order.
                  Please reload and try again.
                </CardBody>
              </Card>
            </div>
          ) : null}
          {!this.state.loading &&
          !this.state.loadingOrder &&
          this.state.order ? (
            <div
              className="label-page-container px-2 bg-white"
              style={{ overflowX: "hidden", minHeight: "100vh" }}
            >
              {/**
               * Standard Header
               */}
              <div
                style={{ maxWidth: 630, margin: "auto", position: "relative" }}
              >
                <div
                  style={{ borderTop: "2px solid var(--dark)" }}
                  className="pb-4"
                />

                <div
                  className="text-uppercase font-weight-bold"
                  style={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                    color: "black",
                  }}
                >
                  Pallet Sheet
                </div>
                <img
                  style={{ maxWidth: 300 }}
                  src={require("../../assets/img/brand/llm_logo.svg")}
                  alt="LLM Logo"
                ></img>
              </div>
              <div className="py-3" />
              {/**
               * Slip Body
               */}
              {this.state.pallets?.map((pallet, i) => (
                <>
                  {i > 0 ? (
                    <div
                      style={{ pageBreakBefore: "always" }}
                      className="pt-4"
                    ></div>
                  ) : null}
                  <div style={{ maxWidth: 630, margin: "auto" }}>
                    <Row className="align-items-center mb-2">
                      <Col xs="12" sm="6">
                        <h2 className="m-0" style={{ color: "black" }}>
                          {this.state.order?.store?.name?.replace(
                            "Project LeanNation",
                            "PLN"
                          )}
                          {" - "}
                          Pallet {i + 1} of {this.state.pallets?.length}
                        </h2>
                      </Col>
                      <Col xs="12" sm="6" className="text-right">
                        <div style={{ color: "black", fontSize: 12 }}>
                          Order #{this.state.order?._id}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="rounded"
                    style={{
                      border: "1px solid black",
                      overflow: "hidden",
                      maxWidth: 630,
                      margin: "auto",
                    }}
                  >
                    <Table
                      className="table-bordered rounded m-0"
                      style={{ overflow: "hidden" }}
                    >
                      {pallet?.meals?.length ? (
                        <>
                          <thead>
                            <tr>
                              <td
                                className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                                style={{
                                  borderColor: "black",
                                  fontSize: 14,
                                  width: 200,
                                  color: "black",
                                  borderBottomWidth: 1,
                                }}
                              >
                                Item #
                              </td>
                              <td
                                className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                                style={{
                                  borderColor: "black",
                                  fontSize: 14,

                                  color: "black",
                                  borderBottomWidth: 1,
                                }}
                              >
                                Description
                              </td>
                              <td
                                className="align-middle text-center px-2 py-1 bg-light text-uppercase font-weight-bold"
                                style={{
                                  borderColor: "black",
                                  fontSize: 14,
                                  width: 150,
                                  color: "black",
                                  borderBottomWidth: 1,
                                }}
                              >
                                Total Cases
                              </td>
                            </tr>
                          </thead>
                        </>
                      ) : null}

                      <tbody>
                        {pallet?.meals?.length ? (
                          <>
                            {pallet?.meals?.map((meal, i) => (
                              <tr key={i}>
                                <td
                                  className="align-middle px-2 py-1 text-uppercase"
                                  style={{
                                    color:
                                      meal?.planType?.name == "Athlete"
                                        ? "red"
                                        : "black",
                                    borderColor: "black",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {meal?.planType?.name}
                                </td>
                                <td
                                  className="align-middle px-2 py-1"
                                  style={{
                                    color:
                                      meal?.planType?.name == "Athlete"
                                        ? "red"
                                        : "black",
                                    borderColor: "black",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {meal?.sku}
                                </td>
                                <td
                                  className="align-middle text-center py-1 px-2"
                                  style={{
                                    color:
                                      meal?.planType?.name == "Athlete"
                                        ? "red"
                                        : "black",
                                    borderColor: "black",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    background: "#dcf7b7",
                                  }}
                                >
                                  {`${StringUtils.numberFormat(
                                    meal?.caseQuantity
                                  )}`}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td
                                className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                                style={{
                                  color: "black",
                                  borderColor: "black",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                }}
                                colspan={2}
                              >
                                Total Lifestyle &amp; Athlete
                              </td>

                              <td
                                className="align-middle bg-superlight text-center py-1 px-2 font-weight-bold"
                                style={{
                                  color: "black",
                                  borderColor: "black",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                }}
                              >
                                {StringUtils.numberFormat(pallet.totalCases)}
                              </td>
                            </tr>
                          </>
                        ) : null}

                        {pallet?.hasLeanCheats && pallet?.leanCheats?.length ? (
                          <>
                            {pallet?.meals?.length > 0 && (
                              <tr>
                                <td
                                  className="align-middle px-3 py-3"
                                  style={{
                                    color: "black",
                                    borderColor: "black",
                                  }}
                                  colspan={3}
                                ></td>
                              </tr>
                            )}

                            {pallet?.leanCheats?.map((meal, i) => (
                              <tr key={i}>
                                <td
                                  className="align-middle px-2 py-1 text-uppercase"
                                  style={{
                                    color: "black",
                                    borderColor: "black",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Lean Cheats
                                </td>
                                <td
                                  className="align-middle px-2 py-1"
                                  style={{
                                    color: "black",
                                    borderColor: "black",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {meal?.sku}
                                </td>
                                {i == 0 && (
                                  <td
                                    className="align-middle text-center py-1 px-2"
                                    style={{
                                      color: "black",
                                      borderColor: "black",
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      background: "#dcf7b7",
                                    }}
                                    rowSpan={pallet?.leanCheats?.length}
                                  >
                                    {`${StringUtils.numberFormat(
                                      Math.ceil(pallet?.leanCheatCaseQuantity)
                                    )}`}
                                  </td>
                                )}
                              </tr>
                            ))}
                            <tr>
                              <td
                                className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                                style={{
                                  color: "black",
                                  borderColor: "black",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                }}
                                colspan={2}
                              >
                                Total Lean Cheats
                              </td>

                              <td
                                className="align-middle bg-superlight text-center py-1 px-2 font-weight-bold"
                                style={{
                                  color: "black",
                                  borderColor: "black",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                }}
                              >
                                {StringUtils.numberFormat(
                                  Math.ceil(pallet?.leanCheatCaseQuantity)
                                )}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        {pallet?.hasBoxes ? (
                          <>
                            {(pallet?.meals?.length > 0 ||
                              pallet?.leanCheats?.length > 0) && (
                              <tr>
                                <td
                                  className="align-middle px-3 py-3"
                                  style={{
                                    color: "black",
                                    borderColor: "black",
                                  }}
                                  colspan={3}
                                ></td>
                              </tr>
                            )}
                            <tr>
                              <td
                                className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                                style={{
                                  borderColor: "black",
                                  fontSize: 14,
                                  width: 200,
                                  color: "black",
                                  borderBottomWidth: 1,
                                }}
                              >
                                Item #
                              </td>
                              <td
                                className="align-middle text-left px-2 py-1 bg-light text-uppercase font-weight-bold"
                                style={{
                                  borderColor: "black",
                                  fontSize: 14,

                                  color: "black",
                                  borderBottomWidth: 1,
                                }}
                              >
                                Description
                              </td>
                              <td
                                className="align-middle text-center px-2 py-1 bg-light text-uppercase font-weight-bold"
                                style={{
                                  borderColor: "black",
                                  fontSize: 14,
                                  width: 150,
                                  color: "black",
                                  borderBottomWidth: 1,
                                }}
                              >
                                Bundle Quantity
                              </td>
                            </tr>
                            {pallet?.boxes?.map((box, i) => (
                              <tr key={i}>
                                <td
                                  className="align-middle px-2 py-1 text-uppercase"
                                  style={{
                                    color: "black",
                                    borderColor: "black",
                                  }}
                                >
                                  Extra Boxes
                                </td>
                                <td
                                  className="align-middle px-2 py-1"
                                  style={{
                                    color: "black",
                                    borderColor: "black",
                                  }}
                                >
                                  {box?.sku}
                                </td>
                                <td
                                  className="align-middle text-center py-1 px-2"
                                  style={{
                                    color: "black",
                                    borderColor: "black",
                                  }}
                                >
                                  {StringUtils.numberFormat(box?.caseQuantity)}
                                </td>
                              </tr>
                            ))}

                            <tr>
                              <td
                                className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                                style={{ color: "black", borderColor: "black" }}
                                colspan={2}
                              >
                                Total Extra Boxes
                              </td>

                              <td
                                className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                                style={{ color: "black", borderColor: "black" }}
                              >
                                {StringUtils.numberFormat(
                                  pallet?.boxBundleQuantity
                                )}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        <tr>
                          <td
                            className="align-middle px-3 py-3"
                            style={{ color: "black", borderColor: "black" }}
                            colspan={4}
                          ></td>
                        </tr>

                        <tr>
                          <td
                            className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                            colspan={2}
                          >
                            Total Cases On Pallet
                          </td>

                          <td
                            className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                            style={{
                              color: "black",
                              borderColor: "black",
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                          >
                            {StringUtils.numberFormat(
                              Math.ceil(pallet.totalBoxes)
                            )}
                          </td>
                        </tr>
                        {pallet?.boxBundleQuantity > 0 && (
                          <tr>
                            <td
                              className="align-middle bg-superlight text-uppercase px-2 py-1 text-right font-weight-bold"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                                fontWeight: "bold",
                              }}
                              colspan={2}
                            >
                              Total Extra Box Bundles On Pallet
                            </td>

                            <td
                              className="align-middle font-weight-bold bg-superlight text-center py-1 px-2"
                              style={{
                                color: "black",
                                borderColor: "black",
                                fontSize: 18,
                                fontWeight: "bold",
                              }}
                            >
                              {StringUtils.numberFormat(
                                Math.ceil(pallet.boxBundleQuantity)
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              ))}

              {/**
               * Standard Footer
               */}

              <div
                className="mt-4 mb-5 p-3 text-center bg-light rounded border border-dark"
                style={{ maxWidth: 630, margin: "auto" }}
              >
                <p className="mb-2" style={{ color: "black" }}>
                  End Of Pallet Sheet: Order #{this.state.order?._id}
                </p>
                <h2 className="m-0 display-3" style={{ color: "black" }}>
                  {this.state.order?.store?.name?.replace(
                    "Project LeanNation",
                    "PLN"
                  )}
                  {" - "}
                  {this.state.order?.store?.location?.address?.city?.trim()},{" "}
                  {this.state.order?.store?.location?.address?.state}
                </h2>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(ProductOrderPalletSheetView);
