/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { withRouter } from "react-router";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import { Drawer } from "rsuite";
import { Capacitor } from "@capacitor/core";
import LinkUtils from "lib/LinkUtils";

class SidebarV2 extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  componentDidMount() {
    PubSub.subscribe("SidenavDrawer", (val) => {
      console.log(val);

      this.setState({
        collapseOpen: val,
      });
    });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName, exact) {
    if (exact) {
      console.log(this.props.location.pathname);

      return this.props.location.pathname == routeName ? "active" : "";
    }

    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes, mobile = false) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          {prop.dropdown ? (
            <>
              <NavLink
                to={this.props.basePath + prop.path}
                tag={NavLinkRRD}
                onClick={prop.dropdown ? null : this.closeCollapse}
                activeClassName="active"
                className={`font-weight-500 ${
                  prop.dropdown ? "sidenav-dropdown" : ""
                }`}
              >
                <i
                  className={`${prop.icon} pr-3 pr-lg-0`}
                  style={{ fontSize: mobile ? "24px" : "20px" }}
                />
                {prop.name}
              </NavLink>
              {this.activeRoute(this.props.basePath + prop.path) ? (
                <>
                  {prop.dropdownItems?.map((item, j) => (
                    <NavItem key={j}>
                      <NavLink
                        to={this.props.basePath + item.path}
                        tag={NavLinkRRD}
                        onClick={this.closeCollapse}
                        activeClassName=""
                        className={`font-weight-500 sidenav-dropdown-item ${this.activeRoute(
                          this.props.basePath + item.path,
                          true
                        )}`}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </>
              ) : null}
            </>
          ) : (
            <NavLink
              to={prop?.external ? prop.path : this.props.basePath + prop.path}
              tag={NavLinkRRD}
              onClick={
                prop.external
                  ? () => {
                      LinkUtils.openInNewTab(prop.path);
                    }
                  : this.closeCollapse
              }
              activeClassName="active"
              className="font-weight-500"
            >
              <i
                className={`${prop.icon} pr-3 pr-lg-0`}
                style={{ fontSize: mobile ? "24px" : "20px" }}
              />
              {prop.name}
              {prop.external ? (
                <i
                  className={`mdi mdi-open-in-new pr-3 pr-lg-0`}
                  style={{ fontSize: 20, position: "absolute", right: 0 }}
                />
              ) : null}
            </NavLink>
          )}
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <>
        <Navbar
          className="navbar-vertical fixed-left navbar-light bg-white border-right d-none d-lg-block sidenav-topmargin"
          expand="lg"
          id="sidenav-main"
          style={{ zIndex: 2 }}
        >
          <Container fluid>
            {/* Toggler */}

            {/* Brand */}

            {/* User */}

            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}

              {/* Form */}

              {/* Navigation */}
              <Nav navbar>{this.createLinks(routes)}</Nav>
              {/* Divider */}

              {/* Heading */}

              {/* Navigation */}
              {/*
              <h6 className="navbar-heading text-muted">Manage</h6>
              <Nav navbar>
              <NavItem>
                <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                  <i className="mdi mdi-account-group" />
                  User Access
                </NavLink>
              </NavItem>
            </Nav>    
            */}
            </Collapse>
          </Container>
        </Navbar>
        <Drawer
          style={{ maxWidth: "100%" }}
          size={"xs"}
          placement={"left"}
          onOpen={(e) => {
            console.log("is open");
          }}
          open={this.state.collapseOpen}
          onClose={() => this.closeCollapse()}
          className="nav-drawer"
        >
          <Drawer.Header>
            <Drawer.Title className="font-weight-bold">Menu</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body
            style={{
              paddingTop: "0px",
              paddingTop: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <Navbar
              className="navbar-vertical navbar-light"
              navbar
              style={{ padding: 0 }}
            >
              <Collapse
                className="collapse-override"
                navbar
                isOpen={true}
                style={{
                  padding: "0.6rem",
                  background: "transparent",
                  boxShadow: "none",
                  borderRadius: "none",
                  animation: "none",
                  opacity: 1,
                  width: "100%",
                  margin: 0,
                  overflowX: "hidden",
                }}
              >
                {/* Collapse header */}

                {/* Form */}

                {/* Navigation */}
                <Nav navbar>{this.createLinks(routes)}</Nav>
                {/* Divider */}

                {/* Heading */}

                {/* Navigation */}
                {/*
              <h6 className="navbar-heading text-muted">Manage</h6>
              <Nav navbar>
              <NavItem>
                <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                  <i className="mdi mdi-account-group" />
                  User Access
                </NavLink>
              </NavItem>
            </Nav>    
            */}
              </Collapse>
            </Navbar>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

SidebarV2.defaultProps = {
  routes: [{}],
};

SidebarV2.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  basePath: PropTypes.string.isRequired,
};

export default withRouter(SidebarV2);
