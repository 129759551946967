import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import StorefrontModifyCouponDrawer from "components/Drawers/StorefrontModifyCouponDrawer";
import StorefrontCreateCouponDrawer from "components/Drawers/StorefrontCreateCouponDrawer";

class StorefrontCouponListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.COUPON.CREATED, () => {
      this.loadCoupons(this.state.page, this.state.count, this.state.filters);
    });

    PubSub.subscribe(Event.COUPON.MODIFIED, () => {
      this.loadCoupons(this.state.page, this.state.count, this.state.filters);
    });
  }

  loadCoupons(page = 1, count = 25, filters = null) {
    this.setState({
      loadingStaff: true,
    });

    APIV2.getCouponsForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters
    )
      .then(
        (data) => {
          if (data && data.data) {
            let coupons = [];

            if (data.data.coupons.length) {
              coupons = data.data.coupons;
            }

            this.setState({
              coupons,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the coupons. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStaff: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchCouponsForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultStaff: data.data.coupons,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultStaff: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  delete(coupons) {
    swal({
      title: "Remove Coupon?",
      text: `Are you sure you want remove ${coupons?.name} coupon? This will remove the coupon from all members who are currently using it.`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: coupons?._id,
      });

      APIV2.deleteStoreCoupon(this?.props?.match?.params?.storeID, coupons?._id)
        .then(
          (data) => {
            this.loadCoupons(
              this.state.page,
              this.state.count,
              this.state.filters
            );
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadCoupons(page, this.state.count, this.state.filters);
  }

  handleTabChange(option, force = false) {
    let filters = null;

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All") {
      filters = null;
    } else if (option.id == "One-Time Usage") {
      filters = {
        couponUsage: "onetime",
      };
    } else if (option.id == "Limited Usage") {
      filters = {
        couponUsage: "limited",
      };
    } else if (option.id == "Tiered Usage") {
      filters = {
        couponUsage: "tiered",
      };
    } else if (option.id == "Recurring Usage") {
      filters = {
        couponUsage: "recurring",
      };
    } else if (option.id == "Flat Discount") {
      filters = {
        discountType: "Flat",
      };
    } else if (option.id == "Percent Discount") {
      filters = {
        discountType: "Percent",
      };
    }

    this.setState({
      filters,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadCoupons(1, this.state.count, filters);
  }

  getDiscountValue(c) {
    return StringUtils.couponValueString(c);
  }

  renderCouponUsage(usage) {
    if (usage == "tiered") {
      return "Tiered";
    }

    if (usage == "onetime") {
      return "One-Time";
    }

    if (usage == "recurring") {
      return "Recurring";
    }

    return "Limited";
  }

  couponRow(c) {
    let frequency = " (One Time)";

    if (!c?.removeFromSubscriptionAfterUses) {
      frequency = " (Recurring)";
    }

    let discountValue = this.getDiscountValue(c);

    return (
      <Row className="align-items-center">
        <Col xs="6" sm="6" md="4">
          <h4 className={`mb-0 text-capitalize`}>
            {c?.description ? (
              <>
                <Whisper
                  placement="auto"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      <p className="m-0" style={{ fontSize: 14 }}>
                        {c?.description}
                      </p>
                    </Tooltip>
                  }
                >
                  <span style={{ position: "relative", top: 0 }}>
                    <i className="mdi mdi-information-outline pr-1"></i>
                  </span>
                </Whisper>
              </>
            ) : null}
            {c.name}
          </h4>
          <p className="mb-0 text-uppercase" style={{ fontSize: "13px" }}>
            {c.code}
          </p>
        </Col>
        <Col xs="6" sm="6" md="2" className="text-right text-md-left">
          {c.couponUsage ? this.renderCouponUsage(c.couponUsage) : "--"}
        </Col>
        <Col xs="6" sm="6" md="3">
          {discountValue}
        </Col>
        <Col xs="6" sm="6" md="3" className="text-right">
          <Button
            color="danger"
            outline
            className="btn-icon-only"
            disabled={this.state.deleteSubmitting == c._id}
            onClick={() => {
              this.delete(c);
            }}
            size="sm"
          >
            {this.state.deleteSubmitting == c._id ? (
              <Spinner color="danger" size="sm"></Spinner>
            ) : (
              <i className="mdi mdi-close"></i>
            )}
          </Button>
          <Button
            color="secondary"
            outline
            className="btn-icon-only"
            onClick={() => {
              this.setState({
                openModify: true,
                modifyCoupon: c,
              });
            }}
            size="sm"
          >
            <i className="mdi mdi-pencil"></i>
          </Button>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Coupons"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },
                    {
                      name: "One-Time Usage",
                      id: "One-Time Usage",
                    },
                    {
                      name: "Limited Usage",
                      id: "Limited Usage",
                    },
                    {
                      name: "Tiered Usage",
                      id: "Tiered Usage",
                    },
                    {
                      name: "Recurring Usage",
                      id: "Recurring Usage",
                    },
                    {
                      name: "Flat Discount",
                      id: "Flat Discount",
                    },
                    {
                      name: "Percent Discount",
                      id: "Percent Discount",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search coupons..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultStaff?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultStaff?.length
                              )
                            : "0"}{" "}
                          coupons
                          {this.state.searchResultStaff?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  coupons
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">Coupon</h4>
                      </Col>
                      <Col
                        xs="6"
                        sm="6"
                        md="2"
                        className="text-right text-md-left"
                      >
                        <h4 className="mb-0">Usage</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Discount</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3" className="text-right">
                        <h4 className="mb-0">Actions</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingStaff || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultStaff?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No coupons found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultStaff?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.searchResultStaff.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            {this.couponRow(c)}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.coupons?.length &&
                        !this.state.loadingStaff ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                Get started by creating your first coupon.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.coupons?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.coupons.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            {this.couponRow(c)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <StorefrontCreateCouponDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          storeID={this?.props?.match?.params?.storeID}
        ></StorefrontCreateCouponDrawer>
        <StorefrontModifyCouponDrawer
          open={this.state.openModify}
          coupon={this.state.modifyCoupon}
          onClose={() => {
            this.setState({
              openModify: false,
              modifyCoupon: null,
            });
          }}
          storeID={this?.props?.match?.params?.storeID}
        ></StorefrontModifyCouponDrawer>
      </>
    );
  }
}

export default StorefrontCouponListView;
