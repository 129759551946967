import React, { useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { Button, Col, Row } from "reactstrap";
import moment from "moment-timezone";

const ContentCalendarCard = ({ content, onClick }) => {
  const renderTime = (dateString) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date = moment.utc(dateString).tz(timezone);

    return date.format("h:mm A");
  };

  const renderPlatforms = (content) => {
    let out = [];

    if (content?.type == "Email") {
      return (
        <>
          <div className="d-flex">
            <svg
              style={{
                height: 18,
                width: 18,
                marginRight: "0.1rem",
                display: "inline-block",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>email</title>
              <path
                fill="var(--dark)"
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
          </div>
        </>
      );
    }

    if (content.type == "Broadcast") {
      return (
        <>
          <div className="d-flex">
            <svg
              style={{
                height: 18,
                width: 18,
                marginRight: "0.1rem",
                display: "inline-block",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>message-fast</title>
              <path
                fill="var(--dark)"
                d="M3 7C2.4 7 2 7.4 2 8S2.4 9 3 9H5V7H3M2 11C1.4 11 1 11.4 1 12S1.4 13 2 13H5V11H2M1 15C.4 15 0 15.4 0 16C0 16.6 .4 17 1 17H5V15H1M20 5H9C7.9 5 7 5.9 7 7V21L11 17H20C21.1 17 22 16.1 22 15V7C22 5.9 21.1 5 20 5Z"
              />
            </svg>
          </div>
        </>
      );
    }

    if (content?.platforms.includes("Instagram")) {
      out.push(
        <div className="d-inline-flex">
          <svg
            style={{
              height: 18,
              width: 18,
              marginRight: "0.1rem",
              display: "inline-block",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>instagram</title>
            <path
              fill="var(--dark)"
              d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
            />
          </svg>
        </div>
      );
    }

    if (content?.platforms.includes("Facebook")) {
      out.push(
        <div className="d-inline-flex">
          <svg
            style={{
              height: 18,
              width: 18,
              marginRight: "0.1rem",
              display: "inline-block",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>facebook</title>
            <path
              fill="var(--dark)"
              d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
            />
          </svg>
        </div>
      );
    }

    return (
      <>
        {out.map((item, i) => (
          <>{item}</>
        ))}
      </>
    );
  };

  return (
    <div
      onClick={onClick}
      style={{ whiteSpace: "pre-wrap" }}
      className={`p-2 m-2 content-card ${content?.type
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()} shadow--hover cursor-pointer rounded`}
    >
      <Row className="align-items-center mb-1" style={{ lineHeight: 1 }}>
        <Col xs="">
          <p
            className={`text-dark m-0 `}
            style={{ fontSize: 14, lineHeight: 1 }}
          >
            {renderTime(content?.scheduledFor)}
          </p>
        </Col>
        {content?.shareable ? (
          <Col xs="auto" style={{ lineHeight: 1 }}>
            <svg
              style={{ height: 16, width: 16, display: "block" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>export-variant</title>
              <path
                fill="var(--dark)"
                d="M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9A2,2 0 0,1 6,7H9V9H6V21H18V9H15V7H18A2,2 0 0,1 20,9V21A2,2 0 0,1 18,23Z"
              />
            </svg>
          </Col>
        ) : null}
      </Row>
      <h4 className={`mb-1 text-dark`}>{content?.name}</h4>

      <Row className="align-items-center">
        <Col xs="auto" className="pr-0" style={{ lineHeight: 1 }}>
          {renderPlatforms(content)}
        </Col>

        <Col xs="" className="pl-1">
          <p className={`text-dark m-0`} style={{ fontSize: 14 }}>
            {content?.type == "Social Media" ? (
              <>{content?.postTypes?.join(", ")}</>
            ) : (
              <>{content?.audiences?.join(", ")}</>
            )}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default ContentCalendarCard;
