import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, isNumber } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import StoreRow from "components/StoreRow";
import ModifyStoreModal from "components/ModifyStoreModal";
import { withRouter } from "react-router-dom";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import _ from "underscore";
import swal from "sweetalert";
import Spinner from "reactstrap/lib/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import { TagPicker, Uploader, SelectPicker, RadioGroup, Radio } from "rsuite";
import Constant from "lib/Constant";

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

class MealCreateView extends React.Component {
  state = {
    store: null,
    loading: false,
    descriptionState: EditorState.createEmpty(),
    microwaveState: EditorState.createEmpty(),
    airFryState: EditorState.createEmpty(),
    ovenState: EditorState.createEmpty(),
    loadingCategories: false,
    categories: [],
    categoryTags: [],
    allergens: [],
    microwave: "standard",
    airFry: "standard",
    oven: "standard",
    transFat: "",
    satFat: "",
    chol: "",
  };

  onDescriptionStateChange(editorState) {
    this.setState({
      descriptionState: editorState,
      modified: true,
    });
  }

  onMicrowaveStateChange(editorState) {
    this.setState({
      microwaveState: editorState,
      modified: true,
    });
  }

  onAirFryStateChange(editorState) {
    this.setState({
      airFryState: editorState,
      modified: true,
    });
  }

  onOvenStateChange(editorState) {
    this.setState({
      ovenState: editorState,
      modified: true,
    });
  }

  async create() {
    let content = draftToHtml(
      convertToRaw(this.state.descriptionState.getCurrentContent())
    );

    let microwave = draftToHtml(
      convertToRaw(this.state.microwaveState.getCurrentContent())
    );

    let oven = draftToHtml(
      convertToRaw(this.state.ovenState.getCurrentContent())
    );

    let airFry = draftToHtml(
      convertToRaw(this.state.airFryState.getCurrentContent())
    );

    if (this.state.microwave == "standard") {
      microwave = Constant.HEATING_INSTRUCTIONS.MICROWAVE;
    } else if (this.state.microwave == "notheated") {
      microwave = "<p>This item does not need heating to eat.</p>";
    }

    if (this.state.airFry == "standard") {
      airFry = Constant.HEATING_INSTRUCTIONS.AIR_FRY;
    } else if (this.state.microwave == "notheated") {
      airFry = "<p>This item does not need heating to eat.</p>";
    }

    if (this.state.oven == "standard") {
      oven = Constant.HEATING_INSTRUCTIONS.OVEN;
    } else if (this.state.microwave == "notheated") {
      oven = "<p>This item does not need heating to eat.</p>";
    }

    if (
      !this.state.name ||
      (!this.state.selectedFile?.length && this.state.modifyImage) ||
      !content
    ) {
      swal({
        title: "Add Meal Information",
        text: "Please include a name, description, and image to continue.",
        icon: "warning",
      });

      return;
    }

    let calories = parseInt(this.state.calories);
    let carbs = parseInt(this.state.carbs);
    let fat = parseInt(this.state.fat);
    let transFat =
      this.state.transFat != "" ? parseInt(this.state.transFat) : null;
    let satFat = this.state.satFat != "" ? parseInt(this.state.satFat) : null;
    let chol = this.state.chol != "" ? parseInt(this.state.chol) : null;
    let protein = parseInt(this.state.protein);
    let sugar = parseInt(this.state.sugar);
    let fiber = parseInt(this.state.fiber);
    let sodium = parseInt(this.state.sodium);
    let foodWeight = parseFloat(this.state.foodWeight);
    let finishedWeight = parseFloat(this.state.finishedWeight);
    let vitaminD =
      this.state.vitaminD !== "" && this.state.vitaminD !== null
        ? parseFloat(this.state.vitaminD)
        : null;

    let calcium =
      this.state.calcium !== "" && this.state.calcium !== null
        ? parseInt(this.state.calcium)
        : null;

    let potassium =
      this.state.potassium !== "" && this.state.potassium !== null
        ? parseInt(this.state.potassium)
        : null;

    let iron =
      this.state.iron !== "" && this.state.iron !== null
        ? parseInt(this.state.iron)
        : null;

    if (
      !isNumber(calories) ||
      !isNumber(carbs) ||
      !isNumber(fat) ||
      !isNumber(protein) ||
      !isNumber(sugar) ||
      !isNumber(fiber) ||
      !isNumber(sodium) ||
      !isNumber(foodWeight) ||
      !isNumber(finishedWeight) ||
      calories < 0 ||
      carbs < 0 ||
      fat < 0 ||
      protein < 0 ||
      sugar < 0 ||
      fiber < 0 ||
      foodWeight < 0 ||
      finishedWeight < 0
    ) {
      swal({
        title: "Add Macros & Info",
        text: "Please include valid macros and production information to continue.",
        icon: "warning",
      });

      return;
    }

    let hasCategory = false;

    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i]) {
        hasCategory = true;
      }
    }

    if (!hasCategory) {
      swal({
        title: "Add A Category",
        text: "Please select at least one category for this meal to continue.",
        icon: "warning",
      });

      return;
    }

    this.setState({
      submitting: true,
    });

    let url = "";

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile[0].blobFile);

    let data = await APIV2.uploadCDNImage(formData);

    url = data.data.data.url;

    let allergens = [];
    let categories = [];

    for (let i = 0; i < this.state.allergens.length; i++) {
      allergens.push(this.state.allergens[i]);
    }

    for (let i = 0; i < this.state.categories.length; i++) {
      categories.push(this.state.categories[i]);
    }

    APIV2.createMeal(
      this.state.name,
      this.state.sku,
      content,
      url,
      this.state.planType,
      calories,
      carbs,
      protein,
      fat,
      sugar,
      fiber,
      sodium,
      foodWeight,
      finishedWeight,
      categories,
      allergens,
      microwave,
      airFry,
      oven,
      this.state.myfitnesspalURL,
      this.state.squareItem,
      this.state.primaryProtein,
      this.state.primaryProteinCategory,
      this.state.secondaryProtein,
      this.state.secondaryProteinCategory,
      this.state.ingredients,
      this.state.labelIngredients,
      transFat,
      satFat,
      chol,
      vitaminD,
      potassium,
      calcium,
      iron
    )
      .then(
        (data) => {
          let m = data.data.meal;

          swal("Meal successfully created", {
            icon: "success",
          });

          let url = `/${
            window.location.href.includes("production")
              ? "production"
              : "global"
          }/meals/details/${m._id}`;

          this.props.history.push(url);
          //this.closeModifyModal();
        },
        (e) => {
          console.error(e);
          swal({
            title: "Error Creating",
            text: "We were unable to create the meal. Please try again.",
            icon: "warning",
          });
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  async getMealCategories() {
    return new Promise((resolve) => {
      this.setState({
        loadingCategories: true,
      });

      APIV2.getCategories()
        .then(
          (data) => {
            if (data && data.data && data.data.categories) {
              this.setState({
                allCategories: data.data.categories,
              });
            }
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.setState({
            loadingCategories: false,
          });

          resolve();
        });
    });
  }

  async getMealAllergens() {
    return new Promise((resolve) => {
      this.setState({
        loadingAllergens: true,
      });

      APIV2.getAllergens()
        .then(
          (data) => {
            if (data && data.data && data.data.allergens) {
              this.setState({
                allAllergens: data.data.allergens,
              });
            }
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.setState({
            loadingAllergens: false,
          });

          resolve();
        });
    });
  }

  async getPlanTypes() {
    this.setState({
      loadingPlanTypes: true,
    });

    return new Promise((resolve) => {
      APIV2.getProductGroups()
        .then(
          (data) => {
            if (data && data.data && data.data.planTypes) {
              this.setState({
                planTypes: data.data.planTypes,
              });
            }
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.setState({
            loadingPlanTypes: false,
          });

          resolve();
        });
    });
  }

  onFileChange(e) {
    let files = [];

    files = e.length ? [e[e.length - 1]] : [];

    this.setState({ selectedFile: files });
  }

  componentDidMount() {
    this.getMealCategories();
    this.getMealAllergens();
    this.getPlanTypes();
    this.getSquareItems();
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value,
    });
  }

  handleInputNoSpaceChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/\s/g, ""),
    });
  }

  handleNumberInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/[^\d.]/g, ""),
    });
  }

  categoryChange(v) {
    this.setState({ categories: v, modified: true });
  }

  allergenChange(v) {
    this.setState({ allergens: v, modified: true });
  }

  async getSquareItems() {
    return new Promise((resolve) => {
      this.setState({
        loadingSquareItems: true,
      });

      APIV2.getSquareItems()
        .then(
          (data) => {
            if (data && data.data && data.data.items) {
              let options = data.data.items?.map((item) => {
                return {
                  value: item.id,
                  label: (
                    <>
                      {item.item_data.name}
                      <div className="small">
                        {item.present_at_all_locations ||
                        item?.present_at_location_ids?.length
                          ? "Available For Sale"
                          : "Unavailable For Sale"}
                      </div>
                    </>
                  ),
                  category:
                    item?.category?.category_data?.name ?? "Other Items",
                };
              });

              options = _.filter(options, (o) => {
                return (
                  o.category == "Lifestyle" ||
                  o.category == "Athlete" ||
                  o.category == "Lean Cheats"
                );
              });

              this.setState({
                squareItemOptions: options,
              });
            }
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          this.setState({
            loadingSquareItems: false,
          });

          resolve();
        });
    });
  }

  hasRequiredFields() {
    let calories = parseInt(this.state.calories);
    let carbs = parseInt(this.state.carbs);
    let fat = parseInt(this.state.fat);
    let protein = parseInt(this.state.protein);
    let sugar = parseInt(this.state.sugar);
    let fiber = parseInt(this.state.fiber);
    let sodium = parseInt(this.state.sodium);
    let foodWeight = parseFloat(this.state.foodWeight);
    let finishedWeight = parseFloat(this.state.finishedWeight);

    if (
      !isNumber(calories) ||
      !isNumber(carbs) ||
      !isNumber(fat) ||
      !isNumber(protein) ||
      !isNumber(sugar) ||
      !isNumber(fiber) ||
      !isNumber(sodium) ||
      !isNumber(foodWeight) ||
      !isNumber(finishedWeight) ||
      calories < 0 ||
      carbs < 0 ||
      fat < 0 ||
      protein < 0 ||
      sugar < 0 ||
      fiber < 0 ||
      foodWeight < 0 ||
      finishedWeight < 0
    ) {
      return false;
    }

    return (
      this.state.planType &&
      this.state.name &&
      this.state.selectedFile?.length &&
      this.state.categories?.length
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            title={
              <>
                <>Create New Meal</>
              </>
            }
            showBackButton={true}
            forceSecondaryDropdown={true}
            secondaryActions={
              [
                /*{
                name: <>Duplicate</>,
                onClick: () => {
                  PubSub.publish(Event.MEAL.CREATE_OPEN);
                },
              },*/
              ]
            }
            secondaryAction={{
              name: <>Cancel</>,
              onClick: () => {
                this.props.history.goBack();
              },
              disabled: this.state.submitting,
            }}
            primaryAction={{
              name: <>Create</>,
              onClick: () => {
                this.create();
              },
              submitting: this.state.submitting,
              disabled: this.state.submitting || !this.hasRequiredFields(),
            }}
          />
          {/* Table */}
          <Row>
            <Col xs="12" xl="8">
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Meal Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <h4>Meal Type</h4>
                    {this.state.loadingPlanTypes ? (
                      <Spinner color="medium" size="sm" />
                    ) : (
                      <SelectPicker
                        placement="auto"
                        block
                        searchable={false}
                        data={this.state.planTypes}
                        labelKey="name"
                        valueKey="_id"
                        value={this.state.planType}
                        onChange={(e) => this.setState({ planType: e })}
                        size="lg"
                        placeholder="Select Meal Type"
                      ></SelectPicker>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <h4>Name</h4>
                    <Input
                      type="text"
                      onChange={this.handleInputChange.bind(this)}
                      name="name"
                      className="text-dark"
                      value={this.state.name}
                      placeholder="Name"
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <h4>SKU</h4>
                    <Input
                      type="text"
                      onChange={this.handleInputChange.bind(this)}
                      name="sku"
                      className="text-dark"
                      value={this.state.sku}
                      placeholder="SKU"
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <h4>Description</h4>
                    <Editor
                      editorState={this.state.descriptionState}
                      onEditorStateChange={this.onDescriptionStateChange.bind(
                        this
                      )}
                    ></Editor>
                  </FormGroup>
                  <FormGroup>
                    <h4>Categories</h4>
                    {this.state.loadingCategories || this.state.loading ? (
                      <>
                        <Spinner color="medium" size="sm" />
                      </>
                    ) : (
                      <>
                        <TagPicker
                          style={{ width: "100%" }}
                          size="lg"
                          value={this.state.categories}
                          data={this.state.allCategories}
                          labelKey={"name"}
                          valueKey={"_id"}
                          placeholder="Select Categories"
                          onClean={() => this.setState({ categories: [] })}
                          onChange={this.categoryChange.bind(this)}
                          cleanable={true}
                        />
                      </>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <h4>Primary Protein</h4>
                    <SelectPicker
                      style={{ width: "100%" }}
                      size="lg"
                      value={this.state.primaryProtein}
                      data={Constant.MEAL_PROTEINS}
                      labelKey={"label"}
                      valueKey={"value"}
                      placeholder="Select A Protein"
                      groupBy="category"
                      onClean={() =>
                        this.setState({
                          primaryProtein: null,
                          primaryProteinCategory: null,
                        })
                      }
                      onChange={(v) => {
                        this.setState({
                          primaryProtein: v,
                          modified: true,
                        });

                        const protein = _.findWhere(Constant.MEAL_PROTEINS, {
                          value: v,
                        });

                        if (protein) {
                          this.setState({
                            primaryProteinCategory: protein?.category,
                          });
                        }
                      }}
                      cleanable={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <h4>Secondary Protein</h4>
                    <SelectPicker
                      style={{ width: "100%" }}
                      size="lg"
                      value={this.state.secondaryProtein}
                      data={Constant.MEAL_PROTEINS}
                      labelKey={"label"}
                      valueKey={"value"}
                      placeholder="Select A Protein"
                      groupBy="category"
                      onClean={() =>
                        this.setState({
                          secondaryProtein: null,
                          secondaryProteinCategory: null,
                        })
                      }
                      onChange={(v) => {
                        this.setState({
                          secondaryProtein: v,
                          modified: true,
                        });

                        const protein = _.findWhere(Constant.MEAL_PROTEINS, {
                          value: v,
                        });

                        if (protein) {
                          this.setState({
                            secondaryProteinCategory: protein?.category,
                          });
                        }
                      }}
                      cleanable={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <h4>Ingredients</h4>
                    <Input
                      placeholder="Ingredients"
                      type="textarea"
                      name="ingredients"
                      value={this.state.ingredients}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  </FormGroup>
                </CardBody>
              </Card>
              <Card className="mb-4 shadow d-xl-none d-block">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Image</h3>
                    </div>
                    <Col xs="auto"></Col>
                  </Row>
                </CardHeader>
                <CardBody className="">
                  <div className="text-left">
                    <Uploader
                      fileList={this.state.selectedFile}
                      autoUpload={false}
                      action="#"
                      onChange={this.onFileChange.bind(this)}
                      draggable={true}
                      listType="picture-text"
                      multiple={false}
                      renderFileInfo={(file, fileElement) => {
                        return (
                          <div>
                            <span>File Name: {file.name}</span>
                          </div>
                        );
                      }}
                    >
                      <div style={{ lineHeight: "200px" }}>
                        Click or Drag an image to this area to upload
                      </div>
                    </Uploader>
                  </div>
                </CardBody>
              </Card>
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Nutrition Facts</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Calories</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Calories"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="calories"
                            value={this.state.calories}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>CAL</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Protein</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Protein"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="protein"
                            value={this.state.protein}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>g</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Carbs</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Carbs"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="carbs"
                            value={this.state.carbs}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>g</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Fats</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Fats"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="fat"
                            value={this.state.fat}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>g</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Saturated Fat</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Saturated Fat"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="satFat"
                            value={this.state.satFat}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>g</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Trans Fat</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Trans Fat"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="transFat"
                            value={this.state.transFat}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>g</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Cholesterol</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Cholesterol"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="chol"
                            value={this.state.chol}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>mg</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Sodium</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Sodium"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="sodium"
                            value={this.state.sodium}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>mg</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Dietary Fiber</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Fiber"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="fiber"
                            value={this.state.fiber}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>g</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Total Sugar</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Sugar"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="sugar"
                            value={this.state.sugar}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>g</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Vitamin D</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Vitamin D"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="vitaminD"
                            value={this.state.vitaminD}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>IU</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Potassium</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Potassium"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="potassium"
                            value={this.state.potassium}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>mg</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Calcium</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Calium"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="calcium"
                            value={this.state.calcium}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>mg</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <h4>Iron</h4>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Iron"
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="iron"
                            value={this.state.iron}
                            className="text-dark"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>mg</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="mt-0 mb-3"></hr>
                  <FormGroup className="mb-0">
                    <h4>Allergens</h4>
                    {this.state.loadingAllergens || this.state.loading ? (
                      <>
                        <Spinner color="medium" size="sm" />
                      </>
                    ) : (
                      <>
                        <TagPicker
                          style={{ width: "100%" }}
                          size="lg"
                          value={this.state.allergens}
                          data={this.state.allAllergens}
                          labelKey={"name"}
                          valueKey={"_id"}
                          placeholder="Select Allergens"
                          onClean={() => this.setState({ allergens: [] })}
                          onChange={this.allergenChange.bind(this)}
                          cleanable={true}
                        />
                      </>
                    )}
                  </FormGroup>
                </CardBody>
              </Card>
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Heating Instructions</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <FormGroup className="m-0">
                    <p
                      style={{
                        fontSize: "14px",
                      }}
                      className="font-weight-bold text-dark mb-2"
                    >
                      Microwave Instructions:
                    </p>
                    <RadioGroup
                      style={{ width: "100%" }}
                      name="radioList"
                      inline
                      appearance="picker"
                      defaultValue="standard"
                      className={this.state.microwave == "custom" ? "mb-3" : ""}
                      value={this.state.microwave}
                      onChange={(v) => {
                        this.setState({ microwave: v });
                      }}
                    >
                      <Radio value="standard">Standard</Radio>
                      <Radio value="custom">Custom</Radio>
                      <Radio value="notheated">Not Heated</Radio>
                    </RadioGroup>
                    {this.state.microwave == "custom" ? (
                      <Editor
                        editorState={this.state.microwaveState}
                        onEditorStateChange={this.onMicrowaveStateChange.bind(
                          this
                        )}
                      ></Editor>
                    ) : null}
                  </FormGroup>
                  <hr className="my-3"></hr>
                  <FormGroup className="m-0">
                    <p
                      style={{
                        fontSize: "14px",
                      }}
                      className="font-weight-bold text-dark mb-2"
                    >
                      Air Fryer Instructions:
                    </p>
                    <RadioGroup
                      style={{ width: "100%" }}
                      name="radioList"
                      inline
                      appearance="picker"
                      defaultValue="standard"
                      className={this.state.airFry == "custom" ? "mb-3" : ""}
                      value={this.state.airFry}
                      onChange={(v) => {
                        this.setState({ airFry: v });
                      }}
                    >
                      <Radio value="standard">Standard</Radio>
                      <Radio value="custom">Custom</Radio>
                      <Radio value="notheated">Not Heated</Radio>
                    </RadioGroup>
                    {this.state.airFry == "custom" ? (
                      <Editor
                        editorState={this.state.airFryState}
                        onEditorStateChange={this.onAirFryStateChange.bind(
                          this
                        )}
                      ></Editor>
                    ) : null}
                  </FormGroup>
                  <hr className="my-3"></hr>
                  <FormGroup className="m-0">
                    <p
                      style={{
                        fontSize: "14px",
                      }}
                      className="font-weight-bold text-dark mb-2"
                    >
                      Oven Instructions:
                    </p>
                    <RadioGroup
                      style={{ width: "100%" }}
                      name="radioList"
                      inline
                      appearance="picker"
                      defaultValue="standard"
                      className={this.state.oven == "custom" ? "mb-3" : ""}
                      value={this.state.oven}
                      onChange={(v) => {
                        this.setState({ oven: v });
                      }}
                    >
                      <Radio value="standard">Standard</Radio>
                      <Radio value="custom">Custom</Radio>
                      <Radio value="notheated">Not Heated</Radio>
                    </RadioGroup>
                    {this.state.oven == "custom" ? (
                      <Editor
                        editorState={this.state.ovenState}
                        onEditorStateChange={this.onOvenStateChange.bind(this)}
                      ></Editor>
                    ) : null}
                  </FormGroup>
                </CardBody>
              </Card>
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Finished Product</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="12">
                      <FormGroup className="mb-0">
                        <h4>Food Weight</h4>
                        <InputGroup>
                          <Input
                            onChange={this.handleNumberInputChange.bind(this)}
                            name="foodWeight"
                            value={this.state.foodWeight}
                            className="text-dark"
                            type="text"
                            placeholder="Food Weight"
                          ></Input>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>oz</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Third Party Apps</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="12">
                      <FormGroup className="mb-0">
                        <h4>MyFitnessPal URL</h4>
                        <Input
                          onChange={this.handleInputChange.bind(this)}
                          name="myfitnesspalURL"
                          value={this.state.myfitnesspalURL}
                          className="text-dark"
                          type="text"
                          placeholder="MyFitnessPal URL"
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="border-top">
                  <Row>
                    <Col xs="12" sm="12">
                      <FormGroup className="mb-0">
                        <h4>Square Retail Catalog Item</h4>
                        <SelectPicker
                          onChange={(v) => {
                            this.setState({
                              squareItem: v,
                              modified: true,
                            });
                          }}
                          value={this.state.squareItem}
                          placeholder="Catalog Item"
                          data={this.state.squareItemOptions}
                          loading={this.state.loadingSquareItems}
                          placement="autoVertical"
                          block
                          cleanable={true}
                          size="lg"
                          groupBy={"category"}
                        ></SelectPicker>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/*<Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Menus</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody></CardBody>
        </Card>*/}
            </Col>
            <Col xs="12" xl="4">
              <Card className="mb-4 shadow d-xl-block d-none">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Image</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="text-center">
                  <div className="text-left">
                    <Uploader
                      fileList={this.state.selectedFile}
                      autoUpload={false}
                      action="#"
                      onChange={this.onFileChange.bind(this)}
                      draggable={true}
                      listType="picture-text"
                      multiple={false}
                      renderFileInfo={(file, fileElement) => {
                        return (
                          <div>
                            <span>File Name: {file.name}</span>
                          </div>
                        );
                      }}
                    >
                      <div style={{ lineHeight: "200px" }}>
                        Click or Drag an image to this area to upload
                      </div>
                    </Uploader>
                  </div>
                </CardBody>
              </Card>
              {/*<Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Insights</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody></CardBody>
    </Card>*/}
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
          <Row>
            <Col xs=""></Col>
            <Col xs="auto">
              <Button
                color="secondary"
                outline
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button
                color={"primary"}
                disabled={this.state.submitting || !this.hasRequiredFields()}
                onClick={() => this.create()}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  <>Create</>
                )}
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(MealCreateView);
