import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, Dropdown, Popover, SelectPicker, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import SuppressedContactBulkCreateDrawer from "../../components/Drawers/SuppressedContactBulkCreateDrawer";
import { useRef } from "react";
import { useState } from "react";
import swal from "sweetalert";

const DropdownOptions = ({ contact }) => {
  const dropdownRef = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteContact = () => {
    swal({
      title: `${contact?.type == "UNSUBSCRIBED" ? "Resubscribe" : ""}${
        contact?.type == "BLOCKED" ? "Unblock" : ""
      } Contact`,
      text: `Are you sure you want to ${
        contact?.type == "UNSUBSCRIBED" ? "resubscribe" : ""
      }${contact?.type == "BLOCKED" ? "unblock" : ""} ${contact?.phone}?`,
      icon: "warning",
      buttons: [
        "Nevermind",
        `${contact?.type == "UNSUBSCRIBED" ? "Resubscribe" : ""}${
          contact?.type == "BLOCKED" ? "Unblock" : ""
        }`,
      ],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      setDeleting(true);

      APIV2.deleteContactSuppressionForStore(contact?.storeID, contact?._id)
        .then(
          (data) => {
            PubSub.publish(Event.SUPPRESSED_CONTACT.DELETED);
          },
          (e) => {
            window.alert(
              e?.response?.message ??
                `Unable to ${
                  contact?.type == "UNSUBSCRIBED" ? "resubscribe" : ""
                }${
                  contact?.type == "BLOCKED" ? "unblock" : ""
                } contact. Please try again.`
            );
          }
        )
        .finally(() => {
          setDeleting(false);
        });
    });
  };

  return (
    <Whisper
      onClose={() => {
        setDropdownOpen(false);
      }}
      trigger="click"
      ref={dropdownRef}
      placement="autoVerticalEnd"
      speaker={
        <Popover className="dropdown-popover" full>
          <Dropdown.Menu
            style={{
              minWidth: 150,
              paddingBottom: 0,
            }}
          >
            <Dropdown.Item
              panel
              className="font-weight-800 text-uppercase"
              style={{
                padding: 12,
              }}
            >
              <h5 className="m-0 text-dark">Actions</h5>
            </Dropdown.Item>
            <Dropdown.Item className="my-0" divider></Dropdown.Item>
            <Dropdown.Item
              disabled={deleting || contact?.type == "UNSUBSCRIBED"}
              onClick={(e) => {
                e.stopPropagation();

                deleteContact();

                dropdownRef.current.close();
              }}
              className={`${
                deleting || contact?.type == "UNSUBSCRIBED"
                  ? "text-light"
                  : "text-dark bg-superlight--hover"
              }`}
            >
              <Row className="align-items-center">
                <Col xs="auto" className="pr-0">
                  <i
                    className={`mdi mdi-check-circle-outline`}
                    style={{ fontSize: 16, lineHeight: 1.3 }}
                  ></i>
                </Col>
                <Col xs="" className="pl-2">
                  <p className="m-0" style={{ fontSize: 14 }}>
                    {contact?.type == "UNSUBSCRIBED" ? "Resubscribe" : null}
                    {contact?.type == "BLOCKED" ? "Unblock" : null}&nbsp;Contact
                  </p>
                </Col>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      }
    >
      <Button
        size="sm"
        outline
        color="dark"
        className="btn-icon-only"
        onClick={(e) => {
          e.stopPropagation();

          if (dropdownOpen) {
            setDropdownOpen(false);

            return dropdownRef.current.close();
          }

          setDropdownOpen(true);

          dropdownRef.current.open();
        }}
      >
        <i className="mdi mdi-dots-vertical"></i>
      </Button>
    </Whisper>
  );
};

class StorefrontSupressionListView extends React.Component {
  state = {
    active: {
      id: "All Unsubscribed",
      name: "All Unsubscribed",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      dueDate: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (prevProps?.match?.params.storeID != this.props.match?.params?.storeID) {
      this.handleTabChange(this.state.active, true);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();

    PubSub.subscribe(Event.SUPPRESSED_CONTACT.CREATED, (m) => {
      this.loadSuppressedContacts(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.SUPPRESSED_CONTACT.MODIFIED, (a) => {
      this.loadSuppressedContacts(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailTask?._id == a?._id) {
        this.setState({
          detailTask: a,
        });
      }
    });

    PubSub.subscribe(Event.SUPPRESSED_CONTACT.DELETED, (a) => {
      this.loadSuppressedContacts(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  loadSuppressedContacts(page = 1, count = 25, filters = null, orderBy) {
    this.setState({
      loadingSuppressedContacts: true,
    });

    APIV2.getSuppressedContactsForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let contacts = [];

            if (data.data.contacts.length) {
              contacts = data.data.contacts;
            }

            this.setState({
              contacts,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the contacts. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingSuppressedContacts: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchSuppressedContactsForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultSuppressedContacts: data.data.contacts,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({
            searchResults: true,
            searchResultSuppressedContacts: [],
          });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All Unsubscribed") {
      filters = {
        type: "UNSUBSCRIBED",
      };
      orderBy = { createdAt: -1 };
    } else if (option.id == "All Blocked") {
      filters = {
        type: "BLOCKED",
      };
      orderBy = { createdAt: -1 };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadSuppressedContacts(1, this.state.count, filters, orderBy);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            let store = _.findWhere(data.data.stores, {
              _id: this.props?.match?.params?.storeID,
            });

            this.setState({ stores: data.data.stores, store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadSuppressedContacts(page, this.state.count, this.state.filters);
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    console.log(dateString);

    let now = moment();
    let due = moment(dateString);

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return <Badge color="info">{status}</Badge>;
    }

    if (status == "Completed") {
      return <Badge color="light">{status}</Badge>;
    }
  }

  row(c, i) {
    return (
      <div
        className={`px-3 py-2 ${
          i != this.state.contacts.length - 1 ? "border-bottom" : ""
        }`}
        key={c?._id}
      >
        <Row className="align-items-center">
          <Col xs="" sm="" md="5">
            <h4 className="mb-0">
              {StringUtils.formatPhoneNumber(c?.phone) ?? c?.phone}
            </h4>
            <p
              className="small m-0 text-muted text-capitalize"
              style={{ lineHeight: 1.2 }}
            >
              {c?.type?.toLowerCase()}
            </p>
          </Col>
          <Col xs="12" sm="12" md="4" className="order-12 order-md-1">
            {moment(c?.createdAt).format("MM/DD/YYYY")}
          </Col>
          <Col
            xs="auto"
            sm="auto"
            md="3"
            className="order-1 order-md-12 text-right"
          >
            <DropdownOptions contact={c}></DropdownOptions>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Suppressed Contacts"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All Unsubscribed",
                      id: "All Unsubscribed",
                    },
                    {
                      name: "All Blocked",
                      id: "All Blocked",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="" className="">
                      <Input
                        type="text"
                        placeholder="Search contacts by phone..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultSuppressedContacts?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultSuppressedContacts
                                  ?.length
                              )
                            : "0"}{" "}
                          contact
                          {this.state.searchResultSuppressedContacts?.length ==
                          1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  contacts
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="" sm="" md="5">
                        <h4 className="mb-0">Contact</h4>
                      </Col>

                      <Col
                        xs="12"
                        sm="6"
                        md="4"
                        className="order-12 order-sm-1"
                      >
                        <h4 className={`mb-0 cursor-pointer`}>Date</h4>
                      </Col>
                      <Col
                        xs="auto"
                        sm="auto"
                        md="3"
                        className="order-1 order-sm-12 text-right d-none d-md-block"
                      >
                        <h4 className="mb-0">Actions</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingSuppressedContacts ||
                    this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultSuppressedContacts?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No contacts found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultSuppressedContacts?.map(
                          (c, i) => (
                            <>{this.row(c, i)}</>
                          )
                        )}
                      </div>
                    ) : (
                      <div>
                        {!this.state.contacts?.length &&
                        !this.state.loadingSuppressedContacts ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                Get started by adding your first contact.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.contacts?.map((c, i) => (
                          <>{this.row(c, i)}</>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <SuppressedContactBulkCreateDrawer
          store={{ _id: this.props.match?.params?.storeID }}
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.SUPPRESSED_CONTACT.CREATED, c);
          }}
        ></SuppressedContactBulkCreateDrawer>
      </>
    );
  }
  f;
}

export default StorefrontSupressionListView;
