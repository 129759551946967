import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Col, Row } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import NewConsultationDrawer from "./NewConsultationDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";

class ConsultationDetailDrawer extends React.Component {
  state = {
    dueDate: moment().day(6).toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    recentFirst: "recentFirst",
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      prevProps?.consultation != this.props.consultation &&
      this.props.consultation
    ) {
      this.fetchNotes(true);
    }
  }

  componentDidMount() {
    if (this.props.consultation) {
      this.fetchNotes(true);
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  modifyStatus(status) {
    this.setState({ submitting: true });

    APIV2.modifyAppointmentStatus(
      this.props?.consultation?.storeID,
      this?.props?.consultation?._id,
      status
    )
      .then(
        (data) => {
          const appointment = data.data.appointment;

          //this.toggleModal();

          PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to update consultation status - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  fetchNotes(forceLoad = false) {
    if (!this.props.consultation) {
      return;
    }

    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      appointmentID: this?.props?.consultation?._id,
    };

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore(
      this.props?.consultation?.storeID,
      this.state.page,
      20,
      query,
      sortBy
    )
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Consultation Details
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Status</h5>
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.consultation?.status == "CONFIRMED" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("CONFIRMED");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as Confirmed
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.consultation?.status == "COMPLETED" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("COMPLETED");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.consultation?.status == "NO SHOW" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("NO SHOW");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as No Show
                      </Dropdown.Item>

                      <Dropdown.Item divider></Dropdown.Item>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Actions</h5>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          this.setState({
                            modifyOpen: true,
                            modifyConsult: this.props.consultation,
                            modifyMode: "reschedule",
                          });

                          this.dropdownRef.current.close();
                        }}
                      >
                        Reschedule
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          this.setState({
                            modifyOpen: true,
                            modifyConsult: this.props.consultation,
                            modifyMode: "information",
                          });

                          this.dropdownRef.current.close();
                        }}
                      >
                        Modify Information
                      </Dropdown.Item>
                      <Dropdown.Item divider></Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => {
                          swal({
                            title: "Cancel Consultation",
                            text: `Are you sure you want to cancel this consultation?`,
                            icon: "warning",
                            buttons: ["Nevermind", "Cancel"],
                            dangerMode: true,
                          }).then((conf) => {
                            if (!conf) {
                              return;
                            }

                            this.modifyStatus("CANCELLED");

                            this.dropdownRef.current.close();
                          });
                        }}
                      >
                        Cancel Appointment
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button size="sm" outline color="secondary">
                  Actions <i className="mdi mdi-chevron-down pl-2"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <Row className="align-items-center mb-2">
                <Col xs="">
                  <h4 className="m-0">
                    {moment(this.props.consultation?.startsAt).format(
                      "MM/DD/YY hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(this.props.consultation?.endsAt).format("hh:mm A")}
                  </h4>
                </Col>
                <Col xs="auto">
                  <Badge
                    color="medium"
                    style={{ position: "relative", top: -2 }}
                  >
                    {this.props.consultation?.status}
                  </Badge>
                </Col>
              </Row>
              <h2 className="m-0">{this.props.consultation?.name}</h2>
            </FormGroup>
            {this.props.consultation?.goal ? (
              <FormGroup>
                <h5>Goal</h5>
                <p className="m-0">{this.props.consultation?.goal}</p>
              </FormGroup>
            ) : null}
            <Row className="align-items-top">
              <Col xs="12" md="12">
                <FormGroup>
                  <h5>
                    Additional Notes Shared By{" "}
                    {this.props.consultation?.customer ? "Member" : "Lead"}
                  </h5>
                  <p className="m-0">
                    {this.props.consultation?.additionalNotes ?? "--"}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <div>
              {this.props.consultation?.customer ||
              this.props.consultation?.lead ? (
                <>
                  <hr className="my-3"></hr>
                  <Row className="mb-3 align-items-center">
                    <Col xs="">
                      <h4 className="mb-0">
                        Related{" "}
                        {this.props.consultation?.customer ? "Member" : ""}
                        {this.props.consultation?.lead &&
                        !this.props.consultation?.customer
                          ? "Lead"
                          : ""}
                      </h4>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="secondary"
                        outline
                        onClick={() => {
                          let url = "";

                          if (this.props.consultation?.customer?._id) {
                            url = `/storefront/${this.props?.consultation?.storeID}/members/details/${this.props.consultation?.customer?._id}/summary`;
                          } else if (this.props.consultation?.lead?._id) {
                            url = `/storefront/${this.props?.consultation?.storeID}/leads/details/${this.props.consultation?.leadID}/summary`;
                          }

                          LinkUtils.openInNewTab(url, true);
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                  {this.props.consultation?.customer ? (
                    <>
                      <CustomerContactInformationWidget
                        customer={this.props.consultation?.customer}
                        flat={true}
                      />
                    </>
                  ) : null}
                  {this.props.consultation?.lead &&
                  !this.props.consultation?.customer ? (
                    <>
                      <LeadContactInformationWidget
                        lead={this.props.consultation?.lead}
                        flat={true}
                      ></LeadContactInformationWidget>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>

            {/**
             * <hr className="my-3"></hr>
            <h4 className="mb-3">Completion Information</h4>
            <Row className="align-items-top">
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>
                    Due Date
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  {this.formatDueDate(
                    this.props.consultation?.dueDate,
                    this.props.consultation?.status
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Status</h5>
                  <p className="m-0">{this.props.consultation?.status}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Assigned To</h5>
                  <p className="m-0">
                    {this.props.consultation?.assignedTo?.name
                      ? this.props.consultation?.assignedTo?.name
                      : "--"}
                  </p>
                </FormGroup>
              </Col>
              
            </Row>
             */}
            <hr className="my-3"></hr>
            <Row className="align-items-center mb-3">
              <Col>
                <h4 className="text-dark mb-0">Notes</h4>
              </Col>
              <Col xs="auto">
                <SelectPicker
                  size="sm"
                  searchable={false}
                  data={[
                    { label: "Newest First", value: "recentFirst" },
                    { label: "Oldest First", value: "oldestFirst" },
                  ]}
                  value={this.state.recentFirst}
                  onChange={(v) => {
                    this.handleRecentFirstChange(v);
                  }}
                  cleanable={false}
                  placement="auto"
                ></SelectPicker>
              </Col>
            </Row>

            <div>
              <NoteBody
                appointment={this.props.consultation}
                onSave={(note) => {
                  console.log(note);

                  this.setState(
                    {
                      page: 1,
                    },
                    () => {
                      this.fetchNotes(true);
                    }
                  );
                }}
              ></NoteBody>
            </div>
            <div>
              {this.state?.loading && (
                <div className="px-3 py-5 text-center">
                  <Spinner size="sm" color="dark"></Spinner>
                </div>
              )}
              {!this.state?.notes?.length && !this.state.loading && (
                <div className="px-3 py-5">
                  <Row>
                    <Col xs="12" className="text-center">
                      <p className="m-0">No notes found.</p>
                    </Col>
                  </Row>
                </div>
              )}

              {this.state?.notes?.map((note, i) => (
                <div key={note?._id}>
                  {this.state.editNote == note?._id ? (
                    <>
                      <div className="p-4">
                        <NoteBody
                          note={note}
                          appointment={this.props.consultation}
                          forceEdit={true}
                          onSave={(n) => {
                            let notes = this.state.notes;

                            let idx = _.findIndex(notes, { _id: n._id });

                            if (idx >= 0) {
                              notes[idx] = n;
                            }

                            this.setState({
                              editNote: "",
                              notes,
                            });
                          }}
                          onCancel={() => {
                            this.setState({ editNote: "" });
                          }}
                        ></NoteBody>
                      </div>
                    </>
                  ) : (
                    <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                      <Row>
                        <Col xs="">
                          {note?.title ? (
                            <>
                              <h3 className="text-dark mb-1">{note?.title}</h3>
                              <Linkify body={note?.body} wrap={true}></Linkify>
                            </>
                          ) : (
                            <>
                              <Linkify body={note?.body} wrap={true}></Linkify>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <Button
                            size="sm"
                            color="secondary"
                            outline
                            className="p-1 mr-0"
                            style={{ width: 22, height: 22 }}
                            onClick={() => {
                              this.setState({
                                editNote: note?._id,
                              });
                            }}
                          >
                            <i
                              className="mdi mdi-pencil"
                              style={{
                                fontSize: 16,
                                lineHeight: 1,
                                position: "relative",
                                left: -2,
                                top: -2,
                              }}
                            ></i>
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            outline
                            className="p-1"
                            style={{ width: 22, height: 22 }}
                            disabled={this.state.deletingNote == note?._id}
                            onClick={() => {
                              swal({
                                title: "Delete Note?",
                                text: "Are you sure you want to delete this note?",
                                icon: "warning",
                                buttons: ["Nevermind", "Delete"],
                                dangerMode: true,
                              }).then((conf) => {
                                if (!conf) {
                                  return;
                                }

                                APIV2.deleteStoreNote(
                                  note?.storeID,
                                  note?._id
                                ).then(() => {
                                  let notes = this.state.notes;

                                  let idx = _.findIndex(notes, {
                                    _id: note._id,
                                  });

                                  notes.splice(idx, 1);

                                  this.setState({
                                    editNote: "",
                                    notes,
                                  });

                                  this.setState({
                                    deletingNote: "",
                                  });
                                });
                              });
                            }}
                          >
                            <i
                              className="mdi mdi-close"
                              style={{
                                fontSize: 18,
                                lineHeight: 1,
                                position: "relative",
                                left: -3,
                                top: -3,
                              }}
                            ></i>
                          </Button>
                        </Col>
                        {note?.attachments?.length > 0 && (
                          <>
                            <Col className="mt-2" xs="12">
                              <h5>Attachments: </h5>
                              {note?.attachments?.map((at, i) => (
                                <div
                                  className={`p-2 border rounded ${
                                    i != this.state?.attachments?.length - 1 &&
                                    "mb-2"
                                  }`}
                                  key={i}
                                >
                                  <Row className="align-items-center">
                                    <Col xs="">
                                      <p className="small m-0 text-dark">
                                        {at?.fileName}
                                      </p>
                                    </Col>
                                    <Col xs="auto">
                                      <Button
                                        className="btn-icon-only"
                                        size="sm"
                                        outline
                                        color="secondary"
                                        onClick={() => {
                                          LinkUtils.openPrivateURL(at?.url);
                                        }}
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </Col>
                          </>
                        )}
                      </Row>
                      <div className="mt-2">
                        <small>
                          {moment(note?.createdAt).format("MM/DD/YY h:mmA")} by{" "}
                          {note?.createdBy?.name}
                        </small>
                      </div>
                    </div>
                  )}
                  {i != this.state?.notes?.length - 1 && (
                    <hr className="m-0"></hr>
                  )}
                </div>
              ))}
            </div>
            <div className="text-center p-2">
              <Button
                size="sm"
                color="secondary"
                outline
                onClick={() => {
                  console.log(this.state.pagination);

                  this.setState(
                    {
                      page: this.state.pagination?.next,
                    },
                    () => {
                      this.fetchNotes();
                    }
                  );
                }}
                disabled={!this.state.pagination?.hasNext}
              >
                {this.state.pagination?.hasNext
                  ? "View More Notes"
                  : "Viewing All Notes"}
              </Button>
            </div>
          </Drawer.Body>
        </Drawer>
        <NewConsultationDrawer
          store={this.props.store}
          open={this.state.modifyOpen}
          consultation={this.state.modifyConsult}
          mode={this.state.modifyMode}
          onClose={() => {
            this.setState({
              modifyOpen: false,
              modifyConsult: null,
              modifyMode: "",
            });
          }}
        ></NewConsultationDrawer>
      </>
    );
  }
}

export default withRouter(ConsultationDetailDrawer);
