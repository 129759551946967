import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  Badge,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner, Badge as RBadge } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class StoreLeaderboardRetailCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
  };

  async _fetchSalesGoalData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsWeeklyRetailSales.metadataSalesTotalAverage",
            "MetricsWeeklyRetailSales.metadataSalesGoalAverage",
            "MetricsWeeklyRetailSales.metadataPercentGoal",
          ],
          order: {
            "MetricsWeeklyRetailSales.metadataPercentGoal": "desc",
          },

          timeDimensions: [
            {
              dimension: "MetricsWeeklyRetailSales.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "MetricsWeeklyRetailSales.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsWeeklyRetailSales.name",
              operator: "equals",
              values: ["weekly_retail_sales"],
            },
            {
              member: "Stores.type",
              operator: "equals",
              values: ["retail"],
            },
          ],
          dimensions: [
            "Stores.name",
            "Stores.locationAddressCity",
            "Stores.locationAddressState",
            "Stores.level",
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          console.log(data);

          data = data.map((item) => {
            return {
              totalGoalCount:
                item["MetricsWeeklyRetailSales.metadataSalesGoalAverage"],
              avgFulfilledCount:
                item["MetricsWeeklyRetailSales.metadataSalesTotalAverage"],
              storeName: item["Stores.name"]?.replace(
                "Project LeanNation",
                "PLN"
              ),
              level: item["Stores.level"],
              goalPercent: parseFloat(
                (
                  item["MetricsWeeklyRetailSales.metadataPercentGoal"] * 100
                ).toFixed(1)
              ),
              location: item["Stores.locationAddressCity"]
                ? `${item["Stores.locationAddressCity"]?.trim()}, ${item[
                    "Stores.locationAddressState"
                  ]?.trim()}`
                : null,
            };
          });

          for (let i = 0; i < data?.length; i++) {
            let item = data[i];

            if (item.goalPercent < 50) {
              item.goalColor = "rose";
            } else if (item.goalPercent < 60) {
              item.goalColor = "orange";
            } else if (item.goalPercent < 70) {
              item.goalColor = "amber";
            } else if (item.goalPercent < 85) {
              item.goalColor = "yellow";
            } else if (item.goalPercent < 93) {
              item.goalColor = "lime";
            } else if (item.goalPercent >= 93) {
              item.goalColor = "emerald";
            }
          }

          return resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchSalesGoalData(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load store order leaderboard summary.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let previousCount = null;

    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(dateRange[0].toISOString());
    let endMoment = moment(dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    try {
      previousCount = await this._fetchSalesGoalData(cubejsApi, stores, [
        startMoment.toDate(),
        endMoment.toDate(),
      ]);
    } catch (e) {}

    if (previousCount !== null) {
      for (let i = 0; i < currentCount?.length; i++) {
        let item = currentCount[i];

        item.delta = null;

        if (_.findWhere(previousCount, { storeName: item?.storeName })) {
          const prev = _.findIndex(previousCount, {
            storeName: item?.storeName,
          });

          if (prev != -1) {
            item.delta = Math.abs(i - prev) * (i <= prev ? 1 : -1);

            item.previousIndex = prev;

            item.delta = parseInt(item.delta);

            if (item?.delta === 0) {
              item.deltaType = "unchanged";
            } else if (item?.delta > 0) {
              item.deltaType = "increase";
            } else if (item?.delta < 0) {
              item.deltaType = "decrease";
            }
          }
        }
      }
    }

    this.setState({
      orderLeaderboard: currentCount,
      loading: false,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <Row>
            <Col xs="">
              <h3 className="m-0 text-dark">Store Leaderboard</h3>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                outline
                color="dark"
                className="border-0 btn-icon-only"
                disabled={this.state.loading}
                onClick={() => {
                  this.loadDetailedReport(
                    this.props.stores,
                    this.props.dateRange
                  );
                }}
              >
                {this.state.loading ? (
                  <Spinner size="sm"></Spinner>
                ) : (
                  <i className="mdi mdi-refresh"></i>
                )}
              </Button>
            </Col>
          </Row>

          <div className="mx--4">
            {this.state.loading ? (
              <>
                <div className="mt-3"></div>
                <div className={`px-3 pt-3 border-top border-bottom pb-3`}>
                  <div
                    style={{ height: 54, width: "100%" }}
                    className="skeleton"
                  ></div>
                </div>
                <div className={`px-3 pt-3 border-bottom pb-3`}>
                  <div
                    style={{ height: 54, width: "100%" }}
                    className="skeleton"
                  ></div>
                </div>
                <div className={`px-3 pt-3 border-bottom pb-3`}>
                  <div
                    style={{ height: 54, width: "100%" }}
                    className="skeleton"
                  ></div>
                </div>
                <div className={`px-3 pt-3 border-bottom pb-3`}>
                  <div
                    style={{ height: 54, width: "100%" }}
                    className="skeleton"
                  ></div>
                </div>
                <div className={`px-3 pt-3 border-bottom pb-3`}>
                  <div
                    style={{ height: 54, width: "100%" }}
                    className="skeleton"
                  ></div>
                </div>
                <div className={`px-3 pt-3`}>
                  <div
                    style={{ height: 54, width: "100%" }}
                    className="skeleton"
                  ></div>
                </div>
              </>
            ) : (
              <>
                {this.state.orderLeaderboard?.length ? (
                  <>
                    <div className="mt-3"></div>
                    {this.state.orderLeaderboard?.map((store, i) => (
                      <div
                        key={i}
                        className={`px-3 pt-3 ${i == 0 ? "border-top" : ""} ${
                          i != this.state.orderLeaderboard?.length - 1 &&
                          "border-bottom pb-3"
                        }`}
                      >
                        <Row className="align-items-center">
                          <Col
                            xs="auto"
                            style={{ width: 55 }}
                            className="text-center h2 m-0 pr-0"
                          >
                            {i + 1}
                          </Col>
                          <Col xs="">
                            <Row className="align-items-center">
                              <Col xs="12" sm="">
                                <Row className="align-items-center">
                                  <Col xs="12" sm="">
                                    <h3
                                      className="m-0"
                                      style={{ lineHeight: 1.3 }}
                                    >
                                      {store?.storeName}
                                      {store?.level ? (
                                        <RBadge
                                          color="light"
                                          className="ml-2 border py-1"
                                          style={{
                                            position: "relative",
                                            top: -2,
                                          }}
                                        >
                                          {store?.level}
                                        </RBadge>
                                      ) : null}
                                    </h3>
                                  </Col>
                                  <Col
                                    xs="12"
                                    className="text-sm-right mt-1 mt-sm-0"
                                    sm=""
                                  >
                                    <p
                                      className="m-0 small text-dark"
                                      style={{ lineHeight: 1.3 }}
                                    >
                                      {store?.location}
                                    </p>
                                  </Col>
                                </Row>
                                <Flex marginTop="mt-0">
                                  <Text>
                                    ~
                                    {StringUtils.centsToCurrency(
                                      store.avgFulfilledCount
                                    )}
                                    /Week &bull; {store.goalPercent}%
                                  </Text>
                                  <Text>
                                    ~
                                    {StringUtils.centsToCurrency(
                                      store?.totalGoalCount
                                    )}
                                    /Week Goal
                                  </Text>
                                </Flex>
                                <ProgressBar
                                  marginTop="mt-1"
                                  percentageValue={store.goalPercent}
                                  color={store.goalColor}
                                />
                              </Col>
                              <Col
                                xs="12"
                                className="mt-3 mt-sm-0 text-sm-right"
                                sm="4"
                                md="3"
                              >
                                {store?.delta !== null ? (
                                  <BadgeDelta
                                    text={`${store?.delta > 1 ? "+" : ""}${
                                      store?.delta
                                    } (prev #${store?.previousIndex + 1})`}
                                    deltaType={store?.deltaType}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="mt-3">No data available.</div>
                  </>
                )}
              </>
            )}
          </div>
        </Card>
      </>
    );
  }
}

export default StoreLeaderboardRetailCard;
