import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { DatePicker, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import ChartRenderer from "components/ChartRenderer";
import LeadsByStorePieChart from "components/Charts/LeadsByStorePieChart";

class LeadSummary extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    compareToOptions: [],
    byStoreDateRangeString: "This week",
    byStoreDateRange: [
      moment()
        .day(0)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format("YYYY-MM-DD"),
      moment()
        .day(6)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(999)
        .format("YYYY-MM-DD"),
    ],
    leadGrowthOpen: true,
    leadConversionOpen: true,
    rangeOptions: [
      {
        value: "Today",
        label: "Today",
      },
      {
        value: "Yesterday",
        label: "Yesterday",
      },
      {
        value: "This week",
        label: "This Week",
      },
      {
        value: "This month",
        label: "This Month",
      },
      {
        value: "This quarter",
        label: "This Quarter",
      },
      {
        value: "This year",
        label: "This Year",
      },
      {
        value: "Last week",
        label: "Last Week",
      },
      {
        value: "Last month",
        label: "Last Month",
      },
      {
        value: "Last quarter",
        label: "Last Quarter",
      },
      {
        value: "Last year",
        label: "Last Year",
      },
      {
        value: "Last 7 days",
        label: "Last 7 Days",
      },
      {
        value: "Last 30 days",
        label: "Last 30 Days",
      },
    ],
  };

  setDateRange(v) {
    this.setState({ byStoreDateRangeString: v });

    let byStoreDateRange = [];

    if (v == "This week") {
      byStoreDateRange = [
        moment()
          .day(0)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .format("YYYY-MM-DD"),
        moment()
          .day(6)
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999)
          .format("YYYY-MM-DD"),
      ];
    } else if (v == "Last week") {
      byStoreDateRange = [
        moment()
          .day(0)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .subtract(1, "week")
          .format("YYYY-MM-DD"),
        moment()
          .day(6)
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999)
          .subtract(1, "week")
          .format("YYYY-MM-DD"),
      ];
    } else {
      byStoreDateRange = v;
    }

    console.log(byStoreDateRange);

    this.setState({
      byStoreDateRange: byStoreDateRange,
    });
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader title="Lead Summary" showBackButton={false} />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-4">
            <div
              className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
              onClick={() => {
                this.setState({ leadGrowthOpen: !this.state.leadGrowthOpen });
              }}
            >
              <h3 className="mb-0">
                <i
                  className={`pr-3 mdi mdi-chevron-${
                    this.state.leadGrowthOpen ? "down" : "right"
                  }`}
                />
                Lead Growth
              </h3>
            </div>
            <Collapse isOpen={this.state.leadGrowthOpen}>
              <Row>
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Total New Leads</h3>
                          <p style={{ lineHeight: 1.2 }} className="m-0">
                            <small>
                              Number of leads that have a name, email, and phone
                              number.
                            </small>
                          </p>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody
                      className="p-2 pt-3"
                      style={{ minHeight: "100px" }}
                    >
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: ["CustomerLeads.totalWithInformation"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.createdat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.totalWithInformation": "desc",
                          },

                          filters: [
                            {
                              member: "Stores.name",
                              operator: "set",
                            },
                          ],
                        }}
                        type="number"
                        pivotConfig={{
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Total New Email Contacts</h3>
                          <p style={{ lineHeight: 1.2 }} className="m-0">
                            <small>
                              Number of leads that have at least an email.
                            </small>
                          </p>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody
                      className="p-2 pt-3"
                      style={{ minHeight: "100px" }}
                    >
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: ["CustomerLeads.total"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.createdat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.total": "desc",
                          },

                          filters: [
                            {
                              member: "Stores.name",
                              operator: "set",
                            },
                          ],
                        }}
                        type="number"
                        pivotConfig={{
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-top">
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">New Leads By Store</h3>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-2" style={{ minHeight: "300px" }}>
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: ["CustomerLeads.totalWithInformation"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.createdat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.total": "desc",
                          },
                          dimensions: ["Stores.name"],
                          filters: [
                            {
                              member: "Stores.name",
                              operator: "set",
                            },
                          ],
                        }}
                        type="pie"
                        pivotConfig={{
                          x: ["Stores.name"],
                          y: ["measures"],
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">New Leads By Day</h3>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-2" style={{ minHeight: "300px" }}>
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: ["CustomerLeads.totalWithInformation"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.createdat",
                              granularity: "day",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.createdat": "asc",
                          },
                          dimensions: [],
                          filters: [],
                        }}
                        type="bar"
                        pivotConfig={{
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Collapse>
            <div
              className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
              onClick={() => {
                this.setState({
                  leadConversionOpen: !this.state.leadConversionOpen,
                });
              }}
            >
              <h3 className="mb-0">
                <i
                  className={`pr-3 mdi mdi-chevron-${
                    this.state.leadConversionOpen ? "down" : "right"
                  }`}
                />
                Lead Conversion
              </h3>
            </div>
            <Collapse isOpen={this.state.leadConversionOpen}>
              <Row>
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Total Conversions</h3>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody
                      className="p-2 pt-3"
                      style={{ minHeight: "100px" }}
                    >
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: ["CustomerLeads.total"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.convertedat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.total": "desc",
                          },

                          filters: [
                            {
                              member: "Stores.name",
                              operator: "set",
                            },
                          ],
                        }}
                        type="number"
                        pivotConfig={{
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Avg. Conversion Rate</h3>
                          <p style={{ lineHeight: 1.2 }} className="m-0">
                            <small>
                              Avg. percentage of leads that were created and
                              converted{" "}
                              <span className="text-lowercase">
                                {this.state.byStoreDateRangeString}
                              </span>{" "}
                              across all stores.
                            </small>
                          </p>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody
                      className="p-2 pt-3"
                      style={{ minHeight: "100px" }}
                    >
                      <ChartRenderer
                        label="Leads Converted"
                        query={{
                          measures: ["CustomerLeads.closeRate"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.createdat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {},
                          dimensions: [],
                          filters: [],
                        }}
                        type="number"
                        format="percent"
                        pivotConfig={{
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-top">
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Conversions By Store</h3>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-2" style={{ minHeight: "300px" }}>
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: ["CustomerLeads.total"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.convertedat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.total": "desc",
                          },
                          dimensions: ["Stores.name"],
                          filters: [
                            {
                              member: "Stores.name",
                              operator: "set",
                            },
                          ],
                        }}
                        type="pie"
                        pivotConfig={{
                          x: ["Stores.name"],
                          y: ["measures"],
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" md="12" lg="6" className="mb-4">
                  <Card className="shadow border" style={{ height: "100%" }}>
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Conversions By Day</h3>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-2" style={{ minHeight: "300px" }}>
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: ["CustomerLeads.total"],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.convertedat",
                              granularity: "day",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],

                          dimensions: [],
                          filters: [],
                        }}
                        type="bar"
                        pivotConfig={{
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-stretch">
                <Col xs="12" md="12" lg="12" className="mb-4">
                  <Card className="shadow border">
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Conversion Analysis By Store</h3>
                          <p style={{ lineHeight: 1.2 }} className="m-0">
                            <small>
                              Summary of leads that were created and converted{" "}
                              <span className="text-lowercase">
                                {this.state.byStoreDateRangeString}
                              </span>
                              .
                            </small>
                          </p>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody
                      className="p-2"
                      style={{ minHeight: "300px", overflowX: "scroll" }}
                    >
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: [
                            "CustomerLeads.totalWithInformation",
                            "CustomerLeads.converted",
                            "CustomerLeads.convertedWithoutInteraction",
                            "CustomerLeads.convertedWithInteraction",
                            "CustomerLeads.closeRate",
                          ],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.createdat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.converted": "desc",
                          },
                          filters: [
                            {
                              member: "Stores.name",
                              operator: "set",
                            },
                          ],
                          dimensions: ["Stores.name"],
                        }}
                        type="table"
                        pivotConfig={{
                          x: ["Stores.name"],
                          y: ["measures"],
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-stretch">
                <Col xs="12" md="12" lg="12" className="mb-4">
                  <Card className="shadow border">
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <h3 className="m-0">Lead Conversion By Store</h3>
                          <p style={{ lineHeight: 1.2 }} className="m-0">
                            <small>
                              Summary of all leads that were converted{" "}
                              <span className="text-lowercase">
                                {this.state.byStoreDateRangeString}
                              </span>{" "}
                              regardless of when they were created.
                            </small>
                          </p>
                        </Col>
                        <Col xs="12" sm="auto">
                          <SelectPicker
                            placement="auto"
                            className="mt-3 mt-sm-0"
                            style={{ width: "100%" }}
                            block
                            data={this.state.rangeOptions}
                            value={this.state.byStoreDateRangeString}
                            onChange={(v) => {
                              this.setDateRange(v);
                            }}
                            cleanable={false}
                          ></SelectPicker>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody
                      className="p-2"
                      style={{ minHeight: "300px", overflowX: "scroll" }}
                    >
                      <ChartRenderer
                        label="leads"
                        query={{
                          measures: [
                            "CustomerLeads.converted",
                            "CustomerLeads.convertedWithoutInteraction",
                            "CustomerLeads.convertedWithInteraction",
                          ],
                          timeDimensions: [
                            {
                              dimension: "CustomerLeads.convertedat",
                              dateRange: this.state.byStoreDateRange,
                            },
                          ],
                          order: {
                            "CustomerLeads.converted": "desc",
                          },
                          filters: [
                            {
                              member: "Stores.name",
                              operator: "set",
                            },
                          ],
                          dimensions: ["Stores.name"],
                        }}
                        type="table"
                        pivotConfig={{
                          x: ["Stores.name"],
                          y: ["measures"],
                          fillMissingDates: true,
                          joinDateRange: false,
                        }}
                      ></ChartRenderer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Collapse>
          </div>
        </Container>
      </>
    );
  }
}

export default LeadSummary;
