import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import ChartRenderer from "components/ChartRenderer";
import PageHeader from "components/Headers/PageHeader";
import LeadsByStorePieChart from "components/Charts/LeadsByStorePieChart";

class DashboardTestView extends React.Component {
  state = {
    promoCode: "",
    leadSource: "",
    loading: true,
  };

  componentDidMount() {}

  create(e) {
    e.preventDefault();

    let link = "https://account.projectleannation.com/start";

    if (this.state.promoCode) {
      link += `?promo=${this.state.promoCode}`;

      if (this.state.leadSource) {
        link += `&lead_source=${this.state.leadSource}`;
      }
    } else if (this.state.leadSource) {
      link += `?lead_source=${this.state.leadSource}`;
    }

    this.setState({
      link: link,
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader title="Test Dashboard"></PageHeader>
          <Row className="align-items-stretch">
            <Col xs="12" md="12" lg="4" className="mb-4">
              <Card className="shadow border">
                <CardHeader>Total New Leads</CardHeader>
                <CardBody>
                  <ChartRenderer
                    label="leads"
                    query={{
                      measures: ["CustomerLeads.count"],
                      timeDimensions: [
                        {
                          dimension: "CustomerLeads.createdat",
                          dateRange: "Last 30 days",
                        },
                      ],
                      order: {
                        "CustomerLeads.count": "desc",
                      },
                      dimensions: ["CustomerLeads.source"],
                    }}
                    type="number"
                    pivotConfig={{
                      x: ["CustomerLeads.source"],
                      y: ["measures"],
                      fillMissingDates: true,
                      joinDateRange: false,
                    }}
                  ></ChartRenderer>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" lg="8" className="mb-4">
              <Card className="shadow border">
                <CardHeader>Status Of New Leads</CardHeader>
                <CardBody>
                  <ChartRenderer
                    label="leads"
                    query={{
                      measures: ["CustomerLeads.count"],
                      timeDimensions: [
                        {
                          dimension: "CustomerLeads.createdat",
                          dateRange: "Last 30 days",
                        },
                      ],
                      order: {
                        "CustomerLeads.count": "desc",
                      },
                      dimensions: ["CustomerLeads.leadstatus"],
                    }}
                    type="pie"
                    pivotConfig={{
                      x: ["CustomerLeads.leadstatus"],
                      y: ["measures"],
                      fillMissingDates: true,
                      joinDateRange: false,
                    }}
                  ></ChartRenderer>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" lg="12" className="mb-4">
              <Card className="shadow border">
                <CardHeader>Leads By Store</CardHeader>
                <CardBody>
                  <LeadsByStorePieChart></LeadsByStorePieChart>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" lg="12" className="mb-4">
              <Card className="shadow border">
                <CardHeader>New Leads By Day</CardHeader>
                <CardBody className="p-2" style={{ minHeight: "300px" }}>
                  <ChartRenderer
                    label="leads"
                    query={{
                      measures: ["CustomerLeads.count"],
                      timeDimensions: [
                        {
                          dimension: "CustomerLeads.createdat",
                          granularity: "day",
                          dateRange: "Last 30 days",
                        },
                      ],
                      order: [["CustomerLeads.createdat", "asc"]],
                      dimensions: ["CustomerLeads.source"],
                    }}
                    type="bar"
                    pivotConfig={{
                      x: ["CustomerLeads.createdat.day"],
                      y: ["measures"],
                      fillMissingDates: true,
                      joinDateRange: false,
                    }}
                  ></ChartRenderer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DashboardTestView;
