import moment from "moment-timezone";

export default class DateUtils {
  /**
   * Returns whether a store is in the coming soon phase or not
   *
   * @param store
   * @param allowBefore
   * @returns
   */
  static storeIsComingSoon(store, allowBefore = false) {
    if (!store) {
      return false;
    }

    let now = store?.timezone ? moment.tz(store?.timezone) : moment();

    if (!store?.operations?.storeOpening?.websiteLaunchDate) {
      if (allowBefore) {
        return true;
      }

      return false;
    }

    let websiteLaunch = store?.timezone
      ? moment.tz(
          store?.operations?.storeOpening?.websiteLaunchDate,
          store?.timezone
        )
      : moment(store?.operations?.storeOpening?.websiteLaunchDate);

    if (now.toDate() >= websiteLaunch?.toDate()) {
      if (!store?.operations?.storeOpening?.prelaunchStartDate) {
        return true;
      }

      let prelaunch = store?.timezone
        ? moment.tz(
            store?.operations?.storeOpening?.prelaunchStartDate,
            store?.timezone
          )
        : moment(store?.operations?.storeOpening?.prelaunchStartDate);

      if (now.toDate() >= prelaunch.toDate()) {
        return false;
      }

      if (store?.operations?.storeOpening?.firstBillDate) {
        let fbd = store?.timezone
          ? moment.tz(
              store?.operations?.storeOpening?.prelaunchStartDate,
              store?.timezone
            )
          : moment(store?.operations?.storeOpening?.prelaunchStartDate);

        if (now.toDate() >= fbd.toDate()) {
          return false;
        }
      }
    }

    if (allowBefore) {
      return true;
    }

    return false;
  }

  /**
   * Returns whether the store is in prelaunch or not
   *
   * @param store
   * @returns
   */
  static storeIsPrelaunch(store) {
    if (!store) {
      return false;
    }

    let now = store?.timezone ? moment.tz(store?.timezone) : moment();

    if (!store?.operations?.storeOpening?.prelaunchStartDate) {
      return false;
    }

    let prelaunch = store?.timezone
      ? moment.tz(
          store?.operations?.storeOpening?.prelaunchStartDate,
          store?.timezone
        )
      : moment(store?.operations?.storeOpening?.prelaunchStartDate);

    if (now.toDate() >= prelaunch.toDate()) {
      if (!store?.operations?.storeOpening?.firstBillDate) {
        return true;
      }

      let fbd = store?.timezone
        ? moment.tz(
            store?.operations?.storeOpening?.firstBillDate,
            store?.timezone
          )
        : moment(store?.operations?.storeOpening?.firstBillDate);

      if (now.toDate() >= fbd.toDate()) {
        return false;
      }

      return true;
    }

    return false;
  }

  /**
   * Returns whether the store is live or not
   *
   * @param store
   * @returns
   */
  static storeIsLive(store) {
    if (!store) {
      return false;
    }

    let now = store?.timezone ? moment().tz(store?.timezone) : moment();

    if (!store?.operations?.storeOpening?.firstBillDate) {
      return false;
    }

    let prelaunch = store?.timezone
      ? moment.tz(
          store?.operations?.storeOpening?.firstBillDate,
          store?.timezone
        )
      : moment(store?.operations?.storeOpening?.firstBillDate);

    if (now.toDate() >= prelaunch.toDate()) {
      return true;
    }

    return false;
  }
}
