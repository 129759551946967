import PubSub from "./PubSub";
import Event from "./Event";
import axios from "axios";
import { AuthorizeNet } from "@projectleannation/capacitorauthorizenet";
import { io } from "socket.io-client";

let Request = require("request-promise-native");

export default class APIV2 {
  static ENV_STAGING = "https://apiv2-staging.projectleannation.com";
  static ENV_PRODUCTION = "https://apiv2.projectleannation.com";
  static ENV_DEV = "http://localhost:8080";
  //static ENV_DEV = "https://3385-72-230-143-130.ngrok.io";

  static ENV_UPLOADER_DEV = "http://localhost:8081/upload";
  static ENV_UPLOADER_PRODUCTION =
    "https://upload.projectleannation.com/upload";

  /**
   * Sets the environment
   *
   * @param {*} env
   */
  static setEnvironment(env) {
    this.ENV_CURRENT = env;

    if (env?.includes("localhost")) {
      this.ENV_UPLOADER_CURRENT = this.ENV_UPLOADER_DEV;
    } else {
      this.ENV_UPLOADER_CURRENT = this.ENV_UPLOADER_PRODUCTION;
    }

    PubSub.publish(Event.ENV_CHANGED);
  }

  /**
   * Gets the current auth token.
   */
  static getAuthToken() {
    return this.ENV_USER_TOKEN;
  }

  /**
   * Sets the user's auth token.
   *
   * @param {*} val
   */
  static setAuthToken(val) {
    this.ENV_USER_TOKEN = val;
  }

  static _wsEventHandler = (event, data) => {
    PubSub.publish(`ws_${event}`, data);
  };

  static _wsConnect() {
    console.log("WS CONNECT ATTEMPT");

    if (this?.socket && this?.socket?.open()) {
      return;
    }

    let socketURL = this.ENV_CURRENT?.replace("http://", "ws://").replace(
      "https://",
      "wss://"
    );

    let socket = io(socketURL, {
      auth: {
        token: this.ENV_USER_TOKEN,
      },
    });

    socket.on("connect", () => {
      console.log("Connected to websocket", socketURL);
    });

    socket.on("connect_error", (e) => {
      console.error("failed to connect", e);
    });

    socket.onAny(this._wsEventHandler);

    this.socket = socket;
  }

  /**
   * Sends a generic request.
   *
   * @param {*} options
   */
  static _sendRequest(options) {
    if (this.ENV_CURRENT) {
      return Request(options);
    } else {
      return new Promise((resolve, reject) => {
        PubSub.subscribe(Event.ENV_CHANGED, () => {
          options.uri = this.ENV_CURRENT + options.uri;

          Request(options).then(
            (data) => {
              if (data) {
                resolve(data);
              }
            },
            (error) => {
              reject(error);
            }
          );
        });
      });
    }
  }

  /**
   * Sends an unauthenticed HTTP request.
   *
   * @param method
   * @param path
   * @param data
   */
  static _unauthenticatedRequest(method, path, data = null) {
    let options = {
      method: method,
      uri: this.ENV_CURRENT + path,
      json: true,
    };

    if (data) {
      if (typeof data === "object") {
        options.json = true;
      }

      options.body = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Sends a basic authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param username
   * @param password
   * @param data
   */
  static _basicAuthRequest(method, path, username, password, data = null) {
    let options = {
      method: method,
      uri: this.ENV_CURRENT + path,
      json: true,
      auth: {
        user: username,
        pass: password,
      },
    };

    if (data) {
      if (typeof data === "object") {
        options.json = true;
      }

      options.body = data;
    }

    return this._sendRequest(options);
  }

  /**
   * Sends a bearer authenticated HTTP request.
   *
   * @param method
   * @param path
   * @param token
   * @param data
   */
  static _bearerAuthRequest(method, path, token, data = null) {
    let options = {
      method: method,
      uri: this.ENV_CURRENT + path,
      json: true,
      auth: {
        bearer: token,
      },
    };

    if (data) {
      if (typeof data === "object") {
        options.json = true;
      }

      options.body = data;
    }

    return this._sendRequest(options);
  }

  static fetchFile(url) {
    let options = {
      method: "post",
      url: this.ENV_CURRENT + "/public/file-fetch",
      responseType: "blob",
      data: {
        url,
      },
      headers: {
        Authorization: `Bearer ${this.ENV_USER_TOKEN}`,
      },
    };

    return axios(options);
  }

  /**
   * Authenticates the user.
   *
   * @param email
   * @param password
   */
  static authenticate(email = "", password = "") {
    return this._basicAuthRequest(
      "GET",
      "/global/authentication/providers/local",
      email,
      password
    );
  }

  static registerUser(
    name = "",
    email = "",
    phone = "",
    password = "",
    token = ""
  ) {
    return this._unauthenticatedRequest(
      "POST",
      "/global/authentication/providers/local/register",
      {
        name,
        email,
        phone,
        password,
        token,
      }
    );
  }

  static modifyAccountInformation(name = "", email = "", phone = "") {
    return this._bearerAuthRequest(
      "PUT",
      "/global/account/information",
      this.ENV_USER_TOKEN,
      {
        name,
        email,
        phone,
      }
    );
  }

  static modifyAccountPassword(password = "", newPassword = "") {
    return this._bearerAuthRequest(
      "PUT",
      "/global/account/password",
      this.ENV_USER_TOKEN,
      {
        password,
        newPassword,
      }
    );
  }

  static sendForgotPasswordEmail(email = "") {
    return this._unauthenticatedRequest(
      "PUT",
      "/global/authentication/providers/local/forgot",
      {
        email,
      }
    );
  }

  static resetPassword(token = "", password = "") {
    return this._unauthenticatedRequest(
      "PUT",
      "/global/authentication/providers/local/reset",
      {
        token,
        password,
      }
    );
  }

  /**
   * Gets the user's account
   */
  static getAccount() {
    return this._bearerAuthRequest(
      "GET",
      "/global/account",
      this.ENV_USER_TOKEN
    );
  }

  /**
   * Gets the user's account
   */
  static getAllUsers() {
    return this._bearerAuthRequest("GET", "/global/users", this.ENV_USER_TOKEN);
  }

  static getBillingAccounts() {
    return this._bearerAuthRequest(
      "GET",
      "/global/billing-accounts",
      this.ENV_USER_TOKEN
    );
  }

  static deleteBillingAccount(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/billing-accounts/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static createBillingAccount(
    name,
    desc,
    key,
    secret,
    publicKey,
    username,
    password,
    email,
    gatewayMerchantID
  ) {
    let params = {
      name: name,

      loginID: key,
      secret: secret,
      publicKey: publicKey,
      username,
      password,
      email,
      gatewayMerchantID,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/billing-accounts",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyBillingAccount(
    id,
    name,
    desc,
    key,
    secret,
    publicKey,
    username,
    password,
    email,
    gatewayMerchantID
  ) {
    let params = {
      name: name,

      loginID: key,
      secret: secret,
      publicKey: publicKey,
      username,
      password,
      email,
      gatewayMerchantID,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/billing-accounts/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static getProductGroups() {
    return this._bearerAuthRequest(
      "GET",
      "/global/plan-types",
      this.ENV_USER_TOKEN
    );
  }

  static deleteProductGroup(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/plan-types/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static createProductGroup(name, groupCode, desc, min, max) {
    let params = {
      name: name,
      description: desc ? desc : "",
      groupCode: groupCode,
      calorieMin: min,
      calorieMax: max,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/plan-types",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyProductGroup(id, name, groupCode, desc, min, max) {
    let params = {
      name: name,
      description: desc ? desc : "",
      groupCode: groupCode,
      calorieMin: min,
      calorieMax: max,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/plan-types/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static getStores(filters = null) {
    return this._bearerAuthRequest(
      "GET",
      `/global/stores${filters ? "?filters=" + JSON.stringify(filters) : ""}`,
      this.ENV_USER_TOKEN
    );
  }

  static getStoreByID(id) {
    return this._bearerAuthRequest(
      "GET",
      "/global/stores/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static createStore(
    code,
    productCode,
    websiteLaunchDate,
    prelaunchStartDate,
    firstBillDate,
    retailOpeningDate,
    firstBillCoupon,
    name,
    type,
    add1,
    add2,
    city,
    state,
    zip,
    email,
    ig,
    fb,
    billingAccountID
  ) {
    let params = {
      name: name,
      internal_name: name,
      code: code,
      productCode,
      type: type == 0 ? "retail" : "online",
      third_party_integrations: {},
      images: {
        featured: "",
        all: [],
      },
      contact: {
        phone: "",
        email: "",
        facebook_url: "",
        instagram_url: "",
        twitter_url: "",
      },
      status: "open",
      menus: {
        meals: {
          active: "",
          upcoming: {},
        },
        shakes: {
          active: "",
          upcoming: {},
        },
      },
      products: {
        mealPlans: [],
      },
      operations: {
        storeOpening: {
          openingDate: retailOpeningDate ? retailOpeningDate : "",
          firstBillDate: firstBillDate ? firstBillDate : "",
          prelaunchStartDate: prelaunchStartDate ? prelaunchStartDate : "",
          websiteLaunchDate: websiteLaunchDate ? websiteLaunchDate : "",
          preorderIncentive: firstBillCoupon ? firstBillCoupon : "",
        },
        hours: {
          standard: [
            {
              day: "Monday",
              opensAt: "",
              closes: "",
              closed: true,
            },
            {
              day: "Tuesday",
              opensAt: "",
              closes: "",
              closed: true,
            },
            {
              day: "Wednesday",
              opensAt: "",
              closes: "",
              closed: true,
            },
            {
              day: "Thursday",
              opensAt: "",
              closes: "",
              closed: true,
            },
            {
              day: "Friday",
              opensAt: "",
              closes: "",
              closed: true,
            },
            {
              day: "Saturday",
              opensAt: "",
              closes: "",
              closed: true,
            },
            {
              day: "Sunday",
              opensAt: "",
              closes: "",
              closed: true,
            },
          ],
          holiday: {},
        },
        billingAccount: {
          id: billingAccountID,
          active: true,
        },
        terms: {
          pickup: "",
          delivery: "",
          general: "",
          preorder: "",
        },
      },
      owner: "",
      users: [],
    };

    if (type == 0) {
      params.location = {
        address: {
          address_1: add1,
          address_2: add2 ? add2 : "",
          city: city,
          state: state,
          zip: zip,
          country_code: "US",
        },
        geographic: {
          latitude: "",
          longitude: "",
        },
        external: {
          google_place_id: "",
        },
      };
    }

    if (email) {
      params.contact.email = email;
    }

    if (ig) {
      params.contact.instagram_url = ig;
    }

    if (fb) {
      params.contact.facebook_url = fb;
    }

    return this._bearerAuthRequest(
      "POST",
      "/global/stores",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyStore(
    id,
    code,
    productCode,
    websiteLaunchDate,
    prelaunchStartDate,
    firstBillDate,
    retailOpeningDate,
    firstBillCoupon,
    name,
    type,
    add1,
    add2,
    city,
    state,
    zip,
    phone,
    email,
    ig,
    fb
  ) {
    let params = {
      name: name,
      internal_name: name,
      code: code,
      productCode,
      type: type == 0 ? "retail" : "online",
      third_party_integrations: {},
      contact: {
        phone: "",
        email: "",
        facebook_url: "",
        instagram_url: "",
        twitter_url: "",
      },
      operations: {
        storeOpening: {
          openingDate: retailOpeningDate ? retailOpeningDate : "",
          firstBillDate: firstBillDate ? firstBillDate : "",
          prelaunchStartDate: prelaunchStartDate ? prelaunchStartDate : "",
          preorderIncentive: firstBillCoupon ? firstBillCoupon : "",
          websiteLaunchDate: websiteLaunchDate ? websiteLaunchDate : "",
        },
      },
    };

    if (type == 0) {
      params.location = {
        address: {
          address_1: add1,
          address_2: add2 ? add2 : "",
          city: city,
          state: state,
          zip: zip,
          country_code: "US",
        },
        geographic: {
          latitude: "",
          longitude: "",
        },
        external: {
          google_place_id: "",
        },
      };
    }

    if (phone) {
      params.contact.phone = phone;
    }

    if (email) {
      params.contact.email = email;
    }

    if (ig) {
      params.contact.instagram_url = ig;
    }

    if (fb) {
      params.contact.facebook_url = fb;
    }

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyStorePhone(id, phone) {
    let params = {
      phone,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/phone",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static toggleStoreBilling(id, billingEnabled = false) {
    let params = {
      billingEnabled,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/operations/billing",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyStoreParameter(id, key = "", value = null) {
    let params = {
      value,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/parameters/" + key,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteStore(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/stores/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static setStoreBillingAccount(storeID, id) {
    let params = {
      billingAccount: id,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + storeID + "/operations/billing-account",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static addFranchisePartnerToStore(
    id,
    name = "",
    email = "",
    phone = "",
    imageURL = "",
    mailingAddress = "",
    location = "",
    occupation = "",
    notes = "",
    decisionMaker = false
  ) {
    let params = {
      name,
      email,
      imageURL,
      phone,
      occupation,
      notes,
      mailingAddress,
      location,
      decisionMaker,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/stores/" + id + "/franchise-partners",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyFranchisePartnerForStore(
    id,
    partnerID,
    name = "",
    email = "",
    phone = "",
    imageURL = "",
    mailingAddress = "",
    location = "",
    occupation = "",
    notes = "",
    decisionMaker = false
  ) {
    let params = {
      name,
      email,
      imageURL,
      phone,
      occupation,
      location,
      notes,
      decisionMaker,
      mailingAddress,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/franchise-partners/" + partnerID,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteFranchisePartnerForStore(id, partnerID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/stores/" + id + "/franchise-partners/" + partnerID,
      this.ENV_USER_TOKEN
    );
  }

  static setStoreDefaultCoupon(storeID, couponCode, startDate, endDate) {
    let params = {
      couponCode,
      startDate,
      endDate,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + storeID + "/operations/default-coupon",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreTax(storeID, taxApplied, taxDescription, taxRate, taxMethod) {
    let params = {
      taxApplied,
      taxDescription,
      taxRate,
      taxMethod,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + storeID + "/operations/tax",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreBlackoutDates(storeID, blackoutDates) {
    let params = {
      blackoutDates,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + storeID + "/operations/blackout-dates",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreRestockingFee(storeID, fee) {
    let params = {
      restockingFee: fee,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + storeID + "/operations/terms/restocking-fee",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreMealMenu(storeID, planTypeID, id) {
    let params = {
      menuID: id,
      planTypeID,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + storeID + "/menus/meals",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreGeneralTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/operations/terms/general",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStorePrelaunchTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/operations/terms/preorder",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStoreDeliveryTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/operations/terms/delivery",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setStorePickupTerms(id, terms) {
    let params = {
      terms: terms,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + id + "/operations/terms/pickup",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static addStoreTerritory(id, zip, reassign) {
    let params = {
      zip: zip,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/stores/" + id + "/operations/territories",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteStoreTerritory(id, zip) {
    let params = {
      zip: zip,
    };

    return this._bearerAuthRequest(
      "DELETE",
      "/global/stores/" + id + "/operations/territories",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static getStorePlans(id) {
    return this._bearerAuthRequest(
      "GET",
      "/stores/" + id + "/plans",
      this.ENV_USER_TOKEN
    );
  }

  static getPlans() {
    return this._bearerAuthRequest("GET", "/global/plans", this.ENV_USER_TOKEN);
  }

  static createStorePlan(
    storeID,
    code,
    name,
    groupID,
    price,
    deliveryFee,
    quantity,
    procurement,
    frequency,
    visible
  ) {
    let params = {
      storeID,
      code: code,
      name: name,
      planTypeID: groupID,
      unitPrice: price,
      deliveryFee,
      quantity: quantity,
      procurement: procurement,
      frequency: frequency,
      availableToNewClients: visible,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/plans",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyStorePlan(
    planID,
    code,
    name,
    groupID,
    price,
    deliveryFee,
    quantity,
    procurement,
    frequency,
    visible
  ) {
    let params = {
      code: code,
      name: name,
      planTypeID: groupID,
      unitPrice: price,
      deliveryFee,
      quantity: quantity,
      procurement: procurement,
      frequency: frequency,
      availableToNewClients: visible,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/plans/" + planID,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteStorePlan(planID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/plans/" + planID,
      this.ENV_USER_TOKEN
    );
  }

  static getMealsByProductGroup(groupID) {
    return this._bearerAuthRequest(
      "GET",
      "/global/meals?planTypeID=" + groupID,
      this.ENV_USER_TOKEN
    );
  }

  static getMeals(archived) {
    return this._bearerAuthRequest(
      "GET",
      "/global/meals" + (archived ? "?archived=true" : ""),
      this.ENV_USER_TOKEN
    );
  }

  static getMealByID(mealID) {
    return this._bearerAuthRequest(
      "GET",
      "/global/meals/" + mealID,
      this.ENV_USER_TOKEN
    );
  }

  static getMealMenusByProductGroup(groupID) {
    return this._bearerAuthRequest(
      "GET",
      `/global/menus/meals${groupID ? `?planTypeID=${groupID}` : ""}`,
      this.ENV_USER_TOKEN
    );
  }

  static getActiveMealMenusByProductGroup(groupID) {
    return this._bearerAuthRequest(
      "GET",
      "/global/menus/meals/active?planTypeID=" + groupID,
      this.ENV_USER_TOKEN
    );
  }

  static getInactiveMealMenusByProductGroup(groupID) {
    return this._bearerAuthRequest(
      "GET",
      "/global/menus/meals/inactive?planTypeID=" + groupID,
      this.ENV_USER_TOKEN
    );
  }

  static getMealMenuTransitions() {
    return this._bearerAuthRequest(
      "GET",
      "/global/menus/meals/transitions",
      this.ENV_USER_TOKEN
    );
  }

  static createMenuTransition(
    oldMenuID,
    newMenuID,
    transitionDate,
    retiringMealSwaps,
    deleteAfter
  ) {
    return this._bearerAuthRequest(
      "POST",
      "/global/menus/meals/transitions",
      this.ENV_USER_TOKEN,
      {
        oldMenuID,
        newMenuID,
        transitionDate,
        retiringMealSwaps,
        deleteAfter,
      }
    );
  }

  static modifyMenuTransition(
    transitionID,
    oldMenuID,
    newMenuID,
    transitionDate,
    retiringMealSwaps,
    deleteAfter
  ) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/menus/meals/transitions/" + transitionID,
      this.ENV_USER_TOKEN,
      {
        oldMenuID,
        newMenuID,
        transitionDate,
        retiringMealSwaps,
        deleteAfter,
      }
    );
  }

  static deleteMenuTransition(transitionID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/menus/meals/transitions/" + transitionID,
      this.ENV_USER_TOKEN
    );
  }

  static deleteMeal(mealID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/meals/" + mealID,
      this.ENV_USER_TOKEN
    );
  }

  static createMeal(
    name,
    sku,
    description,
    imageURL,
    groupID,
    calories,
    carbs,
    protein,
    fat,
    sugar,
    fiber,
    sodium,
    foodWeight,
    finishedWeight,
    categories,
    allergens,
    microwave,
    airFry,
    oven,
    myfitnesspalURL,
    squareItem,
    primaryProtein,
    primaryProteinCategory,
    secondaryProtein,
    secondaryProteinCategory,
    ingredients,
    labelIngredients,
    transFat,
    saturatedFat,
    cholesterol,
    vitaminD,
    potassium,
    calcium,
    iron
  ) {
    let params = {
      name: name,
      description: description,
      imageURL: imageURL,
      sku,
      planTypeID: groupID,
      nutrition: {
        calories: calories,
        carbs: carbs,
        protein: protein,
        fat: fat,
        sugar: sugar,
        fiber: fiber,
        sodium: sodium,
      },
      foodWeight: foodWeight,
      finishedWeight: finishedWeight,
      categories,
      allergens,
      microwaveInstructions: microwave,
      airFryInstructions: airFry,
      ovenInstructions: oven,
      myfitnesspalURL,
      squareItem,
      primaryProtein,
      primaryProteinCategory,
      secondaryProtein,
      secondaryProteinCategory,
      ingredients,
      labelIngredients,
      transFat,
      saturatedFat,
      cholesterol,
      vitaminD,
      potassium,
      calcium,
      iron,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/meals",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyMeal(
    id,
    name,
    sku,
    description,
    imageURL,
    groupID,
    calories,
    carbs,
    protein,
    fat,
    sugar,
    fiber,
    sodium,
    foodWeight,
    finishedWeight,
    categories,
    allergens,
    microwave,
    airFry,
    oven,
    myfitnesspalURL,
    squareItem,
    primaryProtein,
    primaryProteinCategory,
    secondaryProtein,
    secondaryProteinCategory,
    ingredients,
    labelIngredients,
    transFat,
    saturatedFat,
    cholesterol,
    vitaminD,
    potassium,
    calcium,
    iron
  ) {
    let params = {
      name: name,
      description: description,
      imageURL: imageURL,
      sku,
      planTypeID: groupID,
      nutrition: {
        calories: calories,
        carbs: carbs,
        protein: protein,
        fat: fat,
        sugar: sugar,
        fiber: fiber,
        sodium: sodium,
      },
      foodWeight: foodWeight,
      finishedWeight: finishedWeight,
      categories,
      allergens,
      microwaveInstructions: microwave,
      airFryInstructions: airFry,
      ovenInstructions: oven,
      myfitnesspalURL,
      squareItem,
      primaryProtein,
      primaryProteinCategory,
      secondaryProtein,
      secondaryProteinCategory,
      ingredients,
      labelIngredients,
      transFat,
      saturatedFat,
      cholesterol,
      vitaminD,
      potassium,
      calcium,
      iron,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/meals/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setMealAllergens(mealID, allergens) {
    let params = {
      allergens: allergens,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/meals/" + mealID + "/allergens",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static setMealCategories(mealID, categories) {
    let params = {
      categories: categories,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/meals/" + mealID + "/categories",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static uploadCDNImage(file) {
    return axios.post(this.ENV_CURRENT + "/global/cdn/public", file, {
      headers: {
        Authorization: "Bearer " + this.ENV_USER_TOKEN,
      },
    });
  }

  static uploadPrivateFile(file) {
    return axios.post(this.ENV_CURRENT + "/global/cdn/private", file, {
      headers: {
        Authorization: "Bearer " + this.ENV_USER_TOKEN,
      },
    });
  }

  static getPrivateFileURL(url) {
    return this._bearerAuthRequest(
      "GET",
      `/global/cdn/private/url?objectURL=${url}`,
      this.ENV_USER_TOKEN
    );
  }

  static getURLPreview(url) {
    return this._bearerAuthRequest(
      "GET",
      `/global/cdn/private/url/preview?url=${url}`,
      this.ENV_USER_TOKEN
    );
  }

  static getAllergens() {
    return this._bearerAuthRequest(
      "GET",
      "/global/allergens/meals",
      this.ENV_USER_TOKEN
    );
  }

  static createAllergen(name) {
    let params = {
      name,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/allergens/meals",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyAllergen(id, name) {
    let params = {
      name,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/allergens/meals/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteAllergen(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/allergens/meals/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static getCategories() {
    return this._bearerAuthRequest(
      "GET",
      "/global/categories/meals",
      this.ENV_USER_TOKEN
    );
  }

  static createCategory(name) {
    let params = {
      name,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/categories/meals",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyCategory(id, name) {
    let params = {
      name,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/categories/meals/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteCategory(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/categories/meals/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static createMealMenu(planTypeID, name, meals) {
    let params = {
      planTypeID,
      name,
      meals,
    };

    return this._bearerAuthRequest(
      "POST",
      "/global/menus/meals",
      this.ENV_USER_TOKEN,
      params
    );
  }

  static modifyMealMenu(id, planTypeID, name, meals) {
    let params = {
      planTypeID,
      name,
      meals,
    };

    return this._bearerAuthRequest(
      "PUT",
      "/global/menus/meals/" + id,
      this.ENV_USER_TOKEN,
      params
    );
  }

  static deleteMealMenu(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/menus/meals/" + id,
      this.ENV_USER_TOKEN,
      null
    );
  }

  static simpleSearchCustomers(query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/global/customers/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static getCustomers(page = 1, count = 25, filter = null) {
    return this._bearerAuthRequest(
      "GET",
      "/global/customers?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static getCustomerByID(storeID = "", id = "") {
    return this._bearerAuthRequest(
      "GET",
      `/storefront/${storeID}/customers/${id}`,
      this.ENV_USER_TOKEN
    );
  }

  static createCustomer(
    storeID = "",
    name = "",
    email = "",
    phone = "",
    plan = "",
    fulfillmentDate = "",
    signupDate = null,
    source = "store"
  ) {
    let payload = {
      name,
      email,
      phone,
      planID: plan,
      fulfillmentDate,
      signupDate,
      conversionSource: source,
    };

    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/customers`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteCustomer(storeID = "", customerID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/customers/${customerID}`,

      this.ENV_USER_TOKEN
    );
  }

  static clearCustomerCache(storeID = "", customerID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/customers/${customerID}/cache`,

      this.ENV_USER_TOKEN
    );
  }

  static getCoupons() {
    return this._bearerAuthRequest(
      "GET",
      "/global/coupons",
      this.ENV_USER_TOKEN
    );
  }

  static getSubscriptionFulfillmentDates() {
    return this._bearerAuthRequest(
      "GET",
      "/global/reports/subscription-fulfillment/dates",
      this.ENV_USER_TOKEN
    );
  }

  static getSubscriptionFulfillmentSummaryForDate(date) {
    return this._bearerAuthRequest(
      "GET",
      "/global/reports/subscription-fulfillment/dates/" + date,
      this.ENV_USER_TOKEN
    );
  }

  static getStoreSubscriptionFulfillmentSummaryForDate(storeID, date) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/reports/fulfillment/dates/" + date,
      this.ENV_USER_TOKEN
    );
  }

  static getStoreSubscriptionFulfillmentDates(storeID) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/reports/fulfillment/dates",
      this.ENV_USER_TOKEN
    );
  }

  static getSubscriptionFulfillmentOrders(reportID, page, count, filters) {
    return this._bearerAuthRequest(
      "GET",
      "/global/reports/subscription-fulfillment/" +
        reportID +
        "/orders" +
        `?page=${page ? page : 1}&count=${count ? count : 25}${
          filters ? "&filters=" + JSON.stringify(filters) : ""
        }`,
      this.ENV_USER_TOKEN
    );
  }

  static searchSubscriptionFulfillmentOrders(reportID, page, count, filters) {
    return this._bearerAuthRequest(
      "GET",
      "/global/reports/subscription-fulfillment/" +
        reportID +
        "/orders/search" +
        `?page=${page ? page : 1}&count=${count ? count : 25}${
          filters ? "&filters=" + JSON.stringify(filters) : ""
        }`,
      this.ENV_USER_TOKEN
    );
  }

  static searchOrdersForStore(storeID, page, count, filters) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/orders/search" +
        `?page=${page ? page : 1}&count=${count ? count : 25}${
          filters ? "&filters=" + JSON.stringify(filters) : ""
        }`,
      this.ENV_USER_TOKEN
    );
  }

  static rerunSubscriptionFulfillmentReport(reportID) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/reports/subscription-fulfillment/" + reportID + "/rerun",
      this.ENV_USER_TOKEN
    );
  }

  static checkRerunSubscriptionFulfillmentReportStatus(reportID, jobID) {
    return this._bearerAuthRequest(
      "GET",
      "/global/reports/subscription-fulfillment/" +
        reportID +
        "/rerun/status/" +
        jobID,
      this.ENV_USER_TOKEN
    );
  }

  static refreshSubscriptionFulfillmentReportStatistics(reportID) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/reports/subscription-fulfillment/" + reportID + "/recalculate",
      this.ENV_USER_TOKEN
    );
  }

  static getSquareLocations() {
    return this._bearerAuthRequest(
      "GET",
      "/global/apps/square/locations",
      this.ENV_USER_TOKEN
    );
  }

  static getSquareItems() {
    return this._bearerAuthRequest(
      "GET",
      "/global/apps/square/catalog",
      this.ENV_USER_TOKEN
    );
  }

  static getStoresForUser() {
    return this._bearerAuthRequest(
      "GET",
      "/global/stores",
      this.ENV_USER_TOKEN
    );
  }

  static setSquareLocationForStore(storeID, locationID) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/stores/" + storeID + "/apps/square/location",

      this.ENV_USER_TOKEN,
      {
        squareLocationID: locationID,
      }
    );
  }

  static getCustomersForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/customers?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static getLatestInventoryForStore(storeID = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/inventory/meals/latest",
      this.ENV_USER_TOKEN
    );
  }

  static getIncomingInventoryForStore(storeID = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/inventory/meals/incoming",
      this.ENV_USER_TOKEN
    );
  }

  static createInventoryForStore(storeID, payload) {
    return this._bearerAuthRequest(
      "POST",
      "/storefront/" + storeID + "/inventory/meals",

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static getRecommendedProductOrderForStore(storeID = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/products/order-recommendation",
      this.ENV_USER_TOKEN
    );
  }

  static createProductOrder(payload) {
    return this._bearerAuthRequest(
      "POST",
      "/production/orders",
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyProductOrder(orderID, payload) {
    return this._bearerAuthRequest(
      "PUT",
      "/production/orders/" + orderID,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyProductOrderStatus(orderID, status) {
    return this._bearerAuthRequest(
      "PUT",
      "/production/orders/" + orderID + "/status",
      this.ENV_USER_TOKEN,
      {
        status,
      }
    );
  }

  static modifyProductOrderDeliveryDate(orderID, date) {
    return this._bearerAuthRequest(
      "PUT",
      "/production/orders/" + orderID + "/delivery-date",
      this.ENV_USER_TOKEN,
      {
        deliveryDate: date,
      }
    );
  }

  static modifyProductInvoiceStatus(orderID, status) {
    return this._bearerAuthRequest(
      "PUT",
      "/production/orders/" + orderID + "/status/payment",
      this.ENV_USER_TOKEN,
      {
        status,
      }
    );
  }

  static deleteProductOrder(orderID, payload) {
    return this._bearerAuthRequest(
      "DELETE",
      "/production/orders/" + orderID,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static getLeadsForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/leads?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),

      this.ENV_USER_TOKEN
    );
  }

  static getStaffForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/staff?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),

      this.ENV_USER_TOKEN
    );
  }

  static getCouponsForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/coupons?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),

      this.ENV_USER_TOKEN
    );
  }

  static getAffiliatesForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/affiliates?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),

      this.ENV_USER_TOKEN
    );
  }

  static getInvoicesForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/invoices?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static getCustomerEventsForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/customer-events?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static getOrdersForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/orders?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),

      this.ENV_USER_TOKEN
    );
  }

  static getUsageReportForStore(
    storeID = "",
    startDate = null,
    endDate = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      `/production/orders/usage-report?storeID=${storeID}&startDate=${startDate}&endDate=${endDate}`,
      this.ENV_USER_TOKEN
    );
  }

  static getProjectionsForStore(storeID = "", date = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/reports/fulfillment/projections/" + date,

      this.ENV_USER_TOKEN
    );
  }

  static getProjections(date = "") {
    return this._bearerAuthRequest(
      "GET",
      "/global/reports/subscription-fulfillment/projections/" + date,

      this.ENV_USER_TOKEN
    );
  }

  static getNotesForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null,
    noStoreFilter = false
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/notes?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : "") +
        "&noStoreFilter=" +
        (noStoreFilter ? "true" : "false"),

      this.ENV_USER_TOKEN
    );
  }

  static getTasksForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null,
    noStoreFilter = false
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/tasks?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : "") +
        "&noStoreFilter=" +
        (noStoreFilter ? "true" : "false"),
      this.ENV_USER_TOKEN
    );
  }

  static getAppointmentsForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/appointments?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static scheduleAppointment(storeID, payload) {
    return this._bearerAuthRequest(
      "POST",
      "/storefront/" + storeID + "/appointments",

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyAppointmentInformation(storeID, appointmentID, payload) {
    return this._bearerAuthRequest(
      "PUT",
      "/storefront/" +
        storeID +
        "/appointments/" +
        appointmentID +
        "/information",

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyAppointmentStatus(storeID, appointmentID, status) {
    return this._bearerAuthRequest(
      "PUT",
      "/storefront/" + storeID + "/appointments/" + appointmentID + "/status",

      this.ENV_USER_TOKEN,
      {
        status,
      }
    );
  }

  static getAppointmentByID(storeID, appointmentID) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/appointments/" + appointmentID,
      this.ENV_USER_TOKEN
    );
  }

  static cancelAppointment(storeID, appointmentID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/storefront/" + storeID + "/appointments/" + appointmentID,
      this.ENV_USER_TOKEN
    );
  }

  static rescheduleAppointment(storeID, appointmentID, dateTime) {
    return this._bearerAuthRequest(
      "PUT",
      "/storefront/" +
        storeID +
        "/appointments/" +
        appointmentID +
        "/reschedule",

      this.ENV_USER_TOKEN,
      {
        dateTime: dateTime,
      }
    );
  }

  static getTechSupportTickets(
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/global/support/franchise/tickets?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static getTasks(page = 1, count = 25, filter = null, orderBy = null) {
    return this._bearerAuthRequest(
      "GET",
      "/global/tasks?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static modifyTechSupportTicketStatus(id, status) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/support/franchise/tickets/" + id + "/status",

      this.ENV_USER_TOKEN,
      {
        status,
      }
    );
  }

  static requireTechSupportTicketResponse(id) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/support/franchise/tickets/" + id + "/response-required",

      this.ENV_USER_TOKEN
    );
  }

  static createStoreNote(
    storeID = "",
    customerID = "",
    taskID = "",
    leadID = "",
    affiliateID = "",
    appointmentID = "",
    productOrderID = "",
    storeNote = false,
    title = "",
    body = "",
    attachments = []
  ) {
    return this._bearerAuthRequest(
      "POST",
      "/storefront/" + storeID + "/notes",

      this.ENV_USER_TOKEN,
      {
        title,
        body,
        customerID,
        leadID,
        taskID,
        affiliateID,
        appointmentID,
        storeNote,
        attachments,
        productOrderID,
      }
    );
  }

  static modifyStoreNote(
    storeID = "",
    noteID = "",
    customerID = "",
    taskID = "",
    leadID = "",
    affiliateID = "",
    appointmentID = "",
    productOrderID = "",
    storeNote = false,
    title = "",
    body = "",
    attachments = []
  ) {
    return this._bearerAuthRequest(
      "PUT",
      "/storefront/" + storeID + "/notes/" + noteID,

      this.ENV_USER_TOKEN,
      {
        title,
        body,
        customerID,
        leadID,
        taskID,
        affiliateID,
        appointmentID,
        storeNote,
        attachments,
        productOrderID,
      }
    );
  }

  static deleteStoreNote(storeID = "", noteID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      "/storefront/" + storeID + "/notes/" + noteID,

      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchInvoicesForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/invoices/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchCustomersForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/customers/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchLeadsForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/leads/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchAffiliatesForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/affiliates/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchStaffForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/staff/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchCouponsForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/coupons/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchTasksForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + storeID + "/tasks/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static modifyStoreStaff(
    storeID = "",
    memberID = "",
    name = "",
    email = "",
    phone = "",
    position = "",

    hireDate = ""
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/staff/${memberID}`,

      this.ENV_USER_TOKEN,
      {
        name,
        email,
        phone,
        position,
        hireDate,
      }
    );
  }

  static modifyStoreCoupon(
    storeID = "",
    memberID = "",
    name = "",
    code = "",
    description = "",
    couponUsage = "",
    discountType = "",
    flatDiscount = null,
    percentDiscount = null,
    removeFromSubscriptionAfterUses = 1,
    couponTiers = null
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/coupons/${memberID}`,

      this.ENV_USER_TOKEN,
      {
        name,
        code,
        description,
        discountType,
        couponUsage,
        flatDiscount,
        percentDiscount,
        removeFromSubscriptionAfterUses,
        couponTiers,
      }
    );
  }

  static createStoreCoupon(
    storeID = "",
    name = "",
    code = "",
    description = "",
    couponUsage = "",
    discountType = "",
    flatDiscount = null,
    percentDiscount = null,
    removeFromSubscriptionAfterUses = 1,
    couponTiers = null
  ) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/coupons`,

      this.ENV_USER_TOKEN,
      {
        name,
        code,
        couponUsage,
        description,
        discountType,
        flatDiscount,
        percentDiscount,
        removeFromSubscriptionAfterUses,
        couponTiers,
      }
    );
  }

  static createStoreStaff(
    storeID = "",
    name = "",
    email = "",
    phone = "",
    position = "",

    hireDate = ""
  ) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/staff`,

      this.ENV_USER_TOKEN,
      {
        name,
        email,
        phone,
        position,
        hireDate,
      }
    );
  }

  static deleteStoreStaff(storeID = "", memberID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/staff/${memberID}`,

      this.ENV_USER_TOKEN,
      null
    );
  }

  static deleteStoreCoupon(storeID = "", memberID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/coupons/${memberID}`,

      this.ENV_USER_TOKEN,
      null
    );
  }

  static modifyStoreContactInformation(storeID = "", phone = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/settings/contact/phone`,

      this.ENV_USER_TOKEN,
      {
        phone,
      }
    );
  }

  static modifyStoreRetailHours(storeID = "", hours = []) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/settings/hours/standard`,

      this.ENV_USER_TOKEN,
      {
        hours,
      }
    );
  }

  static setCustomerVariable(
    storeID = "",
    customerID = "",
    key = "",
    value = null
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/information/${key}`,

      this.ENV_USER_TOKEN,
      {
        value,
      }
    );
  }

  static assignCustomerToStore(storeID = "", customerID = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/global/customers/${customerID}/store`,

      this.ENV_USER_TOKEN,
      {
        storeID: storeID,
      }
    );
  }

  static updateCustomerInformation(
    storeID = "",
    customerID = "",
    name = "",
    email = "",
    phone = ""
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/contact`,

      this.ENV_USER_TOKEN,
      {
        name,
        email,
        phone,
      }
    );
  }

  static updateCustomerDeliveryInformation(
    storeID = "",
    customerID = "",
    delivery = {}
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/delivery`,

      this.ENV_USER_TOKEN,
      {
        delivery,
      }
    );
  }

  static updateCustomerStatus(storeID = "", customerID = "", status = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/status`,

      this.ENV_USER_TOKEN,
      {
        status,
      }
    );
  }

  static updateCustomerNextBillDate(
    storeID = "",
    customerID = "",
    nextBillDate = ""
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/nextbilldate`,

      this.ENV_USER_TOKEN,
      {
        nextBillDate,
      }
    );
  }

  static updateCustomerHoldDate(storeID = "", customerID = "", holdDate = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/holddate`,

      this.ENV_USER_TOKEN,
      {
        holdDate,
      }
    );
  }

  static updateCustomerHoldReason(storeID = "", customerID = "", reason = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/holdreason`,

      this.ENV_USER_TOKEN,
      {
        holdReason: reason,
      }
    );
  }

  static updateCustomerHoldNotes(storeID = "", customerID = "", notes = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/holdnotes`,

      this.ENV_USER_TOKEN,
      {
        notes,
      }
    );
  }

  static updateCustomerPlan(storeID = "", customerID = "", planID = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/plan`,

      this.ENV_USER_TOKEN,
      {
        planID,
      }
    );
  }

  static updateCustomerCoupon(storeID = "", customerID = "", couponID = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/coupon`,

      this.ENV_USER_TOKEN,
      {
        couponID,
      }
    );
  }

  static updateCustomerBOM(storeID = "", customerID = "", bom = []) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/bom`,

      this.ENV_USER_TOKEN,
      {
        bom,
      }
    );
  }

  static updateCustomerPaymentMethod(
    storeID = "",
    customerID = "",
    opaqueData = null
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/payment`,

      this.ENV_USER_TOKEN,
      {
        opaqueData,
      }
    );
  }

  static removeCustomerPaymentMethod(storeID = "", customerID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/customers/${customerID}/payment`,

      this.ENV_USER_TOKEN
    );
  }

  static createCustomerOrder(
    storeID = "",
    customerID = "",
    fulfillmentDate = ""
  ) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/customers/${customerID}/orders`,

      this.ENV_USER_TOKEN,
      {
        fulfillmentDate,
      }
    );
  }

  static createCustomerConsultation(
    storeID = "",
    customerID = "",
    consultationDate = "",
    coachID = "",
    notes = "",
    type = ""
  ) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/customers/${customerID}/consultations`,

      this.ENV_USER_TOKEN,
      {
        eventTimestamp: consultationDate,
        coachID,
        notes,
        type,
      }
    );
  }

  static modifyCustomerConsultation(
    storeID = "",
    customerID = "",
    consultationID = "",
    consultationDate = "",
    coachID = "",
    notes = "",
    type = ""
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/consultations/${consultationID}`,

      this.ENV_USER_TOKEN,
      {
        eventTimestamp: consultationDate,
        coachID,
        notes,
        type,
      }
    );
  }

  static deleteCustomerConsultation(
    storeID = "",
    customerID = "",
    consultationID = ""
  ) {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/customers/${customerID}/consultations/${consultationID}`,

      this.ENV_USER_TOKEN
    );
  }

  static createLeadConsultation(
    storeID = "",
    leadID = "",
    consultationDate = "",
    coachID = "",
    notes = ""
  ) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/leads/${leadID}/consultations`,

      this.ENV_USER_TOKEN,
      {
        eventTimestamp: consultationDate,
        coachID,
        notes,
      }
    );
  }

  static modifyLeadConsultation(
    storeID = "",
    leadID = "",
    consultationID = "",
    consultationDate = "",
    coachID = "",
    notes = ""
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/leads/${leadID}/consultations/${consultationID}`,

      this.ENV_USER_TOKEN,
      {
        eventTimestamp: consultationDate,
        coachID,
        notes,
      }
    );
  }

  static deleteLeadConsultation(
    storeID = "",
    leadID = "",
    consultationID = ""
  ) {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/leads/${leadID}/consultations/${consultationID}`,

      this.ENV_USER_TOKEN
    );
  }

  static deleteCustomerGivenReferral(
    storeID = "",
    customerID = "",
    referralID = ""
  ) {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/customers/${customerID}/referrals/given/${referralID}`,

      this.ENV_USER_TOKEN
    );
  }

  static createCustomerReferralReceived(
    storeID = "",
    customerID = "",
    referrerID = ""
  ) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/customers/${customerID}/referrals/received`,

      this.ENV_USER_TOKEN,
      {
        customerID: referrerID,
      }
    );
  }

  static deleteCustomerReceivedReferral(
    storeID = "",
    customerID = "",
    referralID = ""
  ) {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/customers/${customerID}/referrals/received/${referralID}`,

      this.ENV_USER_TOKEN
    );
  }

  static regenerateCustomerReferralCode(storeID = "", customerID = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/referrals/code`,

      this.ENV_USER_TOKEN
    );
  }

  static createCustomerInvoice(storeID = "", customerID = "", payload = {}) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/customers/${customerID}/invoices`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static updateCustomerAccountBalance(
    storeID = "",
    customerID = "",
    accountBalance = 0
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/balance`,

      this.ENV_USER_TOKEN,
      {
        accountBalance,
      }
    );
  }

  static updateCustomerPriceOverride(
    storeID = "",
    customerID = "",
    priceOverride = null
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/customers/${customerID}/price-override`,

      this.ENV_USER_TOKEN,
      {
        priceOverride,
      }
    );
  }

  static updateOrderBOM(storeID = "", orderID = "", bom = []) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/orders/${orderID}/bom`,

      this.ENV_USER_TOKEN,
      {
        bom,
      }
    );
  }

  static cancelOrder(storeID = "", orderID = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/orders/${orderID}/status/cancel`,

      this.ENV_USER_TOKEN
    );
  }

  static updateInvoiceStatus(storeID = "", invoiceID = "", status = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/invoices/${invoiceID}/status`,
      this.ENV_USER_TOKEN,
      { status }
    );
  }

  static refundInvoice(
    storeID = "",
    invoiceID = "",
    refundType = "full",
    refundAmount = null,
    refundNote = ""
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/invoices/${invoiceID}/refund`,
      this.ENV_USER_TOKEN,
      {
        type: refundType,
        amount: refundAmount,
        note: refundNote,
      }
    );
  }

  static processInvoice(storeID = "", invoiceID = "", status = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/invoices/${invoiceID}/process`,
      this.ENV_USER_TOKEN,
      { status }
    );
  }

  static submitCardDetails(
    clientKey,
    loginID,
    name,
    number,
    month,
    year,
    code
  ) {
    return new Promise(async (resolve) => {
      /*let authData = {
        clientKey: clientKey,
        apiLoginID: loginID,
      };

      let cardData = {
        cardNumber: number,
        month: month,
        year: year,
        cardCode: code,
        fullName: name,
      };

      let payload = {
        authData: authData,
        cardData: cardData,
      };

      window.Accept.dispatchData(payload, resolve);*/

      let response = await AuthorizeNet.getCardToken({
        clientKey,
        loginID,
        name,
        number,
        month,
        year,
        code,
      });

      console.log(response);

      resolve(response);
    });
  }

  static createGlobalTask(
    storeID = "",
    subject = "",
    type = "",
    description = "",
    priority = "",
    customerID = "",
    leadID = "",
    affiliateID = "",
    status = "",
    dueDate = "",
    staffID = "",
    timeToContact = "",
    storeTask = true
  ) {
    let payload = {
      storeID,
      subject,
      type,
      description,
      priority,
      customerID,
      leadID,
      affiliateID,
      status,
      dueDate,
      assignedTo: staffID,
      timeToContact,
      storeTask,
    };

    return this._bearerAuthRequest(
      "POST",
      `/global/tasks`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static createTask(
    storeID = "",
    subject = "",
    type = "",
    description = "",
    priority = "",
    customerID = "",
    leadID = "",
    affiliateID = "",
    status = "",
    dueDate = "",
    staffID = "",
    timeToContact = "",
    storeTask = false
  ) {
    let payload = {
      subject,
      type,
      description,
      priority,
      customerID,
      leadID,
      affiliateID,
      status,
      dueDate,
      assignedTo: staffID,
      timeToContact,
      storeTask,
    };

    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/tasks`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyTask(
    storeID = "",
    taskID = "",
    subject = "",
    type = "",
    description = "",
    priority = "",
    customerID = "",
    leadID = "",
    affiliateID = "",
    status = "",
    dueDate = "",
    staffID = "",
    timeToContact = "",
    storeTask = false
  ) {
    let payload = {
      subject,
      type,
      description,
      priority,
      customerID,
      leadID,
      affiliateID,
      status,
      dueDate,
      assignedTo: staffID,
      timeToContact,
      storeTask,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/tasks/${taskID}`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyGlobalTask(
    storeID = "",
    taskID = "",
    subject = "",
    type = "",
    description = "",
    priority = "",
    customerID = "",
    leadID = "",
    affiliateID = "",
    status = "",
    dueDate = "",
    staffID = "",
    timeToContact = "",
    storeTask = false
  ) {
    let payload = {
      storeID,
      subject,
      type,
      description,
      priority,
      customerID,
      leadID,
      affiliateID,
      status,
      dueDate,
      assignedTo: staffID,
      timeToContact,
      storeTask,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/global/tasks/${taskID}`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteTask(storeID = "", taskID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/tasks/${taskID}`,

      this.ENV_USER_TOKEN
    );
  }

  static deleteGlobalTask(taskID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/global/tasks/${taskID}`,

      this.ENV_USER_TOKEN
    );
  }

  static createTechSupportTicket(
    storeID = "",
    subject = "",
    supportType = "",
    desiredSolution = "",
    attachments = [],
    customerID = "",
    leadID = "",
    affiliateID = "",
    assignedTo = "",
    dueDate = "",
    priority = "Normal",
    submittedBy = ""
  ) {
    let payload = {
      storeID,
      subject,
      type: "Franchise Support",
      desiredSolution,
      attachments,
      priority,
      customerID,
      leadID,
      affiliateID,
      dueDate,
      assignedTo,
      supportType,
      supportTicket: true,
      submittedBy,
    };

    return this._bearerAuthRequest(
      "POST",
      `/global/support/franchise/tickets`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyTechSupportTicket(
    storeID = "",
    ticketID = "",
    subject = "",
    supportType = "",
    desiredSolution = "",
    attachments = [],
    customerID = "",
    leadID = "",
    affiliateID = "",
    assignedTo = "",
    dueDate = "",
    status = "Pending",
    priority = "Normal",
    submittedBy = ""
  ) {
    let payload = {
      storeID,
      subject,
      desiredSolution,
      attachments,
      priority,
      customerID,
      leadID,
      affiliateID,
      status,
      dueDate,
      assignedTo,
      supportType,
      supportTicket: true,
      submittedBy,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/global/support/franchise/tickets/${ticketID}`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteTechSupportTicket(taskID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/global/support/franchise/tickets/${taskID}`,

      this.ENV_USER_TOKEN
    );
  }

  static createLead(
    storeID = "",
    name = "",
    email = "",
    phone = "",
    zipCode = "",
    source = "Store Visit",
    sourceType = "In-Store",
    status = "Not Contacted"
  ) {
    let payload = {
      name,
      email,
      phone,
      zipCode,
      status,
      initialSource: source,
      sourceType,
    };

    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/leads`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteLead(storeID = "", leadID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/leads/${leadID}`,

      this.ENV_USER_TOKEN
    );
  }

  static getLeadByID(storeID = "", id = "") {
    return this._bearerAuthRequest(
      "GET",
      `/storefront/${storeID}/leads/${id}`,
      this.ENV_USER_TOKEN
    );
  }

  static getAutomatedEmails(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/communications/email/automated?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),

      this.ENV_USER_TOKEN
    );
  }

  static setLeadVariable(storeID = "", leadID = "", key = "", value = null) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/leads/${leadID}/information/${key}`,

      this.ENV_USER_TOKEN,
      {
        value,
      }
    );
  }

  static updateLeadInformation(
    storeID = "",
    leadID = "",
    name = "",
    email = "",
    phone = "",
    zipCode = ""
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/leads/${leadID}/contact`,

      this.ENV_USER_TOKEN,
      {
        name,
        email,
        phone,
        zipCode: zipCode ? zipCode : "",
      }
    );
  }

  static assignLeadToStore(storeID = "", leadID = "") {
    return this._bearerAuthRequest(
      "PUT",
      `/global/leads/${leadID}/store`,

      this.ENV_USER_TOKEN,
      {
        storeID: storeID,
      }
    );
  }

  static getStoreUsers(id) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" + id + "/users",
      this.ENV_USER_TOKEN
    );
  }

  static deleteStoreUser(id, userID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      "/storefront/" + id + "/users/" + userID,
      this.ENV_USER_TOKEN
    );
  }

  static createStoreUser(id, name = "", email = "") {
    return this._bearerAuthRequest(
      "POST",
      "/storefront/" + id + "/users",
      this.ENV_USER_TOKEN,
      {
        name,
        email,
      }
    );
  }

  static resendStoreUserInvite(id, userID = "") {
    return this._bearerAuthRequest(
      "PUT",
      "/storefront/" + id + "/users/" + userID + "/invite",
      this.ENV_USER_TOKEN
    );
  }

  static createAffiliate(
    storeID = "",
    name = "",
    email = "",
    phone = "",
    type = "",
    pointOfContact = "",
    pointOfContactEmail = "",
    numberMembers = null
  ) {
    let payload = {
      name,
      email,
      phone,
      pointOfContact,
      pointOfContactEmail,
      type,
      numberMembers,
    };

    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/affiliates`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteAffiliate(storeID = "", leadID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/affiliates/${leadID}`,

      this.ENV_USER_TOKEN
    );
  }

  static getAffiliateByID(storeID = "", id = "") {
    return this._bearerAuthRequest(
      "GET",
      `/storefront/${storeID}/affiliates/${id}`,
      this.ENV_USER_TOKEN
    );
  }

  static modifyAffiliate(storeID = "", affiliateID = "", payload = {}) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/affiliates/${affiliateID}`,

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static getLibraryCategories() {
    return this._bearerAuthRequest(
      "GET",
      `/university/library/categories`,
      this.ENV_USER_TOKEN,
      null
    );
  }

  static createLibraryCategory(name = "", description = "") {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "POST",
      `/university/library/categories`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyLibraryCategory(categoryID = "", name = "", description = "") {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/categories/${categoryID}`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static reorderLibraryCategories(categories) {
    let payload = {
      categories,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/categories/order`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteLibraryCategory(categoryID = "", name = "", description = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/university/library/categories/${categoryID}`,
      this.ENV_USER_TOKEN
    );
  }

  static getLibraryModules() {
    return this._bearerAuthRequest(
      "GET",
      `/university/library/modules`,
      this.ENV_USER_TOKEN,
      null
    );
  }

  static getLibraryModuleByID(id) {
    return this._bearerAuthRequest(
      "GET",
      `/university/library/modules/${id}`,
      this.ENV_USER_TOKEN,
      null
    );
  }

  static createLibraryModule(name = "", description = "") {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "POST",
      `/university/library/modules`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyLibraryModule(moduleID = "", name = "", description = "") {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/modules/${moduleID}`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteLibraryModule(moduleID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/university/library/modules/${moduleID}`,
      this.ENV_USER_TOKEN
    );
  }

  static addLibraryModuleToCategory(categoryID = "", moduleID = "") {
    let payload = {
      moduleID,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/categories/${categoryID}/modules`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static removeLibraryModuleFromCategory(categoryID = "", moduleID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/university/library/categories/${categoryID}/modules/${moduleID}`,
      this.ENV_USER_TOKEN
    );
  }

  static reorderLibraryCategoryModules(categoryID, modules) {
    let payload = {
      modules,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/categories/${categoryID}/modules/order`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static createLibraryModuleSection(
    moduleID = "",
    name = "",
    description = ""
  ) {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "POST",
      `/university/library/modules/${moduleID}/sections`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyLibraryModuleSection(
    moduleID = "",
    sectionID = "",
    name = "",
    description = ""
  ) {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/modules/${moduleID}/sections/${sectionID}`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteLibraryModuleSection(moduleID = "", sectionID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/university/library/modules/${moduleID}/sections/${sectionID}`,
      this.ENV_USER_TOKEN
    );
  }

  static reorderLibraryModuleSections(moduleID, sections) {
    let payload = {
      sections,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/modules/${moduleID}/sections/order`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static getLibraryTopics() {
    return this._bearerAuthRequest(
      "GET",
      `/university/library/topics`,
      this.ENV_USER_TOKEN,
      null
    );
  }

  static getLibraryTopicByID(id) {
    return this._bearerAuthRequest(
      "GET",
      `/university/library/topics/${id}`,
      this.ENV_USER_TOKEN,
      null
    );
  }

  static createLibraryTopic(name = "", description = "") {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "POST",
      `/university/library/topics`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyLibraryTopic(topicID = "", name = "", description = "") {
    let payload = {
      name,
      description,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/topics/${topicID}`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteLibraryTopic(topicID = "") {
    return this._bearerAuthRequest(
      "DELETE",
      `/university/library/topics/${topicID}`,
      this.ENV_USER_TOKEN
    );
  }

  static addLibraryTopicToModuleSection(
    moduleID = "",
    sectionID = "",
    topicID = ""
  ) {
    let payload = {
      topicID,
    };

    return this._bearerAuthRequest(
      "PUT",
      `/university/library/modules/${moduleID}/sections/${sectionID}/topics`,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static removeLibraryTopicFromModuleSection(
    moduleID = "",
    sectionID = "",
    topicID = ""
  ) {
    return this._bearerAuthRequest(
      "DELETE",
      `/university/library/modules/${moduleID}/sections/${sectionID}/topics/${topicID}`,
      this.ENV_USER_TOKEN
    );
  }

  static reorderLibraryModuleSectionTopics(
    moduleID = "",
    sectionID = "",
    order
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/university/library/modules/${moduleID}/sections/${sectionID}/topics/order`,
      this.ENV_USER_TOKEN,
      { order }
    );
  }

  static modifyLibraryTopicContent(topicID, content) {
    return this._bearerAuthRequest(
      "PUT",
      `/university/library/topics/${topicID}/content`,
      this.ENV_USER_TOKEN,
      { content }
    );
  }

  static getConversations(page, count, filters, orderBy) {
    return this._bearerAuthRequest(
      "GET",
      "/global/conversations" +
        `?page=${page ? page : 1}&count=${count ? count : 25}${
          filters ? "&filters=" + JSON.stringify(filters) : ""
        }${orderBy ? "&orderby=" + JSON.stringify(orderBy) : ""}`,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchConversations(page, count, storeID, query) {
    return this._bearerAuthRequest(
      "GET",
      "/global/conversations/search" +
        `?page=${page ? page : 1}&count=${
          count ? count : 25
        }&query=${query}&storeID=${storeID}`,
      this.ENV_USER_TOKEN
    );
  }

  static markAllConversationsRead(storeID, selected) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/conversations/bulk-actions/" + storeID + "/read",
      this.ENV_USER_TOKEN,
      {
        selectedConversations: selected,
      }
    );
  }

  static closeAllConversations(storeID, selected) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/conversations/bulk-actions/" + storeID + "/close",
      this.ENV_USER_TOKEN,
      {
        selectedConversations: selected,
      }
    );
  }

  static simpleSearchLibraryTopics(query) {
    return this._bearerAuthRequest(
      "GET",
      `/university/library/search/simple?query=${query}`,
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchStores(query) {
    return this._bearerAuthRequest(
      "GET",
      `/global/stores/search/simple?query=${query}`,
      this.ENV_USER_TOKEN
    );
  }

  static createConversation(payload) {
    return this._bearerAuthRequest(
      "POST",
      "/global/conversations",
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static getConversationCount(filters) {
    return this._bearerAuthRequest(
      "GET",
      "/global/conversations/count" +
        `?${filters ? "&filters=" + JSON.stringify(filters) : ""}`,
      this.ENV_USER_TOKEN
    );
  }

  static getConversationMessages(page, count, filters, orderBy) {
    return this._bearerAuthRequest(
      "GET",
      "/global/conversations/messages" +
        `?page=${page ? page : 1}&count=${count ? count : 25}${
          filters ? "&filters=" + JSON.stringify(filters) : ""
        }${orderBy ? "&orderby=" + JSON.stringify(orderBy) : ""}`,
      this.ENV_USER_TOKEN
    );
  }

  static sendConversationMessage(id, body, mediaUrl) {
    return this._bearerAuthRequest(
      "POST",
      "/global/conversations/" + id + "/messages",
      this.ENV_USER_TOKEN,
      {
        body: body,
        mediaUrl: mediaUrl ? mediaUrl : null,
      }
    );
  }

  static resendConversationMessage(id, messageID) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/conversations/" + id + "/messages/" + messageID + "/resend",
      this.ENV_USER_TOKEN
    );
  }

  static getConversationByID(id) {
    return this._bearerAuthRequest(
      "GET",
      `/global/conversations/${id}`,
      this.ENV_USER_TOKEN
    );
  }

  static markConversationAsRead(id) {
    return this._bearerAuthRequest(
      "PUT",
      `/global/conversations/${id}/status/read`,
      this.ENV_USER_TOKEN
    );
  }

  static markConversationAsUnread(id) {
    return this._bearerAuthRequest(
      "PUT",
      `/global/conversations/${id}/status/unread`,
      this.ENV_USER_TOKEN
    );
  }

  static modifyConversationStatus(id, status) {
    return this._bearerAuthRequest(
      "PUT",
      `/global/conversations/${id}/status`,
      this.ENV_USER_TOKEN,
      {
        status,
      }
    );
  }

  static modifyConversationAssignment(id, storeID) {
    return this._bearerAuthRequest(
      "PUT",
      `/global/conversations/${id}`,
      this.ENV_USER_TOKEN,
      {
        storeID: storeID,
      }
    );
  }

  static createConversationTemplate(storeID, name, message) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/conversation-templates`,
      this.ENV_USER_TOKEN,
      {
        name,
        message,
      }
    );
  }

  static getConversationTemplatesForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null,
    noStoreFilter = false
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/conversation-templates?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : "") +
        "&noStoreFilter=" +
        (noStoreFilter ? "true" : "false"),
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchConversationTemplatesForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/conversation-templates/search/simple?query=" +
        query,
      this.ENV_USER_TOKEN
    );
  }

  static modifyConversationTemplate(storeID, id, name, message) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/conversation-templates/${id}`,
      this.ENV_USER_TOKEN,
      {
        name,
        message,
      }
    );
  }

  static deleteConversationTemplate(storeID, id) {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/conversation-templates/${id}`,
      this.ENV_USER_TOKEN
    );
  }

  static createGlobalConversationTemplate(name, message) {
    return this._bearerAuthRequest(
      "POST",
      `/global/conversation-templates`,
      this.ENV_USER_TOKEN,
      {
        name,
        message,
      }
    );
  }

  static getGlobalConversationTemplates(
    page = 1,
    count = 25,
    filter = null,
    orderBy = null,
    noStoreFilter = false
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/global/conversation-templates?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : "") +
        "&noStoreFilter=" +
        (noStoreFilter ? "true" : "false"),
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchGlobalConversationTemplates(query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/global/conversation-templates/search/simple?query=" + query,
      this.ENV_USER_TOKEN
    );
  }

  static modifyGlobalConversationTemplate(id, name, message) {
    return this._bearerAuthRequest(
      "PUT",
      `/global/conversation-templates/${id}`,
      this.ENV_USER_TOKEN,
      {
        name,
        message,
      }
    );
  }

  static deleteGlobalConversationTemplate(id) {
    return this._bearerAuthRequest(
      "DELETE",
      `/global/conversation-templates/${id}`,
      this.ENV_USER_TOKEN
    );
  }

  static getSuppressedContactsForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null,
    noStoreFilter = false
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/suppressed-contacts?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : "") +
        "&noStoreFilter=" +
        (noStoreFilter ? "true" : "false"),
      this.ENV_USER_TOKEN
    );
  }

  static bulkCreateContactSuppressions(storeID, type, contacts) {
    let c = contacts?.map((ct) => {
      return {
        phone: ct,
      };
    });

    return this._bearerAuthRequest(
      "POST",
      "/storefront/" + storeID + "/suppressed-contacts/bulk",
      this.ENV_USER_TOKEN,
      { type, contacts: c }
    );
  }

  static deleteContactSuppressionForStore(storeID, contactID) {
    return this._bearerAuthRequest(
      "DELETE",
      "/storefront/" + storeID + "/suppressed-contacts/" + contactID,
      this.ENV_USER_TOKEN
    );
  }

  static createConversationBroadcast(
    storeID,
    lists,
    name,
    message,
    mediaUrl,
    dateTime,
    customList
  ) {
    return this._bearerAuthRequest(
      "POST",
      `/storefront/${storeID}/conversation-broadcasts`,
      this.ENV_USER_TOKEN,
      {
        name,
        message,
        mediaUrl,
        lists,
        dateTime,
        customList,
      }
    );
  }

  static getConversationBroadcastsForStore(
    storeID = "",
    page = 1,
    count = 25,
    filter = null,
    orderBy = null,
    noStoreFilter = false
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/conversation-broadcasts?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : "") +
        "&noStoreFilter=" +
        (noStoreFilter ? "true" : "false"),
      this.ENV_USER_TOKEN
    );
  }

  static simpleSearchConversationBroadcastsForStore(storeID = "", query = "") {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/conversation-broadcasts/search/simple?query=" +
        query,
      this.ENV_USER_TOKEN
    );
  }

  static modifyConversationBroadcast(
    storeID,
    id,
    lists,
    name,
    message,
    mediaUrl,
    dateTime,
    customList
  ) {
    return this._bearerAuthRequest(
      "PUT",
      `/storefront/${storeID}/conversation-broadcasts/${id}`,
      this.ENV_USER_TOKEN,
      {
        name,
        message,
        lists,
        dateTime,
        mediaUrl,
        customList,
      }
    );
  }

  static deleteConversationBroadcast(storeID, id) {
    return this._bearerAuthRequest(
      "DELETE",
      `/storefront/${storeID}/conversation-broadcasts/${id}`,
      this.ENV_USER_TOKEN
    );
  }

  static getConversationBroadcastListCountForStore(
    storeID = "",
    listName = ""
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/storefront/" +
        storeID +
        "/conversation-broadcast-lists/" +
        listName +
        "/count",
      this.ENV_USER_TOKEN
    );
  }

  static getProductionOrders(
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/production/orders" +
        "?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static getProductionOrderByID(id) {
    return this._bearerAuthRequest(
      "GET",
      "/production/orders/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static getLatestProductionPricing() {
    return this._bearerAuthRequest(
      "GET",
      "/production/pricing/latest",
      this.ENV_USER_TOKEN
    );
  }

  static getProductionPricingByID(id) {
    return this._bearerAuthRequest(
      "GET",
      "/production/pricing/" + id,
      this.ENV_USER_TOKEN
    );
  }

  static createProductionPricing(payload) {
    return this._bearerAuthRequest(
      "POST",
      "/production/pricing",

      this.ENV_USER_TOKEN,
      payload
    );
  }

  static getContentCalendar(
    page = 1,
    count = 25,
    filter = null,
    orderBy = null
  ) {
    return this._bearerAuthRequest(
      "GET",
      "/global/marketing/content-calendar?page=" +
        (page >= 1 ? page : 1) +
        "&count=" +
        (count >= 1 ? count : 25) +
        "&filters=" +
        (typeof filter == "object" ? JSON.stringify(filter) : "") +
        "&orderby=" +
        (typeof orderBy == "object" ? JSON.stringify(orderBy) : ""),
      this.ENV_USER_TOKEN
    );
  }

  static addContentCalendarItem(payload) {
    return this._bearerAuthRequest(
      "POST",
      "/global/marketing/content-calendar",
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static modifyContentCalendarItem(id, payload) {
    return this._bearerAuthRequest(
      "PUT",
      "/global/marketing/content-calendar/" + id,
      this.ENV_USER_TOKEN,
      payload
    );
  }

  static deleteContentCalendarItem(id) {
    return this._bearerAuthRequest(
      "DELETE",
      "/global/marketing/content-calendar/" + id,
      this.ENV_USER_TOKEN
    );
  }
}
