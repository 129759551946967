import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  DeltaBar,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
  Col as TCol,
  ColGrid,
  DonutChart,
  Bold,
  List,
  ListItem,
  BarList,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";

class NewMemberSourcesCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    active: "A",
    typeList: [],
    nameList: [],
    utmSourceList: [],
    utmCampaignList: [],
  };

  /**
   * Fetches a summary of the order data for the given stores and date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, dimension = "") {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["CustomerLeads.total"],
          order: {
            "CustomerLeads.convertedat": "asc",
          },

          timeDimensions: [
            {
              dimension: "CustomerLeads.convertedat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "CustomerLeads.storeid",
              operator: "equals",
              values: stores,
            },
          ],
          dimensions: [dimension],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          data = data?.map((item) => {
            total += item["CustomerLeads.total"];

            return {
              name: item[dimension]
                ? item[dimension]
                : `${
                    dimension == "CustomerLeads.conversionSourceType"
                      ? "Online"
                      : "(none set)"
                  }`,
              count: item["CustomerLeads.total"],
            };
          });

          data = _.sortBy(data, "count");
          data.reverse();

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let typeList = [];
    let nameList = [];
    let utmSourceList = [];
    let utmCampaignList = [];

    try {
      typeList = await this._fetchData(
        cubejsApi,
        stores,
        dateRange,
        "CustomerLeads.conversionSourceType"
      );

      this.setState({
        totalType: typeList.total,
      });

      typeList = typeList.history;
    } catch (e) {
      this.setState({
        typeAvailable: false,
        error: "Unable to load lead counts.",
      });
    }

    try {
      nameList = await this._fetchData(
        cubejsApi,
        stores,
        dateRange,
        "CustomerLeads.conversionSource"
      );

      this.setState({
        totalName: nameList.total,
      });

      nameList = nameList.history;
    } catch (e) {
      this.setState({
        typeAvailable: false,
        error: "Unable to load lead counts.",
      });
    }

    try {
      utmCampaignList = await this._fetchData(
        cubejsApi,
        stores,
        dateRange,
        "CustomerLeads.utmcampaign"
      );

      this.setState({
        totalUTMCampaign: utmCampaignList.total,
      });

      utmCampaignList = utmCampaignList.history;
    } catch (e) {
      this.setState({
        typeAvailable: false,
        error: "Unable to load lead counts.",
      });
    }

    try {
      utmSourceList = await this._fetchData(
        cubejsApi,
        stores,
        dateRange,
        "CustomerLeads.utmsource"
      );

      this.setState({
        totalUTMSource: utmSourceList.total,
      });

      utmSourceList = utmSourceList.history;
    } catch (e) {
      this.setState({
        typeAvailable: false,
        error: "Unable to load lead counts.",
      });
    }

    if (typeList !== null) {
      this.setState({
        typeAvailable: true,
      });
    } else {
      this.setState({
        typeAvailable: false,
      });
    }

    if (nameList !== null) {
      this.setState({
        nameAvailable: true,
      });
    } else {
      this.setState({
        nameAvailable: false,
      });
    }

    if (utmSourceList !== null) {
      this.setState({
        utmSourceAvailable: true,
      });
    } else {
      this.setState({
        utmSourceAvailable: false,
      });
    }

    if (utmCampaignList !== null) {
      this.setState({
        utmCampaignAvailable: true,
      });
    } else {
      this.setState({
        utmCampaignAvailable: false,
      });
    }

    this.setState({
      typeList,
      nameList,
      utmSourceList,
      utmCampaignList,
      loading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.allStores != prevProps?.allStores ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );
    }
  }

  render() {
    return (
      <>
        <Card hFull={true}>
          <div>
            <Row>
              <Col xs="">
                <h3 className="m-0 text-dark">First-Time Member Sources</h3>
              </Col>
            </Row>
          </div>
          <TabList
            color="orange"
            defaultValue="A"
            handleSelect={(value) => {
              this.setState({
                active: value,
              });
            }}
            marginTop="mt-0"
          >
            <Tab value="A" text="By Location" />
            <Tab value="B" text="By Name" />
            <Tab value="C" text="By UTM Source" />
            <Tab value="D" text="By UTM Campaign" />
          </TabList>
          {this.state.loading ? (
            <>
              <ColGrid
                numColsLg={3}
                marginTop="mt-8"
                gapX="gap-x-14"
                gapY="gap-y-10"
              >
                <Flex>
                  <div
                    className="skeleton rounded"
                    style={{ width: "100%", height: 208 }}
                  >
                    &nbsp;
                  </div>
                </Flex>
                <TCol numColSpan={1} numColSpanLg={2}>
                  <div
                    className="skeleton rounded"
                    style={{ width: "100%", height: 208 }}
                  >
                    &nbsp;
                  </div>
                </TCol>
              </ColGrid>
            </>
          ) : (
            <>
              {this.state.active == "A" && (
                <ColGrid
                  numColsLg={3}
                  marginTop="mt-8"
                  gapX="gap-x-14"
                  gapY="gap-y-10"
                >
                  <Flex>
                    <DonutChart
                      data={this.state.typeList}
                      category="count"
                      dataKey="name"
                      variant="donut"
                      valueFormatter={(number) => {
                        return StringUtils.numberFormat(number);
                      }}
                      height="h-52"
                    />
                  </Flex>
                  <TCol numColSpan={1} numColSpanLg={2}>
                    <Flex>
                      <Text truncate={true}>
                        <Bold>Conversion Location</Bold>
                      </Text>
                      <Text>
                        <Bold># Members</Bold>
                      </Text>
                    </Flex>

                    <div>
                      <div
                        className="pt-3 mb--4 pb-4"
                        style={{
                          maxHeight: 368,
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <BarList
                          data={this.state.typeList?.map((item) => {
                            return {
                              name: item?.name,
                              value: item?.count,
                            };
                          })}
                          valueFormatter={(number) => {
                            return (
                              StringUtils.numberFormat(number) +
                              " Members " +
                              `[${(
                                (number / this.state.totalType) *
                                100
                              ).toFixed(1)}%]`
                            );
                          }}
                          color="orange"
                          showAnimation={true}
                          marginTop="mt-0"
                        />
                      </div>
                    </div>
                  </TCol>
                </ColGrid>
              )}
              {this.state.active == "B" && (
                <ColGrid
                  numColsLg={3}
                  marginTop="mt-8"
                  gapX="gap-x-14"
                  gapY="gap-y-10"
                >
                  <Flex>
                    <DonutChart
                      data={this.state.nameList}
                      category="count"
                      dataKey="name"
                      variant="donut"
                      valueFormatter={(number) => {
                        return StringUtils.numberFormat(number);
                      }}
                      height="h-52"
                    />
                  </Flex>
                  <TCol numColSpan={1} numColSpanLg={2}>
                    <Flex>
                      <Text truncate={true}>
                        <Bold>Source Name</Bold>
                      </Text>
                      <Text>
                        <Bold># Members</Bold>
                      </Text>
                    </Flex>

                    <div>
                      <div
                        className="pt-3 mb--4 pb-4"
                        style={{
                          maxHeight: 368,
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <BarList
                          data={this.state.nameList?.map((item) => {
                            return {
                              name: item?.name,
                              value: item?.count,
                            };
                          })}
                          valueFormatter={(number) => {
                            return (
                              StringUtils.numberFormat(number) +
                              " Members " +
                              `[${(
                                (number / this.state.totalName) *
                                100
                              ).toFixed(1)}%]`
                            );
                          }}
                          color="orange"
                          showAnimation={true}
                          marginTop="mt-0"
                        />
                      </div>
                    </div>
                  </TCol>
                </ColGrid>
              )}
              {this.state.active == "C" && (
                <ColGrid
                  numColsLg={3}
                  marginTop="mt-8"
                  gapX="gap-x-14"
                  gapY="gap-y-10"
                >
                  <Flex>
                    <DonutChart
                      data={this.state.utmSourceList}
                      category="count"
                      dataKey="name"
                      variant="donut"
                      valueFormatter={(number) => {
                        return StringUtils.numberFormat(number);
                      }}
                      height="h-52"
                    />
                  </Flex>
                  <TCol numColSpan={1} numColSpanLg={2}>
                    <Flex>
                      <Text truncate={true}>
                        <Bold>UTM Source</Bold>
                      </Text>
                      <Text>
                        <Bold># Members</Bold>
                      </Text>
                    </Flex>

                    <div>
                      <div
                        className="pt-3 mb--4 pb-4"
                        style={{
                          maxHeight: 368,
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <BarList
                          data={this.state.utmSourceList?.map((item) => {
                            return {
                              name:
                                item?.name == "(none set)"
                                  ? "(direct web traffic)"
                                  : item.name,
                              value: item?.count,
                            };
                          })}
                          valueFormatter={(number) => {
                            return (
                              StringUtils.numberFormat(number) +
                              " Members " +
                              `[${(
                                (number / this.state.totalUTMSource) *
                                100
                              ).toFixed(1)}%]`
                            );
                          }}
                          color="orange"
                          showAnimation={true}
                          marginTop="mt-0"
                        />
                      </div>
                    </div>
                  </TCol>
                </ColGrid>
              )}
              {this.state.active == "D" && (
                <ColGrid
                  numColsLg={3}
                  marginTop="mt-8"
                  gapX="gap-x-14"
                  gapY="gap-y-10"
                >
                  <Flex>
                    <DonutChart
                      data={this.state.utmCampaignList}
                      category="count"
                      dataKey="name"
                      variant="donut"
                      valueFormatter={(number) => {
                        return StringUtils.numberFormat(number);
                      }}
                      height="h-52"
                    />
                  </Flex>
                  <TCol numColSpan={1} numColSpanLg={2}>
                    <Flex>
                      <Text truncate={true}>
                        <Bold>UTM Campaign</Bold>
                      </Text>
                      <Text>
                        <Bold># Members</Bold>
                      </Text>
                    </Flex>

                    <div>
                      <div
                        className="pt-3 mb--4 pb-4"
                        style={{
                          maxHeight: 368,
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <BarList
                          data={this.state.utmCampaignList?.map((item) => {
                            return {
                              name:
                                item?.name == "(none set)"
                                  ? "(direct web traffic)"
                                  : item.name,
                              value: item?.count,
                            };
                          })}
                          valueFormatter={(number) => {
                            return (
                              StringUtils.numberFormat(number) +
                              " Members " +
                              `[${(
                                (number / this.state.totalUTMCampaign) *
                                100
                              ).toFixed(1)}%]`
                            );
                          }}
                          color="orange"
                          showAnimation={true}
                          marginTop="mt-0"
                        />
                      </div>
                    </div>
                  </TCol>
                </ColGrid>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default NewMemberSourcesCard;
