import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import API from "lib/API";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import APIV2 from "lib/APIV2";
import { Link } from "react-router-dom/cjs/react-router-dom";

let logo = require("assets/img/brand/storefront_wordmark.svg");

class Login extends React.Component {
  state = {
    submitting: false,
    email: "",
    password: "",
    error: false,
  };

  handleEmailChange(event) {
    const val = event.target.value;

    this.setState({
      email: val,
    });
  }

  handlePasswordChange(event) {
    const val = event.target.value;

    this.setState({
      password: val,
    });
  }

  signIn(e) {
    let self = this;

    e.preventDefault();

    console.log("sign in");

    let email = this.state.email;
    let password = this.state.password;

    if (!email) {
      return;
    }

    if (!password) {
      return;
    }

    this.setState({
      submitting: true,
      error: false,
    });

    APIV2.authenticate(email, password)
      .then(
        (data) => {
          console.log(data);

          if (data && data.data && data.data.authenticationToken) {
            API.setAuthToken(data.data.authenticationToken);
            APIV2.setAuthToken(data.data.authenticationToken);
            APIV2._wsConnect();
            LocalStorage.set(
              Constant.CACHE.TOKEN,
              data.data.authenticationToken
            );
            console.log(LocalStorage.get(Constant.CACHE.TOKEN));

            if (data?.data?.user) {
              data.data.user.id = data.data.user._id;

              let user = data.data.user;

              window[Constant.CACHE.USER] = user;

              console.log(window[Constant.CACHE.USER]);

              LocalStorage.set(Constant.CACHE.USER, user);

              PubSub.publish(Event.USER_LOADED, user);

              if (user?.globalPermissions?.length) {
                self.props.history.push("/frandev/stores");
              } else if (user.storePermissions?.length) {
                self.props.history.push(
                  `/storefront/${user.storePermissions[0]?.storeID}`
                );
              } else if (user.productionPermissions?.length) {
                self.props.history.push(`/production`);
              } else {
                self.props.history.push("/frandev/stores");
              }
            } else {
              self.props.history.push("/frandev/stores");
            }
          }
        },
        (error) => {
          console.error(error);
          this.setState({
            error: true,
          });
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    // Redirect if already authenticated
    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/admin");
    }

    return (
      <>
        <Col lg="6" md="7">
          <Card className="bg-white shadow border">
            <CardHeader className="bg-white py-4 px-5">
              <img
                src={logo}
                style={{
                  width: "90%",
                  maxWidth: "240px",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                className="my-2"
              ></img>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-dark mb-5 font-weight-500">
                Sign in below to continue
              </div>
              <Form role="form" onSubmit={this.signIn.bind(this)}>
                <FormGroup className="mb-3">
                  <h5>Email</h5>
                  <Input
                    required={true}
                    onChange={this.handleEmailChange.bind(this)}
                    value={this.state.email}
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                  />
                </FormGroup>
                <FormGroup>
                  <h5>Password</h5>
                  <Input
                    required={true}
                    onChange={this.handlePasswordChange.bind(this)}
                    value={this.state.password}
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                  <div className="small mt-2">
                    <Link to="/auth/login/forgot">Forgot Password?</Link>
                  </div>
                </FormGroup>

                <div className="text-center">
                  <Button
                    disabled={this.state.submitting}
                    className="my-4 px-6"
                    color="primary"
                    type="submit"
                    size="lg"
                    style={{ fontSize: 15 }}
                  >
                    Sign In
                  </Button>
                </div>
                {this.state.error ? (
                  <div className="text-center">
                    <Alert color="danger">
                      Invalid email or password, please try again.
                    </Alert>
                  </div>
                ) : null}
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            {/*<Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>*/}
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
