import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import ModifyStoreBillingAccountModal from "./ModifyStoreBillingAccountModal";

import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import NumberFormat from "react-number-format";
import Spinner from "reactstrap/lib/Spinner";
import { withRouter } from "react-router";

class StoreViewTermsTab extends React.Component {
  state = {
    restockingFee: {},
    open: false,
    pickupEditorShow: false,
    deliveryEditorShow: false,
    generalEditorShow: false,
    prelaunchEditorShow: false,
    pickupEditorState: EditorState.createEmpty(),
    prelaunchEditorState: EditorState.createEmpty(),
    deliveryEditorState: EditorState.createEmpty(),
    generalEditorState: EditorState.createEmpty(),
    pickupEditorSubmitting: false,
    deliveryEditorSubmitting: false,
    generalEditorSubmitting: false,
    prelaunchEditorSubmitting: false,
  };

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });
    });

    if (this.state.store && this.state.store.operations.terms.pickup) {
      this.setState({
        pickupEditorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              this.state.store.operations.terms.pickup
                ? this.state.store.operations.terms.pickup
                : ""
            )
          )
        ),
      });
    }

    if (this.state.store && this.state.store.operations.terms.delivery) {
      this.setState({
        deliveryEditorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              this.state.store.operations.terms.delivery
                ? this.state.store.operations.terms.delivery
                : ""
            )
          )
        ),
      });
    }

    if (this.state.store && this.state.store.operations.terms.general) {
      this.setState({
        generalEditorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              this.state.store.operations.terms.general
                ? this.state.store.operations.terms.general
                : ""
            )
          )
        ),
      });
    }

    if (this.state.store && this.state.store.operations.terms.preorder) {
      this.setState({
        prelaunchEditorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              this.state.store.operations.terms.preorder
                ? this.state.store.operations.terms.preorder
                : ""
            )
          )
        ),
      });
    }
  }

  onPickupEditorStateChange(editorState) {
    this.setState({
      pickupEditorState: editorState,
    });
  }

  onDeliveryEditorStateChange(editorState) {
    this.setState({
      deliveryEditorState: editorState,
    });
  }

  onGeneralEditorStateChange(editorState) {
    this.setState({
      generalEditorState: editorState,
    });
  }

  onPrelaunchEditorStateChange(editorState) {
    this.setState({
      prelaunchEditorState: editorState,
    });
  }

  savePickup() {
    let content = draftToHtml(
      convertToRaw(this.state.pickupEditorState.getCurrentContent())
    );

    if (!content) {
      window.alert("Please enter Pickup Terms to save and continue.");

      return;
    }

    this.setState({
      pickupEditorSubmitting: true,
    });

    APIV2.setStorePickupTerms(this.state.store._id, content)
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);
          this.setState({
            pickupEditorShow: false,
          });
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to save the terms. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          pickupEditorSubmitting: false,
        });
      });
  }

  cancelPickup() {
    this.setState({
      pickupEditorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            this.state.store.operations.terms.pickup
              ? this.state.store.operations.terms.pickup
              : ""
          )
        )
      ),
      pickupEditorShow: false,
    });
  }

  savePrelaunch() {
    let content = draftToHtml(
      convertToRaw(this.state.prelaunchEditorState.getCurrentContent())
    );

    if (!content) {
      window.alert("Please enter Pickup Terms to save and continue.");

      return;
    }

    this.setState({
      prelaunchEditorSubmitting: true,
    });

    APIV2.setStorePrelaunchTerms(this.state.store._id, content)
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);
          this.setState({
            prelaunchEditorShow: false,
          });
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to save the terms. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          prelaunchEditorSubmitting: false,
        });
      });
  }

  cancelPrelaunch() {
    this.setState({
      prelaunchEditorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            this.state.store.operations.terms.preorder
              ? this.state.store.operations.terms.preorder
              : ""
          )
        )
      ),
      prelaunchEditorShow: false,
    });
  }

  saveDelivery() {
    let content = draftToHtml(
      convertToRaw(this.state.deliveryEditorState.getCurrentContent())
    );

    if (!content) {
      window.alert("Please enter Delivery Terms to save and continue.");

      return;
    }

    this.setState({
      deliveryEditorSubmitting: true,
    });

    APIV2.setStoreDeliveryTerms(this.state.store._id, content)
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);
          this.setState({
            deliveryEditorShow: false,
          });
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to save the terms. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          deliveryEditorSubmitting: false,
        });
      });
  }

  cancelDelivery() {
    this.setState({
      deliveryEditorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            this.state.store.operations.terms.delivery
              ? this.state.store.operations.terms.delivery
              : ""
          )
        )
      ),
      deliveryEditorShow: false,
    });
  }

  setRestockingFee() {
    this.setState({
      restockSubmitting: true,
    });

    APIV2.setStoreRestockingFee(
      this.state.store._id,
      parseInt(this.state.restockingFee.value) * 100
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            showEditRestock: false,
          });
        },
        (e) => {
          console.error(e);

          alert("Failed to set restocking fee.");
        }
      )
      .finally(() => {
        this.setState({
          restockSubmitting: false,
        });
      });
  }

  saveGeneral() {
    let content = draftToHtml(
      convertToRaw(this.state.generalEditorState.getCurrentContent())
    );

    if (!content) {
      window.alert(
        "Please enter General Terms & Conditions to save and continue."
      );

      return;
    }

    this.setState({
      generalEditorSubmitting: true,
    });

    APIV2.setStoreGeneralTerms(this.state.store._id, content)
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);
          this.setState({
            generalEditorShow: false,
          });
        },
        (e) => {
          console.error(e);
          window.alert("We were unable to save the terms. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          generalEditorSubmitting: false,
        });
      });
  }

  cancelGeneral() {
    this.setState({
      generalEditorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            this.state.store.operations.terms.general
              ? this.state.store.operations.terms.general
              : ""
          )
        )
      ),
      generalEditorShow: false,
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Card className="mb-3 shadow">
              <CardHeader className="bg-transparent">
                <Row>
                  <div className="col">
                    <h3 className="mb-0">Pickup Terms</h3>
                  </div>
                  <div className="col-auto text-right">
                    {this.state.pickupEditorShow ? (
                      <>
                        <Button
                          onClick={this.cancelPickup.bind(this)}
                          className="btn-sm btn"
                          color="light"
                          disabled={this.state.pickupEditorSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.savePickup.bind(this)}
                          className="btn-sm btn"
                          color="primary"
                          disabled={this.state.pickupEditorSubmitting}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        {this.state.store &&
                        this.state.store.operations &&
                        this.state.store.operations.terms &&
                        this.state.store.operations.terms.pickup ? (
                          <Button
                            onClick={() => {
                              this.setState({
                                pickupEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({
                                pickupEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {!this.state.pickupEditorShow ? (
                  <>
                    <p className="mb-0">
                      {this.state.store &&
                      this.state.store.operations.terms.pickup ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.store.operations.terms.pickup,
                          }}
                        ></span>
                      ) : (
                        "(None)"
                      )}
                    </p>
                  </>
                ) : (
                  <>
                    <Editor
                      editorState={this.state.pickupEditorState}
                      onEditorStateChange={this.onPickupEditorStateChange.bind(
                        this
                      )}
                    ></Editor>
                  </>
                )}
              </CardBody>
            </Card>
          </div>
          <div className="col-12">
            <Card className="mb-3 shadow">
              <CardHeader className="bg-transparent">
                <Row>
                  <div className="col">
                    <h3 className="mb-0">Delivery Terms</h3>
                  </div>
                  <div className="col-auto text-right">
                    {this.state.deliveryEditorShow ? (
                      <>
                        <Button
                          onClick={this.cancelDelivery.bind(this)}
                          className="btn-sm btn"
                          color="light"
                          disabled={this.state.deliveryEditorSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.saveDelivery.bind(this)}
                          className="btn-sm btn"
                          color="primary"
                          disabled={this.state.deliveryEditorSubmitting}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        {this.state.store &&
                        this.state.store.operations &&
                        this.state.store.operations.terms &&
                        this.state.store.operations.terms.delivery ? (
                          <Button
                            onClick={() => {
                              this.setState({
                                deliveryEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({
                                deliveryEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {!this.state.deliveryEditorShow ? (
                  <>
                    <p className="mb-0">
                      {this.state.store &&
                      this.state.store.operations &&
                      this.state.store.operations.terms &&
                      this.state.store.operations.terms.delivery ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.store.operations.terms.delivery,
                          }}
                        ></span>
                      ) : (
                        "(None)"
                      )}
                    </p>
                  </>
                ) : (
                  <>
                    <Editor
                      editorState={this.state.deliveryEditorState}
                      onEditorStateChange={this.onDeliveryEditorStateChange.bind(
                        this
                      )}
                    ></Editor>
                  </>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-12">
            <Card className="mb-3 shadow">
              <CardHeader className="bg-transparent">
                <Row>
                  <div className="col">
                    <h3 className="mb-0">General Terms &amp; Conditions</h3>
                  </div>
                  <div className="col-auto text-right">
                    {this.state.generalEditorShow ? (
                      <>
                        <Button
                          onClick={this.cancelGeneral.bind(this)}
                          className="btn-sm btn"
                          color="light"
                          disabled={this.state.generalEditorSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.saveGeneral.bind(this)}
                          className="btn-sm btn"
                          color="primary"
                          disabled={this.state.generalEditorSubmitting}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        {this.state.store &&
                        this.state.store.operations &&
                        this.state.store.operations.terms &&
                        this.state.store.operations.terms.general ? (
                          <Button
                            onClick={() => {
                              this.setState({
                                generalEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({
                                generalEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {!this.state.generalEditorShow ? (
                  <>
                    <p className="mb-0">
                      {this.state.store &&
                      this.state.store.operations &&
                      this.state.store.operations.terms &&
                      this.state.store.operations.terms.general ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.store.operations.terms.general,
                          }}
                        ></span>
                      ) : (
                        "(None)"
                      )}
                    </p>
                  </>
                ) : (
                  <>
                    <Editor
                      editorState={this.state.generalEditorState}
                      onEditorStateChange={this.onGeneralEditorStateChange.bind(
                        this
                      )}
                    ></Editor>
                  </>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-12">
            <Card className="mb-3 shadow">
              <CardHeader className="bg-transparent">
                <Row>
                  <div className="col">
                    <h3 className="mb-0">Store Pre-Launch Terms</h3>
                  </div>
                  <div className="col-auto text-right">
                    {this.state.prelaunchEditorShow ? (
                      <>
                        <Button
                          onClick={this.cancelPrelaunch.bind(this)}
                          className="btn-sm btn"
                          color="light"
                          disabled={this.state.prelaunchEditorSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.savePrelaunch.bind(this)}
                          className="btn-sm btn"
                          color="primary"
                          disabled={this.state.prelaunchEditorSubmitting}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        {this.state.store &&
                        this.state.store.operations &&
                        this.state.store.operations.terms &&
                        this.state.store.operations.terms.preorder ? (
                          <Button
                            onClick={() => {
                              this.setState({
                                prelaunchEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="secondary"
                            outline
                          >
                            Modify
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({
                                prelaunchEditorShow: true,
                              });
                            }}
                            className="btn-sm btn"
                            color="primary"
                          >
                            Add
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {!this.state.prelaunchEditorShow ? (
                  <>
                    <p className="mb-0">
                      {this.state.store &&
                      this.state.store.operations &&
                      this.state.store.operations.terms &&
                      this.state.store.operations.terms.preorder ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.store.operations.terms.preorder,
                          }}
                        ></span>
                      ) : (
                        "(None)"
                      )}
                    </p>
                  </>
                ) : (
                  <>
                    <Editor
                      editorState={this.state.prelaunchEditorState}
                      onEditorStateChange={this.onPrelaunchEditorStateChange.bind(
                        this
                      )}
                    ></Editor>
                  </>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-12">
            <Card className=" shadow">
              <CardHeader className="bg-transparent">
                <Row>
                  <div className="col">
                    <h3 className=" mb-0">Account Modifications</h3>
                  </div>
                  <div className="col-auto text-right">
                    {this.state.showEditRestock ? (
                      <>
                        <Button
                          onClick={() => {
                            this.setState({ showEditRestock: false });
                          }}
                          className="btn-sm btn"
                          color="secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            this.setRestockingFee();
                          }}
                          className="btn-sm btn"
                          color="primary"
                          disabled={this.state.restockSubmitting}
                        >
                          {this.state.restockSubmitting ? (
                            <>
                              <Spinner size="sm" color="white"></Spinner>
                            </>
                          ) : (
                            <>Save</>
                          )}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            this.setState({
                              showEditRestock: true,
                              restockingFee: {
                                value:
                                  this.state.store.operations &&
                                  this.state.store.operations.terms &&
                                  this.state.store.operations.terms
                                    .restockingFee
                                    ? Math.floor(
                                        this.state.store.operations.terms
                                          .restockingFee / 100
                                      )
                                    : null,
                              },
                            });
                          }}
                          className="btn-sm btn"
                          color="secondary"
                          outline
                        >
                          Modify
                        </Button>
                      </>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <h4 className="mb-3">Weekly Cutoff Time</h4>
                <p>Wednesday at 11:59 PM ET</p>
                <h4 className="mb-3">Post-Cutoff Restocking Fee</h4>
                {this.state.showEditRestock ? (
                  <>
                    <NumberFormat
                      prefix="$"
                      thousandSeparator={true}
                      type="text"
                      displayType="input"
                      value={this.state.restockingFee.value}
                      customInput={Input}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      onValueChange={(e) => {
                        this.setState({ restockingFee: e });
                      }}
                    ></NumberFormat>
                  </>
                ) : (
                  <>
                    <p className="mb-0">
                      {this.state.store?.operations &&
                      this.state.store.operations.terms &&
                      this.state.store.operations.terms.restockingFee
                        ? StringUtils.centsToCurrency(
                            this.state.store.operations.terms.restockingFee
                          )
                        : "--"}
                    </p>
                  </>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(StoreViewTermsTab);
