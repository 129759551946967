import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";

class CustomerListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidMount() {
    this.loadCustomers(this.state.page, this.state.count, this.state.filters);

    this.loadStores();

    PubSub.subscribe(Event.MEAL.DELETED, (data) => {
      let g = filter(this.state.meals, (a) => {
        return a.id != data._id;
      });

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.CREATED, (m) => {
      let g = this.state.meals;

      g.push(m);

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.MODIFIED, (a) => {
      let g = this.state.meals;
      let idx = findIndex(g, { _id: a._id });

      g[idx] = a;

      this.setState({
        meals: g,
      });
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadCustomers(page = 1, count = 25, filters = null) {
    this.setState({
      loadingCustomers: true,
    });

    APIV2.getCustomers(page, count, filters)
      .then(
        (data) => {
          if (data && data.data) {
            let customers = [];

            if (data.data.customers.length) {
              customers = data.data.customers;
            }

            this.setState({
              customers,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the customers. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingCustomers: false,
        });
      });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            this.setState({ stores: data.data.stores });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadCustomers(page, this.state.count, this.state.filters);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader title="Members" showBackButton={false} />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },

                    {
                      name: "All Active",
                      id: "Active",
                    },
                    {
                      name: "HFN",
                      id: "HFN",
                    },
                  ]}
                  onChange={(option) => {
                    let filters = null;

                    if (option.id == "All") {
                      filters = null;
                    } else if (option.id == "Active") {
                      filters = {
                        accountStatus: "ACTIVE",
                      };
                    } else if (option.id == "HFN") {
                      filters = {
                        accountStatus: "HOLD",
                      };
                    }

                    this.setState({
                      filters,
                      active: option,
                    });

                    this.loadCustomers(1, this.state.count, filters);
                  }}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="" className="pr-sm-0">
                      <Input
                        type="text"
                        placeholder="Search customers..."
                        size="sm"
                        disabled={true}
                      ></Input>
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 pr-0 mt-sm-0">
                      <SelectPicker
                        searchable={false}
                        size="sm"
                        placeholder="Sort By"
                        disabled={true}
                        data={[]}
                        block
                        placement="autoVerticalEnd"
                      />
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 mt-sm-0">
                      <Button
                        color="secondary"
                        size="sm"
                        block
                        outline
                        onClick={() => {
                          PubSub.publish(Event.FILTERS.CUSTOMERS_OPEN);
                        }}
                        disabled
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.result?.start && this.state.result?.end ? (
                        <>
                          Showing{" "}
                          {StringUtils.numberFormat(this.state.result?.start)}-
                          {StringUtils.numberFormat(this.state.result?.end)}
                          {this.state.result?.total ? (
                            <>
                              &nbsp;of{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.total
                              )}{" "}
                              customers
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>---</>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={!this.state.pagination?.hasNext}
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">Customer</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Store</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Status</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Next Bill Date</h4>
                      </Col>
                    </Row>
                  </div>

                  {this.state.loadingCustomers ? (
                    <>
                      <div
                        className="text-center p-3"
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          background: "rgba(255, 255, 255, 0.6)",
                        }}
                      >
                        <Spinner
                          style={{ position: "relative", top: "15vh" }}
                          color="medium"
                          size=""
                        ></Spinner>
                      </div>
                    </>
                  ) : null}
                  {!this.state.customers?.length &&
                  !this.state.loadingCustomers ? (
                    <div className="px-3 py-2">
                      <Row>
                        <Col>Get started by adding your first customer.</Col>
                      </Row>
                    </div>
                  ) : null}

                  {this.state.customers?.map((c, i) => (
                    <div
                      className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                        i != this.state.customers.length - 1
                          ? "border-bottom"
                          : ""
                      }`}
                      key={i}
                      onClick={() => {
                        this.props.history.push(
                          "/storefront/" +
                            c.storeID +
                            "/members/details/" +
                            c._id +
                            "/summary"
                        );
                      }}
                    >
                      <Row className="align-items-center">
                        <Col xs="6" sm="6" md="4">
                          <h4 className="mb-0">{c.name}</h4>
                          <p className="mb-0" style={{ fontSize: "13px" }}>
                            {c.phone
                              ? StringUtils.formatPhoneNumber(
                                  c.phone.replace("+1", "")
                                )
                              : "--"}
                          </p>
                        </Col>
                        <Col xs="6" sm="6" md="3">
                          <div style={{ fontSize: "15px" }}>
                            {_.findWhere(this.state.stores, { _id: c.storeID })
                              ? _.findWhere(this.state.stores, {
                                  _id: c.storeID,
                                }).name.replace("Project LeanNation", "")
                              : "--"}
                          </div>
                        </Col>
                        <Col xs="6" sm="6" md="2">
                          {c.accountStatus == "ACTIVE" ? (
                            <>
                              <Badge color="success">Active</Badge>
                            </>
                          ) : null}
                          {c.accountStatus == "HOLD" ? (
                            <>
                              <Badge color="medium">Hold</Badge>
                            </>
                          ) : null}
                        </Col>
                        <Col xs="6" sm="6" md="auto">
                          <div style={{ fontSize: "15px" }}>
                            {c.accountStatus == "ACTIVE" && c.subscription ? (
                              <>
                                {moment(c.subscription.nextBillDate).format(
                                  "MM/DD/YYYY"
                                )}
                              </>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <CustomerGlobalFilterDrawer
          openEvent={Event.FILTERS.CUSTOMERS_OPEN}
        ></CustomerGlobalFilterDrawer>
      </>
    );
  }
}

export default CustomerListView;
