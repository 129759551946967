import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";

import { CSVLink } from "react-csv";

class StoreOrderBoxingListDownloadView extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    orders: [],
    compareToOptions: [],
    count: 25,
    page: 1,
    selectedOrders: [],
    searchResultOrders: [],
    active: {
      id: "All",
    },
    orders: [],
    ordersCsv: [],
    orderCsvHeaders: [],
  };

  getAvailableDates() {
    return new Promise((resolve) => {
      APIV2.getStoreSubscriptionFulfillmentDates(
        this.props.match?.params?.storeID
      )
        .then(
          (data) => {
            let dates = data.data.dates;

            dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

            this.setState({
              availableDates: dates,
            });
          },
          (e) => {
            this.setState({
              availableDates: [],
            });

            console.error(e);
          }
        )
        .finally(() => {
          resolve();
        });
    });
  }

  _rerunStatus(reportID, jobID, callback) {
    APIV2.checkRerunSubscriptionFulfillmentReportStatus(reportID, jobID).then(
      (data) => {
        console.log(data);

        callback(data);
      },
      (e) => {
        console.log(e);

        callback(null, e);
      }
    );
  }

  checkRerunStatus(reportID, jobID) {
    let self = this;

    let load = (dateString) => {
      this.loadSummary(dateString);
    };

    return new Promise((resolve, reject) => {
      this._rerunStatus(reportID, jobID, function callback(data, error) {
        if (error) {
          resolve();

          return; // Don't continue after error
        }

        if (data?.data?.job?.progress == 100) {
          resolve();

          load(self.state.fulfillmentDateString);

          return;
        }

        setTimeout(() => {
          self._rerunStatus(reportID, jobID, callback);
        }, 5000);
      });
    });
  }

  sortOrders(orders) {
    let outArr = [];
    let firstWeekArr = [];

    let typegroups = _.groupBy(orders, (o) => {
      return o?.subscription?.planTypeID;
    });

    let outObj = {};

    let typeKeys = _.keys(typegroups);

    for (let i = 0; i < typeKeys?.length; i++) {
      let procurementGroups = _.groupBy(typegroups[typeKeys[i]], (o) => {
        return o?.subscription?.plan?.procurement;
      });

      if (!outObj.hasOwnProperty(typeKeys[i])) {
        outObj[typeKeys[i]] = {};
      }

      let procKeys = _.keys(procurementGroups);

      let allProcArr = [];

      for (let j = 0; j < procKeys?.length; j++) {
        let firstWeekOrders = _.filter(procurementGroups[procKeys[j]], (o) => {
          return o?.subscription?.currentFulfillmentCount == 1;
        });

        firstWeekOrders.sort((a, b) => {
          let name1 = a?.customer?.name?.toLowerCase().trim();
          let name2 = b?.customer?.name?.toLowerCase().trim();

          return name1 > name2 ? 1 : -1;
        });

        let otherOrders = _.filter(procurementGroups[procKeys[j]], (o) => {
          return o?.subscription?.currentFulfillmentCount != 1;
        });

        otherOrders.sort((a, b) => {
          let name1 = a?.customer?.name?.toLowerCase().trim();
          let name2 = b?.customer?.name?.toLowerCase().trim();

          return name1 > name2 ? 1 : -1;
        });

        outObj[typeKeys[i]][procKeys[j]] = firstWeekOrders.concat(otherOrders);

        allProcArr = allProcArr.concat(outObj[typeKeys[i]][procKeys[j]]);
      }

      outArr = outArr.concat(allProcArr);
    }

    return outArr;
  }

  rerunReport(reportID) {
    APIV2.rerunSubscriptionFulfillmentReport(reportID).then(
      (data) => {
        this.setState({
          rerunning: true,
          rerunJob: data.data.job,
        });

        this.checkRerunStatus(reportID, data.data.job.id).then(() => {
          console.log("DONE");
        });
      },
      (e) => {
        window.alert("Unable to rerun report. Try again.");
      }
    );
  }

  sortBom(bom) {
    return _.sortBy(bom, "name");
  }

  loadOrders() {
    this.setState({
      loadingOrders: true,
    });

    APIV2.getStoreByID(this.props?.match?.params?.storeID).then((data) => {
      this.setState({ store: data?.data?.store });
    });

    APIV2.getOrdersForStore(this.props?.match?.params?.storeID, 1, 2000000, {
      status: { $ne: "CANCELLED" },
      fulfillmentDateString: {
        $regex: this.state.fulfillmentDateString,
        $options: "i",
      },
    })
      .then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (data.data.orders.length) {
              orders = data.data.orders;
            }

            if (this.state.ordersArr?.length) {
              orders = _.filter(orders, (o) => {
                return this.state.ordersArr.indexOf(o._id) >= 0;
              });
            }

            orders = this.sortOrders(orders);

            let allOrdersSum = {
              Member: "",
              Plan: "",
              Procurement: "",
              "Total Protein": 0,
            };

            let headers = [
              { label: "Member", key: "Member" },
              { label: "Plan", key: "Plan" },
              { label: "Procurement", key: "Procurement" },
              { label: "Total Protein", key: "Total Protein" },
            ];
            let athleteHeaders = [];
            let lifestyleHeaders = [];
            let leanCheatHeaders = [];

            let ordersCsv = orders?.map((order) => {
              let out = {
                Member: order?.customer?.name,
                Plan: order?.subscription?.plan?.name,
                Procurement: order?.subscription?.plan?.procurement,
                "Total Protein": 0,
              };
              let leanCheatOut = {};

              let totalQty = 0;

              for (let i = 0; i < order?.bom?.length; i++) {
                let key = `${
                  order?.subscription?.plan?.planType?.name != "Lifestyle"
                    ? `${order?.subscription?.plan?.planType?.name} `
                    : ""
                }${order.bom[i].name}`;

                if (
                  order?.subscription?.plan?.planType?.name == "Lifestyle" &&
                  !_.findWhere(order.bom[i]?.categories, {
                    _id: "613e46f513c758764b8ca868", // LeanCheat category id
                  }) &&
                  !_.findWhere(order.bom[i]?.categories, {
                    name: "Lean Cheats", // LeanCheat category id
                  })
                ) {
                  key = `Lifestyle ${order.bom[i].name.trim()}`;

                  if (!_.findWhere(lifestyleHeaders, { key })) {
                    lifestyleHeaders.push({ label: key, key });
                  }
                } else if (
                  order?.subscription?.plan?.planType?.name == "Athlete" &&
                  !_.findWhere(order.bom[i]?.categories, {
                    _id: "613e46f513c758764b8ca868", // LeanCheat category id
                  }) &&
                  !_.findWhere(order.bom[i]?.categories, {
                    name: "Lean Cheats", // LeanCheat category id
                  })
                ) {
                  key = `Athlete ${order.bom[i].name.trim()}`;

                  if (!_.findWhere(athleteHeaders, { key })) {
                    athleteHeaders.push({ label: key, key });
                  }
                }

                if (
                  _.findWhere(order.bom[i]?.categories, {
                    _id: "613e46f513c758764b8ca868", // LeanCheat category id
                  }) ||
                  _.findWhere(order.bom[i]?.categories, {
                    name: "Lean Cheats", // LeanCheat category id
                  })
                ) {
                  // Override the name for leancheats to merge them together.
                  key = "Lean Cheats " + order.bom[i].name.trim();

                  leanCheatOut[key] = order?.bom[i].quantity;

                  if (!_.findWhere(leanCheatHeaders, { key })) {
                    leanCheatHeaders.push({ label: key, key });
                  }
                } else {
                  out[key] = order?.bom[i].quantity;
                }

                totalQty += order?.bom[i].quantity;

                if (allOrdersSum[key]) {
                  allOrdersSum[key] += order?.bom[i].quantity;
                } else {
                  allOrdersSum[key] = order?.bom[i].quantity;
                }
              }

              out = _.extend(out, leanCheatOut);

              console.log(out);

              out["Total Protein"] = totalQty;
              allOrdersSum["Total Protein"] += totalQty;

              return out;
            });

            ordersCsv.push(allOrdersSum);

            lifestyleHeaders = _.sortBy(lifestyleHeaders, "key");
            athleteHeaders = _.sortBy(athleteHeaders, "key");
            leanCheatHeaders = _.sortBy(leanCheatHeaders, "key");

            headers = headers.concat(lifestyleHeaders);
            headers = headers.concat(athleteHeaders);
            headers = headers.concat(leanCheatHeaders);

            console.log(headers);

            this.setState({
              orders,
              ordersCsv,
              orderCsvHeaders: headers,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
          loading: false,
        });
      });
  }

  loadSummary(dateString) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState(
      {
        loading: true,
        rerunning: false,
        noReport: false,
        dateString,
      },
      () => {
        this.loadOrders();
      }
    );
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    const orders = urlParams.get("orders");

    let ordersArr = [];

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    if (orders) {
      ordersArr = orders.split(",");
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
        ordersArr,
      },
      () => {
        this.loadSummary(this.state.fulfillmentDateString);
      }
    );
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading || this.state.loadingOrders ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Creating Boxing List</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your pickup orders for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </div>
          ) : null}

          {this.state.noReport ||
          (!this.state.orders.length &&
            !this.state.loadingOrders &&
            !this.state.loading) ? (
            <div className="p-3">
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <span className="pl-3">No Orders Available</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  There are no orders available for fulfillment on{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </div>
          ) : null}
          {!this.state.loading ? (
            <>
              <div className="p-3">
                <Card className="border">
                  <CardHeader>
                    <h3 className="m-0">
                      Boxing List for{" "}
                      {this.state.fulfillmentMoment?.format(
                        "dddd, MMMM Do YYYY"
                      )}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <CSVLink
                      headers={this.state.orderCsvHeaders}
                      filename={`${this.state.store?.name
                        ?.replace("Project LeanNation", "PLN")
                        .split(" ")
                        .join(
                          "_"
                        )}_Boxing_List_${this.state.fulfillmentMoment?.format(
                        "MM-DD-YYYY"
                      )}.csv`}
                      data={this.state.ordersCsv}
                      className="btn btn-primary"
                    >
                      Download Boxing List
                    </CSVLink>
                  </CardBody>
                </Card>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default StoreOrderBoxingListDownloadView;
