import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import NumberFormat from "react-number-format";
import ModifyStoreModal from "./ModifyStoreModal";
import APIV2 from "lib/APIV2";
import { withRouter } from "react-router";
import {
  CheckPicker,
  DatePicker,
  Dropdown,
  Popover,
  SelectPicker,
  Whisper,
} from "rsuite";
import FranchisePartnerDrawer from "./Drawers/FranchisePartnerDrawer";
import StringUtils from "../lib/StringUtils";
import LinkUtils from "../lib/LinkUtils";
import swal from "sweetalert";
import moment from "moment";
import DateUtils from "../lib/DateUtils";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import NSOUtils from "lib/NSOUtils";
import _ from "underscore";

class StoreViewAboutTab extends React.Component {
  state = {
    open: false,
    twilioNumber: {
      value: "",
    },
    phone: {
      value: "",
    },
    retailGoal: {
      value: "",
    },
    level: "",
  };

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
              modifySquareLocation: data.data.store?.squareLocation?.id
                ? data.data.store?.squareLocation?.id
                : "",
            });

            if (data.data.store?.franchisePartners?.length) {
              this.getPartnerImages(data.data.store?.franchisePartners);
            }
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getSquareLocations() {
    const {
      match: { params },
    } = this.props;

    APIV2.getSquareLocations().then(
      (data) => {
        if (data && data.data && data.data.locations) {
          let locations = data.data.locations.map((l) => {
            return {
              value: l.id,
              label: l.name + " (#" + l.id + ")",
              location: l,
            };
          });

          this.setState({
            squareLocations: locations,
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setNSOStage() {
    this.setState({
      nsoSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "nsoStage",
      this.state.nsoStage
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyStage: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify NSO Stage: " + e?.message ?? "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          nsoSubmitting: false,
        });
      });
  }

  setPhone() {
    this.setState({
      phoneSubmitting: true,
    });

    let twilioNumber = "+1" + this.state.twilioNumber?.value;
    let phone = "+1" + this.state.phone?.value;

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "twilioNumber",
      twilioNumber
    ).then(
      (data) => {
        APIV2.modifyStorePhone(this.state?.store?._id, phone)
          .then(
            (data) => {
              PubSub.publish(Event.STORE.MODIFIED, data.data.store);

              this.setState({
                modifyPhone: false,
              });
            },
            (e) => {
              window.alert(
                "Unable to modify Phone: " + e?.message ?? "error occurred."
              );
            }
          )
          .finally(() => {
            this.setState({
              phoneSubmitting: false,
            });
          });
      },
      (e) => {
        window.alert(
          "Unable to modify Phone: " + e?.message ?? "error occurred."
        );
      }
    );
  }

  setValue(key, value, modifyKey = "") {
    this.setState({
      valSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(this.state?.store?._id, key, value)
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            [modifyKey]: false,
          });
        },
        (e) => {
          window.alert("Unable to modify: " + e?.message ?? "error occurred.");
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  setReferralSource() {
    this.setState({
      referralSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "referralSource",
      this.state.referralSource ? this.state?.referralSource : ""
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          console.log(
            JSON.stringify({ source: data.data.store?.referralSource })
          );

          this.setState({
            modifyReferral: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Referral Source: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          referralSubmitting: false,
        });
      });
  }

  setOrderGoal() {
    this.setState({
      orderGoalSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "weeklyOrderGoal",
      this.state.orderGoal ? parseInt(this.state?.orderGoal) : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyOrderGoal: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Weekly Order Goal: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          orderGoalSubmitting: false,
        });
      });
  }

  setLevel() {
    this.setState({
      levelSubmitting: true,
    });

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "level",
      this.state.level ? this.state?.level : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyLevel: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Store Level: " + e?.message ?? "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          levelSubmitting: false,
        });
      });
  }

  setRetailGoal() {
    this.setState({
      retailGoalSubmitting: true,
    });

    let value = this.state.retailGoal?.value
      ? parseFloat(this.state.retailGoal?.value) * 100
      : null;

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "weeklyRetailGoal",
      value
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyRetailGoal: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Weekly Retail Goal: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          retailGoalSubmitting: false,
        });
      });
  }

  setDisplayCityState() {
    this.setState({
      displaySubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "displayCity",
      this.state.displayCity
    ).then(
      (data1) => {
        APIV2.modifyStoreParameter(
          this.state.store?._id,
          "displayState",
          this.state.displayState
        )
          .then(
            (data) => {
              PubSub.publish(Event.STORE.MODIFIED, data.data.store);

              this.setState({
                modifyDisplay: false,
                store: data.data.store,
                displayCity: "",
                displayState: "",
              });
            },
            (e) => {
              window.alert(
                "Unable to modify marketing city & state: " + e?.message ??
                  "error occurred."
              );
            }
          )
          .finally(() => {
            this.setState({
              displaySubmitting: false,
            });
          });
      },
      (e) => {
        this.setState({
          displaySubmitting: false,
        });

        window.alert(
          "Unable to modify marketing city & state: " + e?.message ??
            "error occurred."
        );
      }
    );
  }

  updateSystemCheckParam(name, value) {
    APIV2.modifyStoreParameter(this.state?.store?._id, name, value).then(
      (data1) => {
        PubSub.publish(Event.STORE.MODIFIED, data1.data.store);
      },
      (e) => {
        this.setState({
          displaySubmitting: false,
        });

        window.alert(
          "Unable to update NSO system check item: " + e?.message ??
            "error occurred."
        );
      }
    );
  }

  componentDidMount() {
    this.loadStore();
    this.getSquareLocations();

    PubSub.subscribe(Event.STORE.DELETED, (data) => {
      this.props.history.push("/frandev/stores");
    });

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });

      if (s?.franchisePartners?.length) {
        this.getPartnerImages(s?.franchisePartners);
      }
    });
  }

  async getPartnerImages(partners) {
    let partnerImages = {};

    for (let i = 0; i < partners?.length; i++) {
      try {
        if (!partners[i]?.imageURL) {
          continue;
        }

        let result = await APIV2.getPrivateFileURL(partners[i]?.imageURL);

        if (result?.data?.url) {
          partnerImages[partners[i].id] = result.data.url;
        }
      } catch (e) {}
    }

    this.setState({
      partnerImages,
    });
  }

  removePartner(partner) {
    swal({
      title: "Remove Partner",
      text: `Are you sure you want to remove ${partner?.name} from being a partner?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      APIV2.deleteFranchisePartnerForStore(this.state.store?._id, partner.id)
        .then(
          (data) => {
            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              store: data.data.store,
            });
          },
          (error) => {
            window.alert("Unable to remove partner, please try again.");
          }
        )
        .finally(() => {});
    });
  }

  storeHasMenus(store) {
    return NSOUtils.storeHasMenus(store);
  }

  render() {
    return (
      <>
        <>
          {!DateUtils.storeIsLive(this.state.store) &&
          (this.state.store?.nsoStage == "Site Selection" ||
            this.state.store?.nsoStage == "Buildout" ||
            this.state.store?.nsoStage == "Prelaunch") ? (
            <>
              <Card className="mb-4 shadow">
                <CardHeader
                  className="bg-transparent cursor-pointer"
                  onClick={() => {
                    this.setState({
                      systemChecksOpen: !this.state.systemChecksOpen,
                    });
                  }}
                >
                  <Row className="align-items-center">
                    <Col>
                      <h3 className=" mb-0">NSO System Checks</h3>
                    </Col>
                    <Col xs="auto" className="pr-0">
                      <h2 className=" mb-0">
                        <Badge
                          className={`text-white ${
                            NSOUtils.getOverallCompletion(this.state.store) < 1
                              ? "bg-danger"
                              : "bg-success"
                          }`}
                        >
                          {NSOUtils.getOverallCompletion(this.state.store)
                            ? (
                                NSOUtils.getOverallCompletion(
                                  this.state.store
                                ) * 100
                              ).toFixed(0) + "%"
                            : "0%"}
                        </Badge>
                      </h2>
                    </Col>
                    <Col xs="auto">
                      <h1 className=" mb-0">
                        <i
                          style={{
                            lineHeight: 1,
                            position: "relative",
                            top: 2,
                          }}
                          className={`mdi mdi-chevron-${
                            this.state.systemChecksOpen ? "down" : "right"
                          }`}
                        ></i>
                      </h1>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={this.state.systemChecksOpen}>
                  <CardHeader
                    onClick={() => {
                      this.setState({
                        prelaunchOpen: !this.state.prelaunchOpen,
                      });
                    }}
                    className={`${
                      this.state.prelaunchOpen && "border-0"
                    } cursor-pointer`}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0">Prelaunch System Checks</h3>
                      </Col>
                      <Col xs="auto">
                        <h2 className={`m-0`}>
                          <Badge
                            className={`text-white ${
                              NSOUtils.getPrelaunchCompletion(
                                this.state.store
                              ) < 1
                                ? "bg-danger"
                                : "bg-success"
                            }`}
                          >
                            {NSOUtils.getPrelaunchCompletion(this.state.store)
                              ? (
                                  NSOUtils.getPrelaunchCompletion(
                                    this.state.store
                                  ) * 100
                                ).toFixed(0) + "%"
                              : "0%"}
                          </Badge>
                        </h2>
                      </Col>
                      <Col xs="auto">
                        <h2 className={`m-0`}>
                          <h2 className={`m-0`}>
                            <i
                              className={`mdi mdi-chevron-${
                                this.state.prelaunchOpen ? "down" : "right"
                              }`}
                            />
                          </h2>
                        </h2>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardHeader
                    className={`${
                      this.state.prelaunchOpen ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="border-bottom pb-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">Territories Assigned</p>
                          {this.state.store?.operations?.territories?.length ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.operations?.territories
                                  ?.length
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.operations?.territories
                                  ?.length
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.operations?.territories?.length
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">Plans Created</p>
                          {this.state.store?.products?.mealPlans?.length ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.products?.mealPlans?.length
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.products?.mealPlans?.length
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.products?.mealPlans?.length
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Billing Account Set Up
                          </p>
                          {this.state.store?.operations?.billingAccount &&
                          this.state.store?.operations?.billingAccount?._id !=
                            "62e3ef0891b803110b1a114b" ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.operations?.billingAccount &&
                                this.state.store?.operations?.billingAccount
                                  ?._id != "62e3ef0891b803110b1a114b"
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.operations?.billingAccount &&
                                this.state.store?.operations?.billingAccount
                                  ?._id != "62e3ef0891b803110b1a114b"
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.operations?.billingAccount &&
                              this.state.store?.operations?.billingAccount
                                ?._id != "62e3ef0891b803110b1a114b"
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">Conversations Set Up</p>
                          {this.state.store?.twilioNumber ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.twilioNumber
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.twilioNumber
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.twilioNumber ? "YES" : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">Facebook Page Linked</p>
                          {this.state.store?.facebookPageID ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.facebookPageID
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.facebookPageID
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.facebookPageID ? "YES" : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Instagram Account Linked
                          </p>
                          {this.state.store?.facebookPageID ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.instagramAccountID
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.instagramAccountID
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.instagramAccountID
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Google Maps Listing Linked
                          </p>
                          {this.state.store?.googleMapsPlaceID &&
                          this.state.store?.googleMapsReviewURL ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.googleMapsPlaceID &&
                                this.state.store?.googleMapsReviewURL
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.googleMapsPlaceID &&
                                this.state.store?.googleMapsReviewURL
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.googleMapsPlaceID &&
                              this.state.store?.googleMapsReviewURL
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">Menus Added</p>
                          {this.storeHasMenus(this.state.store) ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.storeHasMenus(this.state.store)
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.storeHasMenus(this.state.store)
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.storeHasMenus(this.state.store)
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Prelaunch Email Automation Queued
                          </p>
                          {this.state.store?.prelaunchMigrationJobID ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.prelaunchMigrationJobID
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.prelaunchMigrationJobID
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.prelaunchMigrationJobID
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Founding Member Coupon Added
                          </p>
                          {!this.state.store
                            ?.nsoCheckFoundingMemberCouponAdded ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckFoundingMemberCouponAdded",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store
                                    ?.nsoCheckFoundingMemberCouponAdded
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store
                                    .nsoCheckFoundingMemberCouponAdded
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store
                                    ?.nsoCheckFoundingMemberCouponAdded
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store
                                  ?.nsoCheckFoundingMemberCouponAdded
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store
                                  ?.nsoCheckFoundingMemberCouponAdded
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store
                                ?.nsoCheckFoundingMemberCouponAdded
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Prelaunch Broadcasts Scheduled
                          </p>
                          {!this.state.store?.nsoCheckBroadcastsScheduled ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckBroadcastsScheduled",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store?.nsoCheckBroadcastsScheduled
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store.nsoCheckBroadcastsScheduled
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store?.nsoCheckBroadcastsScheduled
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.nsoCheckBroadcastsScheduled
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.nsoCheckBroadcastsScheduled
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckBroadcastsScheduled
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="pt-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">Checkout Tested</p>
                          {!this.state.store?.nsoCheckCheckoutTested ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckCheckoutTested",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store?.nsoCheckCheckoutTested
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store.nsoCheckCheckoutTested
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store?.nsoCheckCheckoutTested
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.nsoCheckCheckoutTested
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.nsoCheckCheckoutTested
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckCheckoutTested
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                  </CardHeader>
                  <CardHeader
                    className={`${
                      this.state.retailOpen && "border-0"
                    } cursor-pointer`}
                    onClick={() => {
                      this.setState({
                        retailOpen: !this.state.retailOpen,
                      });
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0">Retail Opening System Checks</h3>
                      </Col>
                      <Col xs="auto">
                        <h2 className={`m-0`}>
                          <Badge
                            className={`text-white ${
                              NSOUtils.getRetailCompletion(this.state.store) < 1
                                ? "bg-danger"
                                : "bg-success"
                            }`}
                          >
                            {NSOUtils.getRetailCompletion(this.state.store)
                              ? (
                                  NSOUtils.getRetailCompletion(
                                    this.state.store
                                  ) * 100
                                ).toFixed(0) + "%"
                              : "0%"}
                          </Badge>
                        </h2>
                      </Col>
                      <Col xs="auto">
                        <h2 className={`m-0`}>
                          <i
                            className={`mdi mdi-chevron-${
                              this.state.retailOpen ? "down" : "right"
                            }`}
                          />
                        </h2>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardHeader
                    className={`${
                      this.state.retailOpen ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="border-bottom pb-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Square POS Connected To Storefront
                          </p>
                          {this.state.store?.squareLocation ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.squareLocation
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.squareLocation
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.squareLocation ? "YES" : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Retail & Subscription Goals Set
                          </p>
                          {this.state.store?.weeklyRetailGoal &&
                          this.state.store?.weeklyOrderGoal ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.weeklyRetailGoal &&
                                this.state.store?.weeklyOrderGoal
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.weeklyRetailGoal &&
                                this.state.store?.weeklyOrderGoal
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.weeklyRetailGoal &&
                              this.state.store?.weeklyOrderGoal
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Tax Requirements Reviewed
                          </p>
                          {!this.state.store
                            ?.nsoCheckTaxRequirementsReviewed ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckTaxRequirementsReviewed",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store
                                    ?.nsoCheckTaxRequirementsReviewed
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store
                                    .nsoCheckTaxRequirementsReviewed
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store
                                    ?.nsoCheckTaxRequirementsReviewed
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store
                                  ?.nsoCheckTaxRequirementsReviewed
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store
                                  ?.nsoCheckTaxRequirementsReviewed
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckTaxRequirementsReviewed
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Square POS Catalog Items Created
                          </p>
                          {!this.state.store?.nsoCheckSquareCatalog ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckSquareCatalog",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store?.nsoCheckSquareCatalog
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store.nsoCheckSquareCatalog
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store?.nsoCheckSquareCatalog
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.nsoCheckSquareCatalog
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.nsoCheckSquareCatalog
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckSquareCatalog
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Apple Maps Listing Created
                          </p>
                          {!this.state.store?.nsoCheckAppleMaps ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckAppleMaps",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store?.nsoCheckAppleMaps
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store.nsoCheckAppleMaps
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store?.nsoCheckAppleMaps
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.nsoCheckAppleMaps
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.nsoCheckAppleMaps
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckAppleMaps
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Hours Updated In Storefront
                          </p>
                          {!this.state.store?.nsoCheckHoursUpdated ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckHoursUpdated",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store?.nsoCheckHoursUpdated
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store.nsoCheckHoursUpdated
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store?.nsoCheckHoursUpdated
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.nsoCheckHoursUpdated
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.nsoCheckHoursUpdated
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckHoursUpdated
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="pt-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Hours Updated On Google &amp; Apple Maps
                          </p>
                          {!this.state.store?.nsoCheckHoursUpdatedMaps ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckHoursUpdatedMaps",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store?.nsoCheckHoursUpdatedMaps
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store.nsoCheckHoursUpdatedMaps
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store?.nsoCheckHoursUpdatedMaps
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.nsoCheckHoursUpdatedMaps
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.nsoCheckHoursUpdatedMaps
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckHoursUpdatedMaps
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                  </CardHeader>
                  <CardHeader
                    onClick={() => {
                      this.setState({
                        fbdOpen: !this.state.fbdOpen,
                      });
                    }}
                    className={`${
                      this.state.fbdOpen && "border-0"
                    } cursor-pointer`}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0">First Bill Date System Checks</h3>
                      </Col>
                      <Col xs="auto">
                        <h2 className={`m-0`}>
                          <Badge
                            className={`text-white ${
                              NSOUtils.getFirstBillDateCompletion(
                                this.state.store
                              ) < 1
                                ? "bg-danger"
                                : "bg-success"
                            }`}
                          >
                            {NSOUtils.getFirstBillDateCompletion(
                              this.state.store
                            )
                              ? (
                                  NSOUtils.getFirstBillDateCompletion(
                                    this.state.store
                                  ) * 100
                                ).toFixed(0) + "%"
                              : "0%"}
                          </Badge>
                        </h2>
                      </Col>
                      <Col xs="auto">
                        <h2 className={`m-0`}>
                          <h2 className={`m-0`}>
                            <i
                              className={`mdi mdi-chevron-${
                                this.state.fbdOpen ? "down" : "right"
                              }`}
                            />
                          </h2>
                        </h2>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardHeader
                    className={`${this.state.fbdOpen ? "d-block" : "d-none"}`}
                  >
                    <div className="border-bottom pb-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            General, Pickup, and Delivery Terms Added
                          </p>
                          {this.state.store?.operations?.terms?.general &&
                          this.state.store?.operations?.terms?.pickup &&
                          this.state.store?.operations?.terms?.delivery ? (
                            <p
                              className="small text-muted m-0"
                              style={{ lineHeight: 1.2 }}
                            >
                              Automatically marked as completed by system
                            </p>
                          ) : null}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.operations?.terms?.general &&
                                this.state.store?.operations?.terms?.pickup &&
                                this.state.store?.operations?.terms?.delivery
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.operations?.terms?.general &&
                                this.state.store?.operations?.terms?.pickup &&
                                this.state.store?.operations?.terms?.delivery
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.operations?.terms?.general &&
                              this.state.store?.operations?.terms?.pickup &&
                              this.state.store?.operations?.terms?.delivery
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                    <div className="pt-2">
                      <Row>
                        <Col xs="">
                          <p className="m-0 text-dark">
                            Authorize.net Configuration Reviewed
                          </p>
                          {!this.state.store?.nsoCheckAuthNetReview ? (
                            <Button
                              size="sm"
                              color="primary"
                              className="btn-link border-0 px-0"
                              onClick={() => {
                                let user = LocalStorage.get(
                                  Constant.CACHE.USER
                                );

                                this.updateSystemCheckParam(
                                  "nsoCheckAuthNetReview",
                                  {
                                    completed: true,
                                    completedBy: {
                                      _id: user?._id,
                                      name: user?.name,
                                      email: user?.email,
                                    },
                                    completedAt: new Date(),
                                  }
                                );
                              }}
                            >
                              Mark As Completed
                            </Button>
                          ) : (
                            <>
                              <p
                                className="small text-muted m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Marked as completed by{" "}
                                {
                                  this.state.store?.nsoCheckAuthNetReview
                                    ?.completedBy?.name
                                }{" "}
                                (
                                {
                                  this.state.store.nsoCheckAuthNetReview
                                    ?.completedBy?.email
                                }
                                ) on{" "}
                                {moment(
                                  this.state.store?.nsoCheckAuthNetReview
                                    ?.completedAt
                                ).format("MM/DD/YY")}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col xs="auto">
                          <h2 className="m-0">
                            <Badge
                              className={`${
                                this.state.store?.nsoCheckAuthNetReview
                                  ? "bg-success"
                                  : "bg-danger"
                              } text-white font-weight-bold`}
                              color={
                                this.state.store?.nsoCheckAuthNetReview
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {this.state.store?.nsoCheckAuthNetReview
                                ? "YES"
                                : "NO"}
                            </Badge>
                          </h2>
                        </Col>
                      </Row>
                    </div>
                  </CardHeader>
                </Collapse>
              </Card>
            </>
          ) : null}
          <Card className=" shadow">
            <CardHeader className="bg-transparent">
              <Row>
                <div className="col">
                  <h3 className=" mb-0">About</h3>
                </div>
                <div className="col-auto text-right">
                  <Button
                    onClick={() => {
                      PubSub.publish(Event.STORE.MODIFY_OPEN, this.state.store);
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div>
                <div className="row">
                  {this.state.store && this.state.store.type == "retail" ? (
                    <>
                      <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <h3 className="mb-3">Location</h3>
                        <h4>{this.state.store ? this.state.store.name : ""}</h4>
                        {this.state.store
                          ? this.state.store.location.address.address_1
                          : ""}
                        &nbsp;
                        {this.state.store
                          ? this.state.store.location.address.address_2
                          : ""}
                        <br></br>
                        {this.state.store
                          ? this.state.store.location.address.city
                          : ""}
                        ,&nbsp;
                        {this.state.store
                          ? this.state.store.location.address.state
                          : ""}
                        &nbsp;
                        {this.state.store
                          ? this.state.store.location.address.zip
                          : ""}
                      </div>
                    </>
                  ) : null}
                  <div className="col-12 col-md-6">
                    <h3 className="mb-3">Contact</h3>
                    <p className="small">
                      Store Phone Number:{" "}
                      {this.state.store && this.state.store.twilioNumber ? (
                        <NumberFormat
                          format={"+# (###) ###-####"}
                          value={this.state.store.twilioNumber}
                          displayType={"text"}
                        ></NumberFormat>
                      ) : (
                        "(None)"
                      )}
                    </p>
                    <p className="small">
                      Email:{" "}
                      {this.state.store && this.state.store.contact.email
                        ? this.state.store.contact.email
                        : "(None)"}
                    </p>
                    <p className="small">
                      {this.state.store &&
                      this.state.store.contact.instagram_url ? (
                        <a
                          href={this.state.store.contact.instagram_url}
                          target="_blank"
                          className="mr-3"
                        >
                          Instagram<i className="mdi mdi-chevron-right"></i>
                        </a>
                      ) : null}
                      {this.state.store &&
                      this.state.store.contact.facebook_url ? (
                        <a
                          href={this.state.store.contact.facebook_url}
                          target="_blank"
                          className="mr-3"
                        >
                          Facebook<i className="mdi mdi-chevron-right"></i>
                        </a>
                      ) : null}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <hr></hr>
                  </div>
                </div>
                <Row>
                  <Col xs="12" md="6" className="mb-3 mb-md-0">
                    <h3 className="mb-3">Store Launch</h3>
                    <h4>
                      Website Launch Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening
                          ?.websiteLaunchDate
                          ? this.state.store.operations.storeOpening
                              .websiteLaunchDate
                          : "(none set)"}
                        s
                      </span>
                    </h4>
                    <h4>
                      Prelaunch Start Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening
                          ?.prelaunchStartDate
                          ? this.state.store.operations.storeOpening
                              .prelaunchStartDate
                          : "(none set)"}
                      </span>
                    </h4>
                    <h4>
                      First Fulfillment Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening
                          ?.firstBillDate
                          ? this.state.store.operations.storeOpening
                              .firstBillDate
                          : "(none set)"}
                      </span>
                    </h4>
                    <h4>
                      Retail Opening Date:{" "}
                      <span className="font-weight-normal pl-2">
                        {this.state.store?.operations?.storeOpening?.openingDate
                          ? this.state.store.operations.storeOpening.openingDate
                          : "(none set)"}
                      </span>
                    </h4>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">NSO Stage</h3>
                  <p className="small mb-0">New Store Opening process stage.</p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyStage ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyStage: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setNSOStage();
                        }}
                        disabled={
                          this.state.nsoSubmitting || !this.state.nsoStage
                        }
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.nsoSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyStage: true,
                          nsoStage: this.state.store?.nsoStage ?? "",
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.modifyStage ? (
                <>
                  <Input
                    type="select"
                    name="nsoStage"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.nsoStage}
                  >
                    <option disabled value="">
                      Select A Stage
                    </option>
                    <option value="Lead">Lead</option>
                    <option value="Discovery">Discovery</option>
                    <option value="Funding">Funding</option>
                    <option value="Site Selection">Site Selection</option>
                    <option value="Buildout">Buildout</option>
                    <option value="Prelaunch">Prelaunch</option>
                    <option value="Live">Live</option>
                    <option value="Cold">Cold</option>
                  </Input>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.nsoStage ?? "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Milestone Dates</h3>
                  <p className="small mb-0">Franchise development milestones</p>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="py-3 border-bottom">
              <h4 className="text-dark">Focus Call:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyFocusCall ? (
                    <>
                      <DatePicker
                        value={this.state.focusCallDate}
                        onChange={(v) => {
                          this.setState({ focusCallDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.focusCallDate
                          ? moment(this.state.store?.focusCallDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyFocusCall ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyFocusCall: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "focusCallDate",
                            this.state.focusCallDate?.toISOString(),
                            "modifyFocusCall"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyFocusCall: true,
                          focusCallDate: this.state.store?.focusCallDate
                            ? moment(this.state.store?.focusCallDate)?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3 border-bottom">
              <h4 className="text-dark">Discovery Call:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyDiscoveryCall ? (
                    <>
                      <DatePicker
                        value={this.state.discoveryCallDate}
                        onChange={(v) => {
                          this.setState({ discoveryCallDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.discoveryCallDate
                          ? moment(this.state.store?.discoveryCallDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyDiscoveryCall ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyDiscoveryCall: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "discoveryCallDate",
                            this.state.discoveryCallDate?.toISOString(),
                            "modifyDiscoveryCall"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyDiscoveryCall: true,
                          discoveryCallDate: this.state.store?.discoveryCallDate
                            ? moment(
                                this.state.store?.discoveryCallDate
                              )?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3 border-bottom">
              <h4 className="text-dark">Discovery Day:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyDiscoveryDay ? (
                    <>
                      <DatePicker
                        value={this.state.discoveryDayDate}
                        onChange={(v) => {
                          this.setState({ discoveryDayDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.discoveryDayDate
                          ? moment(this.state.store?.discoveryDayDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyDiscoveryDay ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyDiscoveryDay: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "discoveryDayDate",
                            this.state.discoveryDayDate?.toISOString(),
                            "modifyDiscoveryDay"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyDiscoveryDay: true,
                          discoveryDayDate: this.state.store?.discoveryDayDate
                            ? moment(
                                this.state.store?.discoveryDayDate
                              )?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3 border-bottom">
              <h4 className="text-dark">Franchise Fee Funded:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyFeeDate ? (
                    <>
                      <DatePicker
                        value={this.state.feeFundedDate}
                        onChange={(v) => {
                          this.setState({ feeFundedDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.feeFundedDate
                          ? moment(this.state.store?.feeFundedDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyFeeDate ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyFeeDate: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "feeFundedDate",
                            this.state.feeFundedDate?.toISOString(),
                            "modifyFeeDate"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyFeeDate: true,
                          feeFundedDate: this.state.store?.feeFundedDate
                            ? moment(this.state.store?.feeFundedDate)?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3  border-bottom">
              <h4 className="text-dark">Lease Signed:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyLeaseDate ? (
                    <>
                      <DatePicker
                        value={this.state.leaseSignedDate}
                        onChange={(v) => {
                          this.setState({ leaseSignedDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.leaseSignedDate
                          ? moment(this.state.store?.leaseSignedDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyLeaseDate ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyLeaseDate: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "leaseSignedDate",
                            this.state.leaseSignedDate?.toISOString(),
                            "modifyLeaseDate"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyLeaseDate: true,
                          leaseSignedDate: this.state.store?.leaseSignedDate
                            ? moment(
                                this.state.store?.leaseSignedDate
                              )?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>

            <CardBody className="py-3  border-bottom">
              <h4 className="text-dark">On-Site Training Date:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyOnSiteTrainingDate ? (
                    <>
                      <DatePicker
                        value={this.state.onSiteTrainingDate}
                        onChange={(v) => {
                          this.setState({ onSiteTrainingDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.onSiteTrainingDate
                          ? moment(this.state.store?.onSiteTrainingDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyOnSiteTrainingDate ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyOnSiteTrainingDate: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "onSiteTrainingDate",
                            this.state.onSiteTrainingDate?.toISOString(),
                            "modifyOnSiteTrainingDate"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyOnSiteTrainingDate: true,
                          onSiteTrainingDate: this.state.store
                            ?.onSiteTrainingDate
                            ? moment(
                                this.state.store?.onSiteTrainingDate
                              )?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3  border-bottom">
              <h4 className="text-dark">Lease Renewal Date:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyLeaseRenewalDate ? (
                    <>
                      <DatePicker
                        value={this.state.leaseRenewalDate}
                        onChange={(v) => {
                          this.setState({ leaseRenewalDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.leaseRenewalDate
                          ? moment(this.state.store?.leaseRenewalDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyLeaseRenewalDate ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyLeaseRenewalDate: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "leaseRenewalDate",
                            this.state.leaseRenewalDate?.toISOString(),
                            "modifyLeaseRenewalDate"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyLeaseRenewalDate: true,
                          leaseRenewalDate: this.state.store?.leaseRenewalDate
                            ? moment(
                                this.state.store?.leaseRenewalDate
                              )?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3">
              <h4 className="text-dark">Franchise Renewal Date:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyFranchiseRenewalDate ? (
                    <>
                      <DatePicker
                        value={this.state.franchiseRenewalDate}
                        onChange={(v) => {
                          this.setState({ franchiseRenewalDate: v });
                        }}
                        placement="auto"
                        oneTap={true}
                        cleanable={true}
                        block
                        format="MM/dd/yyyy"
                      ></DatePicker>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.franchiseRenewalDate
                          ? moment(
                              this.state.store?.franchiseRenewalDate
                            ).format("MM/DD/YYYY")
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyFranchiseRenewalDate ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyFranchiseRenewalDate: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setValue(
                            "franchiseRenewalDate",
                            this.state.franchiseRenewalDate?.toISOString(),
                            "modifyFranchiseRenewalDate"
                          );
                        }}
                        disabled={this.state.valSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.valSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyFranchiseRenewalDate: true,
                          franchiseRenewalDate: this.state.store
                            ?.franchiseRenewalDate
                            ? moment(
                                this.state.store?.franchiseRenewalDate
                              )?.toDate()
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Phone Numbers</h3>
                  <p className="small mb-0">Store call/SMS phone numbers</p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyPhone ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyPhone: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setPhone();
                        }}
                        disabled={
                          this.state.phoneSubmitting ||
                          !this.state.phone?.value ||
                          !this.state.twilioNumber?.value
                        }
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.phoneSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyPhone: true,
                          twilioNumber: {
                            value: this.state.store?.twilioNumber
                              ? this.state.store?.twilioNumber?.replace(
                                  "+1",
                                  ""
                                )
                              : "",
                          },
                          phone: {
                            value: this.state.store?.contact?.phone
                              ? this.state.store?.contact?.phone?.replace(
                                  "+1",
                                  ""
                                )
                              : "",
                          },
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <h4 className="mb-0">Store Phone Number</h4>
              <p className="text-muted" style={{ fontSize: 14 }}>
                The Twilio phone number assigned to this store that clients and
                leads will see and use to communicate with the store.
              </p>
              {this.state.modifyPhone ? (
                <>
                  <NumberFormat
                    className="form-control mb-3"
                    format="(###) ###-####"
                    name="phoneNumberInput"
                    placeholder="Phone Number (Optional)"
                    onValueChange={(v) => {
                      this.setState({
                        twilioNumber: v,
                      });
                    }}
                    value={this.state.twilioNumber.value}
                    type="tel"
                    mask="_"
                  />
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.twilioNumber
                      ? StringUtils.formatPhoneNumber(
                          this.state.store?.twilioNumber
                        )
                      : "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
            <CardBody className="border-top">
              <h4 className="mb-0">Call Redirect Phone Number</h4>
              <p className="text-muted" style={{ fontSize: 14 }}>
                When the store receives a call to the Store Phone Number, it
                will be redirected to this number.
              </p>
              {this.state.modifyPhone ? (
                <>
                  <NumberFormat
                    className="form-control mb-3"
                    format="(###) ###-####"
                    name="phoneNumberInput"
                    placeholder="Phone Number"
                    onValueChange={(v) => {
                      this.setState({
                        phone: v,
                      });
                    }}
                    value={this.state.phone.value}
                    type="tel"
                    mask="_"
                  />
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.contact?.phone
                      ? StringUtils.formatPhoneNumber(
                          this.state.store?.contact?.phone
                        )
                      : "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Scorecard Goals</h3>
                  <p className="small mb-0">Set the stores's scorecard goals</p>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="py-3 border-bottom">
              <h4 className="text-dark">Store Level:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyLevel ? (
                    <>
                      <Input
                        type="select"
                        value={this.state.level}
                        onChange={this.handleInputChange.bind(this)}
                        name="level"
                        placeholder="Store level"
                        bsSize="sm"
                      >
                        <option value="" disabled>
                          Select A Level
                        </option>
                        <option value="Level 1">Level 1</option>
                        <option value="Level 2">Level 2</option>
                        <option value="Level 3">Level 3</option>
                        <option value="Level 4">Level 4</option>
                        <option value="Level 5">Level 5</option>
                      </Input>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.level
                          ? this.state.store.level
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyLevel ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyLevel: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setLevel();
                        }}
                        disabled={this.state.levelSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.levelSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyLevel: true,
                          level: this.state.store?.level
                            ? this.state.store?.level
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3 border-bottom">
              <h4 className="text-dark">Weekly Member Order Goal:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyOrderGoal ? (
                    <>
                      <Input
                        type="number"
                        value={this.state.orderGoal}
                        onChange={this.handleInputChange.bind(this)}
                        name="orderGoal"
                        placeholder="Order Quantity"
                        bsSize="sm"
                      ></Input>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.weeklyOrderGoal
                          ? StringUtils.numberFormat(
                              this.state.store?.weeklyOrderGoal
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyOrderGoal ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyOrderGoal: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setOrderGoal();
                        }}
                        disabled={this.state.orderGoalSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.orderGoalSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyOrderGoal: true,
                          orderGoal: this.state.store?.weeklyOrderGoal
                            ? this.state.store?.weeklyOrderGoal
                            : null,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="py-3 border-bottom">
              <h4 className="text-dark">Weekly Retail Sales Goal:</h4>
              <Row className="align-items-center">
                <Col xs="">
                  {this.state.modifyRetailGoal ? (
                    <>
                      <NumberFormat
                        className="form-control form-control-sm"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        required={true}
                        placeholder="$0.00"
                        value={this.state.retailGoal.value}
                        onValueChange={(v) => {
                          this.setState({
                            retailGoal: v,
                          });
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        {this.state.store?.weeklyRetailGoal
                          ? StringUtils.centsToCurrency(
                              this.state.store?.weeklyRetailGoal
                            )
                          : "--"}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {this.state.modifyRetailGoal ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyRetailGoal: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setRetailGoal();
                        }}
                        disabled={this.state.retailGoalSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.retailGoalSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyRetailGoal: true,
                          retailGoal: {
                            value: this.state.store?.weeklyRetailGoal
                              ? (
                                  this.state.store?.weeklyRetailGoal / 100
                                )?.toString()
                              : "",
                          },
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Referral Source</h3>
                  <p className="small mb-0">
                    How did we connect with the franchise partners?
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyReferral ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyReferral: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setReferralSource();
                        }}
                        disabled={this.state.referralSubmitting}
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.referralSubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyReferral: true,
                          referralSource:
                            this.state.store?.referralSource ?? "",
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Modify
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.modifyReferral ? (
                <>
                  <Input
                    type="text"
                    name="referralSource"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.referralSource}
                    placeholder="Referral Source"
                  ></Input>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.referralSource
                      ? this.state.store?.referralSource
                      : "(none set)"}
                  </p>
                </>
              )}
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Franchise Partners</h3>
                  <p className="small mb-0">
                    Contact information for all partners associated with this
                    store.
                  </p>
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={() => {
                      this.setState({
                        addPartnerOpen: true,
                      });
                    }}
                    className="btn-sm btn"
                    color="primary"
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0">
              {this.state.store?.franchisePartners?.length ? (
                <>
                  {this.state.store?.franchisePartners?.map((partner, i) => (
                    <>
                      <div
                        className={`p-3 ${
                          i !=
                            this.state.store?.franchisePartners?.length - 1 &&
                          "border-bottom"
                        }`}
                      >
                        <Row className={"align-items-top"}>
                          <Col xs="">
                            <Row
                              className={
                                partner?.phone ||
                                partner?.occupation ||
                                partner?.location ||
                                partner?.notes
                                  ? "align-items-top"
                                  : "align-items-center"
                              }
                            >
                              <Col
                                xs="12"
                                sm="auto"
                                className="pr-2 mb-3 mb-sm-0"
                              >
                                <div
                                  style={{
                                    height: 50,
                                    width: 50,
                                    overflow: "hidden",
                                    borderRadius: 4,
                                  }}
                                  className="border border-lighter bg-superlight"
                                >
                                  {this.state.partnerImages?.hasOwnProperty(
                                    partner.id
                                  ) ? (
                                    <div>
                                      <Whisper
                                        trigger={["hover", "click"]}
                                        placement="auto"
                                        preventOverflow={true}
                                        delayOpen={200}
                                        speaker={
                                          <Popover full>
                                            <img
                                              src={
                                                this.state.partnerImages[
                                                  partner.id
                                                ]
                                              }
                                              style={{ width: 270 }}
                                            ></img>
                                          </Popover>
                                        }
                                      >
                                        <img
                                          src={
                                            this.state.partnerImages[partner.id]
                                          }
                                          className="cursor-pointer"
                                          style={{ width: "100%" }}
                                        ></img>
                                      </Whisper>
                                    </div>
                                  ) : (
                                    <div className="p-2">
                                      <i
                                        className="mdi mdi-account text-medium"
                                        style={{ fontSize: 33, lineHeight: 1 }}
                                      ></i>
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs="" sm="">
                                <Row className="align-items-center">
                                  <Col xs="">
                                    <h3 className="m-0 text-dark">
                                      {partner?.name}{" "}
                                      {partner.decisionMaker && (
                                        <>
                                          <Badge
                                            size="sm"
                                            color="primary"
                                            className="ml-1"
                                            style={{
                                              position: "relative",
                                              top: -2,
                                            }}
                                          >
                                            Decision Maker
                                          </Badge>
                                        </>
                                      )}
                                    </h3>
                                    {partner.occupation || partner.location ? (
                                      <>
                                        <p className="m-0 small text-dark">
                                          {partner?.occupation &&
                                          partner.location
                                            ? `${partner.occupation} from ${partner.location}`
                                            : null}
                                          {partner.occupation &&
                                          !partner.location
                                            ? partner.occupation
                                            : null}
                                          {partner.location &&
                                          !partner.occupation
                                            ? partner.location
                                            : null}
                                        </p>
                                      </>
                                    ) : null}
                                  </Col>

                                  {partner.email ? (
                                    <>
                                      <Col xs="12" className="mt-1">
                                        <p
                                          className="m-0 text-dark"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="font-weight-bold text-dark">
                                            Email:
                                          </span>
                                          &nbsp;{partner.email}
                                        </p>
                                      </Col>
                                    </>
                                  ) : null}
                                  {partner.phone ? (
                                    <>
                                      <Col xs="12" className="mt-1">
                                        <p
                                          className="m-0 text-dark"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="font-weight-bold text-dark">
                                            Phone:
                                          </span>
                                          &nbsp;
                                          {StringUtils.formatPhoneNumber(
                                            partner.phone.replace("+1", "")
                                          )}
                                        </p>
                                      </Col>
                                    </>
                                  ) : null}
                                  {partner.mailingAddress ? (
                                    <>
                                      <Col xs="12" className="mt-1">
                                        <p
                                          className="m-0 text-dark"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="font-weight-bold text-dark">
                                            Mailing Address:
                                          </span>
                                          &nbsp;{partner.mailingAddress}
                                        </p>
                                      </Col>
                                    </>
                                  ) : null}

                                  {partner.notes ? (
                                    <>
                                      <Col xs="12" className="mt-1">
                                        <p
                                          style={{ fontSize: 14 }}
                                          className="mb-0 font-weight-bold text-dark"
                                        >
                                          Notes:
                                        </p>
                                        <p
                                          style={{ fontSize: 14 }}
                                          className="m-0 text-dark"
                                        >
                                          {partner.notes}
                                        </p>
                                      </Col>
                                    </>
                                  ) : null}
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              trigger="click"
                              placement="autoVerticalEnd"
                              ref={this.menuRef}
                              speaker={
                                <Popover full>
                                  <Dropdown.Menu
                                    style={{
                                      minWidth: 150,
                                      paddingBottom: 0,
                                    }}
                                  >
                                    <Dropdown.Item
                                      panel
                                      className="py-2 font-weight-800 text-uppercase"
                                      style={{
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                      }}
                                    >
                                      <h5 className="m-0">Actions</h5>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="my-0"
                                      divider
                                    ></Dropdown.Item>
                                    <Dropdown.Item
                                      disabled={!partner?.email}
                                      onClick={() => {
                                        this.menuRef.current.close();

                                        LinkUtils.openInNewTab(
                                          "mailto:" + partner.email
                                        );
                                      }}
                                    >
                                      Email
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      disabled={!partner?.phone}
                                      onClick={() => {
                                        this.menuRef.current.close();

                                        LinkUtils.openInNewTab(
                                          "tel:" + partner.phone
                                        );
                                      }}
                                    >
                                      Call
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      disabled={
                                        !this.state.partnerImages?.hasOwnProperty(
                                          partner.id
                                        )
                                      }
                                      onClick={() => {
                                        this.menuRef.current.close();

                                        LinkUtils.openInNewTab(
                                          this.state.partnerImages[partner.id]
                                        );
                                      }}
                                    >
                                      Download Image
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        this.menuRef.current.close();

                                        this.setState({
                                          modifyPartner: partner,
                                          addPartnerOpen: true,
                                        });
                                      }}
                                    >
                                      Modify Partner
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="my-0"
                                      divider
                                    ></Dropdown.Item>
                                    <Dropdown.Item
                                      className="text-danger"
                                      onClick={() => {
                                        this.removePartner(partner);
                                      }}
                                    >
                                      Remove Partner
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Popover>
                              }
                            >
                              <Button
                                size="sm"
                                outline
                                color="dark"
                                className="btn-icon-only"
                              >
                                <i className="mdi mdi-dots-vertical"></i>
                              </Button>
                            </Whisper>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="p-4">
                    <p className="m-0">
                      Get started by adding the first franchise partner to this
                      store.
                    </p>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Marketing Location</h3>
                  <p className="small mb-0">
                    Override the city &amp; state used for marketing purposes.
                    By default, the retail address is used.
                  </p>
                </Col>
                <Col xs="auto">
                  {this.state.modifyDisplay ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyDisplay: false,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.setDisplayCityState();
                        }}
                        disabled={
                          this.state.displaySubmitting ||
                          !this.state.displayCity ||
                          !this.state.displayState
                        }
                        className="btn-sm btn"
                        color="primary"
                      >
                        {this.state.displaySubmitting ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            modifyDisplay: true,
                            displayCity: this.state.store?.displayCity ?? "",
                            displayState: this.state.store?.displayState ?? "",
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Modify
                      </Button>
                      {this.state.store?.displayCity ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState(
                                {
                                  displayCity: "",
                                  displayState: "",
                                },
                                () => {
                                  this.setDisplayCityState();
                                }
                              );
                            }}
                            className="btn-sm btn"
                            color="danger"
                            outline
                          >
                            Reset
                          </Button>
                        </>
                      ) : null}
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.modifyDisplay ? (
                <>
                  <FormGroup>
                    <h5>City</h5>
                    <Input
                      type="text"
                      placeholder="City"
                      name="displayCity"
                      value={this.state.displayCity}
                      onChange={this.handleInputChange.bind(this)}
                    ></Input>
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <h5>State</h5>
                    <Input
                      type="select"
                      name="displayState"
                      value={this.state.displayState}
                      onChange={this.handleInputChange.bind(this)}
                      id="createStoreState"
                    >
                      <option value="" disabled>
                        Select A State
                      </option>
                      <option value="AL">AL - Alabama</option>
                      <option value="AK">AK - Alaska</option>
                      <option value="AZ">AZ - Arizona</option>
                      <option value="AR">AR - Arkansas</option>
                      <option value="CA">CA - California</option>
                      <option value="CO">CO - Colorado</option>
                      <option value="CT">CT - Connecticut</option>
                      <option value="DE">DE - Delaware</option>
                      <option value="DC">DC - District Of Columbia</option>
                      <option value="FL">FL - Florida</option>
                      <option value="GA">GA - Georgia</option>
                      <option value="HI">HI - Hawaii</option>
                      <option value="ID">ID - Idaho</option>
                      <option value="IL">IL - Illinois</option>
                      <option value="IN">IN - Indiana</option>
                      <option value="IA">IA - Iowa</option>
                      <option value="KS">KS - Kansas</option>
                      <option value="KY">KY - Kentucky</option>
                      <option value="LA">LA - Louisiana</option>
                      <option value="ME">ME - Maine</option>
                      <option value="MD">MD - Maryland</option>
                      <option value="MA">MA - Massachusetts</option>
                      <option value="MI">MI - Michigan</option>
                      <option value="MN">MN - Minnesota</option>
                      <option value="MS">MS - Mississippi</option>
                      <option value="MO">MO - Missouri</option>
                      <option value="MT">MT - Montana</option>
                      <option value="NE">NE - Nebraska</option>
                      <option value="NV">NV - Nevada</option>
                      <option value="NH">NH - New Hampshire</option>
                      <option value="NJ">NJ - New Jersey</option>
                      <option value="NM">NM - New Mexico</option>
                      <option value="NY">NY - New York</option>
                      <option value="NC">NC - North Carolina</option>
                      <option value="ND">ND - North Dakota</option>
                      <option value="OH">OH - Ohio</option>
                      <option value="OK">OK - Oklahoma</option>
                      <option value="OR">OR - Oregon</option>
                      <option value="PA">PA - Pennsylvania</option>
                      <option value="RI">RI - Rhode Island</option>
                      <option value="SC">CS - South Carolina</option>
                      <option value="SD">SD - South Dakota</option>
                      <option value="TN">TN - Tennessee</option>
                      <option value="TX">TX - Texas</option>
                      <option value="UT">UT - Utah</option>
                      <option value="VT">VT - Vermont</option>
                      <option value="VA">VA - Virginia</option>
                      <option value="WA">WA - Washington</option>
                      <option value="WV">WV - West Virginia</option>
                      <option value="WI">WI - Wisconsin</option>
                      <option value="WY">WY - Wyoming</option>
                    </Input>
                  </FormGroup>
                </>
              ) : (
                <>
                  <p className="m-0">
                    {this.state.store?.displayCity &&
                    this.state.store?.displayState ? (
                      <>
                        <p className="m-0">
                          {this.state.store?.displayCity},{" "}
                          {this.state.store?.displayState}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="m-0">
                          {this.state.store?.location?.address?.city},{" "}
                          {this.state.store?.location?.address?.state}{" "}
                          {this.state.store?.location?.address?.zip}
                        </p>
                      </>
                    )}
                  </p>
                </>
              )}
            </CardBody>
          </Card>

          <ModifyStoreModal></ModifyStoreModal>
          <FranchisePartnerDrawer
            open={this.state.addPartnerOpen}
            store={this.state.store}
            partner={this.state.modifyPartner}
            onClose={() => {
              this.setState({
                addPartnerOpen: false,
                modifyPartner: null,
              });
            }}
          ></FranchisePartnerDrawer>
        </>
      </>
    );
  }
}

export default withRouter(StoreViewAboutTab);
