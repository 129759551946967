import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import NumberFormat from "react-number-format";
import LinkUtils from "../../lib/LinkUtils";

class StorefrontTerritoryView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    phone: {
      value: "",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  formatHours(store) {
    let hours = [
      {
        day: "Monday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Tuesday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Wednesday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Thursday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Friday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Saturday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Sunday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
    ];

    if (!store?.operations?.hours?.standard?.length) {
      return hours;
    }

    for (let i = 0; i < store?.operations?.hours?.standard?.length; i++) {
      const hour = store?.operations?.hours?.standard[i];

      const idx = _.findIndex(hours, { day: hour.day });

      if (idx < 0) {
        continue;
      }

      if (!hour?.opensAt && !hour.closesAt) {
        continue;
      }

      hours[idx].opensAt = hour?.opensAt;
      hours[idx].closesAt = hour?.closesAt;
      hours[idx].closed = hour?.closed;

      if (hours[idx].opensAt.includes("AM")) {
        hours[idx].opensAt = hours[idx].opensAt.replace("AM", "").trim();
      }

      if (hours[idx].opensAt.includes("PM")) {
        hours[idx].opensAt = hours[idx].opensAt.replace("PM", "").trim();

        let opensHours = hours[idx].opensAt.split(":");

        let openMinutes = opensHours[1];

        opensHours = parseInt(opensHours[0]);
        opensHours = opensHours + 12;

        hours[idx].opensAt = `${opensHours}:${openMinutes}`;
      }

      if (hours[idx].closesAt.includes("AM")) {
        hours[idx].closesAt = hours[idx].closesAt.replace("AM", "").trim();
      }

      if (hours[idx].closesAt.includes("PM")) {
        hours[idx].closesAt = hours[idx].closesAt.replace("PM", "").trim();

        let opensHours = hours[idx].closesAt.split(":");

        let openMinutes = opensHours[1];

        opensHours = parseInt(opensHours[0]);
        opensHours = opensHours + 12;

        hours[idx].closesAt = `${opensHours}:${openMinutes}`;
      }

      if (hours[idx].opensAt) {
        const arr = hours[idx].opensAt.split(":");

        if (arr?.length == 2) {
          let hr = parseInt(arr[0]);

          if (hr < 10) {
            hr = `0${hr}`;
          }

          hours[idx].opensAt = `${hr}:${arr[1]}`;
        }
      }

      if (hours[idx].closesAt) {
        const arr = hours[idx].closesAt.split(":");

        if (arr?.length == 2) {
          let hr = parseInt(arr[0]);

          if (hr < 10) {
            hr = `0${hr}`;
          }

          hours[idx].closesAt = `${hr}:${arr[1]}`;
        }
      }
    }

    return hours;
  }

  hoursAreValid(hours) {
    if (hours?.length != 7) {
      return false;
    }

    for (let i = 0; i < hours?.length; i++) {
      const hour = hours[i];

      if (hour?.closed && (hour?.opensAt || hour.closesAt)) {
        return false;
      }

      if (!hour?.closed && (!hour?.opensAt || !hour.closesAt)) {
        return false;
      }
    }

    return true;
  }

  convertMilitaryToAMPM(time) {
    if (!time) {
      return "";
    }

    const timeArr = time.split(":");

    if (timeArr?.length != 2) {
      return "";
    }

    let hours = parseInt(timeArr[0]);
    const minutes = timeArr[1];

    const amPm = hours >= 12 ? "PM" : "AM";

    if (hours == 0) {
      hours = "12";
    } else if (hours > 12) {
      hours = hours - 12;
    }

    return `${hours}:${minutes} ${amPm}`;
  }

  loadStore() {
    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {
        alert("Unable to load store settings. Reload and try again.");
      }
    );
  }

  saveStoreContact() {
    this.setState({
      contactSubmitting: true,
    });

    APIV2.modifyStoreContactInformation(
      this.props.match.params?.storeID,
      `+1${this.state.phone?.value}`
    )
      .then(
        (data) => {
          this.setState({
            store: data.data.store,
            editContact: false,
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          contactSubmitting: false,
        });
      });
  }

  saveStoreHours() {
    this.setState({
      hoursSubmitting: true,
    });

    APIV2.modifyStoreRetailHours(
      this.props.match.params?.storeID,
      this.state.hours
    )
      .then(
        (data) => {
          this.setState({
            store: data.data.store,
            editHours: false,
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          hoursSubmitting: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.storeID != prevProps.match.params.storeID) {
      this.loadStores();
    }
  }

  filterTerritories(pivot) {
    const store = this.state.store;
    const stores = this.state.stores;

    let allOtherZips = [];

    if (!store || !stores?.length) {
      return [];
    }

    for (let i = 0; i < stores?.length; i++) {
      if (
        stores[i]?._id != store?._id &&
        store?.operations?.territories?.length
      ) {
        allOtherZips = allOtherZips.concat(stores[i]?.operations?.territories);
      }
    }

    let out = _.filter(store?.operations?.territories, (zip) => {
      if (pivot) {
        return allOtherZips?.includes(zip);
      }

      return !allOtherZips?.includes(zip);
    });

    out.sort();

    return out;
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            const store = _.findWhere(data.data.stores, {
              _id: this.props.match.params.storeID,
            });

            this.setState({ stores: data.data.stores, store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the store. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentDidMount() {
    this.loadStores();
  }

  generateTerritoryMapURL(zips, title) {
    if (!this.state.store) {
      return "";
    }

    let zipsStr = zips?.length ? zips.join(",") : "";

    let url = `https://www.randymajors.com/p/customgmap.html?zips=${zipsStr}&zipboundary=show&title=${title
      ?.split(" ")
      .join("+")}&zipcodes=show`;

    return url;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Franchise Territories"
            showBackButton={false}
            primaryAction={{
              name: <>View Map</>,
              onClick: () => {
                LinkUtils.openInNewTab(
                  this.generateTerritoryMapURL(
                    this.state.store?.operations?.territories,
                    `${this.state.store?.name?.trim()}: Dedicated, Allocated, and Pivot Territories`
                  )
                );
              },
            }}
          />

          <Row>
            <Col xs="12" md="12" className="mb-4">
              <Card className="border shadow" style={{ height: "100%" }}>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Territories</h3>
                      <p className="m-0 small">
                        Dedicated &amp; allocated zip codes in your franchise
                        territory
                      </p>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        disabled={!this.filterTerritories(false)?.length}
                        onClick={() => {
                          LinkUtils.openInNewTab(
                            this.generateTerritoryMapURL(
                              this.filterTerritories(false),
                              `${this.state.store?.name?.trim()}: Dedicated and Allocated Territories`
                            )
                          );
                        }}
                      >
                        View Map
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-3">
                  {this.state.storesLoading ? (
                    <>
                      <p>Loading territories</p>
                    </>
                  ) : (
                    <Row className="align=items-center">
                      {!this.filterTerritories(false)?.length && (
                        <Col xs="12">
                          <p className="m-0">No territories assigned</p>
                        </Col>
                      )}
                      {this.filterTerritories(false).map((zip, i) => (
                        <Col xs="6" key={i} sm="auto">
                          <p className="m-0">
                            <a
                              href={`${this.generateTerritoryMapURL(
                                [zip],
                                `${this.state.store?.name?.trim()}: Franchise Territory - ${zip}`
                              )}`}
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault();

                                LinkUtils.openInNewTab(
                                  this.generateTerritoryMapURL(
                                    [zip],
                                    `${this.state.store?.name?.trim()}: Franchise Territory - ${zip}`
                                  )
                                );
                              }}
                            >
                              {zip}
                            </a>
                          </p>
                        </Col>
                      ))}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="12" className="mb-4">
              <Card className="border shadow" style={{ height: "100%" }}>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Pivot Territories</h3>
                      <p className="m-0 small">
                        Zip codes in territory that you share with other
                        franchises
                      </p>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        disabled={!this.filterTerritories(true)?.length}
                        onClick={() => {
                          LinkUtils.openInNewTab(
                            this.generateTerritoryMapURL(
                              this.filterTerritories(true),
                              `${this.state.store?.name?.trim()}: Pivot Territories`
                            )
                          );
                        }}
                      >
                        View Map
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-3">
                  {this.state.storesLoading ? (
                    <>
                      <p>Loading territories</p>
                    </>
                  ) : (
                    <Row className="align=items-center">
                      {!this.filterTerritories(true)?.length && (
                        <Col xs="12">
                          <p className="m-0">No pivot territories assigned</p>
                        </Col>
                      )}
                      {this.filterTerritories(true).map((zip, i) => (
                        <Col xs="6" key={i} sm="auto">
                          <p className="m-0">
                            <a
                              href={`${this.generateTerritoryMapURL(
                                [zip],
                                `${this.state.store?.name?.trim()}: Franchise Pivot Territory - ${zip}`
                              )}`}
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault();

                                LinkUtils.openInNewTab(
                                  this.generateTerritoryMapURL(
                                    [zip],
                                    `${this.state.store?.name?.trim()}: Franchise Pivot Territory - ${zip}`
                                  )
                                );
                              }}
                            >
                              {zip}
                            </a>
                          </p>
                        </Col>
                      ))}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default StorefrontTerritoryView;
