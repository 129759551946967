import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import { Popover, SelectPicker, Tooltip, Uploader, Whisper } from "rsuite";
import LinkUtils from "lib/LinkUtils";
import swal from "sweetalert";
import { DateRangePicker } from "react-date-range";

class PLNDateRangePicker extends React.Component {
  state = {
    dateRange: [
      {
        id: "this-month",
        startDate: moment()
          .startOf("month")
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toDate(),
        endDate: moment()
          .endOf("month")
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .add(moment().utcOffset(), "minutes")
          .toDate(),
        key: "selection",
      },
    ],
    presetDateRanges: [
      {
        label: "This Week",
        range: () => {
          return {
            id: "this-week",
            key: "selection",
            startDate: moment()
              .startOf("week")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("week")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("week")

                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("week")

                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "Last Week",
        range: () => {
          return {
            id: "last-week",
            key: "selection",
            startDate: moment()
              .startOf("week")
              .subtract(1, "week")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("week")
              .subtract(1, "week")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("week")
                .subtract(1, "week")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("week")
                .subtract(1, "week")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "This Month",
        range: () => {
          return {
            id: "this-month",
            key: "selection",
            startDate: moment()
              .startOf("month")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("month")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("month")

                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("month")

                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "Last Month",
        range: () => {
          return {
            id: "last-month",
            key: "selection",
            startDate: moment()
              .startOf("month")
              .subtract(1, "month")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("month")
              .subtract(1, "month")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("month")
                .subtract(1, "month")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("month")
                .subtract(1, "month")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "This Quarter",
        range: () => {
          return {
            id: "this-qtr",
            key: "selection",
            startDate: moment()
              .startOf("quarter")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("quarter")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("quarter")

                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("quarter")

                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "Last Quarter",
        range: () => {
          return {
            id: "last-qtr",
            key: "selection",
            startDate: moment()
              .startOf("quarter")
              .subtract(1, "quarter")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("quarter")
              .subtract(1, "quarter")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("quarter")
                .subtract(1, "quarter")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("quarter")
                .subtract(1, "quarter")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "This Year",
        range: () => {
          return {
            id: "this-year",
            key: "selection",
            startDate: moment()
              .startOf("year")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("year")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("year")

                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("year")

                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "Last Year",
        range: () => {
          return {
            id: "last-year",
            key: "selection",
            startDate: moment()
              .startOf("year")
              .subtract(1, "year")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .endOf("year")
              .subtract(1, "year")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .startOf("year")
                .subtract(1, "year")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .endOf("year")
                .subtract(1, "year")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "Last 7 Days",
        range: () => {
          return {
            id: "last-year",
            key: "selection",
            startDate: moment()
              .subtract(7, "days")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .subtract(1, "day")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .subtract(7, "days")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .subtract(1, "day")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "Last 30 Days",
        range: () => {
          return {
            id: "last-year",
            key: "selection",
            startDate: moment()
              .subtract(30, "days")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .subtract(1, "day")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .subtract(30, "days")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .subtract(1, "day")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
      {
        label: "Last 90 Days",
        range: () => {
          return {
            id: "last-year",
            key: "selection",
            startDate: moment()
              .subtract(90, "days")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate(),
            endDate: moment()
              .subtract(1, "day")
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .add(moment().utcOffset(), "minutes")
              .toDate(),
          };
        },
        isSelected: (val) => {
          return (
            val?.startDate?.toISOString() ==
              moment()
                .subtract(90, "days")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
                .toISOString() &&
            val.endDate?.toISOString() ==
              moment()
                .subtract(1, "day")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(999)
                .add(moment().utcOffset(), "minutes")
                .toDate()
                .toISOString()
          );
        },
      },
    ],
  };

  renderRange() {
    if (
      !this.state?.dateRange?.length ||
      !this.state.dateRange[0]?.startDate ||
      !this.state.dateRange[0]?.endDate
    ) {
      return "Select Date Range";
    }

    let startMoment = moment(
      this.state.dateRange[0]?.startDate?.toISOString()
    ).format("MM/DD/YYYY");
    let endMoment = moment(
      this.state.dateRange[0]?.endDate?.toISOString()
    ).format("MM/DD/YYYY");

    if (startMoment == endMoment) {
      return startMoment;
    }

    return `${startMoment}-${endMoment}`;
  }

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  onDateRangeChange(v) {
    this.setState({
      dateRange: [v.selection],
    });
  }

  componentDidMount() {
    if (this.props.value) {
      if (!this.props.value?.length) {
        return this.setState({
          dateRange: [
            {
              key: "selection",
              startDate: null,
              endDate: null,
            },
          ],
        });
      }

      return this.setState({
        dateRange: [
          {
            key: "selection",
            startDate: this.props.value[0],
            endDate: this.props.value[1],
          },
        ],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value != prevProps.value) {
      if (!this.props.value) {
        return this.setState({
          dateRange: [
            {
              key: "selection",
              startDate: null,
              endDate: null,
            },
          ],
        });
      }

      return this.setState({
        dateRange: [
          {
            key: "selection",
            startDate: this.props.value[0],
            endDate: this.props.value[1],
          },
        ],
      });
    }
  }

  canGoForward() {
    if (
      !this.state?.dateRange?.length ||
      !this.state.dateRange[0]?.startDate ||
      !this.state.dateRange[0]?.endDate
    ) {
      return false;
    }

    let startDate = moment(this.state.dateRange[0]?.startDate?.toISOString());
    let endDate = moment(this.state.dateRange[0]?.endDate?.toISOString());

    let duration = moment.duration(endDate.diff(startDate));
    let days = duration.asDays();

    let newEnd = moment(endDate?.toISOString()).add(days, "days");

    let now = moment();

    if (now?.add(days, "days").toDate() < newEnd?.toDate()) {
      return false;
    }

    return true;
  }

  canGoBackward() {
    if (
      !this.state?.dateRange?.length ||
      !this.state.dateRange[0]?.startDate ||
      !this.state.dateRange[0]?.endDate
    ) {
      return false;
    }

    let startDate = moment(this.state.dateRange[0]?.startDate?.toISOString());
    let endDate = moment(this.state.dateRange[0]?.endDate?.toISOString());

    let duration = moment.duration(endDate.diff(startDate));
    let days = duration.asDays();

    let newEnd = moment(startDate?.toISOString()).subtract(days, "days");

    let compare = new Date(this.props.minDate ?? "2014-01-01");

    if (compare > newEnd?.toDate()) {
      return false;
    }

    return true;
  }

  goForward() {
    if (
      !this.state?.dateRange?.length ||
      !this.state.dateRange[0]?.startDate ||
      !this.state.dateRange[0]?.endDate
    ) {
      return false;
    }

    let startDate = moment(this.state.dateRange[0]?.startDate?.toISOString());
    let endDate = moment(this.state.dateRange[0]?.endDate?.toISOString());

    let duration = moment.duration(endDate.diff(startDate));
    let days = duration.asDays();

    let newStart = moment(startDate?.format("YYYY-MM-DD"))
      .add(days, "days")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate();
    let newEnd = moment(endDate?.format("YYYY-MM-DD"))
      .add(days, "days")
      .hours(23)
      .minutes(59)
      .seconds(59)
      .milliseconds(999)
      .toDate();

    this.setState({
      dateRange: [
        {
          id: "",
          key: "selection",
          startDate: newStart,
          endDate: newEnd,
        },
      ],
    });

    if (typeof this.props.onChange == "function") {
      this.props.onChange([newStart, newEnd]);
    }
  }

  getMonthDateRange(year, month) {
    // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
    // array is 'year', 'month', 'day', etc
    var startDate = moment([year, month]);

    // Clone the value before .endOf()
    var endDate = moment(startDate).endOf("month");

    // make sure to call toDate() for plain JavaScript date type
    return { startDate: startDate, endDate: endDate };
  }

  getQuarterDateRange(year, month) {
    // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
    // array is 'year', 'month', 'day', etc
    var startDate = moment([year, month]).startOf("quarter");

    // Clone the value before .endOf()
    var endDate = moment(startDate).endOf("quarter");

    // make sure to call toDate() for plain JavaScript date type
    return { startDate: startDate, endDate: endDate };
  }

  goBackward() {
    if (
      !this.state?.dateRange?.length ||
      !this.state.dateRange[0]?.startDate ||
      !this.state.dateRange[0]?.endDate
    ) {
      return false;
    }

    let startDate = moment(this.state.dateRange[0]?.startDate?.toISOString());
    let endDate = moment(this.state.dateRange[0]?.endDate?.toISOString());

    let duration = moment.duration(endDate.diff(startDate));
    let days = duration.asDays();

    let monthRange = this.getMonthDateRange(
      startDate?.year(),
      startDate?.month()
    );

    let quarterRange = this.getQuarterDateRange(
      startDate?.year(),
      startDate?.month()
    );

    if (
      monthRange?.startDate?.format("YYYY-MM-DD") ==
        startDate?.format("YYYY-MM-DD") &&
      monthRange?.endDate?.format("YYYY-MM-DD") == endDate?.format("YYYY-MM-DD")
    ) {
      let newStart = moment(startDate?.format("YYYY-MM-DD"))
        .subtract(1, "month")
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .toDate();
      let newEnd = moment(endDate?.format("YYYY-MM-DD"))
        .subtract(1, "month")
        .endOf("month")
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
        .add(moment().utcOffset(), "minutes")
        .toDate();

      this.setState({
        dateRange: [
          {
            id: "",
            key: "selection",
            startDate: newStart,
            endDate: newEnd,
          },
        ],
      });

      if (typeof this.props.onChange == "function") {
        this.props.onChange([newStart, newEnd]);
      }

      return;
    }

    if (
      quarterRange?.startDate?.format("YYYY-MM-DD") ==
        startDate?.format("YYYY-MM-DD") &&
      quarterRange?.endDate?.format("YYYY-MM-DD") ==
        endDate?.format("YYYY-MM-DD")
    ) {
      let newStart = moment(startDate?.format("YYYY-MM-DD"))
        .subtract(1, "quarter")
        .startOf("quarter")
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .toDate();
      let newEnd = moment(endDate?.format("YYYY-MM-DD"))
        .subtract(1, "quarter")
        .endOf("quarter")
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
        .add(moment().utcOffset(), "minutes")
        .toDate();

      this.setState({
        dateRange: [
          {
            id: "",
            key: "selection",
            startDate: newStart,
            endDate: newEnd,
          },
        ],
      });

      if (typeof this.props.onChange == "function") {
        this.props.onChange([newStart, newEnd]);
      }

      return;
    }

    let newStart = moment(startDate?.format("YYYY-MM-DD"))
      .subtract(days, "days")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate();
    let newEnd = moment(endDate?.format("YYYY-MM-DD"))
      .subtract(days, "days")
      .hours(23)
      .minutes(59)
      .seconds(59)
      .milliseconds(999)
      .add(moment().utcOffset(), "minutes")
      .toDate();

    this.setState({
      dateRange: [
        {
          id: "",
          key: "selection",
          startDate: newStart,
          endDate: newEnd,
        },
      ],
    });

    if (typeof this.props.onChange == "function") {
      this.props.onChange([newStart, newEnd]);
    }
  }

  render() {
    return (
      <>
        <Whisper
          trigger={this.state.rangeOpen ? "click" : "none"}
          enterable={true}
          placement="autoVertical"
          preventOverflow={true}
          onOpen={() => {
            this.setState({
              rangeOpen: true,
            });
          }}
          onClose={() => {
            this.setState({
              rangeOpen: false,
            });
          }}
          full
          ref={this.ref}
          speaker={
            <Popover className="p-0">
              <DateRangePicker
                onChange={this.onDateRangeChange.bind(this)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={this.state.dateRange}
                direction="horizontal"
                scroll={{ enabled: true }}
                staticRanges={this.state.presetDateRanges}
                className={`${
                  (!this.state.dateRange?.length ||
                    !this.state?.dateRange[0]?.startDate ||
                    !this.state?.dateRange[0]?.endDate) &&
                  "rdrNoSelection"
                }`}
                minDate={new Date(this.props.minDate ?? "2014-01-01")}
                maxDate={
                  new Date(
                    this.props.maxDate ??
                      moment()
                        .add(1, "day")
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .millisecond(0)
                        .format("YYYY-MM-DD")
                  )
                }
              />
              <div className="border-top border-lighter p-2 ">
                <Row>
                  <Col xs="">
                    <Button
                      size="sm"
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          dateRange: [
                            {
                              key: "selection",
                              startDate: null,
                              endDate: null,
                            },
                          ],
                        });

                        if (typeof this.props.onChange == "function") {
                          this.props.onChange([null, null]);
                        }

                        this.ref.current.close();
                      }}
                      outline
                    >
                      Clear
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      color="secondary"
                      onClick={() => {
                        if (typeof this.props.onCancel == "function") {
                          this.props.onCancel();
                        }

                        if (this.props.value?.length == 2) {
                          this.setState({
                            dateRange: [
                              {
                                key: "selection",
                                startDate: this.props.value[0],
                                endDate: this.props.value[1],
                              },
                            ],
                          });
                        } else {
                          this.setState({
                            dateRange: [
                              {
                                key: "selection",
                                startDate: null,
                                endDate: null,
                              },
                            ],
                          });
                        }

                        this.ref.current.close();
                      }}
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      className="ml-0"
                      disabled={
                        !this.state.dateRange?.length ||
                        !this.state?.dateRange[0]?.startDate ||
                        !this.state?.dateRange[0]?.endDate
                      }
                      onClick={() => {
                        if (typeof this.props.onChange == "function") {
                          this.props.onChange([
                            this.state.dateRange[0].startDate,
                            this.state.dateRange[0].endDate,
                          ]);
                        }

                        this.ref.current.close();
                      }}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>
              </div>
            </Popover>
          }
        >
          <div>
            <ButtonGroup className="pln-daterange-group">
              <Button
                size="sm"
                className="btn-icon-only bg-white text-dark pln-daterange-prev"
                outline
                style={{
                  border: "1px solid #cad1d7",
                }}
                disabled={this.state.rangeOpen || !this.canGoBackward()}
                onClick={() => {
                  this.goBackward();
                }}
              >
                <i className="mdi mdi-chevron-left"></i>
              </Button>
              <Button
                size="sm"
                outline
                className="bg-white text-dark pln-daterange"
                style={{
                  border: "1px solid #cad1d7",
                }}
                onClick={() => {
                  this.ref.current.open();
                }}
              >
                {this.renderRange()}
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  this.goForward();
                }}
                className="btn-icon-only bg-white text-dark pln-daterange-next"
                outline
                style={{
                  border: "1px solid #cad1d7",
                }}
                disabled={this.state.rangeOpen || !this.canGoForward()}
              >
                <i className="mdi mdi-chevron-right"></i>
              </Button>
            </ButtonGroup>
          </div>
        </Whisper>
      </>
    );
  }
}

export default PLNDateRangePicker;
