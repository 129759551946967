import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class CustomerCreateOrderDrawer extends React.Component {
  state = {
    fulfillmentDate: moment().day(6).toDate(),
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  toggleModal() {
    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createCustomerOrder(
      this.props?.customer?.storeID,
      this.props?.customer?._id,
      this.state.fulfillmentDate
    )
      .then(
        (data) => {
          const order = data.data.order;

          this.toggleModal();

          PubSub.publish(Event.ORDER.CREATED, order);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create order - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Order
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={this.state.submitting || !this.state.fulfillmentDate}
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>Fulfillment Date</h5>
              <DatePicker
                oneTap
                required
                block
                placeholder="Select A Date"
                defaultValue={moment().day(6).toDate()}
                value={this.state.fulfillmentDate}
                onChange={(e) => {
                  this.setState({ fulfillmentDate: e });
                }}
                disabledDate={(date) =>
                  moment(date.toISOString())
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .toDate() <
                  moment().hour(0).minute(0).second(0).milliseconds(0).toDate()
                }
                ranges={[
                  {
                    label: "Today",
                    value: moment().toDate(),
                    closeOverlay: true,
                  },
                  {
                    label: "This Saturday",
                    value:
                      moment().day() == 6
                        ? moment().toDate()
                        : moment().day(6).toDate(),
                    closeOverlay: true,
                  },
                ]}
              ></DatePicker>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(CustomerCreateOrderDrawer);
