import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";

class GlobalCreateUserDrawer extends React.Component {
  state = {
    position: "",
    name: "",
    email: "",
    phone: {
      value: "",
    },
    hireDate: null,
    role: "",
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.open != prevProps?.open && this.props.open) {
      this.getAllStores();
    }
  }

  componentDidMount() {
    this.getAllStores();
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      role: "",
      selectedStores: [],
    });

    this.props.onClose();
  }

  getAllStores() {
    APIV2.getStores().then((data) => {
      this.setState({
        stores: data.data.stores,
        storeOptions: data.data.stores?.map((st) => {
          return {
            value: st?._id,
            label: st?.name?.replace("Project LeanNation", "PLN"),
          };
        }),
      });
    });
  }

  submit() {
    this.setState({ submitting: true });

    let globalPermissions = [];
    let productionPermissions = [];
    let storePermissions = [];

    APIV2.createGlobalUser(
      this.state.name,
      this.state.email,
      globalPermissions,
      productionPermissions,
      storePermissions
    )
      .then(
        (data) => {
          const user = data.data.user;

          PubSub.publish(Event.USER.CREATED, user);

          this.setState({
            name: "",
            email: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create user - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Add User
            </h3>
            <Drawer.Actions>
              <Button
                onClick={this.submit.bind(this)}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name || !this.state.email || this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>Name</h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Email</h5>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                required
                value={this.state.email}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Role</h5>
              <Input
                type="select"
                name="role"
                placeholder="Choose A Role"
                required
                value={this.state.role}
                onChange={this.handleInputChange.bind(this)}
              >
                <option value="" disabled>
                  Choose A Role
                </option>
                <optgroup label="Internal Users">
                  <option value="Franchise Development">
                    Franchise Development
                  </option>
                  <option value="Production">Production</option>
                </optgroup>
                <optgroup label="External Users">
                  <option value="Franchise Partner">
                    Franchise Partner / Staff
                  </option>
                </optgroup>
              </Input>
            </FormGroup>
            {this.state.role == "Franchise Partner" && (
              <FormGroup>
                <h5 className="mb-0">Stores</h5>
                <p className="small mb-2">
                  Select one or more stores the user should have access to.
                </p>
                <CheckPicker
                  block
                  searchable
                  placement="auto"
                  placeholder="Select Stores"
                  cleanable
                  data={this.state.storeOptions}
                  value={this.state.selectedStores}
                  onChange={(v) => {
                    this.setState({
                      selectedStores: v,
                    });
                  }}
                ></CheckPicker>
              </FormGroup>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(GlobalCreateUserDrawer);
