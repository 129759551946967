import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import AdminNavbarV2 from "components/Navbars/AdminNavbarV2";
import StorefrontSidebarV2 from "components/Sidebar/StorefrontSidebarV2";
import StoreList from "views/examples/StoreList";
import MealList from "views/examples/MealList";
import MenuList from "views/examples/MenuList";
import BillingAccountList from "views/examples/BillingAccountList";
import ProductGroupList from "views/examples/ProductGroupList";
import SignupLinkCreatorView from "views/examples/SignupLinkCreatorView";
import StoreView from "views/examples/StoreView";
import MealView from "views/examples/MealView";
import routesv2 from "routesv2";
import DashboardTestView from "views/examples/DashboardTestView";
import MealCreateView from "views/examples/MealCreateView";
import MealCategoryList from "views/examples/MealCategoryList";
import MealAllergenList from "views/examples/MealAllergenList";
import CustomerView from "views/storefront/CustomerView";
import { Capacitor } from "@capacitor/core";
import FulfillmentSummary from "views/reports/FulfillmentSummary";
import StorefrontCustomerListView from "views/storefront/StorefrontCustomerListView";
import StoreFulfillmentSummary from "views/storefront/StoreFulfillmentSummary";
import StoreFulfillmentOrderListView from "views/storefront/StoreFulfillmentOrderListView";
import StoreOrderLabelView from "views/storefront/StoreOrderLabelView";
import StorefrontInvoiceListView from "views/storefront/StorefrontInvoiceListView";
import StorefrontLeadListView from "views/storefront/StorefrontLeadListView";
import StorefrontStaffListView from "views/storefront/StorefrontStaffListView";
import StorefrontTaskListView from "views/storefront/StorefrontTaskListView";
import LeadView from "views/storefront/LeadView";
import StorefrontUserListView from "views/storefront/StorefrontUserListView";
import StoreFulfillmentPickupListView from "views/storefront/StoreFulfillmentPickupListView";
import StoreOrderPickupListPrintView from "views/storefront/StoreOrderPickupListPrintView";
import StoreOrderDeliveryListPrintView from "views/storefront/StoreOrderDeliveryListPrintView";
import StoreOrderDeliveryListDownloadView from "views/storefront/StoreOrderDeliveryListDownloadView";
import StoreOrderPickupListDownloadView from "views/storefront/StoreOrderPickupListDownloadView";
import StoreProjectionsDownloadView from "views/storefront/StoreProjectionsDownloadView";
import GlobalProjectionsDownloadView from "views/storefront/GlobalProjectionsDownloadView";
import StoreOrderBoxingListDownloadView from "views/storefront/StoreOrderBoxingListDownloadView";
import StoreUsageReportDownloadView from "views/storefront/StoreUsageReportDownloadView";
import StoreBulkUsageReportDownloadView from "views/storefront/StoreBulkUsageReportDownloadView";
import ProductOrderPackingSlipView from "../views/storefront/ProductOrderPackingSlipView";
import ProductOrderPalletSheetView from "../views/storefront/ProductOrderPalletSheetView";
import ProductOrderCSVDownloadView from "../views/storefront/ProductOrderCSVDownloadView";
import ProductOrderSummaryCSVDownloadView from "../views/storefront/ProductOrderSummaryCSVDownloadView";

class StorefrontPrint extends React.Component {
  componentDidUpdate(e) {
    if (e.history?.location?.state?.noScroll) {
      return;
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  getStoresForUser() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser()
      .then(
        (data) => {
          if (data?.data?.stores?.length) {
            localStorage.setItem("selected_store", data.data.stores[0]._id);

            this.props.history.push(
              "/storefront/" + data.data.stores[0]._id + "/home"
            );
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentWillMount() {
    if (Capacitor.getPlatform() == "ios") {
      document.body.className += " ios-padding";
    }

    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      API.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2._wsConnect();
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      window[Constant.CACHE.USER] = User._fromData(
        LocalStorage.get(Constant.CACHE.USER)
      );
      PubSub.publish(Event.USER_LOADED, window[Constant.CACHE.USER]);
    }

    APIV2.getAccount().then(
      (data) => {
        if (data && data.data && data.data.user) {
          data.data.user.id = data.data.user._id;

          let user = data.data.user;

          window[Constant.CACHE.USER] = user;
          LocalStorage.set(Constant.CACHE.USER, user);

          console.log("fetched user");

          PubSub.publish(Event.USER_LOADED, user);
        }
      },
      (error) => {
        console.error(error);
      }
    );

    if (
      !this.props.match?.params?.storeID ||
      this.props.match?.params?.storeID == "select"
    ) {
      console.log("here");

      this.getStoresForUser();
    }
  }

  render() {
    if (!LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/auth");
    }

    return (
      <>
        <Switch>
          <Route
            path="/storefront-print/:storeID/fulfillment/orders/labels"
            component={StoreOrderLabelView}
            exact
          />
          <Route
            path="/storefront-print/:storeID/fulfillment/orders/pickup-list"
            component={StoreOrderPickupListPrintView}
            exact
          />

          <Route
            path="/storefront-print/:storeID/fulfillment/orders/pickup-list/csv"
            component={StoreOrderPickupListDownloadView}
            exact
          />
          <Route
            path="/storefront-print/:storeID/fulfillment/orders/boxing-list/csv"
            component={StoreOrderBoxingListDownloadView}
            exact
          />
          <Route
            path="/storefront-print/:storeID/fulfillment/orders/usage-report/csv"
            component={StoreUsageReportDownloadView}
            exact
          />
          <Route
            path="/storefront-print/:storeID/fulfillment/orders/delivery-list"
            component={StoreOrderDeliveryListPrintView}
            exact
          />
          <Route
            path="/storefront-print/:storeID/fulfillment/orders/delivery-list/csv"
            component={StoreOrderDeliveryListDownloadView}
            exact
          />
          <Route
            path="/storefront-print/:storeID/fulfillment/projections/csv"
            component={StoreProjectionsDownloadView}
            exact
          />
          <Route
            path="/storefront-print/fulfillment/projections/csv"
            component={GlobalProjectionsDownloadView}
            exact
          />
          <Route
            path="/storefront-print/product/orders/:orderID/packing-slip"
            component={ProductOrderPackingSlipView}
            exact
          />
          <Route
            path="/storefront-print/product/orders/:orderID/csv"
            component={ProductOrderCSVDownloadView}
            exact
          />
          <Route
            path="/storefront-print/product/order-summary/:orderIDs/csv"
            component={ProductOrderSummaryCSVDownloadView}
            exact
          />
          <Route
            path="/storefront-print/product/orders/:orderID/pallet-sheet"
            component={ProductOrderPalletSheetView}
            exact
          />
          <Route
            path="/storefront-print/fulfillment/bulk-usage-report/csv"
            component={StoreBulkUsageReportDownloadView}
            exact
          />
        </Switch>
      </>
    );
  }
}

export default StorefrontPrint;
