/*eslint-disable*/
import moment from "moment";
import React from "react";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer bg-superlight">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © {moment().format("YYYY")} Project LeanNation Franchising Inc.
            </div>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
