import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Col, Row } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import TaskModifyDrawer from "./TaskModifyDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import TechSupportTicketDrawer from "./TechSupportTicketDrawer";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import User from "lib/Model/User";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";

class SupportTicketDetailDrawer extends React.Component {
  state = {
    dueDate: moment().day(6).toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    recentFirst: "recentFirst",
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps?.task != this.props.task && this.props.task) {
      this.fetchNotes(true);
    }
  }

  componentDidMount() {
    if (this.props.task) {
      this.fetchNotes(true);
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  modifyStatus(status) {
    this.setState({ submitting: true });

    APIV2.modifyTechSupportTicketStatus(this?.props?.task?._id, status)
      .then(
        (data) => {
          const task = data.data.task;

          //this.toggleModal();

          PubSub.publish(Event.TASK.MODIFIED, task);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to update ticket status - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  requestResponse() {
    this.setState({ submitting: true });

    APIV2.requireTechSupportTicketResponse(this?.props?.task?._id)
      .then(
        (data) => {
          const task = data.data.task;

          //this.toggleModal();

          PubSub.publish(Event.TASK.MODIFIED, task);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to request response - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  delete() {
    swal({
      title: "Delete Ticket",
      text: "Are you sure you want delete this ticket?",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteTechSupportTicket(this?.props?.task?._id)
        .then(
          (data) => {
            this.toggleModal();

            PubSub.publish(Event.TASK.DELETED);

            this.toggleModal();
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                "Unable to delete tickets - unknown error occurred. Try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  addReopenNote() {
    let user = null;

    if (window[Constant.CACHE.USER]) {
      user = window[Constant.CACHE.USER];
    } else if (LocalStorage.get(Constant.CACHE.USER)) {
      user = User._fromData(LocalStorage.get(Constant.CACHE.USER));
    }

    APIV2.createStoreNote(
      this.props.task?.storeID,
      null,
      this.props.task ? this.props?.task?._id : null,
      null,
      null,
      false,
      "",
      `Ticket reopened by ${
        user?.name ? `${user.name} (${user?.email})` : "PLN Team Member"
      }`
    );
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    console.log(dateString);

    let now = moment();
    let due = moment(dateString);

    if (now.toDate() > due.toDate() && status != "Completed") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {due.format("MMM DD, YYYY")}
        </Badge>
      );
    }

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return <Badge color="info">{status}</Badge>;
    }

    if (status == "Completed") {
      return <Badge color="light">{status}</Badge>;
    }
  }

  openAttachment(attachment) {
    APIV2.getPrivateFileURL(attachment?.url).then(
      (data) => {
        LinkUtils.openInNewTab(data?.data?.url, false);
      },
      () => {
        alert("Unable to open attachment.");
      }
    );
  }

  fetchNotes(forceLoad = false) {
    if (!this.props.task) {
      return;
    }

    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {
      taskID: this?.props?.task?._id,
    };

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore(
      this.props?.task?.storeID,
      this.state.page,
      20,
      query,
      sortBy
    )
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  render() {
    return (
      <>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Ticket Details
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Status</h5>
                      </Dropdown.Item>
                      {!this.props.storeMode && (
                        <>
                          <Dropdown.Item
                            disabled={
                              this.props.task?.status == "Open" ||
                              this.state.submitting
                            }
                            onClick={() => {
                              this.modifyStatus("Open");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Open
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.task?.status == "Investigating" ||
                              this.state.submitting
                            }
                            onClick={() => {
                              this.modifyStatus("Investigating");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as Investigating
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.task?.status == "In Progress" ||
                              this.state.submitting
                            }
                            onClick={() => {
                              this.modifyStatus("In Progress");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as In Progress
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              this.props.task?.status == "On Hold" ||
                              this.state.submitting
                            }
                            onClick={() => {
                              this.modifyStatus("On Hold");

                              this.dropdownRef.current.close();
                            }}
                          >
                            Mark as On Hold
                          </Dropdown.Item>
                        </>
                      )}

                      {this.props.storeMode &&
                        this.props.task?.status == "Resolved" && (
                          <>
                            <Dropdown.Item
                              disabled={
                                this.props.task?.status == "Open" ||
                                this.state.submitting
                              }
                              onClick={() => {
                                this.modifyStatus("Open");

                                this.addReopenNote();

                                this.dropdownRef.current.close();
                              }}
                            >
                              Reopen Ticket
                            </Dropdown.Item>
                          </>
                        )}
                      {(!this.props.storeMode ||
                        this.props?.task?.status != "Resolved") && (
                        <Dropdown.Item
                          disabled={
                            this.props.task?.status == "Resolved" ||
                            this.state.submitting
                          }
                          onClick={() => {
                            this.modifyStatus("Resolved");

                            this.dropdownRef.current.close();
                          }}
                        >
                          Mark as Resolved
                        </Dropdown.Item>
                      )}

                      {!this.props.storeMode && (
                        <>
                          <Dropdown.Item divider></Dropdown.Item>
                          <Dropdown.Item
                            panel
                            className="py-2 font-weight-800 text-uppercase"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                          >
                            <h5 className="m-0">Actions</h5>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              this.requestResponse();

                              this.dropdownRef.current.close();
                            }}
                            disabled={this.state.submitting}
                          >
                            Request Response
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({
                                modifyOpen: true,
                                modifyTask: this.props.task,
                              });

                              this.dropdownRef.current.close();
                            }}
                          >
                            Modify Ticket
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-danger"
                            disabled={this.state.deleting}
                            onClick={() => {
                              this.delete();

                              this.dropdownRef.current.close();
                            }}
                          >
                            Delete Ticket
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button size="sm" outline color="secondary">
                  Actions <i className="mdi mdi-chevron-down pl-2"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  {this.props.task?.requiresResponse &&
                    this.props.task?.status != "Resolved" && (
                      <>
                        <Whisper
                          placement="auto"
                          trigger="hover"
                          delayOpen={200}
                          speaker={
                            <Tooltip>
                              <p className="m-0">
                                {this.props.storeMode ? (
                                  <>
                                    Your response to this ticket is requested.
                                  </>
                                ) : (
                                  <>Store's response is requested</>
                                )}
                              </p>
                            </Tooltip>
                          }
                        >
                          <Button
                            size="sm"
                            color={this.props.storeMode ? "danger" : "dark"}
                            className="btn-icon-only mr-2 border-0"
                            outline
                            style={{
                              height: 22,
                              width: 22,
                              position: "relative",
                            }}
                          >
                            <i
                              ref={(node) => {
                                if (node) {
                                  node.style.setProperty(
                                    "font-size",
                                    "18px",
                                    "important"
                                  );
                                }
                              }}
                              className="mdi mdi-chat-alert"
                            ></i>
                          </Button>
                        </Whisper>
                      </>
                    )}
                  <p className="m-0 d-inline-block text-muted small">
                    Ticket #{this.props.task?._id}
                  </p>
                  {!this.props.storeMode && this.props?.task?.store?.name && (
                    <>
                      <p className="mt-1 mb-0 text-dark">
                        {this.props?.task?.store?.name}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  <h3 className="m-0">
                    <Badge color="medium">{this.props?.task?.status}</Badge>
                  </h3>
                </Col>
              </Row>
              <hr className="my-3"></hr>
              <Row>
                <Col xs="12" md="6">
                  <FormGroup>
                    <h5>Ticket Type</h5>
                    <p className="m-0">{this.props.task?.supportType}</p>
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <h5>Submitted By</h5>
                    <p className="m-0">
                      {this.props.task?.submittedBy ?? "--"}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <h5>Requesting Assistance With</h5>
              <p className="mb-0 p-2 border" style={{ borderRadius: 4 }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.props.task?.subject?.replace(/\n/g, "<br />"),
                  }}
                />
              </p>
            </FormGroup>
            <Row className="align-items-top">
              <Col xs="12" md="12">
                <FormGroup>
                  <h5>Desired Solution</h5>
                  <div
                    className="rounded p-2 border"
                    style={{ borderRadius: 4 }}
                  >
                    <Linkify
                      body={this.props.task?.desiredSolution}
                      wrap={true}
                    ></Linkify>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="12" md="12">
                <FormGroup>
                  <h5>Attachments</h5>
                  {this.props.task?.attachments?.length > 0 ? (
                    <>
                      {this.props.task?.attachments?.map((attachment, i) => (
                        <p className="m-0" key={i}>
                          <a
                            href="javascript:void(0)"
                            onClick={(e) => {
                              e.preventDefault();

                              this.openAttachment(attachment);
                            }}
                          >
                            {attachment?.fileName}
                            <i
                              className="mdi mdi-open-in-new pl-2"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                          </a>
                        </p>
                      ))}
                    </>
                  ) : (
                    <>
                      <p className="m-0">--</p>
                    </>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div>
              {this.props.task?.customer ||
              this.props.task?.lead ||
              this.props.task?.affiliate ? (
                <>
                  <hr className="my-3"></hr>
                  <Row className="mb-3 align-items-center">
                    <Col xs="">
                      <h4 className="mb-0">
                        Related {this.props.task?.customer ? "Member" : ""}
                        {this.props.task?.lead ? "Lead" : ""}
                        {this.props.task?.affiliate ? "Affiliate" : ""}
                      </h4>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="secondary"
                        outline
                        onClick={() => {
                          let url = "";

                          if (this.props.task?.customer?._id) {
                            url = `/storefront/${this.props?.task?.storeID}/members/details/${this.props.task?.customer?._id}/summary`;
                          } else if (this.props.task?.lead?._id) {
                            url = `/storefront/${this.props?.task?.storeID}/leads/details/${this.props.task?.leadID}/summary`; // TODO: fix this once lead page is built
                          } else if (this.props.task?.affiliate?._id) {
                            url = `/storefront/${this.props?.task?.storeID}/affiliates/details/${this.props.task?.affiliateID}/summary`; // TODO: fix this once lead page is built
                          }

                          LinkUtils.openInNewTab(url, true);
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                  {this.props.task?.customer ? (
                    <>
                      <CustomerContactInformationWidget
                        customer={this.props.task?.customer}
                        flat={true}
                      />
                    </>
                  ) : null}
                  {this.props.task?.lead ? (
                    <>
                      <LeadContactInformationWidget
                        lead={this.props.task?.lead}
                        flat={true}
                      ></LeadContactInformationWidget>
                    </>
                  ) : null}
                  {this.props.task?.affiliate ? (
                    <>
                      <AffiliateAboutWidget
                        flat={true}
                        affiliate={this.props.task?.affiliate}
                      ></AffiliateAboutWidget>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>

            <hr className="my-3"></hr>
            <h4 className="mb-3">Status &amp; Assignment</h4>
            <Row className="align-items-top">
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Status</h5>
                  <p className="m-0">{this.props.task?.status}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Priority</h5>
                  <p className="m-0">
                    {this.props.task?.priority == "High" ? (
                      <i
                        className="mdi mdi-exclamation-thick text-danger pr-1"
                        style={{ lineHeight: 1 }}
                      ></i>
                    ) : null}
                    <span
                      className={`${
                        this.props.task?.priority == "High" && "text-danger"
                      }`}
                    >
                      {this.props.task?.priority}
                    </span>
                  </p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Assigned To</h5>

                  <p className="m-0 text-dark text-truncate">
                    {this.props.task?.assignedTo?.name
                      ? this.props.task?.assignedTo?.name
                      : "--"}
                  </p>
                  {this.props.task?.assignedTo?.email ? (
                    <p
                      className="m-0 text-truncate"
                      style={{ fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.props.task?.assignedTo?.email}
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>
                    Due Date
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  {this.formatDueDate(
                    this.props.task?.dueDate,
                    this.props.task?.status
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Created By User</h5>
                  <p className="m-0 text-dark text-truncate">
                    {this.props.task?.createdBy?.name
                      ? this.props.task?.createdBy?.name
                      : "--"}
                  </p>
                  {this.props.task?.createdBy?.email ? (
                    <p
                      className="m-0 text-truncate"
                      style={{ fontSize: 12, lineHeight: 1.2 }}
                    >
                      {this.props.task?.createdBy?.email}
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Created At</h5>
                  {this.props.task?.createdAt
                    ? moment(this.props.task?.createdAt)?.format(
                        "MM/DD/YY h:mm A"
                      )
                    : "--"}
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-3"></hr>
            <Row className="align-items-center mb-3">
              <Col>
                <h4 className="text-dark mb-0">Conversation</h4>
                <p className="small">
                  Conversation messages will be sent to the ticket creator &amp;
                  assigned team member.
                </p>
              </Col>
              <Col xs="auto">
                <SelectPicker
                  size="sm"
                  searchable={false}
                  data={[
                    { label: "Newest First", value: "recentFirst" },
                    { label: "Oldest First", value: "oldestFirst" },
                  ]}
                  value={this.state.recentFirst}
                  onChange={(v) => {
                    this.handleRecentFirstChange(v);
                  }}
                  cleanable={false}
                  placement="auto"
                ></SelectPicker>
              </Col>
            </Row>

            <div>
              <NoteBody
                task={this.props.task}
                onSave={(note) => {
                  console.log(note);

                  this.setState(
                    {
                      page: 1,
                    },
                    () => {
                      this.fetchNotes(true);
                    }
                  );
                }}
              ></NoteBody>
            </div>
            <div>
              {this.state?.loading && (
                <div className="px-3 py-5 text-center">
                  <Spinner size="sm" color="dark"></Spinner>
                </div>
              )}
              {!this.state?.notes?.length && !this.state.loading && (
                <div className="px-3 py-5">
                  <Row>
                    <Col xs="12" className="text-center">
                      <p className="m-0">No notes found.</p>
                    </Col>
                  </Row>
                </div>
              )}

              {this.state?.notes?.map((note, i) => (
                <div key={note?._id}>
                  {this.state.editNote == note?._id ? (
                    <>
                      <div className="p-4">
                        <NoteBody
                          note={note}
                          task={this.props.task}
                          forceEdit={true}
                          onSave={(n) => {
                            let notes = this.state.notes;

                            let idx = _.findIndex(notes, { _id: n._id });

                            if (idx >= 0) {
                              notes[idx] = n;
                            }

                            this.setState({
                              editNote: "",
                              notes,
                            });
                          }}
                          onCancel={() => {
                            this.setState({ editNote: "" });
                          }}
                        ></NoteBody>
                      </div>
                    </>
                  ) : (
                    <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                      <Row>
                        <Col
                          xs=""
                          style={{
                            maxWidth: "calc(100% - 78px)",
                          }}
                        >
                          {note?.title ? (
                            <>
                              <h3 className="text-dark mb-1">{note?.title}</h3>
                              <Linkify wrap={true} body={note?.body}></Linkify>
                            </>
                          ) : (
                            <>
                              <Linkify wrap={true} body={note?.body}></Linkify>
                            </>
                          )}
                          <div style={{ whiteSpace: "pre-wrap" }}></div>
                        </Col>
                        <Col xs="auto">
                          <Button
                            size="sm"
                            color="secondary"
                            outline
                            className="p-1 mr-0"
                            style={{ width: 22, height: 22 }}
                            onClick={() => {
                              this.setState({
                                editNote: note?._id,
                              });
                            }}
                          >
                            <i
                              className="mdi mdi-pencil"
                              style={{
                                fontSize: 16,
                                lineHeight: 1,
                                position: "relative",
                                left: -2,
                                top: -2,
                              }}
                            ></i>
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            outline
                            className="p-1"
                            style={{ width: 22, height: 22 }}
                            disabled={this.state.deletingNote == note?._id}
                            onClick={() => {
                              swal({
                                title: "Delete Note?",
                                text: "Are you sure you want to delete this note?",
                                icon: "warning",
                                buttons: ["Nevermind", "Delete"],
                                dangerMode: true,
                              }).then((conf) => {
                                if (!conf) {
                                  return;
                                }

                                APIV2.deleteStoreNote(
                                  note?.storeID,
                                  note?._id
                                ).then(() => {
                                  let notes = this.state.notes;

                                  let idx = _.findIndex(notes, {
                                    _id: note._id,
                                  });

                                  notes.splice(idx, 1);

                                  this.setState({
                                    editNote: "",
                                    notes,
                                  });

                                  this.setState({
                                    deletingNote: "",
                                  });
                                });
                              });
                            }}
                          >
                            <i
                              className="mdi mdi-close"
                              style={{
                                fontSize: 18,
                                lineHeight: 1,
                                position: "relative",
                                left: -3,
                                top: -3,
                              }}
                            ></i>
                          </Button>
                        </Col>
                        {note?.attachments?.length > 0 && (
                          <>
                            <Col className="mt-2" xs="12">
                              <h5>Attachments: </h5>
                              {note?.attachments?.map((at, i) => (
                                <div
                                  className={`p-2 border rounded ${
                                    i != this.state?.attachments?.length - 1 &&
                                    "mb-2"
                                  }`}
                                  key={i}
                                >
                                  <Row className="align-items-center">
                                    <Col xs="">
                                      <p className="small m-0 text-dark">
                                        {at?.fileName}
                                      </p>
                                    </Col>
                                    <Col xs="auto">
                                      <Button
                                        className="btn-icon-only"
                                        size="sm"
                                        outline
                                        color="secondary"
                                        onClick={() => {
                                          LinkUtils.openPrivateURL(at?.url);
                                        }}
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </Col>
                          </>
                        )}
                      </Row>
                      <div className="mt-2">
                        <small>
                          {moment(note?.createdAt).format("MM/DD/YY h:mmA")} by{" "}
                          {note?.createdBy?.name}
                        </small>
                      </div>
                    </div>
                  )}
                  {i != this.state?.notes?.length - 1 && (
                    <hr className="m-0"></hr>
                  )}
                </div>
              ))}
            </div>
            <div className="text-center p-2">
              <Button
                size="sm"
                color="secondary"
                outline
                onClick={() => {
                  console.log(this.state.pagination);

                  this.setState(
                    {
                      page: this.state.pagination?.next,
                    },
                    () => {
                      this.fetchNotes();
                    }
                  );
                }}
                disabled={!this.state.pagination?.hasNext}
              >
                {this.state.pagination?.hasNext
                  ? "View More Of Conversation"
                  : "Viewing Entire Conversation"}
              </Button>
            </div>
          </Drawer.Body>
        </Drawer>
        <TechSupportTicketDrawer
          store={this.props.store}
          open={this.state.modifyOpen}
          storeTask={true}
          storeMode={false}
          ticket={this.state.modifyTask}
          onClose={() => {
            this.setState({
              modifyOpen: false,
              modifyTask: null,
            });
          }}
        ></TechSupportTicketDrawer>
      </>
    );
  }
}

export default withRouter(SupportTicketDetailDrawer);
