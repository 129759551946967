import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, isNumber } from "underscore";
import { withRouter } from "react-router-dom";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import _ from "underscore";
import swal from "sweetalert";
import Spinner from "reactstrap/lib/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import { TagPicker, Uploader } from "rsuite";
import moment from "moment";
import StringUtils from "lib/StringUtils";
import ArrowText from "components/ArrowText";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LeadInformationWidget from "components/Widgets/LeadInformationWidget";
import { Capacitor } from "@capacitor/core";
import LeadSummaryWidget from "components/Widgets/LeadSummaryWidget";
import LeadAllTasksWidget from "components/Widgets/LeadAllTasksWidget";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import LeadCommunicationTabView from "components/TabView/LeadCommunicationTabView";
import LeadTimelineWidget from "components/Widgets/LeadTimelineWidget";
import TechSupportTicketDrawer from "components/Drawers/TechSupportTicketDrawer";

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

class LeadView extends React.Component {
  state = {
    store: null,
    loading: true,
    activeTab: "about",
    descriptionState: EditorState.createEmpty(),
    loadingCategories: false,
    categories: [],
    categoryTags: [],
    allergens: [],
    tab: { name: "Summary", id: "summary" },
    allTabs: [
      {
        name: "Summary",
        id: "summary",
      },
      {
        name: "Information",
        id: "information",
      },
      {
        name: "Tasks",
        id: "tasks",
      },
      {
        name: "Communications",
        id: "communications",
      },
      {
        name: "Timeline",
        id: "timeline",
      },
    ],
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
  }

  onDescriptionStateChange(editorState) {
    this.setState({
      descriptionState: editorState,
      modified: true,
    });
  }

  _loadLeadState(lead) {
    if (!lead) {
      return;
    }

    if (!lead.details) {
      lead.details = "";
    }

    let leadDetails = lead.details;

    this.setState({
      lead: lead,
      leadDetails: leadDetails,
    });
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    if (!params?.storeID) {
      return;
    }

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  async loadLead() {
    const {
      match: { params },
    } = this.props;

    APIV2.getLeadByID(params.storeID, params.leadID)
      .then(
        (data) => {
          console.log(data.data);

          if (data && data.data && data.data.lead) {
            this._loadLeadState(data.data.lead);
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  delete() {
    swal({
      title: "Delete Lead?",
      text: "Are you sure you want to delete this lead? This cannot be undone.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (!willDelete) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteLead(this.state.lead?.storeID, this.state.lead._id)
        .then(
          (data) => {
            PubSub.publish(Event.LEAD.DELETED, {
              id: this.state.lead._id,
            });

            swal("Lead successfully deleted", {
              icon: "success",
            });

            this.props.history.push(
              "/storefront/" + this?.props?.match?.params?.storeID + "/leads"
            );
          },
          (e) => {
            console.error(e);
            window.alert(
              "We're having trouble removing this lead. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  checkStickyHeader(e) {
    if (this.headerRef.current) {
      const stickyElm = this.headerRef.current;

      var rect = stickyElm.getBoundingClientRect();

      if (rect.top <= (Capacitor.getPlatform() == "ios" ? 110 : 80)) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }

      if (rect.top == 0) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  }

  componentDidMount() {
    this.loadLead();
    this.loadStore();

    PubSub.subscribe(Event.LEAD.MODIFIED, (c) => {
      this.setState({ lead: c, leadDetails: c?.details });
    });

    if (this.props.match?.params?.tabID) {
      this.setTab(this.props.match?.params?.tabID);
    } else {
      this.setTab("summary");
    }

    document.addEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  setTab(tab) {
    if (!tab) {
      return;
    }

    let t = _.findWhere(this.state.allTabs, { id: tab });

    if (t) {
      this.setState({ tab: t });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.leadID != prevProps.match?.params?.leadID ||
      this.props.match?.params?.storeID != prevProps.match?.params?.storeID
    ) {
      this.loadLead();
    }

    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID
    ) {
      this.loadStore();
    }

    if (this.props.match?.params?.tabID !== prevProps.match?.params?.tabID) {
      if (this.props.match?.params?.tabID) {
        this.setTab(this.props.match?.params?.tabID);
      } else {
        this.setTab("summary");
      }
    }
  }

  /**
   * Sets the lead's details
   */
  setLeadDetails() {
    this.setState({
      submittingLeadDetails: true,
    });

    APIV2.setLeadVariable(
      this.props.match.params.storeID,
      this.state.lead._id,
      "details",
      this.state.leadDetails
    )
      .then(
        (data) => {
          this._loadLeadState(data.data.lead);
        },
        (err) => {}
      )
      .finally(() => {
        this.setState({ submittingLeadDetails: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value,
    });
  }

  handleInputNoSpaceChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/\s/g, ""),
    });
  }

  handleNumberInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/[^\d.]/g, ""),
    });
  }

  categoryChange(v) {
    this.setState({ categories: v, modified: true });
  }

  allergenChange(v) {
    this.setState({ allergens: v, modified: true });
  }

  sinceDateFormat(d) {
    let date = moment(d);

    return date.format("MMMM YYYY");
  }

  renderNextBillDate(d) {
    let mom = moment(d);

    let now = moment();

    if (mom.format("MMMM Do YYYY") == now.format("MMMM Do YYYY")) {
      return "Today";
    }

    return mom.format("M/D/YY");
  }

  formatStatus(c) {
    if (c?.status == "Not Contacted") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {c?.status}
        </Badge>
      );
    }

    if (c?.status == "Attempted") {
      return <Badge color="info">{c?.status}</Badge>;
    }

    if (c?.status == "closedwon") {
      return <Badge color="success">Converted</Badge>;
    }

    if (c?.status == "Inactive") {
      return <Badge color="light">{c?.status}</Badge>;
    }

    return "--";
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            backOverride={`/storefront/${this?.props?.match?.params?.storeID}/leads`}
            title={
              this.state.lead ? (
                <>
                  {this.state.lead?.name
                    ? this.state.lead.name
                    : this.state.lead.email}
                  {this.state.lead?.email?.includes("@projectleannation.") ||
                  this.state.lead?.email?.includes("@pln.") ||
                  this.state.lead?.email?.includes("@test.") ? (
                    <>
                      <Badge
                        color="warning"
                        className="ml-2"
                        style={{
                          position: "relative",
                          top: -1,
                        }}
                      >
                        TEST LEAD
                      </Badge>
                    </>
                  ) : null}
                  <br />
                  <small>{this.formatStatus(this.state.lead)}</small>
                </>
              ) : (
                "Loading..."
              )
            }
            showBackButton={true}
            forceSecondaryDropdown={true}
            secondaryActions={[
              {
                disabled: this.state.lead?.status == "closedwon",
                name: <>Convert To Member</>,
                onClick: () => {
                  this.setState({
                    createCustomerOpen: true,
                  });
                },
              },
              {
                name: <>Open Support Ticket</>,
                onClick: () => {
                  this.setState({
                    supportTicket: true,
                  });
                },
              },
            ]}
          />
          {/* Table */}
          <Row>
            <Col xs="12" md="12">
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-top">
                    <Col xs="12" sm="6">
                      <h3 className=" mb-1">
                        {this.state.lead?.name
                          ? this.state.lead?.name
                          : this.state.lead?.email}
                      </h3>
                      <p className="mb-0">
                        {this.state.lead?.phone
                          ? StringUtils.formatPhoneNumber(
                              this.state.lead?.phone
                            )
                          : this.state.lead?.email}
                      </p>
                    </Col>
                    <Col xs="12" sm="6" className="text-left text-sm-right">
                      <div>
                        <small className="mb-0" style={{ lineHeight: 1.4 }}>
                          Created{" "}
                          {this.renderNextBillDate(this.state.lead?.createdAt)}
                          <br />
                          via {this.state.lead?.initialSource}
                        </small>
                      </div>

                      {this.state.lead?.signupDate ? (
                        <div>
                          <small className="m-0" style={{ lineHeight: 1.4 }}>
                            Member since{" "}
                            {this.sinceDateFormat(this.state.lead?.signupDate)}
                          </small>
                        </div>
                      ) : null}
                    </Col>
                    <Col xs="12" className="mt-4">
                      <h5 className="text-muted text-uppercase">
                        Lead Details
                      </h5>
                      <Input
                        type="textarea"
                        value={this.state.leadDetails}
                        onChange={this.handleInputChange.bind(this)}
                        name="leadDetails"
                        placeholder="Details"
                      ></Input>
                      {this.state.lead &&
                      this.state.leadDetails != this.state.lead?.details ? (
                        <div className="text-right mt-3">
                          <Button
                            size="sm"
                            className="btn-icon-only"
                            color="secondary"
                            outline
                            disabled={this.state.submittingLeadDetails}
                            onClick={() => {
                              this.setState({
                                leadDetails: this.state.lead?.details
                                  ? this.state.leadDetails
                                  : "",
                              });
                            }}
                          >
                            <i className="mdi mdi-close" />
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon-only"
                            color="primary"
                            disabled={this.state.submittingLeadDetails}
                            onClick={() => this.setLeadDetails()}
                          >
                            {this.state.submittingLeadDetails ? (
                              <Spinner size="sm" color="white" />
                            ) : (
                              <i className="mdi mdi-check" />
                            )}
                          </Button>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              {/** Page navigation */}
              <div
                ref={this.headerRef}
                style={{ position: "sticky", top: "80px" }}
                className={`stickable ${this.state.sticky ? "stuck" : ""}`}
              >
                <Card className="mb-4 shadow border">
                  <CardSelectorHeader
                    id="stickyHeader"
                    value={this.state.tab}
                    options={this.state.allTabs}
                    onChange={(v) => {
                      if (v.id != this.state.tab.id) {
                        this.props.history.push(
                          "/storefront/" +
                            this.props.match?.params?.storeID +
                            "/leads/details/" +
                            this.props.match?.params?.leadID +
                            "/" +
                            v.id,
                          { noScroll: true }
                        );
                      }
                    }}
                  ></CardSelectorHeader>
                </Card>
              </div>
              {this.state.tab.id == "summary" ? (
                <>
                  <LeadSummaryWidget
                    loading={this.state.loading}
                    lead={this.state.lead}
                    store={this.state.store}
                  />
                </>
              ) : null}
              {/**Primary  Widgets */}
              {this.state.tab.id == "information" ? (
                <>
                  <LeadInformationWidget
                    loading={this.state.loading}
                    lead={this.state.lead}
                    store={this.state.store}
                  />
                </>
              ) : null}
              {this.state.tab.id == "tasks" ? (
                <>
                  <LeadAllTasksWidget
                    lead={this.state.lead}
                    store={this.state.store}
                    loading={this.state.loading}
                  ></LeadAllTasksWidget>
                </>
              ) : null}
              {this.state.tab.id == "communications" ? (
                <>
                  <LeadCommunicationTabView
                    lead={this.state.lead}
                    store={this.state.store}
                    loading={this.state.loading}
                  ></LeadCommunicationTabView>
                </>
              ) : null}
              {this.state.tab.id == "timeline" ? (
                <>
                  <LeadTimelineWidget
                    lead={this.state.lead}
                    store={this.state.store}
                    loading={this.state.loading}
                  ></LeadTimelineWidget>
                </>
              ) : null}
            </Col>
            <Col xs="12" md="4">
              {/*<Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Insights</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody></CardBody>
    </Card>*/}
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
          <Row>
            <Col xs="auto">
              <Button
                color="danger"
                onClick={() => this.delete()}
                outline
                disabled={this.state.deleting}
              >
                {this.state.deleting ? (
                  <>
                    <Spinner size="sm" color="danger" />
                  </>
                ) : (
                  <>Delete Lead</>
                )}
              </Button>
            </Col>
            <Col xs=""></Col>
          </Row>
        </Container>
        <CustomerCreateDrawer
          store={this.state.lead?.store}
          open={this.state.createCustomerOpen}
          lead={this.state?.lead}
          onClose={() => {
            this.setState({
              createCustomerOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.CUSTOMER.CREATED, c);
          }}
        ></CustomerCreateDrawer>
        <TechSupportTicketDrawer
          store={{ _id: this.props.match?.params?.storeID }}
          open={this.state.supportTicket}
          storeMode={true}
          lead={this.state.lead}
          onClose={() => {
            this.setState({
              supportTicket: false,
            });
          }}
          onCreate={(c) => {
            swal({
              title: "Support Ticket Opened",
              text: "We've received your support ticket and will contact you shortly!",
              icon: "success",
            });
          }}
        ></TechSupportTicketDrawer>
      </>
    );
  }
}

export default withRouter(LeadView);
