import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import { Capacitor } from "@capacitor/core";
import LinkUtils from "lib/LinkUtils";

class BrandDefinitionView extends React.Component {
  openFile(url) {
    LinkUtils.openInNewTab(url);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  copyToClipboard(val) {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(val);
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div
          className="bg-white border-bottom px-4 py-4 py-md-5 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="auto">
              <h1 className="display-1 text-primary m-0">
                <i className="mdi mdi-star-circle"></i>
              </h1>
            </Col>
            <Col xs="">
              <h1 className="m-0 display-3 text-dark">Brand Definition</h1>
            </Col>
          </Row>
        </div>
        <Container fluid>
          {/* Table */}
          <div className="mb-4">
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">
                      What is Project LeanNation
                    </h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h2 className="text-dark font-weight-500 mb-0">
                  Project LeanNation is a comprehensive wellness company that
                  empowers individuals to achieve their health goals through
                  personalized nutrition plans, expert coaching, and
                  technology-driven tracking.
                </h2>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Mission</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h2 className="text-dark font-weight-500 mb-3">
                  Our mission is to build the health of our communities by
                  empowering individuals with support, education, technology,
                  and nutritious meals.
                </h2>
                <p className="m-0">
                  Being a purpose-driven company does not mean being the
                  biggest. It does mean being the best in terms of member value,
                  our commitment to the client experience, team development, and
                  consistent, predictable growth.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Vision</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h2 className="text-dark font-weight-500 m-0">
                  Our Vision is to be the most impactful nutrition company in
                  the nation by elevating the health and knowledge of every
                  community we serve.
                </h2>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Values</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  At Project LeanNation, our guiding principles are our core
                  values. Our values are the foundation of our company,
                  essential to our success, and serve as the lens through which
                  we view every business decision. (Hire, Fire, Recognize,
                  Reward, and Partner). In both words and actions, our
                  commitment to these values will build a more robust, healthier
                  culture, directly benefiting our community, members, team, and
                  franchisees.
                </p>
                <p>
                  <span className="text-dark font-weight-bold">KNOWLEDGE</span>{" "}
                  – Our core value of Knowledge is rooted in a solid practical
                  understanding of PLN philosophy and strategies through
                  personal experience, healthy lifestyle choices, and continued
                  education.
                </p>
                <p>
                  <span className="text-dark font-weight-bold">ENTHUSIASM</span>{" "}
                  – Our core value of Enthusiasm is rooted in our ability to
                  have a high level of excitement and appreciation for others.
                  We are fostering a culture of awesome by being upbeat, eager,
                  and engaged.
                </p>
                <p>
                  <span className="text-dark font-weight-bold">
                    EMOTIONAL IQ
                  </span>{" "}
                  – Our core value of Emotional IQ refers to our capacity to
                  recognize and manage our own and other people’s emotions and
                  effectively cultivate strong relationships. (People Smart)
                </p>
                <p>
                  <span className="text-dark font-weight-bold">DISCIPLINE</span>{" "}
                  – Our core value of Discipline is the ability to hold
                  ourselves and others to a high standard of accountability.
                </p>
                <p>
                  <span className="text-dark font-weight-bold">INFLUENCE</span>{" "}
                  – Our core value of Influence is rooted in our competency to
                  pursue and provide a high level of positive
                  leadership/coaching to our community, members, team, and
                  franchisees.
                </p>
                <p className="text-uppercase text-dark font-weight-bold border-bottom">
                  Our Anchor Core Value:
                </p>
                <p className="m-0">
                  <span className="text-dark font-weight-bold">FAITH</span> –
                  Our core value of faith refers to our ability to have an
                  unwavering belief that our shared mission has a purpose beyond
                  our understanding. Our humble beginnings keep us grounded in
                  our faith, this fuels our devotion to serving our team,
                  community, and partners through all things. Faith provides us
                  the confidence to take steps forward without the burden of
                  fear and doubt.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Purpose</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h2 className="text-dark font-weight-500 mb-3">
                  Sparking transformations and nourishing health for longer,
                  happier lives.
                </h2>
                <p>
                  Project LeanNation is a results-driven nutrition company
                  fueled by a passion for empowering and supporting our
                  community to achieve optimal health. We believe in the
                  transformative power of a healthy lifestyle and are dedicated
                  to fostering a supportive and knowledgeable community where
                  individuals can learn, grow, and thrive in all aspects of
                  health.
                </p>
                <p className="m-0">
                  We never set out to be in the food business; we are in the
                  business of empowering change by supporting our community with
                  nutritious meals, education, technology tracking, and
                  accountability that drives them towards a healthier lifestyle.
                </p>
              </CardBody>
            </Card>

            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Ambition</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p className="m-0">
                  In the next 60 months, Project LeanNation aims to positively
                  impact the lives of millions of people by providing the tools
                  and support necessary to achieve their health and nutrition
                  goals. We are committed to empowering our members and teams to
                  transform their lives and become the best version of
                  themselves, one community at a time. By expanding our reach
                  and serving 200 communities, we will create a ripple effect of
                  positive change that extends far beyond our immediate reach.
                </p>
              </CardBody>
            </Card>

            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Story</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  Project LeanNation is a health-inspired nutrition company
                  fueled by a deep passion for serving others. Our concept was
                  born with the idea that we could foster a community where
                  people can learn and grow in all aspects of health by adopting
                  a healthy lifestyle. We never set out to be in the food
                  business; we are in the business of empowering change by
                  supporting our community with nutritious meals, education, and
                  accountability.
                </p>
                <p>
                  A constant that has never wavered is our commitment to our
                  core values of knowledge, enthusiasm, emotional IQ,
                  discipline, influence, and faith. Our values are the soul of
                  our culture and are lived out by our people daily. Our vision
                  has always guided our long-term goals, and it is so meaningful
                  to watch them become the reality of today.
                </p>
              </CardBody>
            </Card>

            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">One-Page Summary</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  Project LeanNation is a results-driven, comprehensive wellness
                  company that empowers individuals to achieve their health
                  goals through personalized nutrition plans, expert coaching,
                  and technology-driven tracking. Our vision is to elevate the
                  health and knowledge of every community we serve, and our
                  mission is to build the health of our communities by
                  empowering individuals with Support, Education, Technology,
                  and Prepared Healthy Meals. Our purpose is to spark
                  transformations and nourish health for longer, happier lives.
                </p>
                <p>
                  As a brand, we pride ourselves on being an influential
                  cheerleader, knowledgeable guide, and motivational coach for
                  our community. We offer Support, Technology, Education, and
                  Prepared Healthy Meals that meet members where they are on
                  their journey, providing a place for connection, health
                  restoration, discipline cultivation, confidence elevation, and
                  recognition for every step. Our focus on cultivating
                  meaningful relationships with our members sets us apart from
                  other brands in our industry.
                </p>
                <p>
                  Our approach includes developing an exceptional team who are
                  passionate about supporting our members, leveraging technology
                  to create a data-driven experience, providing educational
                  tools and communications, and offering high-quality,
                  ready-to-heat meals throughout the process. We understand that
                  building strong relationships with our members is essential,
                  which is why we prioritize the relationship with our members
                  in our "people first" model.
                </p>
                <p>
                  At Project LeanNation, we use support, technology, education,
                  and prepared healthy meals to transform communities into
                  healthier versions of themselves. Our product line includes
                  Lifestyle Meals, Athlete Meals, Protein Shakes, and Lean
                  Cheats. Our brand is founded on the principles of inclusivity
                  and support, providing a welcoming and inclusive environment
                  where everyone can thrive, regardless of their background or
                  experience level.
                </p>
                <p>
                  It's essential that we are mindful of the language we use when
                  speaking about Project LeanNation. We are not a meal prep
                  company; we are in the business of empowering change by
                  supporting our community with nutritious meals, education,
                  technology tracking, and accountability that drives them
                  towards a healthier lifestyle. We prioritize the relationship
                  with our members and strive to make every member feel valued
                  and supported throughout their journey with us.
                </p>
                <p className="m-0">
                  As we continue to develop our brand, we will remain committed
                  to our members' results and provide a comprehensive,
                  supportive experience that meets their needs and exceeds their
                  expectations. At Project LeanNation, we believe in the
                  transformative power of a healthy lifestyle and are dedicated
                  to fostering a supportive and knowledgeable community where
                  individuals can learn, grow, and thrive in all aspects of
                  health.
                </p>
              </CardBody>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

export default BrandDefinitionView;
