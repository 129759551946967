import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";

class StorefrontTaskListView extends React.Component {
  state = {
    active: {
      id: "All Upcoming Tasks",
      name: "All Upcoming Tasks",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
    orderBy: {
      dueDate: 1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (prevProps?.match?.params.storeID != this.props.match?.params?.storeID) {
      this.handleTabChange(this.state.active, true);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();

    PubSub.subscribe(Event.TASK.CREATED, (m) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailTask?._id == a?._id) {
        this.setState({
          detailTask: a,
        });
      }
    });

    PubSub.subscribe(Event.TASK.DELETED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadTasks(page = 1, count = 25, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    APIV2.getTasksForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tasks.length) {
              tasks = data.data.tasks;
            }

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchTasksForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultTasks: data.data.tasks,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultTasks: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All Upcoming Tasks") {
      let mom = moment();

      mom.add(7, "days");

      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        dueDate: {
          $lte: { $date: mom.toDate().toISOString() },
        },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Lead Engagement") {
      filters = {
        type: "Lead Engagement",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Affiliate Engagement") {
      filters = {
        type: "Affiliate Engagement",
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Member Engagement") {
      filters = {
        type: "Member Engagement",
        subject: { $ne: "New Online Sign-Up: Schedule Consultation" },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "New Online Signups") {
      filters = {
        subject: { $regex: "New Online", $options: "i" },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Billing") {
      filters = {
        type: "Billing",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Fulfillment") {
      filters = {
        type: "Fulfillment",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Admin") {
      filters = {
        type: "Admin",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Incomplete Tasks") {
      filters = {
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Completed Tasks") {
      filters = {
        status: "Completed",
        storeTask: { $ne: true },
      };
      orderBy = { completedAt: -1 };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTasks(1, this.state.count, filters, orderBy);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            let store = _.findWhere(data.data.stores, {
              _id: this.props?.match?.params?.storeID,
            });

            this.setState({ stores: data.data.stores, store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    console.log(dateString);

    let now = moment();
    let due = moment(dateString);

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return <Badge color="info">{status}</Badge>;
    }

    if (status == "Completed") {
      return <Badge color="light">{status}</Badge>;
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Tasks"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All Upcoming Tasks",
                      id: "All Upcoming Tasks",
                    },
                    {
                      name: "Lead Engagement",
                      id: "Lead Engagement",
                    },
                    {
                      name: "Member Engagement",
                      id: "Member Engagement",
                    },
                    {
                      name: "New Online Signups",
                      id: "New Online Signups",
                    },
                    {
                      name: "Billing",
                      id: "Billing",
                    },
                    {
                      name: "Fulfillment",
                      id: "Fulfillment",
                    },
                    {
                      name: "Admin",
                      id: "Admin",
                    },
                    {
                      name: "Affiliate Engagement",
                      id: "Affiliate Engagement",
                    },
                    {
                      name: "All Incomplete Tasks",
                      id: "All Incomplete Tasks",
                    },
                    {
                      name: "All Completed Tasks",
                      id: "All Completed Tasks",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="" className="pr-sm-0">
                      <Input
                        type="text"
                        placeholder="Search tasks..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 pr-0 mt-sm-0">
                      <SelectPicker
                        searchable={false}
                        size="sm"
                        placeholder="Sort By"
                        disabled={true}
                        data={[]}
                        block
                        placement="autoVerticalEnd"
                      />
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 mt-sm-0">
                      <Button
                        color="secondary"
                        size="sm"
                        block
                        outline
                        onClick={() => {
                          PubSub.publish(Event.FILTERS.CUSTOMERS_OPEN);
                        }}
                        disabled
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultTasks?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultTasks?.length
                              )
                            : "0"}{" "}
                          task
                          {this.state.searchResultTasks?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  tasks
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Subject</h4>
                      </Col>

                      <Col xs="6" sm="6" md="2">
                        <h4
                          className={`mb-0 cursor-pointer ${
                            this.state.sortByOption == "dueDate"
                              ? "font-weight-bold"
                              : ""
                          }`}
                          onClick={() => {
                            let dir = "ascending";

                            if (this.state.sortByOption == "dueDate") {
                              if (this.state.sortByDirection == "descending") {
                                return this.setState(
                                  {
                                    sortByOption: "",
                                    sortByDirection: "",
                                    orderBy: null,
                                  },
                                  () => {
                                    this.loadTasks(
                                      1,
                                      this.state.count,
                                      this.state.filters,
                                      this.state.orderBy
                                    );
                                  }
                                );
                              } else if (
                                this.state.sortByDirection == "ascending"
                              ) {
                                dir = "descending";
                              }
                            }

                            this.setState(
                              {
                                sortByOption: "dueDate",
                                sortByDirection: dir,
                                orderBy: {
                                  dueDate: dir == "descending" ? -1 : 1,
                                },
                              },
                              () => {
                                this.loadTasks(
                                  1,
                                  this.state.count,
                                  this.state.filters,
                                  this.state.orderBy
                                );
                              }
                            );
                          }}
                        >
                          Due Date
                          {this.state.sortByOption == "dueDate" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            let dir = "ascending";

                            if (this.state.sortByOption == "priority") {
                              if (this.state.sortByDirection == "descending") {
                                return this.setState(
                                  {
                                    sortByOption: "",
                                    sortByDirection: "",
                                    orderBy: null,
                                  },
                                  () => {
                                    this.loadTasks(
                                      1,
                                      this.state.count,
                                      this.state.filters,
                                      this.state.orderBy
                                    );
                                  }
                                );
                              } else if (
                                this.state.sortByDirection == "ascending"
                              ) {
                                dir = "descending";
                              }
                            }

                            this.setState(
                              {
                                sortByOption: "priority",
                                sortByDirection: dir,
                                orderBy: {
                                  priority: dir == "descending" ? -1 : 1,
                                },
                              },
                              () => {
                                this.loadTasks(
                                  1,
                                  this.state.count,
                                  this.state.filters,
                                  this.state.orderBy
                                );
                              }
                            );
                          }}
                        >
                          Priority
                          {this.state.sortByOption == "priority" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            let dir = "ascending";

                            if (this.state.sortByOption == "status") {
                              if (this.state.sortByDirection == "descending") {
                                return this.setState(
                                  {
                                    sortByOption: "",
                                    sortByDirection: "",
                                    orderBy: null,
                                  },
                                  () => {
                                    this.loadTasks(
                                      1,
                                      this.state.count,
                                      this.state.filters,
                                      this.state.orderBy
                                    );
                                  }
                                );
                              } else if (
                                this.state.sortByDirection == "ascending"
                              ) {
                                dir = "descending";
                              }
                            }

                            this.setState(
                              {
                                sortByOption: "status",
                                sortByDirection: dir,
                                orderBy: {
                                  status: dir == "descending" ? -1 : 1,
                                },
                              },
                              () => {
                                this.loadTasks(
                                  1,
                                  this.state.count,
                                  this.state.filters,
                                  this.state.orderBy
                                );
                              }
                            );
                          }}
                        >
                          Status
                          {this.state.sortByOption == "status" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            let dir = "ascending";

                            if (this.state.sortByOption == "createdAt") {
                              if (this.state.sortByDirection == "descending") {
                                return this.setState(
                                  {
                                    sortByOption: "",
                                    sortByDirection: "",
                                    orderBy: null,
                                  },
                                  () => {
                                    this.loadTasks(
                                      1,
                                      this.state.count,
                                      this.state.filters,
                                      this.state.orderBy
                                    );
                                  }
                                );
                              } else if (
                                this.state.sortByDirection == "ascending"
                              ) {
                                dir = "descending";
                              }
                            }

                            this.setState(
                              {
                                sortByOption: "createdAt",
                                sortByDirection: dir,
                                orderBy: {
                                  createdAt: dir == "descending" ? -1 : 1,
                                },
                              },
                              () => {
                                this.loadTasks(
                                  1,
                                  this.state.count,
                                  this.state.filters,
                                  this.state.orderBy
                                );
                              }
                            );
                          }}
                        >
                          Created
                          {this.state.sortByOption == "createdAt" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingTasks || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultTasks?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No tasks found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultTasks?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.searchResultTasks.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.setState({
                                detailTask: c,
                                detailOpen: true,
                              });
                            }}
                          >
                            <Row className="align-items-center">
                              <Col xs="6" sm="6" md="4">
                                <h4 className="mb-0">{c.subject}</h4>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.type}
                                  {c?.customer
                                    ? ` For ${c?.customer?.name}`
                                    : ""}
                                  {c?.lead ? ` For ${c?.lead?.name}` : ""}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {this.formatDueDate(c.dueDate, c.status)}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {c.priority == "High" ? (
                                  <i className="mdi mdi-alert-boxtext-danger pr-1"></i>
                                ) : null}
                                {c.priority}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {this.formatStatus(c.status)}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {moment(c?.createdAt).format("MMM DD, YYYY")}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.tasks?.length &&
                        !this.state.loadingTasks ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>Get started by adding your first task.</Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.tasks?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.tasks.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.setState({
                                detailTask: c,
                                detailOpen: true,
                              });
                            }}
                          >
                            <Row className="align-items-center">
                              <Col xs="6" sm="6" md="3">
                                <h4 className="mb-0">{c.subject}</h4>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.type}
                                  {c?.customer
                                    ? ` for ${c?.customer?.name}`
                                    : ""}
                                  {c?.lead?.name ? ` for ${c?.lead?.name}` : ""}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {this.formatDueDate(c.dueDate, c.status)}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {c.priority == "High" ? (
                                  <i className="mdi mdi-alert-box text-danger pr-1"></i>
                                ) : null}
                                {c.priority}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {this.formatStatus(c.status)}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {moment(c?.createdAt).format("MMM DD, YYYY")}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <TaskCreateDrawer
          store={this.state.store}
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.TASK.CREATED, c);
          }}
        ></TaskCreateDrawer>
        <TaskDetailDrawer
          store={this.state.store}
          open={this.state.detailOpen}
          task={this.state.detailTask}
          onClose={() => {
            this.setState({
              detailOpen: false,
              detailTask: null,
            });
          }}
        ></TaskDetailDrawer>
      </>
    );
  }
  f;
}

export default StorefrontTaskListView;
