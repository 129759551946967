import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import OneSignal from "react-onesignal";

import "antd/dist/antd.css";
import "rsuite/dist/rsuite.min.css";
import "@tremor/react/dist/esm/tremor.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import ReportLayout from "layouts/Reports.js";
import AuthLayout from "layouts/Auth.js";
import StorefrontLayout from "layouts/Storefront.js";
import ProductionLayout from "layouts/Production.js";
import StorefrontPrintLayout from "layouts/StorefrontPrint.js";
import API from "lib/API";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import APIV2 from "lib/APIV2";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import DataAPI from "lib/DataAPI";
import University from "layouts/University";
import Marketing from "layouts/Marketing";
import FranchiseDevelopment from "layouts/FranchiseDevelopment";
import Account from "layouts/Account";
import { Keyboard } from "@capacitor/keyboard";

let oneSignalConfig = {};

if (window.location.href.indexOf(Constant.ENV_HOST.DEV) >= 0) {
  API.setEnvironment(API.ENV_DEV);
  APIV2.setEnvironment(APIV2.ENV_DEV);
  DataAPI.setEnvironment(DataAPI.ENV_DEV);
  DataAPI.setAuthToken(DataAPI.TOKEN_DEV);
  oneSignalConfig = {
    appId: "df2081f6-737f-446b-8ffd-f113d0048408",
    safari_web_id: "web.onesignal.auto.2068edc0-2ec7-4d8d-bc37-83913e3acbff",

    allowLocalhostAsSecureOrigin: true,
  };
} else if (window.location.href.indexOf(Constant.ENV_HOST.STAGING) >= 0) {
  API.setEnvironment(API.ENV_STAGING);
  APIV2.setEnvironment(APIV2.ENV_STAGING);
  DataAPI.setEnvironment(DataAPI.ENV_STAGING);
  DataAPI.setAuthToken(DataAPI.TOKEN_STAGING);
  oneSignalConfig = {
    appId: "75f17a3a-8644-4493-99ee-76f0cd80b288",
    safari_web_id: "web.onesignal.auto.06bdf785-23ca-464d-875b-535e8684e36a",
  };
} else {
  API.setEnvironment(API.ENV_PRODUCTION);
  APIV2.setEnvironment(APIV2.ENV_PRODUCTION);
  DataAPI.setEnvironment(DataAPI.ENV_PRODUCTION);
  DataAPI.setAuthToken(DataAPI.TOKEN_PRODUCTION);
  oneSignalConfig = {
    appId: "75f17a3a-8644-4493-99ee-76f0cd80b288",
    safari_web_id: "web.onesignal.auto.06bdf785-23ca-464d-875b-535e8684e36a",
  };
}

if (Capacitor.isNativePlatform()) {
  API.setEnvironment(API.ENV_PRODUCTION);
  APIV2.setEnvironment(APIV2.ENV_PRODUCTION);
  DataAPI.setEnvironment(DataAPI.ENV_PRODUCTION);

  oneSignalConfig = {
    appId: "75f17a3a-8644-4493-99ee-76f0cd80b288",
    safari_web_id: "web.onesignal.auto.06bdf785-23ca-464d-875b-535e8684e36a",
  };

  SplashScreen.hide();
}

let isGlobal = () => {
  let user = LocalStorage.get(Constant.CACHE.USER);

  if (!user) {
    return false;
  }

  return user?.globalPermissions?.length ? true : false;
};

let isProduction = () => {
  let user = LocalStorage.get(Constant.CACHE.USER);

  if (!user) {
    return false;
  }

  return user?.productionPermissions?.length ? true : false;
};

let defaultStore = () => {
  let user = LocalStorage.get(Constant.CACHE.USER);

  if (!user) {
    return "";
  }

  return user?.storePermissions?.length
    ? `/${user?.storePermissions[0]?.storeID}`
    : "";
};

let url = new URL(window.location.href); // or construct from window.location

let params = new URLSearchParams(url.search.slice(1));

if (params.get("authToken")) {
  localStorage.setItem(Constant.CACHE.TOKEN, params.get("authToken"));
}

// Handle OneSignal registration depending on platform
if (Capacitor.isNativePlatform()) {
  Keyboard.getResizeMode().then((data) => {
    console.log(data);
  });

  Keyboard.addListener("keyboardWillShow", (info) => {
    console.log(info.keyboardHeight);

    if (!document.body.className?.includes("keyboard-open")) {
      document.body.className += " keyboard-open";
    }

    let conversationMessageActions = document.getElementsByClassName(
      "conversation-content-actions"
    );

    /*if (conversationMessageActions?.length) {
      conversationMessageActions[0].style.bottom = `${info?.keyboardHeight}px`;
    }*/
  });

  Keyboard.addListener("keyboardDidShow", (info) => {
    console.log(info.keyboardHeight);

    if (!document.body.className?.includes("keyboard-open")) {
      document.body.className += " keyboard-open";
    }

    let conversationMessageActions = document.getElementsByClassName(
      "conversation-content-actions"
    );

    /*if (conversationMessageActions?.length) {
      conversationMessageActions[0].style.bottom = `0px`;
    }*/
  });

  Keyboard.setAccessoryBarVisible({ isVisible: true });

  Keyboard.addListener("keyboardWillHide", () => {
    document.body.className = document.body.className?.replace(
      /keyboard-open/,
      ""
    );

    let conversationMessageActions = document.getElementsByClassName(
      "conversation-content-actions"
    );

    /*if (conversationMessageActions?.length) {
      conversationMessageActions[0].style.bottom = `0px`;
    }*/
  });

  Keyboard.addListener("keyboardDidHide", () => {
    document.body.className = document.body.className?.replace(
      /keyboard-open/,
      ""
    );

    let conversationMessageActions = document.getElementsByClassName(
      "conversation-content-actions"
    );

    /*if (conversationMessageActions?.length) {
      conversationMessageActions[0].style.bottom = `0px`;
    }*/
  });
} else {
  OneSignal.init(oneSignalConfig).then(() => {
    window.ONESIGNAL_INITIALIZED = true;
  });
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/global" render={(props) => <AdminLayout {...props} />} />
      <Route
        path="/production"
        render={(props) => <ProductionLayout {...props} />}
      />
      <Route path="/university" render={(props) => <University {...props} />} />
      <Route path="/marketing" render={(props) => <Marketing {...props} />} />
      <Route
        path="/frandev"
        render={(props) => <FranchiseDevelopment {...props} />}
      />
      <Route path="/account" render={(props) => <Account {...props} />} />
      <Route path="/reports" render={(props) => <ReportLayout {...props} />} />
      <Route
        path="/storefront/:storeID"
        render={(props) => <StorefrontLayout {...props} />}
      />
      <Route
        path="/storefront-print/:storeID"
        render={(props) => <StorefrontPrintLayout {...props} />}
      />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      {LocalStorage.get(Constant.CACHE.TOKEN) ? (
        <>
          {isGlobal() ? (
            <Redirect from="/" to="/frandev" />
          ) : (
            <>
              {isProduction() ? (
                <Redirect from="/" to={`/production`} />
              ) : (
                <Redirect from="/" to={`/storefront${defaultStore()}`} />
              )}
            </>
          )}
        </>
      ) : (
        <Redirect from="/" to="/auth" />
      )}

      <Redirect from="/storefront" to={`/storefront${defaultStore()}`} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
