import { nodeName } from "dom-lib";
import HapticUtils from "lib/HapticUtils";
import React from "react";
import { withRouter } from "react-router";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
} from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import Spinner from "reactstrap/lib/Spinner";

class CardSelectorHeader extends React.Component {
  state = {
    active: "",
  };

  handleClick(opt) {
    this.setState({
      active: opt.id,
    });

    HapticUtils.navigationEvent();

    if (this.props.onChange && typeof this.props.onChange == "function") {
      this.props.onChange(opt);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && prevProps.value?.id != this.props.value?.id) {
      this.handleClick(this.props.value);
    }
  }

  componentDidMount() {
    if (this.props.value && this.state.active != this.props.value?.id) {
      this.handleClick(this.props.value);
    }
  }

  render() {
    return (
      <>
        <CardHeader
          className={`px-2 pb-0 ${this.props.compact ? "pt-0" : "pt-2"}`}
          style={{
            overflowX: "scroll",
            whiteSpace: "nowrap",
            overflowY: "hidden",
            position: this.props.sticky ? "sticky" : "",
            top: this.props.sticky ? "81px" : "",
          }}
        >
          {this.props.loading ? (
            <>
              <Button
                disabled
                color=""
                className={`btn btn-selector ${
                  this.props.compact ? "" : "pb-3"
                }`}
              >
                <Spinner color="dark" size="sm"></Spinner>
              </Button>
            </>
          ) : (
            <>
              {this.props.options &&
                this.props.options.map((option, i) => (
                  <Button
                    key={i}
                    onClick={() => {
                      this.handleClick(option);
                    }}
                    color=""
                    className={`btn btn-selector ${
                      this.props.compact ? "" : "pb-3"
                    } ${this.state.active == option.id ? "active" : ""}`}
                  >
                    {option.name}
                    {this.props.showCount ? (
                      <>
                        <div
                          style={{
                            lineHeight: 1.25,
                            fontSize: 13,
                            position: "relative",
                            top: -1,
                          }}
                          className={`${
                            this.state.active == option.id
                              ? "bg-primary-superlight border-primary-light"
                              : "bg-superlight border-light"
                          }  d-inline-block rounded ml-1 px-2 border `}
                        >
                          {option?.count ? option?.count : 0}
                        </div>
                      </>
                    ) : null}
                  </Button>
                ))}
            </>
          )}
        </CardHeader>
      </>
    );
  }
}

export default withRouter(CardSelectorHeader);
