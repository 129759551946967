import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { DatePicker, SelectPicker } from "rsuite";
import moment from "moment";

class CustomerCouponWidget extends React.Component {
  state = {
    nextBillDate: null,
  };

  componentDidMount() {
    this.loadCoupons();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  setCoupon(couponID) {
    this.setState({
      submitting: true,
    });

    APIV2.updateCustomerCoupon(
      this.props.customer.storeID,
      this.props.customer._id,
      couponID
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({
            modify: false,
            plan: null,
          });
        },
        (err) => {
          this.setError(
            "error",
            "Unable to update customer's coupon. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  getDiscountValue(c) {
    return StringUtils.couponValueString(c);
  }

  getUsage(c) {
    if (c?.couponUsage == "tiered") {
      return "Tiered Usage";
    }

    if (c?.couponUsage == "limited") {
      return "Limited Usage";
    }

    if (c?.couponUsage == "onetime") {
      return "One-Time Usage";
    }

    return "Recurring Usage";
  }

  loadCoupons() {
    APIV2.getCoupons().then(
      (data) => {
        let coupData = data.data?.coupons?.map((c) => {
          let discountValue = this.getDiscountValue(c);

          let label = (
            <>
              <p className="m-0 font-weight-bold">{c.name}</p>
              <p className="m-0" style={{ lineHeight: 1.3 }}>
                <small>{discountValue}</small>
              </p>
            </>
          );

          return {
            label: label,
            value: c._id,
            usage: this.getUsage(c),
          };
        });

        this.setState({ coupons: coupData });
      },
      (e) => {}
    );
  }

  render() {
    return (
      <>
        <Card
          style={{ height: "100%" }}
          className={`${this.props.flat ? "border-0" : "border shadow"}`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Coupon</h3>
              </div>
              <Col xs="auto">
                {this.state.modify ? (
                  <>
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modify: false,
                          coupon: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      disabled={!this.state.coupon || this.state.submitting}
                      onClick={() => {
                        this.setCoupon(this.state.coupon);
                      }}
                    >
                      {this.state.submitting ? (
                        <Spinner color="white" size="sm" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    {this.props.customer?.subscription?.coupon ? (
                      <>
                        <Button
                          size="sm"
                          color="secondary"
                          outline
                          onClick={() => {
                            this.setState({
                              modify: true,
                              coupon:
                                this.props.customer?.subscription?.couponID,
                            });
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          color="danger"
                          outline
                          onClick={() => {
                            this.setCoupon("");
                          }}
                        >
                          Remove
                        </Button>
                      </>
                    ) : (
                      <Button
                        size="sm"
                        color="secondary"
                        outline
                        onClick={() => {
                          this.setState({
                            modify: true,
                            coupon: this.props.customer?.subscription?.couponID,
                          });
                        }}
                      >
                        Add
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="align-items-center">
              {!this.state.modify ? (
                <Col xs="" className="">
                  {this.props.customer?.subscription?.coupon ? (
                    <>
                      <h3 className="mb-0">
                        {this.props.customer?.subscription?.coupon.code}
                      </h3>
                      <p className="mb-0">
                        {this.getDiscountValue(
                          this.props.customer?.subscription?.coupon
                        )}
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>No Coupon Applied</h3>
                    </>
                  )}
                </Col>
              ) : (
                <Col xs="" className="">
                  <SelectPicker
                    placeholder="Select Coupon"
                    placement="auto"
                    data={this.state.coupons}
                    value={this.state.coupon}
                    groupBy="usage"
                    block
                    creatable
                    onChange={(value, item) => {
                      this.setState({ coupon: value });
                    }}
                  ></SelectPicker>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerCouponWidget);
