import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import CustomerOrderDrawer from "./CustomerOrderDrawer";
import CustomerInvoiceDrawer from "./CustomerInvoiceDrawer";

class CustomerAllInvoicesDrawer extends React.Component {
  state = {
    fulfillmentDate: moment().day(6).toDate(),
    order: [],
    loading: false,
  };

  openInvoiceDrawer(invoice) {
    this.setState({
      openInvoice: invoice,
      invoiceDrawer: true,
    });
  }

  getAllInvoices(customer) {
    this.setState({
      loading: true,
    });

    let query = {
      "customer.id": customer?._id,
    };

    let sortBy = { dueOn: -1 };

    APIV2.getInvoicesForStore(
      this.props?.match?.params?.storeID,
      1,
      10000,
      query,
      sortBy
    )
      .then(
        (data) => {
          this.setState({ invoices: data?.data?.invoices ?? [] });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.customer != this.props.customer && this.props.customer) {
      this.getAllInvoices(this.props.customer);
    }
  }

  componentDidMount() {
    if (this.props.customer) {
      this.getAllInvoices(this.props.customer);
    }

    PubSub.subscribe(Event.INVOICE.CREATED, () => {
      this.getAllInvoices(this.props.customer);
    });

    PubSub.subscribe(Event.INVOICE.MODIFIED, (order) => {
      if (this.state?.invoices?.length) {
        let idx = _.findIndex(this.state.invoices, { _id: order?.id });

        if (idx >= 0) {
          let od = this.state.invoices;
          od[idx] = order;

          this.setState({
            orders: od,
          });
        }
      }
    });
  }

  invoiceRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="8" sm="6" md="4">
          <h4 className="mb-0">{c.name}</h4>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {c.customer?.name ? c.customer?.name : "--"}
          </p>
        </Col>
        <Col xs="4" sm="6" md="2" className="text-right text-sm-left">
          {c.status == "PAID" ? (
            <>
              <Badge color="success">Paid</Badge>
            </>
          ) : null}
          {c.status == "UNPAID" ? (
            <>
              <Badge color="" className="bg-yellow text-dark">
                UNPAID
              </Badge>
            </>
          ) : null}
          {c.status != "PAID" && c.status != "UNPAID" && (
            <>
              <Badge color="medium">{c.status}</Badge>
            </>
          )}
        </Col>
        <Col xs="6" sm="6" md="2">
          <div style={{ fontSize: "15px" }}>
            {(c.dueOnString && c.status == "PENDING") ||
            c.status == "UNPAID" ||
            c.status == "PAID" ? (
              <>
                <span className="d-inline-block d-md-none">Due:&nbsp;</span>
                {moment(c.dueOn ?? c.dueOnString).format("MM/DD/YYYY")}
              </>
            ) : (
              <>--</>
            )}
          </div>
        </Col>
        <Col xs="6" sm="6" md="3" className="d-none d-sm-flex">
          <div style={{ fontSize: "15px" }}>
            {c.createdAt ? (
              <>{moment(c.createdAt).format("MM/DD/YYYY hh:mm A")}</>
            ) : (
              <>--</>
            )}
          </div>
        </Col>
      </Row>
    );
  }

  toggleModal() {
    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              All Invoices
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            {this.state?.loading && (
              <div className="px-3 py-5 text-center">
                <Spinner size="sm" color="dark"></Spinner>
              </div>
            )}
            {!this.state?.invoices?.length && !this.state.loading && (
              <div className="px-3 py-5">
                <Row>
                  <Col xs="12">
                    <h3 className="m-0">No invoices available.</h3>
                    <p>
                      {this.props?.customer?.name
                        ? StringUtils.getFirstName(this.props?.customer?.name)
                        : "This customer"}{" "}
                      does not have any invoices.
                    </p>
                  </Col>
                </Row>
              </div>
            )}

            {this.state?.invoices?.length > 0 && (
              <div className="px-4 py-2 bg-superlight border-bottom">
                <Row>
                  <Col xs="12" sm="6" md="4">
                    <h4 className="m-0">Invoice</h4>
                  </Col>
                  <Col xs="12" sm="6" md="2" className="d-none d-sm-flex">
                    <h4 className="m-0">Status</h4>
                  </Col>
                  <Col xs="12" sm="6" md="2" className="d-none d-sm-flex">
                    <h4 className="m-0">Due On</h4>
                  </Col>
                  <Col xs="12" sm="6" md="3" className="d-none d-sm-flex">
                    <h4 className="m-0">Created At</h4>
                  </Col>
                </Row>
              </div>
            )}
            {this.state?.invoices?.map((invoice, i) => (
              <div key={invoice?._id}>
                <div
                  className="px-4 py-3 cursor-pointer bg-superlight--hover"
                  onClick={() => {
                    this.openInvoiceDrawer(invoice);
                  }}
                >
                  {this.invoiceRow(invoice)}
                </div>
                {i != this.state?.invoices?.length - 1 && (
                  <hr className="m-0"></hr>
                )}
              </div>
            ))}
          </Drawer.Body>
        </Drawer>
        <CustomerInvoiceDrawer
          invoice={this.state.openInvoice}
          open={this.state.invoiceDrawer}
          onClose={() => {
            this.setState({ invoiceDrawer: false });
          }}
        ></CustomerInvoiceDrawer>
      </>
    );
  }
}

export default withRouter(CustomerAllInvoicesDrawer);
