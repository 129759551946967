import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Block,
  Card,
  ColGrid,
  Col as TCol,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import NewLeadDetailChartCard from "./NewLeadDetailChartCard";

import NewLeadByStorePieChartCard from "./NewLeadByStorePieChartCard";
import NewMemberDetailChartCard from "./NewMemberDetailChartCard";
import NewMemberByStorePieChartCard from "./NewMemberByStorePieChartCard";
import MemberTypePieChartCard from "./MemberTypePieChartCard";
import MemberReferralPieChartCard from "./MemberReferralPieChartCard";

class ScorecardNewMemberDetailDrawer extends React.Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (this.props.open != prevProps.open && this.props.open) {
      this.setState({
        reloadCards: true,
      });
    }
  }

  componentDidMount() {
    if (this.props.open) {
      this.setState({
        reloadCards: true,
      });
    }
  }

  hasValidDateRange() {
    if (
      this.props?.dateRange?.length != 2 ||
      !this.props?.dateRange[0] ||
      !this.props?.dateRange[1]
    ) {
      return false;
    }

    return true;
  }

  renderDateRange() {
    if (!this.hasValidDateRange()) {
      return "Select Date Range";
    }

    let startMoment = moment(this.props.dateRange[0]?.toISOString());
    let endMoment = moment(this.props.dateRange[1]?.toISOString());

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  renderPreviousDateRange() {
    if (!this.hasValidDateRange()) {
      return "--";
    }

    let secondDifference =
      this.props.dateRange[1].getTime() - this.props.dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(this.props.dateRange[0].toISOString());
    let endMoment = moment(this.props.dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    if (startMoment?.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      return `${startMoment?.format("MMM D, YYYY")}`;
    }

    return `${startMoment?.format("MMM D, YYYY")} - ${endMoment?.format(
      "MMM D, YYYY"
    )}`;
  }

  render() {
    return (
      <>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              New Members
            </h3>
            <Drawer.Actions>
              <p
                className="small m-0 text-dark"
                style={{ position: "relative", top: "8px", lineHeight: 1.2 }}
              >
                {this.props.stores?.length} Store
                {this.props.stores?.length != 1 && "s"}
              </p>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <div className="mb-3">
              <h1 className="m-0">{this.renderDateRange()}</h1>
              <p className="m-0 text-muted">
                Compared to {this.renderPreviousDateRange()}
              </p>
            </div>
            <NewMemberDetailChartCard
              stores={this.props.stores}
              reload={this.state.reloadCards}
              dateRange={this.props.dateRange}
              onLoaded={() => {
                this.setState({
                  reloadCards: false,
                });
              }}
            ></NewMemberDetailChartCard>
            <div className="my-4"></div>
            <NewMemberByStorePieChartCard
              stores={this.props.stores}
              reload={this.state.reloadCards}
              dateRange={this.props.dateRange}
              onLoaded={() => {
                this.setState({
                  reloadCards: false,
                });
              }}
            ></NewMemberByStorePieChartCard>
            <div className="my-4"></div>
            <MemberTypePieChartCard
              stores={this.props.stores}
              reload={this.state.reloadCards}
              dateRange={this.props.dateRange}
              onLoaded={() => {
                this.setState({
                  reloadCards: false,
                });
              }}
            ></MemberTypePieChartCard>
            <div className="my-4"></div>
            <MemberReferralPieChartCard
              stores={this.props.stores}
              reload={this.state.reloadCards}
              dateRange={this.props.dateRange}
              onLoaded={() => {
                this.setState({
                  reloadCards: false,
                });
              }}
            ></MemberReferralPieChartCard>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default ScorecardNewMemberDetailDrawer;
