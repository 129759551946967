import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";

class SignupLinkCreatorView extends React.Component {
  state = {
    promoCode: "",
    leadSource: "",
    loading: true,
  };

  componentDidMount() {}

  create(e) {
    e.preventDefault();

    let link = "https://account.projectleannation.com/start";

    if (this.state.promoCode) {
      link += `?promo=${this.state.promoCode}`;

      if (this.state.leadSource) {
        link += `&lead_source=${this.state.leadSource}`;
      }
    } else if (this.state.leadSource) {
      link += `?lead_source=${this.state.leadSource}`;
    }

    this.setState({
      link: link,
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardHeader className=" bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Create Signup Link</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.link ? (
                    <>
                      <div className="bg-light">
                        <pre className="text-dark p-3">{this.state.link}</pre>
                      </div>
                      <Button
                        color="secondary"
                        className="mt-3"
                        onClick={() => {
                          this.setState({
                            link: "",
                            promoCode: "",
                            leadSource: "",
                          });
                        }}
                      >
                        Create New Link
                      </Button>
                    </>
                  ) : (
                    <Form onSubmit={this.create.bind(this)}>
                      <FormGroup>
                        <h5>Promo Code</h5>
                        <Input
                          name="promoCode"
                          value={this.state.promoCode}
                          onChange={this.handleInputChange.bind(this)}
                          placeholder="Promo Code"
                          type="text"
                        ></Input>
                      </FormGroup>
                      <FormGroup>
                        <h5>Lead Source</h5>
                        <Input
                          name="leadSource"
                          value={this.state.leadSource}
                          onChange={this.handleInputChange.bind(this)}
                          placeholder="Lead Source"
                          type="text"
                        ></Input>
                      </FormGroup>
                      <Button color="primary" type="submit">
                        Create Link
                      </Button>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default SignupLinkCreatorView;
