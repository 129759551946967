import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import { isBefore } from "date-fns";

class NewConsultationDrawer extends React.Component {
  state = {
    consultationDate: moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .millisecond(0)
      .toDate(),
    coachID: null,
    notes: "",
    type: "nc-consult",
    types: [
      {
        label: Constant.APPOINTMENT_TYPES["nc-consult"].NAME,
        value: "nc-consult",
      },
      {
        label: Constant.APPOINTMENT_TYPES["ec-consult"].NAME,
        value: "ec-consult",
      },
    ],
    phone: {
      value: "",
    },
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.consultation != prevProps.consultation &&
      this.props.consultation
    ) {
      this.setState({
        name: this.props.consultation?.customer
          ? this.props.consultation?.customer?.name
          : this.props.consultation?.lead?.name,
        email: this.props.consultation?.email,
        phone: {
          value: this.props.consultation?.phone?.replace("+1", ""),
        },
        goal: this.props.consultation?.goal,
        notes: this.props.consultation?.additionalNotes,
        type: this.props.consultation?.appointmentType,
        consultationDate: moment(this.props.consultation?.startsAt)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        consultationTime: moment(this.props.consultation?.startsAt).format(
          "HH:mm"
        ),
      });
    }

    if (
      (this.props.lead != prevProps.lead ||
        this.props.open != prevProps.open) &&
      this.props.lead
    ) {
      this.setState({
        name: this.props.lead?.name,
        email: this.props.lead?.email,
        phone: {
          value: this.props.lead?.phone?.replace("+1", ""),
        },
        type: "nc-consult",
      });
    }

    if (
      (this.props.customer != prevProps.customer ||
        this.props.open != prevProps.open) &&
      this.props.customer
    ) {
      this.setState({
        name: this.props.customer?.name,
        email: this.props.customer?.email,
        phone: {
          value: this.props.customer?.phone?.replace("+1", ""),
        },
        type: "ec-consult",
      });
    }
  }

  componentDidMount() {
    if (this.props.consultation) {
      this.setState({
        name: this.props.consultation?.name,
        email: this.props.consultation?.email,
        phone: {
          value: this.props.consultation?.phone?.replace("+1", ""),
        },
        goal: this.props.consultation?.goal,
        notes: this.props.consultation?.additionalNotes,
        type: this.props.consultation?.appointmentType,
      });
    } else if (this.props.lead) {
      this.setState({
        name: this.props.lead?.name,
        email: this.props.lead?.email,
        phone: {
          value: this.props.lead?.phone?.replace("+1", ""),
        },
        type: "nc-consult",
      });
    } else if (this.props.customer) {
      this.setState({
        name: this.props.customer?.name,
        email: this.props.customer?.email,
        phone: {
          value: this.props.customer?.phone?.replace("+1", ""),
        },
        type: "ec-consult",
      });
    }
  }

  toggleModal() {
    this.setState({
      notes: "",
      consultationDate: moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
        .toDate(),
      type: "nc-consult",
      goal: "",
      name: "",
      email: "",
      phone: {
        value: "",
      },
      consultationTime: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    let startMoment = moment(this.state.consultationDate?.toISOString());
    let timeMoment = moment(this.state.consultationTime, "HH:mm");

    startMoment.hours(timeMoment.hours()).minutes(timeMoment.minutes());

    let dateTime = startMoment.toDate()?.toISOString();

    let payload = {
      appointmentType: this.state.type,
      dateTime,
      name: this.state.name,
      email: this.state.email,
      phone: "+1" + this.state.phone?.value,
      notes: this.state.notes ? this.state.notes : "",
      goal: this.state.goal ? this.state.goal : "",
    };

    APIV2.scheduleAppointment(this.props?.store?._id, payload)
      .then(
        (data) => {
          const appointment = data.data.appointment;

          this.toggleModal();

          PubSub.publish(Event.APPOINTMENT.CREATED, appointment);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              `Unable to schedule consultation - unknown error occurred. Try again.`
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({
      submitting: true,
    });

    if (this.props.mode == "reschedule") {
      let startMoment = moment(this.state.consultationDate?.toISOString());
      let timeMoment = moment(this.state.consultationTime, "HH:mm");

      startMoment.hours(timeMoment.hours()).minutes(timeMoment.minutes());

      let dateTime = startMoment.toDate()?.toISOString();

      APIV2.rescheduleAppointment(
        this.props.consultation?.storeID,
        this.props.consultation?._id,
        dateTime
      )
        .then(
          (data) => {
            const appointment = data.data.appointment;

            this.toggleModal();

            PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                `Unable to reschedule consultation - unknown error occurred. Try again.`
            );
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    } else {
      let payload = {
        email: this.state.email,
        phone: this.state.phone?.value,
        notes: this.state.notes ?? "",
        goal: this.state.goal ?? "",
      };

      APIV2.modifyAppointmentInformation(
        this.props.consultation?.storeID,
        this.props.consultation?._id,
        payload
      )
        .then(
          (data) => {
            const appointment = data.data.appointment;

            this.toggleModal();

            PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                `Unable to modify consultation - unknown error occurred. Try again.`
            );
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    }
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  getHourlyTimeslots(date) {
    if (!date) {
      return [];
    }

    let day = date.getDay();

    if (day == 0) {
      day = "Sunday";
    } else if (day == 1) {
      day = "Monday";
    } else if (day == 2) {
      day = "Tuesday";
    } else if (day == 3) {
      day = "Wednesday";
    } else if (day == 4) {
      day = "Thursday";
    } else if (day == 5) {
      day = "Friday";
    } else if (day == 6) {
      day = "Saturday";
    }

    if (!this.props.store?.operations?.hours?.standard?.length) {
      return [];
    }

    let hours = _.findWhere(this.props.store?.operations?.hours?.standard, {
      day,
    });

    if (!hours || hours?.closed || !hours.opensAt || !hours.closesAt) {
      return [];
    }

    let opensAtInt = parseInt(hours.opensAt?.replace(":")) * 60;
    let closesAtInt = parseInt(hours.closesAt?.replace(":")) * 60;

    let now = moment();

    let chosenDate = moment(date?.toISOString());

    let opensAt = moment(hours?.opensAt, "HH:mm");

    if (chosenDate?.format("YYYY-MM-DD") == now?.format("YYYY-MM-DD")) {
      if (now?.toDate() <= opensAt?.toDate()) {
        opensAtInt = moment(hours?.opensAt, "HH:mm").add(30, "minutes");
      } else {
        if (now.minutes() >= 20) {
          now = now
            .hour(now.hour() + 1)
            .minutes(0)
            .seconds(0);
        } else {
          now = now.minutes(30).seconds(0);
        }

        opensAtInt = now;
      }
    } else {
      opensAtInt = moment(hours?.opensAt, "HH:mm").add(30, "minutes");
    }

    closesAtInt = moment(hours?.closesAt, "HH:mm").subtract(60, "minutes");

    let out = [];

    let counter = moment(opensAtInt.toISOString());

    while (counter.toDate() <= closesAtInt.toDate()) {
      out.push({
        label: counter.format("h:mm A"),
        value: counter.format("HH:mm"),
      });

      counter.add(30, "minutes");
    }

    return out;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.consultation ? (
                <>{this.props.mode == "reschedule" ? "Reschedule" : "Modify"}</>
              ) : (
                "Schedule"
              )}{" "}
              Consultation
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  (!this.props.consultation &&
                    (!this.state.consultationDate ||
                      !this.state.consultationTime ||
                      !this.state.name ||
                      !this.state.email ||
                      !this.state.phone?.value ||
                      !this.state.type)) ||
                  (this.props.mode == "reschedule" &&
                    (!this.state.consultationDate ||
                      !this.state.consultationTime)) ||
                  (this.props.mode == "information" &&
                    (!this.state.name ||
                      !this.state.email ||
                      !this.state.phone?.value))
                }
                color="primary"
                onClick={() => {
                  this.props.consultation ? this.modify() : this.create();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.consultation ? "Save" : "Schedule"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {!this.props.consultation ? (
              <FormGroup>
                <h5>
                  Consultation Type
                  <i
                    className="mdi mdi-octagram text-danger pl-1"
                    style={{ fontSize: "80%", position: "relative", top: -1 }}
                  ></i>
                </h5>
                <SelectPicker
                  searchable={false}
                  oneTap
                  data={this.state.types}
                  value={this.state.type}
                  block
                  placeholder="Select Type"
                  onChange={(v) => {
                    this.setState({ type: v });
                  }}
                  placement="autoVerticalStart"
                ></SelectPicker>
              </FormGroup>
            ) : null}
            {this.props.consultation && this.props.mode == "reschedule" ? (
              <>
                <FormGroup>
                  <h5>
                    Consultation Date
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <DatePicker
                    required
                    block
                    oneTap
                    placeholder="Select A Date"
                    disabledDate={(date) => {
                      return (
                        moment(date?.toISOString())
                          .hour(0)
                          .minute(0)
                          .second(0)
                          .millisecond(0)
                          .toDate() <
                        moment()
                          .hour(0)
                          .minute(0)
                          .second(0)
                          .millisecond(0)
                          .toDate()
                      );
                    }}
                    value={this.state.consultationDate}
                    format="MM/dd/yy"
                    placement="autoVerticalStart"
                    onChange={(e) => {
                      this.setState({
                        consultationDate: e,
                        consultationTime: null,
                      });
                    }}
                    ranges={[]}
                  ></DatePicker>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Appointment Time
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <SelectPicker
                    required
                    block
                    oneTap
                    placeholder="Select A Time Slot"
                    searchable={true}
                    value={this.state.consultationTime}
                    data={this.getHourlyTimeslots(this.state.consultationDate)}
                    placement="autoVerticalStart"
                    onChange={(e) => {
                      console.log(e);

                      this.setState({ consultationTime: e });
                    }}
                  ></SelectPicker>
                </FormGroup>
              </>
            ) : (
              <>
                {!this.props.consultation ? (
                  <>
                    <FormGroup>
                      <h5>
                        Consultation Date
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                      <DatePicker
                        required
                        block
                        oneTap
                        placeholder="Select A Date"
                        disabledDate={(date) => {
                          return (
                            moment(date?.toISOString())
                              .hour(0)
                              .minute(0)
                              .second(0)
                              .millisecond(0)
                              .toDate() <
                            moment()
                              .hour(0)
                              .minute(0)
                              .second(0)
                              .millisecond(0)
                              .toDate()
                          );
                        }}
                        value={this.state.consultationDate}
                        format="MM/dd/yy"
                        placement="autoVerticalStart"
                        onChange={(e) => {
                          this.setState({
                            consultationDate: e,
                            consultationTime: null,
                          });
                        }}
                        ranges={[]}
                      ></DatePicker>
                    </FormGroup>
                    <FormGroup>
                      <h5>
                        Appointment Time
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                      <SelectPicker
                        required
                        block
                        oneTap
                        placeholder="Select A Time Slot"
                        searchable={true}
                        value={this.state.consultationTime}
                        data={this.getHourlyTimeslots(
                          this.state.consultationDate
                        )}
                        placement="autoVerticalStart"
                        onChange={(e) => {
                          console.log(e);

                          this.setState({ consultationTime: e });
                        }}
                      ></SelectPicker>
                    </FormGroup>
                  </>
                ) : null}
                <FormGroup>
                  <h5>
                    Name
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    type="text"
                    name="name"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.name}
                    placeholder="Name"
                    bsSize="sm"
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Email
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    type="email"
                    name="email"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.email}
                    placeholder="Email"
                    bsSize="sm"
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Phone
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <NumberFormat
                    className="form-control mb-3 form-control-sm"
                    format="(###) ###-####"
                    name="phoneNumberInput"
                    placeholder="Phone"
                    onValueChange={this.handlePhoneChange.bind(this)}
                    value={this.state.phone.value}
                    type="tel"
                    mask="_"
                  />
                </FormGroup>
                {this.state.type == "nc-consult" ? (
                  <FormGroup>
                    <h5>Goal</h5>
                    <SelectPicker
                      required
                      block
                      oneTap
                      searchable={false}
                      placeholder="Select A Goal"
                      value={this.state.goal}
                      data={Constant.SELECT_PICKER.CLIENT_GOALS}
                      placement="autoVerticalStart"
                      onChange={(e) => {
                        console.log(e);

                        this.setState({ goal: e });
                      }}
                    ></SelectPicker>
                  </FormGroup>
                ) : null}
                <FormGroup>
                  <h5>Additional Notes Shared By Member</h5>
                  <Input
                    type="textarea"
                    name="notes"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.notes}
                    placeholder="Notes"
                    bsSize="sm"
                  ></Input>
                </FormGroup>
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(NewConsultationDrawer);
