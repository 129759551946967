import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Uploader,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class TechSupportTicketDrawer extends React.Component {
  state = {
    dueDate: moment().add(1, "days").toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    supportType: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    store: "",
  };

  componentDidUpdate(prevProps) {
    if (!this.props.storeMode && this.props.storeMode != prevProps.storeMode) {
      this.getAllStores();
      this.getAllUsers();
    }

    if (prevProps.customer != this.props.customer && this.props.customer) {
      this.setState({
        relatedToType: "Member",
        relatedCustomer: this.props.customer,
      });
    }

    if (prevProps.lead != this.props.lead && this.props.lead) {
      this.setState({
        relatedToType: "Lead",
        relatedCustomer: this.props.lead,
      });
    }

    if (prevProps.affiliate != this.props.affiliate && this.props.affiliate) {
      this.setState({
        relatedToType: "Affiliate",
        relatedCustomer: this.props.affiliate,
      });
    }

    if (this.props.ticket != prevProps.ticket && this.props.ticket) {
      console.log(this.props.ticket);

      this.loadTicket();
    }
  }

  componentDidMount() {
    if (!this.props.storeMode) {
      this.getAllStores();
      this.getAllUsers();
    }

    if (this.props.ticket) {
      this.loadTicket();
    }

    if (this.props.customer) {
      this.setState({
        relatedToType: "Member",
        relatedCustomer: this.props.customer,
      });
    }

    if (this.props.lead) {
      this.setState({
        relatedToType: "Lead",
        relatedCustomer: this.props.lead,
      });
    }

    if (this.props.affiliate) {
      this.setState({
        relatedToType: "Affiliate",
        relatedCustomer: this.props.affiliate,
      });
    }
  }

  getAllStores() {
    APIV2.getStores().then((data) => {
      this.setState({ stores: data.data.stores });
    });
  }

  toggleModal() {
    this.setState({
      subject: "",
      description: "",
      dueDate: moment().add(1, "days").toDate(),
      type: "",
      priority: "",
      assignedTo: null,
      relatedToType: "",
      relatedCustomer: null,
      relatedLead: null,
      status: "",
      supportType: "",
      store: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  async uploadAttachments() {
    return new Promise(async (resolve) => {
      let attachments = [];

      for (let i = 0; i < this.state.files?.length; i++) {
        try {
          // Create an object of formData
          let formData = new FormData();

          formData.append("file", this.state.files[i].blobFile);

          let data = await APIV2.uploadPrivateFile(formData);

          let url = data.data.data.url;

          attachments.push({
            url,
            fileName: this.state.files[i]?.name,
          });
        } catch (e) {}
      }

      return resolve(attachments);
    });
  }

  loadTicket() {
    if (this.props?.ticket) {
      let relatedToType = "";
      let relatedCustomer = null;
      let relatedLead = null;

      if (this.props.ticket?.customer) {
        relatedToType = "Member";
        relatedCustomer = this.props.ticket?.customer;
      } else if (this.props.ticket?.lead) {
        relatedToType = "Lead";
        relatedCustomer = this.props.ticket?.lead;
      } else if (this.props.ticket?.affiliate) {
        relatedToType = "Affiliate";
        relatedCustomer = this.props.ticket?.affiliate;
      }

      this.setState({
        subject: this.props.ticket?.subject,
        description: this?.props?.ticket?.desiredSolution,
        dueDate: moment(this?.props?.ticket?.dueDate).toDate(),
        type: this.props.ticket?.type,
        priority: this?.props?.ticket?.priority,
        assignedTo: this.props?.ticket?.assignedTo?._id
          ? this.props?.ticket?.assignedTo?._id
          : null,
        relatedToType,
        relatedCustomer,
        relatedLead,
        status: this.props?.ticket?.status,
        supportType: this.props.ticket?.supportType,
        store: this.props.ticket?.storeID,
        submittedBy: this.props.ticket?.submittedBy,
      });
    }
  }

  async create() {
    this.setState({ submitting: true });

    let dueDate = moment(this.state.dueDate?.toISOString())
      .minutes(0)
      .hours(0)
      .seconds(0)
      .millisecond(0);

    let attachments = await this.uploadAttachments();

    let storeID = this.props.store ? this.props?.store?._id : this.state.store;

    APIV2.createTechSupportTicket(
      storeID,
      this.state.subject,
      this.state.supportType,
      this.state.description,
      attachments,
      this.state.relatedCustomer && this.state.relatedToType == "Member"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Lead"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Affiliate"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.assignedTo,
      dueDate.toDate().toISOString(),
      this.state.priority,
      this.state.submittedBy
    )
      .then(
        (data) => {
          const task = data.data.ticket;

          this.toggleModal();

          PubSub.publish(Event.TASK.CREATED, task);

          this.setState({
            dueDate: moment().add(1, "days").toDate(),
            subject: "",
            description: "",
            relatedToType: "",
            supportType: "",
            priority: "Normal",
            status: "Not Started",
            assignedTo: null,
            timeToContact: "",
            customers: [],
            files: [],
          });

          if (typeof this.props?.onCreate == "function") {
            this.props.onCreate(task);
          }
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create tech support ticket - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  async modify() {
    this.setState({ submitting: true });

    let dueDate = moment(this.state.dueDate?.toISOString())
      .minutes(0)
      .hours(0)
      .seconds(0)
      .millisecond(0);

    //let attachments = await this.uploadAttachments();

    APIV2.modifyTechSupportTicket(
      this.state.store,
      this.props?.ticket?._id,
      this.state.subject,
      this.state.supportType,
      this.state.description,
      this.props.ticket?.attachments ?? [],
      this.state.relatedCustomer && this.state.relatedToType == "Member"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Lead"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Affiliate"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.assignedTo,
      dueDate.toDate().toISOString(),
      this.state.status,
      this.state.priority,
      this.state.submittedBy
    )
      .then(
        (data) => {
          console.log(data.data);

          const task = data.data.ticket;

          PubSub.publish(Event.TASK.MODIFIED, task);

          this.toggleModal();

          console.log("here 1");

          this.setState({
            dueDate: moment().add(1, "days").toDate(),
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify tech support ticket - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getAllStaff() {
    if (!this.props.store) {
      return;
    }

    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this?.props?.store?._id, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  getAllUsers() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getAllUsers()
      .then(
        (data) => {
          let coachOptions = data.data.users?.map((s) => {
            return {
              value: s._id,
              label: (
                <>
                  <p className="m-0" style={{ lineHeight: 1.2 }}>
                    {s.name}
                  </p>
                  <p className="m-0 small">{s.email}</p>
                </>
              ),
            };
          });

          this.setState({
            coaches: data.data.users,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch users.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  onFileChange(e) {
    let files = [];

    files = e;

    console.log(files);

    this.setState({ files: files });
  }

  performSearch() {
    console.log(this.state.search);

    let promise = null;

    let storeID = this?.props?.match?.params?.storeID;

    if (this.state.store && !storeID) {
      storeID = this.state.store;
    }

    if (this.state.relatedToType == "Member") {
      promise = APIV2.simpleSearchCustomersForStore(storeID, this.state.search);
    } else if (this.state.relatedToType == "Lead") {
      promise = APIV2.simpleSearchLeadsForStore(storeID, this.state.search);
    } else if (this.state.relatedToType == "Affiliate") {
      promise = APIV2.simpleSearchAffiliatesForStore(
        storeID,
        this.state.search
      );
    }

    promise
      .then(
        (data) => {
          if (this.state.relatedToType == "Member") {
            this.setState({
              customers: data.data.customers,
            });
          } else if (this.state.relatedToType == "Lead") {
            this.setState({
              customers: data.data.leads,
            });
          } else if (this.state.relatedToType == "Affiliate") {
            this.setState({
              customers: data.data.affiliates,
            });
          }
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Ticket
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.dueDate ||
                  !this.state.subject ||
                  !this.state.supportType ||
                  !this.state.submittedBy
                }
                color="primary"
                onClick={() => {
                  this.props.ticket ? this.modify() : this.create();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.ticket ? "Save" : "Create"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h4 className="mb-3">Ticket Information</h4>
            {!this.props.storeMode && (
              <>
                <FormGroup>
                  <h5>Store</h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="store"
                    placeholder="Store"
                    value={this.state.store}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="" disabled>
                      Select A Store
                    </option>
                    {this.state.stores?.map((store, i) => (
                      <option key={store?._id} value={store?._id}>
                        {store?.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </>
            )}
            <FormGroup>
              <h5>
                Ticket Submitted By
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="submittedBy"
                placeholder="Your Name"
                value={this.state.submittedBy}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Type Of Challenge
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="select"
                name="supportType"
                placeholder="Type"
                value={this.state.supportType}
                onChange={this.handleInputChange.bind(this)}
              >
                <option value="" disabled>
                  Select A Type
                </option>
                <option value="Billing">Billing</option>
                <option value="Fulfillment">Fulfillment</option>
                <option value="Member Signup/Management">
                  Member Signup/Management
                </option>
                <option value="Lead Management">Lead Management</option>
                <option value="Software Usability">Software Usability</option>
                <option value="Retail / Square POS">Retail / Square POS</option>
                <option value="Marketing">Marketing</option>
                <option value="Production">Production</option>
                <option value="Other">Other</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <h5>
                What Can We Assist With?
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="textarea"
                name="subject"
                placeholder="Challenge"
                value={this.state.subject}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Desired Solution
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="textarea"
                name="description"
                placeholder="Desired Solution"
                required={true}
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            {!this.props.storeMode && (
              <>
                <FormGroup>
                  <h5>Priority</h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="priority"
                    placeholder="Priority"
                    value={this.state.priority}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="Normal">Normal</option>
                    <option value="High">High</option>
                  </Input>
                </FormGroup>
              </>
            )}
            <hr className="my-3"></hr>
            <h4 className="mb-3">Related To</h4>
            <FormGroup>
              <h5>Related To</h5>
              <Input
                bsSize="sm"
                type="select"
                name="relatedToType"
                placeholder="Related To"
                value={this.state.relatedToType}
                onChange={this.handleInputChange.bind(this)}
              >
                <option value="" disabled>
                  Select An Option
                </option>
                <option value="Member">Member</option>
                <option value="Lead">Lead</option>
                <option value="Affiliate">Affiliate</option>
                <option value="Other">Other</option>
              </Input>
            </FormGroup>
            {this.state.relatedToType == "Member" ||
            this.state.relatedToType == "Lead" ||
            this.state.relatedToType == "Affiliate" ? (
              <>
                <FormGroup>
                  <h5>{this.state.relatedToType}</h5>
                  {this.state.relatedCustomer ? (
                    <>
                      <div className="form-control form-control-sm text-dark">
                        {this.state.relatedCustomer?.name
                          ? this.state.relatedCustomer?.name
                          : this.state.relatedCustomer?.email}
                      </div>
                      <div className="text-right mt-2">
                        <Button
                          onClick={() => {
                            this.setState({
                              relatedCustomer: null,
                              search: "",
                            });
                          }}
                          size="sm"
                          color="danger"
                          outline
                        >
                          Remove
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Whisper
                      placement="autoVerticalStart"
                      trigger="focus"
                      enterable
                      speaker={
                        <Popover
                          style={{
                            width: "calc(100% - 20px)",
                            maxWidth: 450,
                          }}
                          className="p-0"
                        >
                          <div className="border-bottom p-2">
                            <h5 className="mb-1">
                              {this.state.relatedToType}s
                            </h5>
                            <div className="mb-1">
                              {!this.state.search ? (
                                <>
                                  Search for a{" "}
                                  {this.state.relatedToType.toLowerCase()}.
                                </>
                              ) : null}
                              {this.state.search && this.state.searchLoading ? (
                                <>Loading results...</>
                              ) : null}
                              {this.state.search &&
                              !this.state.searchLoading &&
                              !this.state.customers?.length ? (
                                <>
                                  No {this.state.relatedToType.toLowerCase()}s
                                  matching your search.
                                </>
                              ) : null}
                              {this.state.search &&
                              this.state.customers?.length &&
                              !this.state.searchLoading ? (
                                <>{this.state.customers?.length} results</>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className=""
                            style={{
                              maxHeight: "400px",
                              overflowY: "scroll",
                            }}
                          >
                            {!this.state.searchLoading &&
                              this.state.customers?.map((c, i) => (
                                <>
                                  <div
                                    className="cursor-pointer bg-superlight--hover px-2 py-1"
                                    key={c?._id}
                                    onClick={() => {
                                      this.setState({
                                        relatedCustomer: c,
                                      });
                                    }}
                                  >
                                    <h4 className="m-0">
                                      {c?.name ? c.name : c.email}
                                    </h4>
                                    <p
                                      className="m-0"
                                      style={{ lineHeight: 1.2 }}
                                    >
                                      {this.state.relatedToType == "Member" ? (
                                        <small>{c.email}</small>
                                      ) : null}
                                      {this.state.relatedToType == "Lead" ? (
                                        <small>
                                          {c.name
                                            ? c.email
                                            : `Source: ${c.initialSource}`}
                                        </small>
                                      ) : null}
                                      {this.state.relatedToType ==
                                      "Affiliate" ? (
                                        <small>{c.name}</small>
                                      ) : null}
                                    </p>
                                  </div>
                                  {i != this.state.customers?.length - 1 ? (
                                    <hr className="m-0"></hr>
                                  ) : null}
                                </>
                              ))}
                            {this.state.searchLoading ? (
                              <>
                                <div className="text-center py-4">
                                  <Spinner size="sm" color="dark"></Spinner>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </Popover>
                      }
                    >
                      <Input
                        bsSize="sm"
                        type="text"
                        name="search"
                        placeholder={`Search ${this.state.relatedToType?.toLowerCase()}s...`}
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Whisper>
                  )}
                </FormGroup>
              </>
            ) : null}
            {!this.props.ticket && (
              <>
                <hr className="my-3"></hr>
                <h4 className="mb-3">Attachments</h4>
                <Card className="border">
                  <CardBody className="p-2">
                    <div className="text-left">
                      <Uploader
                        fileList={this.state.file}
                        autoUpload={false}
                        action="#"
                        onChange={this.onFileChange.bind(this)}
                        draggable={true}
                        listType="picture-text"
                        multiple={true}
                        renderFileInfo={(file, fileElement) => {
                          return (
                            <div>
                              <span>File Name: {file.name}</span>
                            </div>
                          );
                        }}
                      >
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                          }}
                        >
                          Click or Drag a file to this area to upload
                        </div>
                      </Uploader>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
            {!this.props.storeMode && (
              <>
                <hr className="my-3"></hr>
                <h4 className="mb-3">Completion Information</h4>
                <Row className="align-items-top">
                  <Col xs="12" md="6">
                    <FormGroup>
                      <h5>
                        Due Date
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                      <DatePicker
                        size="sm"
                        oneTap
                        required
                        block
                        placement="auto"
                        placeholder="Select A Date"
                        cleanable={false}
                        defaultValue={moment().add(1, "days").toDate()}
                        value={this.state.dueDate}
                        onChange={(e) => {
                          this.setState({ dueDate: e });
                        }}
                        ranges={[
                          {
                            label: "Today",
                            value: moment().toDate(),
                            closeOverlay: true,
                          },
                          {
                            label: "Next Week",
                            value:
                              moment().day() == 6
                                ? moment().add(1, "week").toDate()
                                : moment().day(6).add(1, "week").toDate(),
                            closeOverlay: true,
                          },
                        ]}
                      ></DatePicker>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <h5>Status</h5>
                      <Input
                        bsSize="sm"
                        type="select"
                        name="status"
                        placeholder="Status"
                        value={this.state.status}
                        onChange={this.handleInputChange.bind(this)}
                      >
                        <option value="Open">Open</option>
                        <option value="Investigating">Investigating</option>
                        <option value="In Progress">In Progress</option>
                        <option value="On Hold">On Hold</option>
                        <option value="Resolved">Resolved</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <h5>Assigned To</h5>
                      <SelectPicker
                        size="sm"
                        placement="auto"
                        loading={this.state.loadingCoaches}
                        cleanable={true}
                        placeholder={`Select A ${
                          !this.props.storeTask ? "Staff Member" : "Team Member"
                        }`}
                        data={this.state.coachOptions}
                        style={{}}
                        value={this.state.assignedTo}
                        block
                        onChange={(val) => {
                          this.setState({ assignedTo: val });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/*<Col xs="12" md="6">
                <FormGroup>
                  <h5>Best Time To Contact</h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="timeToContact"
                    placeholder="Best Time To Contact"
                    value={this.state.timeToContact}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="">--</option>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                  </Input>
                </FormGroup>
              </Col> */}
                </Row>
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(TechSupportTicketDrawer);
