import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";

class LeadCreateDrawer extends React.Component {
  state = {
    phone: {
      value: "",
    },
    zip: {
      value: "",
    },
    name: "",
    email: "",
    source: "Store Visit",
    sourceType: "In-Store",
    sources: Constant.SELECT_PICKER.LEAD_SOURCES,
    statuses: Constant.SELECT_PICKER.LEAD_STATUSES,
    status: "Not Contacted",
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      source: "Store Visit",
      status: "Not Contacted",
    });

    this.props.onClose();
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  handleZipChange(values) {
    this.setState({
      zip: values,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createLead(
      this.props?.store?._id,
      this.state.name ?? "",
      this.state.email,
      this.state.phone?.value ? this.state.phone?.value : "",
      this.state.zip?.value ? this.state.zip?.value : "",
      this.state.source,
      this.state.sourceType,
      this.state.status
    )
      .then(
        (data) => {
          const lead = data.data.lead;

          this.toggleModal();

          PubSub.publish(Event.LEAD.CREATED, lead);

          this.props.history.push(
            `/storefront/${this?.props?.store?._id}/leads/details/${lead?._id}/summary`
          );
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create lead - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Lead
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.email ||
                  !this.state.status ||
                  !this.state.source
                }
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h4 className="mb-3">Contact Information</h4>
            <FormGroup>
              <h5>Name</h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Email
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Phone</h5>
              <NumberFormat
                className="form-control mb-3 form-control-sm"
                format="(###) ###-####"
                name="phoneNumberInput"
                placeholder="Phone"
                onValueChange={this.handlePhoneChange.bind(this)}
                value={this.state.phone.value}
                type="tel"
                mask="_"
              />
            </FormGroup>
            <FormGroup>
              <h5>Zip Code</h5>
              <NumberFormat
                className="form-control mb-3 form-control-sm"
                format="#####"
                name="phoneNumberInput"
                placeholder="Zip Code"
                onValueChange={this.handleZipChange.bind(this)}
                value={this.state.zip.value}
                type="tel"
                mask="_"
              />
            </FormGroup>
            <FormGroup>
              <h5>
                Source
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                placement="auto"
                placeholder="Select A Source"
                data={this.state.sources}
                value={this.state.source}
                cleanable={false}
                groupBy="type"
                block
                onChange={(value, item) => {
                  let i = _.findWhere(this.state.sources, { value });

                  this.setState({ source: value, sourceType: i?.type });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Status
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                placement="auto"
                placeholder="Select A Status"
                data={this.state.statuses}
                value={this.state.status}
                cleanable={false}
                block
                onChange={(value, item) => {
                  this.setState({ status: value });
                }}
              ></SelectPicker>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LeadCreateDrawer);
