import ReactDOM from "react-dom";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { Statistic, Table } from "antd";
import APIV2 from "../../lib/APIV2";
import DataAPI from "lib/DataAPI";
import { Button, Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import StringUtils from "lib/StringUtils";
import _ from "underscore";

import moment from "moment";

import {
  Chart,
  Area,
  Line,
  Tooltip,
  Axis,
  Coordinate,
  Point,
  Interaction,
} from "bizcharts";

class SalesSummaryTrendlineChart extends React.Component {
  state = {
    activeMode: "source",
    chartData: [],
    colorKey: "",
    scale: {
      date: {
        type: "timeCat",
        mask: "MM/DD/YYYY",
        tickCount: 30,
        nice: true,
        alias: "Date",
        formatter: (v) => {
          const mom = moment(v);

          return this.getPeriodString(mom);
        },
      },
      value: {
        nice: true,
        alias: "Sales",
        formatter: (v) => {
          return !isNaN(v) ? StringUtils.centsToCurrency(v * 100) : v;
        },
      },
    },
  };

  constructor(props) {
    super(props);

    this.chartRef = React.createRef();
  }

  getPeriodString(startMoment) {
    if (!startMoment) {
      return "--";
    }

    if (this.props.groupBy == "daily" || this.props.groupBy == "summary") {
      return startMoment.format("MM/DD/YYYY");
    }

    if (this.props.groupBy == "weekly") {
      return `${startMoment?.format("MM/DD/YYYY")} - ${moment(
        startMoment?.format("YYYY-MM-DD")
      )
        .endOf("week")
        .format("MM/DD/YYYY")}`;
    }

    if (this.props.groupBy == "monthly") {
      return `${startMoment?.format("MMMM YYYY")}`;
    }

    if (this.props.groupBy == "quarterly") {
      return `Q${startMoment?.format("Q YYYY")}`;
    }

    if (this.props.groupBy == "yearly") {
      return `FY ${startMoment?.format("YYYY")}`;
    }
  }

  setActiveMode(mode) {
    this.setState(
      {
        activeMode: mode,
      },
      () => {
        this.loadChartData(this.state.data);
      }
    );
  }

  componentDidMount() {
    if (this.props.data) {
      if (this.props.data?.revenueSource != "all") {
        this.setState(
          {
            activeMode: "store",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      } else {
        this.setState(
          {
            activeMode: "source",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      }
    }
  }

  loadChartData(data) {
    this.setState({
      data: data,
    });

    let preparedData = [];

    if (this.state.activeMode == "source") {
      if (data?.revenueSource == "all") {
        const subData = data?.subscriptionData?.chartData?.map((v) => {
          return {
            value: v?.revenue,
            date: moment(v?.dateString)?.toDate().getTime(),
            dateString: v?.dateString,
            revenueSource: "Subscription Sales",
          };
        });

        const retailData = data?.retailData?.chartData?.map((v) => {
          return {
            value: v?.revenue,
            date: moment(v?.dateString)?.toDate().getTime(),
            dateString: v?.dateString,
            revenueSource: "Retail Sales",
          };
        });

        preparedData = subData?.concat(retailData);

        return this.setState({
          chartData: preparedData,
          colorKey: ["revenueSource", ["#E17853", "#81D96D"]],
        });
      }

      if (data?.revenueSource == "subscription") {
        const subData = data?.subscriptionData?.chartData?.map((v) => {
          return {
            value: v?.revenue,
            date: moment(v?.dateString)?.toDate().getTime(),
            dateString: v?.dateString,
            revenueSource: "Subscription Sales",
          };
        });

        preparedData = subData;

        return this.setState({
          chartData: preparedData,
          colorKey: ["revenueSource", ["#E17853"]],
        });
      }

      if (data?.revenueSource == "retail") {
        const subData = data?.retailData?.chartData?.map((v) => {
          return {
            value: v?.revenue,
            date: moment(v?.dateString)?.toDate().getTime(),
            dateString: v?.dateString,
            revenueSource: "Retail Sales",
          };
        });

        preparedData = subData;

        return this.setState({
          chartData: preparedData,

          colorKey: ["revenueSource", ["#81D96D"]],
        });
      }
    } else if (this.state.activeMode == "store") {
      // TODO: fix combined data here

      let source = {};

      if (data.revenueSource == "all") {
        source = data.combinedData;
      } else if (data.revenueSource == "subscription") {
        source = data.subscriptionData;
      } else if (data.revenueSource == "retail") {
        source = data.retailData;
      }

      for (let i = 0; i < this.props?.stores?.length; i++) {
        const store = this.props.stores[i];

        if (this.props.groupBy == "summary") {
          console.log(`Source Chart Data`, source);

          let storeData = [];

          if (source?.storeData?.interval?.hasOwnProperty(store?._id)) {
            storeData = source?.storeData.interval[store?._id];
          }

          if (storeData?.length) {
            console.log("Store Data", storeData);

            let combinedData = storeData?.map((v) => {
              if (!v?.date) {
                return null;
              }

              return {
                value: v?.revenue,
                date: v.date?.getTime(),
                storeName: store?.name?.replace("Project LeanNation", ""),
              };
            });

            combinedData = _.filter(combinedData, (data) => {
              return data ? true : false;
            });

            //console.log(combinedData);

            preparedData = preparedData.concat(combinedData);
          }
        } else {
          if (
            source?.storeData?.hasOwnProperty(store?._id) &&
            source?.storeData[store?._id]?.length
          ) {
            //console.log(data?.combinedData?.storeData[store?._id]);

            let combinedData = source?.storeData[store?._id]?.map((v) => {
              if (!v?.date) {
                return null;
              }

              return {
                value: v?.revenue,
                date: v.date?.getTime(),

                storeName: store?.name?.replace("Project LeanNation", ""),
              };
            });

            combinedData = _.filter(combinedData, (data) => {
              return data ? true : false;
            });

            preparedData = preparedData.concat(combinedData);
          }
        }
      }

      return this.setState({
        chartData: preparedData,

        colorKey: "storeName",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data) != JSON.stringify(prevProps?.data)) {
      if (this.props.data?.revenueSource != "all") {
        this.setState(
          {
            activeMode: "store",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      } else {
        this.setState(
          {
            activeMode: "source",
          },
          () => {
            this.loadChartData(this.props.data);
          }
        );
      }

      // Reload here
    }
  }

  generateFileName() {
    let source = "";

    if (this.props.data?.revenueSource == "all") {
      source = "All Sales";
    } else if (this.props.data?.revenueSource == "subscription") {
      source = "Subscription Sales";
    } else if (this.props.data?.revenueSource == "retail") {
      source = "Retail Sales";
    }

    let mode = "";

    if (this.state.activeMode == "source") {
      mode = "By Source";
    } else {
      mode = "By Store";
    }

    let range = "";

    if (this.props.dateRange?.length == 2) {
      range = `${moment(this.props?.dateRange[0]?.toISOString()).format(
        "MM-DD-YYYY"
      )}-${moment(this.props?.dateRange[1]?.toISOString()).format(
        "MM-DD-YYYY"
      )}`;
    }

    return `${source} ${mode} ${range}.jpeg`;
  }

  exportToImage() {
    let canvas =
      this?.chartRef?.current?.props?.container?.querySelector("canvas");

    if (canvas) {
      var ctx = canvas.getContext("2d");
      // Add behind elements.
      ctx.globalCompositeOperation = "destination-over";
      // Now draw!
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const img = canvas.toDataURL("image/jpeg", 1.0);

      console.log(img);

      let link = document.createElement("a");
      link.download = this.generateFileName();
      link.href = img;
      link.click();

      //window.location.href = img;
    }

    return;
  }

  render() {
    return (
      <>
        <Card className="border shadow border-lighter">
          <CardHeader className="p-2 border-lighter">
            <Row className="align-items-center">
              <Col xs="">
                <Button
                  outline={this.state.activeMode != "source"}
                  size="sm"
                  color={this.state.activeMode == "source" ? "primary" : "dark"}
                  onClick={() => {
                    if (this.state.activeMode != "source") {
                      this.setActiveMode("source");
                    }
                  }}
                  className="mr-1"
                >
                  {this.props.data?.revenueSource != "all"
                    ? "Total Revenue"
                    : "By Revenue Source"}
                </Button>
                <Button
                  outline={this.state.activeMode != "store"}
                  size="sm"
                  color={this.state.activeMode == "store" ? "primary" : "dark"}
                  onClick={() => {
                    if (this.state.activeMode != "store") {
                      this.setActiveMode("store");
                    }
                  }}
                >
                  By Store
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    this.exportToImage();
                  }}
                  size="sm"
                  className="btn-icon-only border-0"
                  color="dark"
                  outline
                >
                  <i className="mdi mdi-download"></i>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-3 sales-chart" style={{ overflow: "hidden" }}>
            {this.props.loading ? (
              <>
                <div className="skeleton" style={{ height: 260 }}></div>
              </>
            ) : (
              <>
                <Chart
                  ref={this.chartRef}
                  height={260}
                  autoFit
                  scale={this.state.scale}
                  data={this.state.chartData}
                >
                  <Axis name="value" title visible={true}></Axis>
                  <Tooltip
                    showCrosshairs={true}
                    crosshairs={{
                      type: "x",
                      line: {
                        style: {
                          stroke: "#8898aa",
                          fill: "#ffffff",

                          lineWidth: 2,
                        },
                      },
                    }}
                    shared
                  />
                  <Axis
                    name="date"
                    visible={true}
                    tickLine={{
                      style: {
                        stroke: "#ddd",
                        fill: "#ffffff",

                        lineWidth: 2,
                      },
                    }}
                    grid={{
                      align: "center",
                      line: {
                        type: "line",
                        style: {
                          stroke: "#EEE",
                          fill: "#ffffff",

                          lineWidth: 1,
                        },
                        alignTick: true,
                      },
                    }}
                  />
                  <Area
                    adjust="stack"
                    color={this.state.colorKey}
                    position="date*value"
                  ></Area>
                  <Line
                    adjust="stack"
                    color={this.state.colorKey}
                    position="date*value"
                  ></Line>
                  <Interaction type="active-region" />
                </Chart>
              </>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default SalesSummaryTrendlineChart;
