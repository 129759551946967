import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import ScorecardActiveMemberDetailDrawer from "./ScorecardActiveMemberDetailDrawer";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class ActiveMemberSummaryChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "performanceTrend",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchOrderDataByWeek(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["SubscriptionFulfillmentReports.totalActiveCount"],
          order: {
            "SubscriptionFulfillmentReports.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "SubscriptionFulfillmentReports.fulfillmentdate",
              dateRange: [dateRange[0], dateRange[1]],
              granularity: "week",
            },
          ],
          filters: [
            {
              member: "SubscriptionFulfillmentReports.storeid",
              operator: "equals",
              values: stores,
            },
            {
              member: "SubscriptionFulfillmentReports.fulfillmentdate",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
          dimensions: ["Stores.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            return {
              storeName: item["Stores.name"]
                ?.replace("Project LeanNation", "PLN")
                ?.trim(),
              fulfillmentDate: moment(
                item["SubscriptionFulfillmentReports.fulfillmentdate.week"]
              )
                .day(6)
                .toDate(),
              fulfillmentDateString: moment(
                item["SubscriptionFulfillmentReports.fulfillmentdate.week"]
              )
                .day(6)
                .format("MM/DD/YY"),
              count: item["SubscriptionFulfillmentReports.totalActiveCount"],
            };
          });

          let outByStore2 = data.map((item) => {
            return {
              storeName: item?.storeName,
              fulfillmentDate: item?.fulfillmentDate,
              fulfillmentDateString: item?.fulfillmentDateString,
              count: item?.count,
            };
          });

          let outByStore = [];

          let outOverall = [];

          let totalArr = [];

          let totalCount = 0;

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];

            const storeIDX = _.findIndex(outByStore, {
              fulfillmentDateString: item.fulfillmentDateString,
            });
            const overallIDX = _.findIndex(outOverall, {
              fulfillmentDateString: item.fulfillmentDateString,
            });

            if (storeIDX == -1) {
              outByStore.push({
                fulfillmentDateString: item?.fulfillmentDateString,
                [item.storeName]: item.count,
                fulfillmentDate: item?.fulfillmentDate,
              });
            } else {
              outByStore[storeIDX][item?.storeName] = item.count;
            }

            if (overallIDX == -1) {
              outOverall.push({
                fulfillmentDateString: item?.fulfillmentDateString,
                count: item.count,
                fulfillmentDate: item?.fulfillmentDate,
              });
            } else {
              outOverall[overallIDX].count += item.count;
            }

            const totalArrIDX = _.findIndex(totalArr, {
              storeName: item?.storeName,
            });

            if (totalArrIDX >= 0) {
              if (item?.count !== null && item?.count !== undefined) {
                totalArr[totalArrIDX].count += item?.count ? item?.count : 0;
                totalArr[totalArrIDX].items++;
              }
            } else if (item?.count !== null && item?.count !== undefined) {
              totalArr.push({
                storeName: item?.storeName,
                items: 1,
                count: item?.count ? item?.count : 0,
              });
            }
          }

          for (let i = 0; i < totalArr?.length; i++) {
            let item = totalArr[i];

            const avg = item?.count / item?.items;

            totalCount += avg;
          }

          outByStore = _.sortBy(outByStore, "fulfillmentDate");
          outByStore2 = _.sortBy(outByStore2, "fulfillmentDate");
          outOverall = _.sortBy(outOverall, "fulfillmentDate");

          console.log(outByStore);

          return resolve({
            byStore: outByStore,
            byStore2: outByStore2,
            overall: outOverall,
            totalCount,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchOrderDataByWeek(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load client order quantities by week.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let storeNames = [];

    for (let i = 0; i < currentCount?.byStore?.length; i++) {
      let keys = _.keys(currentCount.byStore[i]);

      let names = _.filter(keys, (name) => {
        return name.includes("PLN");
      });

      storeNames = storeNames.concat(names);
    }

    storeNames = _.uniq(storeNames);

    let previousCount = null;

    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let startMoment = moment(dateRange[0].toISOString());
    let endMoment = moment(dateRange[1].toISOString());

    startMoment.subtract(secondDifference, "seconds");
    endMoment.subtract(secondDifference + 1, "seconds");

    console.log(startMoment.toDate(), endMoment.toDate());

    try {
      previousCount = await this._fetchOrderDataByWeek(cubejsApi, stores, [
        startMoment.toDate(),
        endMoment.toDate(),
      ]);
    } catch (e) {}

    if (previousCount !== null) {
      let percentChange =
        (currentCount?.totalCount - previousCount?.totalCount) /
        previousCount?.totalCount;

      let declineMode = "";
      let isNegative = false;

      if (percentChange > 0) {
        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateIncrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "increase";
        }
      } else {
        isNegative = true;

        if (Math.abs(percentChange) < 0.015) {
          declineMode = "unchanged";
        } else if (Math.abs(percentChange) < 0.1) {
          declineMode = "moderateDecrease";
        } else if (Math.abs(percentChange) >= 0.1) {
          declineMode = "decrease";
        }
      }

      this.setState({
        previousCount: previousCount,
        changeIsNegative: isNegative,
        deltaType: declineMode,
        percentChange: percentChange,
        percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",
      });
    }

    let performanceTrend = [];

    for (let i = 0; i < currentCount?.overall?.length; i++) {
      const item = currentCount?.overall[i];

      performanceTrend.push({
        fulfillmentDate: item?.fulfillmentDate,
        fulfillmentDateString: "EOW " + item?.fulfillmentDateString,
        "Active Members": item?.count,
        Previous: null,
      });
    }

    for (let i = 0; i < previousCount?.overall?.length; i++) {
      const item = previousCount?.overall[i];

      //console.log(item?.fulfillmentDate);

      const fulfillmentDate = moment(item?.fulfillmentDate?.toISOString())
        .add(secondDifference, "seconds")
        .day(6)
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      const realDate = moment(item?.fulfillmentDate?.toISOString())
        .day(6)
        .hours(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      //console.log(fulfillmentDate?.toISOString());

      const idx = _.findIndex(performanceTrend, {
        fulfillmentDateString: "EOW " + fulfillmentDate?.format("MM/DD/YY"),
      });

      if (idx >= 0) {
        performanceTrend[idx].Previous = item?.count;
        performanceTrend[idx].fulfillmentDateString += ` vs. ${realDate?.format(
          "MM/DD/YY"
        )}`;
      } else if (
        fulfillmentDate.toDate() >= dateRange[0] &&
        fulfillmentDate.toDate() <= dateRange[1]
      ) {
        performanceTrend.push({
          Previous: item?.count,
          Leads: null,
          fulfillmentDate: fulfillmentDate?.toDate(),
          fulfillmentDateString:
            "EOW " +
            fulfillmentDate?.format("MM/DD/YY") +
            ` vs. ${realDate?.format("MM/DD/YY")}`,
        });
      }
    }

    performanceTrend = _.sortBy(performanceTrend, "fulfillmentDate");

    storeNames.sort();

    let colors = StringUtils.randomChartColorSet(storeNames?.length);

    this.setState({
      currentCount: currentCount,
      loading: false,
      performanceTrend,
      byStore: currentCount?.byStore,
      byStore2: currentCount?.byStore2,
      storeNames: storeNames,
      byStoreColors: colors,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Weekly Active Members"
            forceWrapComparison={true}
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`~${StringUtils.numberFormat(
              Math.round(this.state.currentCount?.totalCount)
            )} Actives`}
            comparisonMetric={
              this.state.previousCount !== null
                ? `~${StringUtils.numberFormat(
                    Math.round(this.state.previousCount?.totalCount)
                  )}`
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          <TabList
            color="orange"
            defaultValue="performanceTrend"
            handleSelect={(value) => {
              this.setState({
                tab: value,
              });

              if (value == "byStore") {
                this.setState({
                  chartAdjust: [{ type: "stack" }],
                });
              } else {
                this.setState({
                  chartAdjust: [{ type: "dodge", marginRatio: 1 / 32 }],
                });
              }
            }}
            marginTop="mt-3"
            disabled={this.state.loading || !this.state.dataAvailable}
          >
            <Tab value="performanceTrend" text="Member Trend" />
            <Tab value="byStore" text="Store Summary" />
            <Tab value="byStoreBar" text="Store Comparison" />
          </TabList>
          {this.state.loading ? (
            <div
              className="skeleton"
              style={{ height: "calc(320px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  {this.state.tab == "performanceTrend" && (
                    <div className="mt-3">
                      <AreaChart
                        data={this.state.performanceTrend}
                        categories={["Active Members", "Previous"]}
                        dataKey="fulfillmentDateString"
                        colors={["orange", "gray"]}
                        valueFormatter={(number) => {
                          return StringUtils.numberFormat(number);
                        }}
                        height="h-80"
                      />
                    </div>
                  )}
                  {this.state.tab == "byStore" && (
                    <>
                      <div className="mt-3">
                        <Chart
                          height={320}
                          appendPadding={[20, 0, 10, 30]}
                          data={this.state.byStore2}
                          autoFit
                        >
                          <Coordinate transposed></Coordinate>
                          <Tooltip
                            itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Active Members</span></li>`}
                            shared
                          ></Tooltip>
                          <Axis
                            name="fulfillmentDateString"
                            label={{ offset: 12 }}
                          ></Axis>
                          <Axis
                            name="count"
                            grid={{
                              line: {
                                style: {
                                  stroke: "#CCC",
                                  lineDash: [3, 3],
                                },
                              },
                            }}
                          ></Axis>
                          <Legend
                            marker={{ symbol: "circle" }}
                            label={{}}
                            position="top-right"
                            layout={"horizontal"}
                            offsetY={0}
                          ></Legend>
                          <Interval
                            adjust={this.state.chartAdjust}
                            color="storeName"
                            position="fulfillmentDateString*count"
                          ></Interval>
                          <Interaction type="active-region"></Interaction>
                        </Chart>
                      </div>
                    </>
                  )}
                  {this.state.tab == "byStoreBar" && (
                    <>
                      <div className="mt-3">
                        <Chart
                          height={320}
                          appendPadding={[20, 0, 10, 30]}
                          data={this.state.byStore2}
                          autoFit
                        >
                          <Coordinate transposed></Coordinate>
                          <Tooltip
                            itemTpl={`<li style="padding-bottom: 8px; color: var(--dark);" data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}:<span style="padding-left: 5px;">\{value} Active Members</span></li>`}
                            shared
                          ></Tooltip>
                          <Axis
                            name="fulfillmentDateString"
                            label={{ offset: 12 }}
                          ></Axis>
                          <Axis
                            name="count"
                            grid={{
                              line: {
                                style: {
                                  stroke: "#CCC",
                                  lineDash: [3, 3],
                                },
                              },
                            }}
                          ></Axis>
                          <Legend
                            marker={{ symbol: "circle" }}
                            label={{}}
                            position="top-right"
                            layout={"horizontal"}
                            offsetY={0}
                          ></Legend>
                          <Interval
                            adjust={this.state.chartAdjust}
                            color="storeName"
                            position="fulfillmentDateString*count"
                          ></Interval>
                          <Interaction type="active-region"></Interaction>
                        </Chart>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(320px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
        <ScorecardActiveMemberDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default ActiveMemberSummaryChartCard;
