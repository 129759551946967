import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";

class StorefrontCustomerListView extends React.Component {
  state = {
    active: {
      id: "Active",
      name: "All Active",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();

    PubSub.subscribe(Event.MEAL.DELETED, (data) => {
      let g = filter(this.state.meals, (a) => {
        return a.id != data._id;
      });

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.CREATED, (m) => {
      let g = this.state.meals;

      g.push(m);

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.MODIFIED, (a) => {
      let g = this.state.meals;
      let idx = findIndex(g, { _id: a._id });

      g[idx] = a;

      this.setState({
        meals: g,
      });
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadCustomers(page = 1, count = 25, filters = null, orderBy = null) {
    this.setState({
      loadingCustomers: true,
    });

    if (!orderBy) {
      orderBy = {
        name: 1,
      };
    } else if (orderBy.hasOwnProperty("subscriptionObject.lastBillDate")) {
      if (!filters) {
        filters = {
          "subscriptionObject.lastBillDate": { $nin: [null, ""] },
        };
      } else {
        filters["subscriptionObject.lastBillDate"] = { $nin: [null, ""] };
      }
    }

    APIV2.getCustomersForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let customers = [];

            if (data.data.customers.length) {
              customers = data.data.customers;
            }

            this.setState({
              customers,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the members. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingCustomers: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchCustomersForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultCustomers: data.data.customers,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultCustomers: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            let store = _.findWhere(data.data.stores, {
              _id: this.props?.match?.params?.storeID,
            });

            this.setState({ stores: data.data.stores, store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadCustomers(page, this.state.count, this.state.filters);
  }

  handleTabChange(option, force = false) {
    let filters = null;

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All") {
      filters = null;
    } else if (option.id == "Active") {
      filters = {
        accountStatus: "ACTIVE",
      };
    } else if (option.id == "Active This Week") {
      filters = {
        accountStatus: "ACTIVE",
        "subscriptionObject.nextBillDate": moment().day(6).format("YYYY-MM-DD"),
      };
    } else if (option.id == "Check NBD") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        $or: [
          {
            "subscriptionObject.nextBillDate": {
              $lt: moment().day(6).format("YYYY-MM-DD"),
            },
          },
          {
            "subscriptionObject.nextBillDate": "2050-01-01",
          },
          {
            "subscriptionObject.nextBillDate": "1999-01-01",
          },
        ],
      };
    } else if (option.id == "New This Week") {
      filters = {
        accountStatus: "ACTIVE",
        $or: [
          {
            signupDate: {
              $gte: {
                $date: moment()
                  .day(0)
                  .hour(0)
                  .minute(0)
                  .seconds(0)
                  .millisecond(0)
                  .toDate()
                  .toISOString(),
              },
              $lt: {
                $date: moment()
                  .day(6)
                  .hour(23)
                  .minute(59)
                  .seconds(59)
                  .millisecond(999)
                  .toDate()
                  .toISOString(),
              },
            },
          },
        ],
      };
    } else if (option.id == "Reactivated This Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        "subscriptionObject.reactivateDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .day(6)
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "New Last Week") {
      filters = {
        accountStatus: "ACTIVE",
        $or: [
          {
            signupDate: {
              $gte: {
                $date: moment()
                  .day(0)
                  .hour(0)
                  .minute(0)
                  .seconds(0)
                  .millisecond(0)
                  .subtract(1, "week")
                  .toDate()
                  .toISOString(),
              },
              $lt: {
                $date: moment()
                  .day(6)
                  .hour(23)
                  .minute(59)
                  .seconds(59)
                  .millisecond(999)
                  .subtract(1, "week")
                  .toDate()
                  .toISOString(),
              },
            },
          },
        ],
      };
    } else if (option.id == "Reactivated Last Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        "subscriptionObject.reactivateDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hour(0)
              .minute(0)
              .seconds(0)
              .millisecond(0)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
          $lt: {
            $date: moment()
              .day(6)
              .hour(23)
              .minute(59)
              .seconds(59)
              .millisecond(999)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "Active Next Week") {
      filters = {
        accountStatus: "ACTIVE",
        "subscriptionObject.nextBillDate": moment()
          .day(6)
          .add(1, "week")
          .format("YYYY-MM-DD"),
      };
    } else if (option.id == "HFN This Week") {
      filters = {
        "subscriptionObject.holdDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hour(0)
              .minute(0)
              .seconds(0)
              .millisecond(0)
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .day(6)
              .hour(23)
              .minute(59)
              .seconds(59)
              .millisecond(9999)
              .toDate()
              .toISOString(),
          },
        },
        "subscriptionObject.status": "HOLD",
      };
    } else if (option.id == "HFN Last Week") {
      filters = {
        "subscriptionObject.status": "HOLD",
        "subscriptionObject.holdDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hour(0)
              .minute(0)
              .seconds(0)
              .millisecond(0)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .day(6)
              .hour(23)
              .minute(59)
              .seconds(59)
              .millisecond(999)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "HFN") {
      filters = {
        "subscriptionObject.status": "HOLD",
      };
    }

    this.setState({
      filters,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadCustomers(1, this.state.count, filters, this.state.orderBy);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Members"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All Active",
                      id: "Active",
                    },

                    {
                      name: "Active This Week",
                      id: "Active This Week",
                    },
                    {
                      name: "Active Next Week",
                      id: "Active Next Week",
                    },
                    {
                      name: "New This Week",
                      id: "New This Week",
                    },
                    {
                      name: "New Last Week",
                      id: "New Last Week",
                    },
                    {
                      name: "Reactivated This Week",
                      id: "Reactivated This Week",
                    },
                    {
                      name: "Reactivated Last Week",
                      id: "Reactivated Last Week",
                    },
                    {
                      name: "HFN This Week",
                      id: "HFN This Week",
                    },
                    {
                      name: "HFN Last Week",
                      id: "HFN Last Week",
                    },

                    {
                      name: "All HFN",
                      id: "HFN",
                    },
                    {
                      name: "All",
                      id: "All",
                    },
                    {
                      name: "Check NBD",
                      id: "Check NBD",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="" className="pr-sm-0">
                      <Input
                        type="text"
                        placeholder="Search members..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 pr-0 mt-sm-0">
                      <SelectPicker
                        searchable={false}
                        size="sm"
                        placeholder="Sort By"
                        disabled={true}
                        data={[]}
                        block
                        placement="autoVerticalEnd"
                      />
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 mt-sm-0">
                      <Button
                        color="secondary"
                        size="sm"
                        block
                        outline
                        onClick={() => {
                          PubSub.publish(Event.FILTERS.CUSTOMERS_OPEN);
                        }}
                        disabled
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <>
                          Found{" "}
                          {this.state.searchResultCustomers?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultCustomers?.length
                              )
                            : "0"}{" "}
                          member
                          {this.state.searchResultCustomers?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  members
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">Customer</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Plan</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Status</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Next Bill Date</h4>
                      </Col>
                      <Col
                        xs="6"
                        sm="6"
                        md="2"
                        onClick={() => {
                          let dir = "ascending";

                          if (this.state.sortByOption == "lastBillDate") {
                            if (this.state.sortByDirection == "descending") {
                              return this.setState(
                                {
                                  sortByOption: "",
                                  sortByDirection: "",
                                  orderBy: null,
                                },
                                () => {
                                  this.loadCustomers(
                                    1,
                                    this.state.count,
                                    this.state.filters,
                                    this.state.orderBy
                                  );
                                }
                              );
                            } else if (
                              this.state.sortByDirection == "ascending"
                            ) {
                              dir = "descending";
                            }
                          }

                          this.setState(
                            {
                              sortByOption: "lastBillDate",
                              sortByDirection: dir,
                              orderBy: {
                                "subscriptionObject.lastBillDate":
                                  dir == "descending" ? -1 : 1,
                              },
                            },
                            () => {
                              this.loadCustomers(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        }}
                      >
                        <h4 className="mb-0 cursor-pointer">
                          Last Bill Date{" "}
                          {this.state.sortByOption == "lastBillDate" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingCustomers || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultCustomers?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No members found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultCustomers?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.searchResultCustomers.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.props.history.push(
                                "/storefront/" +
                                  this.props?.match?.params?.storeID +
                                  "/members/details/" +
                                  c._id +
                                  "/summary"
                              );
                            }}
                          >
                            <Row className="align-items-center">
                              <Col xs="6" sm="6" md="4">
                                <h4 className="mb-0">{c.name}</h4>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.phone
                                    ? StringUtils.formatPhoneNumber(
                                        c.phone.replace("+1", "")
                                      )
                                    : "--"}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                <p className="text-dark m-0">
                                  {c?.subscription?.plan?.name}
                                </p>
                                <p className="m-0" style={{ lineHeight: 1.2 }}>
                                  <small>{c?.subscription?.plan?.code}</small>
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {c.accountStatus == "ACTIVE" ? (
                                  <>
                                    <Badge color="success">Active</Badge>
                                  </>
                                ) : null}
                                {c.accountStatus == "HOLD" ? (
                                  <>
                                    <Badge color="medium">Hold</Badge>
                                  </>
                                ) : null}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                <div
                                  style={{ fontSize: "15px" }}
                                  className="text-dark"
                                >
                                  {c.accountStatus == "ACTIVE" &&
                                  c.subscription ? (
                                    <>
                                      {moment(
                                        c.subscription.nextBillDate
                                      ).format("MM/DD/YYYY")}
                                    </>
                                  ) : (
                                    <>--</>
                                  )}
                                </div>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                <div
                                  style={{ fontSize: "15px" }}
                                  className={`${
                                    c?.subscription?.lastBillDate &&
                                    c?.subscription?.status == "ACTIVE" &&
                                    moment(
                                      c.subscription.lastBillDate
                                    ).toDate() <
                                      moment()
                                        .startOf("day")
                                        .subtract(30, "days")
                                        .toDate() &&
                                    (!c?.subscription?.reactivateDate ||
                                      moment(
                                        c.subscription.reactivateDate
                                      ).toDate() <
                                        moment()
                                          .startOf("day")
                                          .subtract(30, "days")
                                          .toDate())
                                      ? "text-danger"
                                      : "text-dark"
                                  }`}
                                >
                                  {c.subscription?.lastBillDate ? (
                                    <>
                                      {moment(
                                        c.subscription.lastBillDate
                                      ).format("MM/DD/YYYY")}
                                    </>
                                  ) : (
                                    <>--</>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.customers?.length &&
                        !this.state.loadingCustomers ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                Get started by adding your first member.
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.customers?.map((c, i) => (
                          <div
                            className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                              i != this.state.customers.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              this.props.history.push(
                                "/storefront/" +
                                  this.props?.match?.params?.storeID +
                                  "/members/details/" +
                                  c._id +
                                  "/summary"
                              );
                            }}
                          >
                            <Row className="align-items-center">
                              <Col xs="6" sm="6" md="4">
                                <h4 className="mb-0">{c.name}</h4>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.phone
                                    ? StringUtils.formatPhoneNumber(
                                        c.phone.replace("+1", "")
                                      )
                                    : "--"}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                <p className="text-dark m-0">
                                  {c?.subscription?.plan?.name}
                                </p>
                                <p className="m-0" style={{ lineHeight: 1.2 }}>
                                  <small>{c?.subscription?.plan?.code}</small>
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                {c.accountStatus == "ACTIVE" ? (
                                  <>
                                    <Badge color="success">Active</Badge>
                                  </>
                                ) : null}
                                {c.accountStatus == "HOLD" ? (
                                  <>
                                    <Badge color="medium">Hold</Badge>
                                  </>
                                ) : null}
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                <div
                                  style={{ fontSize: "15px" }}
                                  className="text-dark"
                                >
                                  {c.accountStatus == "ACTIVE" &&
                                  c.subscription ? (
                                    <>
                                      {moment(
                                        c.subscription.nextBillDate
                                      ).format("MM/DD/YYYY")}
                                    </>
                                  ) : (
                                    <>--</>
                                  )}
                                </div>
                              </Col>
                              <Col xs="6" sm="6" md="2">
                                <div
                                  style={{ fontSize: "15px" }}
                                  className={`${
                                    c?.subscription?.lastBillDate &&
                                    c?.subscription?.status == "ACTIVE" &&
                                    moment(
                                      c.subscription.lastBillDate
                                    ).toDate() <
                                      moment()
                                        .startOf("day")
                                        .subtract(30, "days")
                                        .toDate() &&
                                    (!c?.subscription?.reactivateDate ||
                                      moment(
                                        c.subscription.reactivateDate
                                      ).toDate() <
                                        moment()
                                          .startOf("day")
                                          .subtract(30, "days")
                                          .toDate())
                                      ? "text-danger"
                                      : "text-dark"
                                  }`}
                                >
                                  {c.subscription?.lastBillDate ? (
                                    <>
                                      {moment(
                                        c.subscription.lastBillDate
                                      ).format("MM/DD/YYYY")}
                                    </>
                                  ) : (
                                    <>--</>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <CustomerGlobalFilterDrawer
          openEvent={Event.FILTERS.CUSTOMERS_OPEN}
        ></CustomerGlobalFilterDrawer>
        <CustomerCreateDrawer
          store={this.state.store}
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.CUSTOMER.CREATED, c);
          }}
        ></CustomerCreateDrawer>
      </>
    );
  }
}

export default StorefrontCustomerListView;
