import { Cell } from "rsuite-table";
import React, { useState } from "react";
import StringUtils from "lib/StringUtils";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
  Table,
} from "reactstrap";
import moment from "moment";

function Invoice({ invoice, ...props }) {
  let badgeColor = "medium";
  let badgeClasses = "";

  let [historyExpanded, setHistoryExpanded] = useState(false);
  let [transactionExpanded, setTransactionExpanded] = useState(false);
  let [attemptHistoryExpanded, setAttemptHistoryExpanded] = useState(false);
  let [attemptRecentHistoryExpanded, setAttemptRecentHistoryExpanded] =
    useState(false);
  let [viewData, setViewData] = useState(null);
  let [viewDetails, setViewDetails] = useState(null);
  let [viewRecentData, setViewRecentData] = useState(false);

  if (invoice?.status == "UNPAID") {
    badgeColor = "";
    badgeClasses = "bg-yellow text-dark";
  }

  return (
    <>
      <Row className="align-items-center mb-3">
        <Col xs="">
          <small>#{invoice?._id}</small>
          <h3 className="mb-0">{invoice?.name}</h3>
        </Col>
        <Col xs="auto">
          <h3 className="mb-0">
            <Badge color={badgeColor} className={badgeClasses}>
              {invoice?.status}
            </Badge>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table className="table-sm table-bordered">
            <tbody>
              <tr>
                <th className="p-2">
                  <h4 className="m-0">Created:</h4>
                </th>
                <td className="p-2">
                  <h4 className="m-0 font-weight-normal">
                    {moment(invoice?.createdAt).format("MM/DD/YYYY")}
                  </h4>
                </td>
              </tr>
              <tr>
                <th className="p-2">
                  <h4 className="m-0">Due On:</h4>
                </th>
                <td className="p-2">
                  <h4 className="m-0 font-weight-normal">
                    {moment(invoice?.dueOn).format("MM/DD/YYYY")}
                  </h4>
                </td>
              </tr>
              <tr>
                <th className="p-2">
                  <h4 className="m-0">Bill To:</h4>
                </th>
                <td className="p-2">
                  <h4 className="m-0 font-weight-normal">
                    {invoice?.customer?.billingAddress?.name}
                  </h4>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>{invoice?.customer?.billingAddress?.address1}</small>
                  </p>
                  {invoice?.customer?.billingAddress?.address2 ? (
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {invoice?.customer?.billingAddress?.address2}
                      </small>
                    </p>
                  ) : null}
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>
                      {invoice?.customer?.billingAddress?.city},{" "}
                      {invoice?.customer?.billingAddress?.state}{" "}
                      {invoice?.customer?.billingAddress?.zip}
                    </small>
                  </p>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>
                      {invoice?.customer?.phone
                        ? StringUtils.formatPhoneNumber(
                            invoice?.customer?.phone
                          )
                        : null}
                    </small>
                  </p>
                </td>
              </tr>
              <tr>
                <th className="p-2">
                  <h4 className="m-0">Ship To:</h4>
                </th>
                <td className="p-2">
                  <h4 className="m-0 font-weight-normal">
                    {invoice?.customer?.shippingAddress?.name}
                  </h4>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>
                      {invoice?.customer?.shippingAddress?.address1}
                    </small>
                  </p>
                  {invoice?.customer?.shippingAddress?.address2 ? (
                    <p className="m-0" style={{ lineHeight: 1.3 }}>
                      <small>
                        {invoice?.customer?.shippingAddress?.address2}
                      </small>
                    </p>
                  ) : null}
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>
                      {invoice?.customer?.shippingAddress?.city},{" "}
                      {invoice?.customer?.shippingAddress?.state}{" "}
                      {invoice?.customer?.shippingAddress?.zip}
                    </small>
                  </p>
                  <p className="m-0" style={{ lineHeight: 1.3 }}>
                    <small>
                      {invoice?.customer?.phone
                        ? StringUtils.formatPhoneNumber(
                            invoice?.customer?.phone
                          )
                        : null}
                    </small>
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="12">
          <h4 className="mb-3">Items:</h4>
        </Col>
        <Col xs="12">
          <Table className="table-sm table-bordered">
            <thead>
              <th className="p-2 bg-superlight text-dark">Item</th>
              <th className="p-2 bg-superlight text-dark">Qty</th>
              <th className="p-2 bg-superlight text-dark">Price</th>
              <th className="text-right p-2 bg-superlight text-dark">
                Subtotal
              </th>
            </thead>
            <tbody>
              {invoice?.lineItems?.map((item, i) => (
                <tr>
                  <td
                    className="p-2"
                    style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                  >
                    {item.name}
                  </td>
                  <td className="p-2" style={{ verticalAlign: "middle" }}>
                    {item.quantity}
                  </td>
                  <td className="p-2" style={{ verticalAlign: "middle" }}>
                    {StringUtils.centsToCurrency(item.unitPrice)}
                  </td>
                  <td
                    className="p-2 text-right"
                    style={{ verticalAlign: "middle" }}
                  >
                    {StringUtils.centsToCurrency(item.totalPrice)}
                  </td>
                </tr>
              ))}

              <tr>
                <td
                  colSpan={2}
                  className="p-2"
                  style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                ></td>

                <th
                  className="p-2 font-weight-bold text-dark text-uppercase text-right"
                  style={{ verticalAlign: "middle", fontSize: "0.65rem" }}
                >
                  Subtotal:
                </th>
                <td
                  className="p-2 text-right"
                  style={{ verticalAlign: "middle" }}
                >
                  {StringUtils.centsToCurrency(invoice?.subtotalPrice)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  className="p-2"
                  style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                >
                  {invoice?.taxDescription ?? ""}
                </td>

                <th
                  className="p-2 font-weight-bold text-dark text-uppercase text-right"
                  style={{ verticalAlign: "middle", fontSize: "0.65rem" }}
                >
                  Tax:
                </th>
                <td
                  className="p-2 text-right"
                  style={{ verticalAlign: "middle" }}
                >
                  {StringUtils.centsToCurrency(invoice?.tax)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  className="p-2"
                  style={{ whiteSpace: "pre-wrap", verticalAlign: "middle" }}
                ></td>

                <th
                  className="p-2 font-weight-bold text-dark text-uppercase text-right"
                  style={{ verticalAlign: "middle", fontSize: "0.65rem" }}
                >
                  Total:
                </th>
                <td
                  className="p-2 text-right"
                  style={{ verticalAlign: "middle" }}
                >
                  {StringUtils.centsToCurrency(invoice?.totalPrice)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <div className="mt-4 pt-4 border-top">
        <div
          className="cursor-pointer"
          onClick={() => {
            setAttemptRecentHistoryExpanded(!attemptRecentHistoryExpanded);
          }}
        >
          <Row className="align-items-center mb-3">
            <Col xs="">
              <h3 className="mb-0">
                <i
                  className={`${
                    attemptRecentHistoryExpanded
                      ? "mdi mdi-chevron-down"
                      : "mdi mdi-chevron-right"
                  } pr-2`}
                ></i>
                Latest Authorize.Net Attempt
              </h3>
            </Col>
            <Col xs="auto">
              {invoice?.attemptHistory?.length ? (
                <>
                  {invoice?.attemptHistory[invoice?.attemptHistory.length - 1]
                    ?.error ? (
                    <>
                      <Badge color="danger">Error</Badge>
                    </>
                  ) : (
                    <>
                      <Badge color="success">Success</Badge>
                    </>
                  )}
                </>
              ) : (
                <>--</>
              )}
            </Col>
          </Row>
        </div>
        <Collapse isOpen={attemptRecentHistoryExpanded}>
          <Card className="border-lighter mb-3">
            <CardBody>
              {invoice?.attemptHistory?.length ? (
                <>
                  <div>
                    <Row className="align-items-center pb-2">
                      <Col xs="auto">
                        <div>
                          {invoice?.attemptHistory[
                            invoice?.attemptHistory.length - 1
                          ]?.error ? (
                            <Badge color="danger">Error</Badge>
                          ) : (
                            <Badge color="success">Success</Badge>
                          )}
                        </div>
                      </Col>
                      <Col xs="" className="text-right">
                        <div className="text-muted">
                          <small>
                            {moment(
                              invoice?.attemptHistory[
                                invoice?.attemptHistory.length - 1
                              ].eventTimestamp
                            ).format("MM/DD/YY hh:mmA")}
                          </small>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="mb-0">
                          {
                            invoice?.attemptHistory[
                              invoice?.attemptHistory.length - 1
                            ].message
                          }
                        </h4>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          color="dark"
                          outline
                          onClick={() => {
                            setViewRecentData(!viewRecentData);
                          }}
                        >
                          View Data
                        </Button>
                      </Col>
                    </Row>
                    {viewRecentData ? (
                      <>
                        <div
                          className="mt-3 p-2 border bg-white"
                          dangerouslySetInnerHTML={{
                            __html: StringUtils.jsonToHTML(
                              invoice?.attemptHistory[
                                invoice?.attemptHistory.length - 1
                              ]
                            ),
                          }}
                        ></div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <>--</>
              )}
            </CardBody>
          </Card>
        </Collapse>
      </div>
      {invoice?.transactionDetails ? (
        <div className="mt-4 pt-4 border-top">
          <div
            className="cursor-pointer"
            onClick={() => {
              setTransactionExpanded(!transactionExpanded);
            }}
          >
            <Row className="align-items-center mb-3">
              <Col xs="">
                <h3 className="mb-0">
                  <i
                    className={`${
                      transactionExpanded
                        ? "mdi mdi-chevron-down"
                        : "mdi mdi-chevron-right"
                    } pr-2`}
                  ></i>
                  Transaction Details
                </h3>
              </Col>
            </Row>
          </div>
          <Collapse isOpen={transactionExpanded}>
            <Card className="border-lighter mb-3">
              <CardBody>
                <Row className="align-items-center pb-2">
                  <Col xs="auto">
                    <div>
                      <small>
                        #{invoice?.transactionDetails?.transactionID}
                      </small>
                    </div>
                  </Col>
                  <Col xs="">
                    <div className="text-muted text-right">
                      <small>
                        {moment(
                          invoice?.transactionDetails?.eventTimestamp
                        ).format("MM/DD/YY hh:mmA")}
                      </small>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs="">
                    <div className="text-dark">
                      {invoice?.transactionDetails?.error ? (
                        <h3 className="mb-0">Error processing transaction.</h3>
                      ) : (
                        <h3 className="mb-0">
                          Transaction for{" "}
                          {
                            invoice?.transactionDetails?.response
                              ?.createTransactionResponse?.transactionResponse
                              ?.accountType
                          }{" "}
                          {
                            invoice?.transactionDetails?.response
                              ?.createTransactionResponse?.transactionResponse
                              ?.accountNumber
                          }{" "}
                          successfully approved.
                        </h3>
                      )}
                    </div>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => {
                        setViewDetails(!viewDetails);
                      }}
                      className="m-0"
                      size="sm"
                      color="dark"
                      outline
                    >
                      View Data
                    </Button>
                  </Col>
                </Row>
                {viewDetails ? (
                  <>
                    <div
                      className="mt-3 p-2 border bg-white"
                      dangerouslySetInnerHTML={{
                        __html: StringUtils.jsonToHTML(
                          invoice?.transactionDetails
                        ),
                      }}
                    ></div>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </Collapse>
        </div>
      ) : null}

      <div className="mt-4 pt-4 border-top">
        <div
          className="cursor-pointer"
          onClick={() => {
            setAttemptHistoryExpanded(!attemptHistoryExpanded);
          }}
        >
          <Row className="align-items-center mb-3">
            <Col xs="">
              <h3 className="mb-0">
                <i
                  className={`${
                    attemptHistoryExpanded
                      ? "mdi mdi-chevron-down"
                      : "mdi mdi-chevron-right"
                  } pr-2`}
                ></i>
                Authorize.Net Attempt History
              </h3>
            </Col>
            <Col xs="auto">
              <Badge color="medium">
                {invoice?.attemptHistory?.length} Attempt
                {invoice?.attemptHistory?.length != 1 ? "s" : ""}
              </Badge>
            </Col>
          </Row>
        </div>
        <Collapse isOpen={attemptHistoryExpanded}>
          <Card className="border-lighter mb-3">
            <CardBody>
              {invoice?.attemptHistory?.map((h, i) => (
                <div key={i}>
                  <div>
                    <Row className="align-items-center pb-2">
                      <Col xs="auto">
                        <div>
                          {h?.error ? (
                            <Badge color="danger">Error</Badge>
                          ) : (
                            <Badge color="success">Success</Badge>
                          )}
                        </div>
                      </Col>
                      <Col xs="" className="text-right">
                        <div className="text-muted">
                          <small>
                            {moment(h.eventTimestamp).format("MM/DD/YY hh:mmA")}
                          </small>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="mb-0">{h.message}</h4>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          color="dark"
                          outline
                          onClick={() => {
                            viewData === i ? setViewData(null) : setViewData(i);
                          }}
                        >
                          View Data
                        </Button>
                      </Col>
                    </Row>
                    {viewData === i ? (
                      <>
                        <div
                          className="mt-3 p-2 border bg-white"
                          dangerouslySetInnerHTML={{
                            __html: StringUtils.jsonToHTML(h),
                          }}
                        ></div>
                      </>
                    ) : null}
                  </div>
                  {i != invoice?.attemptHistory.length - 1 ? (
                    <hr className="my-2" />
                  ) : null}
                </div>
              ))}
            </CardBody>
          </Card>
        </Collapse>
      </div>
      <div className="mt-4 pt-4 border-top">
        <div
          className="cursor-pointer"
          onClick={() => {
            setHistoryExpanded(!historyExpanded);
          }}
        >
          <Row className="align-items-center mb-3">
            <Col xs="">
              <h3 className="mb-0">
                <i
                  className={`${
                    historyExpanded
                      ? "mdi mdi-chevron-down"
                      : "mdi mdi-chevron-right"
                  } pr-2`}
                ></i>
                Invoice History
              </h3>
            </Col>
            <Col xs="auto">
              <Badge color="medium">
                {invoice?.history?.length} Event
                {invoice?.history?.length != 1 ? "s" : ""}
              </Badge>
            </Col>
          </Row>
        </div>
        <Collapse isOpen={historyExpanded}>
          <Card className="border-lighter mb-3">
            <CardBody>
              {invoice?.history?.map((h, i) => (
                <div key={i}>
                  <div className="text-muted">
                    <small>
                      {moment(h.eventTimestamp).format("MM/DD/YY hh:mmA")}
                    </small>
                  </div>
                  <div>
                    <h4 className="mb-0">{h.title}</h4>
                  </div>
                  {i != invoice?.history.length - 1 ? (
                    <hr className="my-2" />
                  ) : null}
                </div>
              ))}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </>
  );
}

export default Invoice;
