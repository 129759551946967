import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import ProductGroupRow from "components/ProductGroupRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import CreateProductGroupModal from "components/CreateProductGroupModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";

class ProductGroupList extends React.Component {
  state = {
    groups: [],
    loading: true,
  };

  loadProductGroups() {
    APIV2.getProductGroups()
      .then(
        (data) => {
          if (data && data.data && data.data.planTypes) {
            this.setState({
              groups: data.data.planTypes,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadProductGroups();

    PubSub.subscribe(Event.PRODUCT_GROUP.DELETED, (data) => {
      let g = filter(this.state.groups, (a) => {
        return a._id != data.id;
      });

      this.setState({
        groups: g,
      });
    });

    PubSub.subscribe(Event.PRODUCT_GROUP.CREATED, (account) => {
      let g = this.state.groups;

      g.push(account);

      this.setState({
        groups: g,
      });
    });

    PubSub.subscribe(Event.PRODUCT_GROUP.MODIFIED, (a) => {
      let g = this.state.groups;
      let idx = findIndex(g, { _id: a._id });

      g[idx] = a;

      this.setState({
        groups: g,
      });
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            title="Product Categories"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                PubSub.publish(Event.PRODUCT_GROUP.CREATE_OPEN);
              },
            }}
          />
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  sticky={false}
                  loading={this.state.loading}
                  value={{ name: "All", id: "All", _id: "All" }}
                  options={[{ name: "All", id: "All", _id: "All" }]}
                ></CardSelectorHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? (
                      <tr>
                        <td colSpan="4">Loading...</td>
                      </tr>
                    ) : null}
                    {!this.state.loading && !this.state.groups.length ? (
                      <tr>
                        <td colSpan="4">No product categories.</td>
                      </tr>
                    ) : null}
                    {this.state.groups.map((group, i) => (
                      <ProductGroupRow key={i} group={group}></ProductGroupRow>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
        <CreateProductGroupModal></CreateProductGroupModal>
      </>
    );
  }
}

export default ProductGroupList;
