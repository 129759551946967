import ReactDOM from "react-dom";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { Chart, Axis, Tooltip, Geom, PieChart } from "bizcharts";
import { useDeepCompareMemo } from "use-deep-compare";
import { Row, Col, Statistic, Table } from "antd";
import APIV2 from "../../lib/APIV2";
import DataAPI from "lib/DataAPI";

const stackedChartData = (resultSet) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};

const LineChartRenderer = ({ resultSet }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet]
  );
  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom type="line" position="x*measure" size={2} color="color" />
    </Chart>
  );
};

const BarChartRenderer = ({ resultSet, pivotConfig }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet.serialize()]
  );
  const stacked = !(pivotConfig.x || []).includes("measures");
  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip />
      <Geom
        type="interval"
        position="x*measure"
        color="color"
        adjust={stacked ? "stack" : "dodge"}
      />
    </Chart>
  );
};

const AreaChartRenderer = ({ resultSet }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet.serialize()]
  );
  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom
        type="area"
        adjust="stack"
        position="x*measure"
        size={2}
        color="color"
      />
    </Chart>
  );
};

const PieChartRenderer = ({ resultSet }) => {
  const [data, angleField] = useDeepCompareMemo(() => {
    return [resultSet.chartPivot(), resultSet.series()];
  }, [resultSet]);
  return (
    <PieChart
      data={data}
      radius={0.8}
      angleField={angleField[0].key}
      colorField="x"
      label={{
        visible: true,
        offset: 20,
      }}
      legend={{
        position: "bottom",
      }}
    />
  );
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }

    if (type === "boolean") {
      return Boolean(value).toString();
    }

    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }

    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const TableRenderer = ({ resultSet, pivotConfig }) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);
  return (
    <Table pagination={false} columns={tableColumns} dataSource={dataSource} />
  );
};

const cubejsApi = cubejs(DataAPI.getAuthToken(), {
  apiUrl: DataAPI.getEnvironment(),
});

const renderChart = ({ resultSet, error, pivotConfig }) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <Spin />;
  }

  return <BarChartRenderer resultSet={resultSet} pivotConfig={pivotConfig} />;
};

class LeadsByStorePieChart extends React.Component {
  render() {
    return (
      <QueryRenderer
        query={{
          measures: ["CustomerLeads.count"],
          timeDimensions: [
            {
              dimension: "CustomerLeads.createdat",
              dateRange: "Last 30 days",
            },
          ],
          order: [["CustomerLeads.count", "asc"]],
          dimensions: ["Stores.name"],
          limit: 5000,
          filters: [
            {
              member: "Stores.name",
              operator: "set",
            },
          ],
        }}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={false}
        render={(props) =>
          renderChart({
            ...props,
            chartType: "bar",
            pivotConfig: {
              x: ["CustomerLeads.storeid"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
          })
        }
      />
    );
  }
}

export default LeadsByStorePieChart;
