import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";
import CustomerAllInvoicesDrawer from "components/Drawers/CustomerAllInvoicesDrawer";
import CustomerCreateInvoiceDrawer from "components/Drawers/CustomerCreateInvoiceDrawer";

class CustomerInvoicesWidget extends React.Component {
  state = {
    expand: false,
    openInvoice: null,
  };

  fetchRecentInvoices() {
    this.setState({
      loading: true,
    });

    let query = {
      "customer.id": this?.props?.match?.params?.customerID,
    };

    let sortBy = { dueOn: -1 };

    APIV2.getInvoicesForStore(
      this.props?.match?.params?.storeID,
      1,
      10,
      query,
      sortBy
    )
      .then(
        (data) => {
          console.log(data?.data?.invoices);

          this.setState({ recentInvoices: data?.data?.invoices ?? [] });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchRecentInvoices();

    PubSub.subscribe(Event.INVOICE.CREATED, () => {
      this.fetchRecentInvoices();
    });

    PubSub.subscribe(Event.INVOICE.MODIFIED, (order) => {
      if (this.state?.recentInvoices?.length) {
        let idx = _.findIndex(this.state?.recentInvoices, { _id: order?._id });

        if (idx >= 0) {
          let od = this.state.recentInvoices;
          od[idx] = order;

          this.setState({
            recentInvoices: od,
          });
        }
      }

      if (this?.state?.openInvoice?._id == order?._id) {
        this.setState({
          openInvoice: order,
        });
      }

      if (this.state?.invoices?.length) {
        let idx = _.findIndex(this.state.invoices, { _id: order?._id });

        if (idx >= 0) {
          let od = this.state.invoices;
          od[idx] = order;

          this.setState({
            invoices: od,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.customerID !=
        this.props?.match?.params?.customerID ||
      prevProps?.match?.params?.storeID != this.props?.match?.params?.storeID
    ) {
      this.fetchRecentInvoices();
    }
  }

  openInvoiceDrawer(invoice) {
    this.setState({
      openInvoice: invoice,
      invoiceDrawer: true,
    });
  }

  invoiceRow(c) {
    return (
      <Row className="align-items-center">
        <Col xs="8" sm="6" md="4">
          <h4 className="mb-0">{c.name}</h4>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {moment(c.createdAt).format("MM/DD/YY hh:mm A")}
          </p>
        </Col>
        <Col xs="4" sm="6" md="2" className="text-right text-sm-left">
          {c.status == "PAID" ? (
            <>
              <Badge color="success">Paid</Badge>
            </>
          ) : null}
          {c.status == "UNPAID" ? (
            <>
              <Badge color="" className="bg-yellow text-dark">
                UNPAID
              </Badge>
            </>
          ) : null}
          {c.status != "PAID" && c.status != "UNPAID" && (
            <>
              <Badge color="medium">{c.status}</Badge>
            </>
          )}
        </Col>
        <Col xs="6" sm="6" md="3" className="d-none d-sm-flex">
          <div style={{ fontSize: "15px" }}>
            {!isNaN(c.totalPrice) ? (
              <>{StringUtils.centsToCurrency(c.totalPrice)}</>
            ) : (
              <>--</>
            )}
          </div>
        </Col>
        <Col xs="6" sm="6" md="2">
          <div style={{ fontSize: "15px" }}>
            {(c.dueOnString && c.status == "PENDING") ||
            c.status == "UNPAID" ||
            c.status == "PAID" ? (
              <>
                <span className="d-inline-block d-md-none">Due:&nbsp;</span>
                {moment(c.dueOn ?? c.dueOnString).format("MM/DD/YYYY")}
              </>
            ) : (
              <>--</>
            )}
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Recent Invoices</h3>
                  </Col>
                  <Col xs="auto">
                    {/*<Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modified: true,
                          modifyImage: true,
                          imageURL: "",
                        });
                      }}
                    >
                      Mark All <i className="mdi mdi-chevron-down"></i>
                    </Button>*/}

                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ openCreate: true });
                      }}
                    >
                      New Invoice
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.recentInvoices?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12">
                        <h3 className="m-0">No invoices available.</h3>
                        <p>
                          {this.props?.customer?.name
                            ? StringUtils.getFirstName(
                                this.props?.customer?.name
                              )
                            : "This customer"}{" "}
                          does not have any invoices.
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.recentInvoices?.length > 0 && (
                  <div className="px-4 py-2 bg-superlight border-bottom">
                    <Row>
                      <Col xs="12" sm="6" md="4">
                        <h4 className="m-0">Invoice</h4>
                      </Col>
                      <Col xs="12" sm="6" md="2" className="d-none d-sm-flex">
                        <h4 className="m-0">Status</h4>
                      </Col>

                      <Col xs="12" sm="6" md="3" className="d-none d-sm-flex">
                        <h4 className="m-0">Total</h4>
                      </Col>
                      <Col xs="12" sm="6" md="2" className="d-none d-sm-flex">
                        <h4 className="m-0">Due On</h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {this.state?.recentInvoices?.map((invoice, i) => (
                  <div key={invoice?._id}>
                    <div
                      className="px-4 py-3 cursor-pointer bg-superlight--hover"
                      onClick={() => {
                        this.openInvoiceDrawer(invoice);
                      }}
                    >
                      {this.invoiceRow(invoice)}
                    </div>
                    {i != this.state?.recentInvoices?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => {
                    this.setState({ openAll: true });
                  }}
                >
                  View All <ArrowText></ArrowText>
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
        <CustomerInvoiceDrawer
          invoice={this.state.openInvoice}
          customer={this.props.customer}
          open={this.state.invoiceDrawer}
          onClose={() => {
            this.setState({ invoiceDrawer: false });
          }}
        ></CustomerInvoiceDrawer>
        <CustomerAllInvoicesDrawer
          customer={this.props.customer}
          open={this.state.openAll}
          onClose={() => {
            this.setState({ openAll: false });
          }}
        />
        <CustomerCreateInvoiceDrawer
          customer={this.props.customer}
          open={this.state.openCreate}
          onClose={() => {
            this.setState({ openCreate: false });
          }}
        ></CustomerCreateInvoiceDrawer>
      </>
    );
  }
}

export default withRouter(CustomerInvoicesWidget);
