import CustomerCreateInvoiceDrawer from "components/Drawers/CustomerCreateInvoiceDrawer";
import StringUtils from "lib/StringUtils";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Badge from "reactstrap/lib/Badge";
import CustomerAccountBalanceWidget from "./CustomerAccountBalanceWidget";
import CustomerInvoicesWidget from "./CustomerInvoicesWidget";
import { Visa, Mastercard, Amex, Discover } from "react-pay-icons";
import NumberFormat from "react-number-format";
import APIV2 from "lib/APIV2";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import Alert from "reactstrap/lib/Alert";
import { Capacitor } from "@capacitor/core";
import { Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";

class CustomerPaymentMethodWidget extends React.Component {
  state = {
    expand: false,
    number: {
      value: null,
    },
    cvc: {
      value: null,
    },
    expiry: {
      value: null,
    },
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleNumberChange(value) {
    this.setState({
      number: value,
    });
  }

  handleCVCChange(value) {
    this.setState({
      cvc: value,
    });
  }

  handleExpiryChange(value) {
    this.setState({
      expiry: value,
    });
  }

  getStore() {
    APIV2.getStoreByID(this?.props?.customer?.storeID).then((data) => {
      this.setState({
        store: data?.data?.store,
      });
    });
  }

  componentDidMount() {
    if (this.props.customer) {
      this.getStore();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer != prevProps.customer) {
      this.getStore();
    }
  }

  removePayment() {
    swal({
      title: "Remove Payment Method",
      text: `Are you sure you want to remove the member's payment method?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        removing: true,
      });

      APIV2.removeCustomerPaymentMethod(
        this.props?.customer?.storeID,
        this.props?.customer?._id
      )
        .then(
          (data) => {
            PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);
          },
          (e) => {
            this.setState({
              errorMsg:
                "Oops! We're having trouble removing the payment method. Please try again.",
            });
          }
        )
        .finally(() => {
          this.setState({
            removing: false,
          });
        });
    });
  }

  submit() {
    console.log("Submit");

    this.setState({
      errorMsg: "",
      submitting: true,
    });

    let dateArr = this.state.expiry.formattedValue.split("/");

    let m = dateArr[0];
    let y = dateArr[1];

    var transactionID = function () {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      return "transaction_" + Math.random().toString(36).substr(2, 9);
    };

    console.log("submitting card details", {
      name: this.state.cardName,
      cc: this.state.number?.value,
      m,
      y,
      cvc: this.state.cvc?.value,
    });

    APIV2.submitCardDetails(
      this.state.store.operations.billingAccount.publicKey,
      this.state.store.operations.billingAccount.loginID,
      this.state.cardName,
      this.state.number.value,
      m,
      y,
      this.state.cvc.value
    ).then(
      (resp) => {
        console.log("card details submitted");

        if (resp.messages.resultCode == "Error") {
          this.setState({
            errorMsg:
              "We're unable to update the payment method. Authorize.net Error: " +
              resp.messages.message[0].text,
            submitting: false,
          });

          setTimeout(() => {
            this.setState({
              errorMsg: "",
            });
          }, 4000);

          return;
        }

        console.log(resp);

        console.log("submitting card details for update");

        APIV2.updateCustomerPaymentMethod(
          this.props?.customer?.storeID,
          this.props?.customer?._id,
          resp?.opaqueData
        )
          .then(
            (data) => {
              PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

              this.setState({
                editPayment: false,
                number: {
                  value: null,
                },
                cvc: {
                  value: null,
                },
                expiry: {
                  value: null,
                },
                cardName: "",
              });
            },
            (err) => {
              this.setState({
                errorMsg:
                  "Oops! We're having trouble updating the payment method. Please try again.",
              });
            }
          )
          .finally(() => {
            this.setState({
              submitting: false,
            });
          });
      },
      (e) => {
        console.log(e);

        this.setState({
          errorMsg:
            "Oops! We're not able to validate the payment method with Authorize.net. Please try again.",
          submitting: false,
        });

        setTimeout(() => {
          this.setState({
            errorMsg: "",
          });
        }, 4000);
      }
    );
  }

  render() {
    return (
      <>
        <Card className="mb-4 border shadow">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Payment Method</h3>
              </div>
              <Col xs="auto">
                {!this.state.editPayment ? (
                  <>
                    {this.props?.customer?.paymentMethod?.customerProfileID &&
                    this.props?.customer?.paymentMethod?.paymentProfileID ? (
                      <Button
                        size="sm"
                        color="danger"
                        outline
                        disabled={this.state.removing}
                        onClick={() => {
                          this.removePayment();
                        }}
                      >
                        {this.state.removing ? (
                          <>
                            <Spinner size="sm"></Spinner>
                          </>
                        ) : (
                          "Remove"
                        )}
                      </Button>
                    ) : null}
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          editPayment: true,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          editPayment: false,
                          number: {
                            value: null,
                          },
                          cvc: {
                            value: null,
                          },
                          expiry: {
                            value: null,
                          },
                          cardName: "",
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      disabled={
                        !this.state?.cardName ||
                        !this.state?.number?.value ||
                        !this.state?.cvc?.value ||
                        !this?.state?.expiry?.value ||
                        this.state.submitting ||
                        !this.props.customer?.billingAddress?.address1 ||
                        !this.props.customer?.billingAddress?.city ||
                        !this.props.customer?.billingAddress?.state ||
                        !this.props.customer?.billingAddress?.zip
                      }
                      onClick={() => {
                        this.submit();
                      }}
                    >
                      {this.state.submitting ? (
                        <>
                          <Spinner size="sm" color="white"></Spinner>
                        </>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </CardHeader>
          {this?.state?.errorMsg ? (
            <>
              <CardHeader>
                <Alert color="danger">{this.state.errorMsg}</Alert>
              </CardHeader>
            </>
          ) : null}
          {this.state.editPayment ? (
            <>
              <CardHeader>
                <Row className="align-items-center">
                  <Col className="font-weight-bold text-dark">
                    <h5 className="mb-0 text-uppercase text-muted">
                      Credit Card
                    </h5>
                  </Col>
                  <Col xs="auto">
                    <Visa
                      style={{
                        display: "inline-block",
                        marginRight: 6,
                        width: 35,
                        borderRadius: 2,
                      }}
                    />
                    <Mastercard
                      style={{
                        display: "inline-block",
                        marginRight: 6,
                        width: 35,
                        borderRadius: 2,
                      }}
                    />
                    <Amex
                      style={{
                        display: "inline-block",
                        marginRight: 6,
                        width: 35,
                        borderRadius: 2,
                      }}
                    />
                    <Discover
                      style={{
                        display: "inline-block",
                        marginRight: 6,
                        width: 35,
                        borderRadius: 2,
                      }}
                    />
                    <small>and more..</small>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  {!this.props.customer?.billingAddress?.address1 ||
                  !this.props.customer?.billingAddress?.city ||
                  !this.props.customer?.billingAddress?.state ||
                  !this.props.customer?.billingAddress?.zip ? (
                    <>
                      <Col xs="12">
                        <Alert color="danger">
                          This member does not have a billing address. Please
                          add a billing address to continue.
                        </Alert>
                      </Col>
                    </>
                  ) : null}
                  <Col xs="12" sm="12" md="12" lg="12">
                    <h5>
                      Card Number <span className="text-danger">*</span>
                    </h5>
                    <InputGroup className="mb-3">
                      <NumberFormat
                        autoComplete="cc-number"
                        placeholder="Card number"
                        name="number"
                        format="#### #### #### ####"
                        className="form-control"
                        required={true}
                        value={this.state.number.value}
                        onValueChange={this.handleNumberChange.bind(this)}
                      />
                      <InputGroupAddon
                        addonType="append"
                        style={{ height: "46px" }}
                      >
                        <InputGroupText>
                          <i className="mdi mdi-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <h5>
                      Name On Card <span className="text-danger">*</span>
                    </h5>
                    <Input
                      autoComplete="cc-name"
                      name="cardName"
                      value={this.state.cardName}
                      onChange={this.handleInputChange.bind(this)}
                      id="name"
                      placeholder="Name On Card"
                    ></Input>
                    <Row className="mt-3">
                      <Col xs="12" md="6">
                        <h5>
                          Expiration Date <span className="text-danger">*</span>
                        </h5>
                        <NumberFormat
                          autoComplete="cc-exp"
                          placeholder="Expiration date (MM/YY)"
                          name="expiry"
                          format="##/##"
                          className="form-control mb-3"
                          required={true}
                          value={this.state.expiry.value}
                          onValueChange={this.handleExpiryChange.bind(this)}
                        />
                      </Col>
                      <Col xs="12" md="6">
                        <h5>
                          Security Code <span className="text-danger">*</span>
                        </h5>
                        <NumberFormat
                          autoComplete="cc-csc"
                          allowLeadingZeros={true}
                          placeholder="Security code"
                          name="cvc"
                          type="text"
                          className="form-control mb-3"
                          required={true}
                          value={this.state.cvc.value}
                          onValueChange={this.handleCVCChange.bind(this)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </>
          ) : (
            <>
              <CardBody className="">
                <Row className="align-items-center">
                  {this.props?.customer?.paymentMethod?.customerProfileID &&
                  this.props.customer?.paymentMethod?.paymentProfileID ? (
                    <>
                      <Col xs="12">
                        <h3 className="mb-0 text-dark">
                          {this.props.customer?.paymentMethod?.type ? (
                            <span className="text-uppercase">
                              {this.props.customer?.paymentMethod?.type}&nbsp;
                            </span>
                          ) : (
                            ""
                          )}
                          {this.props.customer?.paymentMethod?.last4
                            ? `x${this.props.customer?.paymentMethod?.last4}`
                            : "Credit Card"}
                        </h3>
                        <div className="table-responsive">
                          <Table className="mt-4 border">
                            <thead>
                              <th className="bg-superlight text-dark">
                                Provider
                              </th>
                              <th className="bg-superlight text-dark">
                                Customer ID
                              </th>
                              <th className="bg-superlight text-dark">
                                Payment Profile ID
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Authorize.net</td>
                                <td>
                                  {
                                    this.props?.customer?.paymentMethod
                                      ?.customerProfileID
                                  }
                                </td>
                                <td>
                                  {
                                    this.props?.customer?.paymentMethod
                                      ?.paymentProfileID
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <Col xs="12">
                      <h3 className="text-dark" style={{ lineHeight: 1.2 }}>
                        <Badge color="" className="bg-yellow text-dark">
                          No Payment Method
                        </Badge>
                      </h3>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default CustomerPaymentMethodWidget;
