import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerOrderRow from "components/CustomerOrderRow";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import LinkUtils from "lib/LinkUtils";

class StoreFulfillmentOrderListView extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    orders: [],
    compareToOptions: [],
    count: 25,
    page: 1,
    selectedOrders: [],
    searchResultOrders: [],
    active: {
      id: "All",
    },
    filters: null,
    orderBy: {
      "customer.name": 1,
    },
  };

  getAvailableDates() {
    return new Promise((resolve) => {
      APIV2.getStoreSubscriptionFulfillmentDates(
        this.props.match?.params?.storeID
      )
        .then(
          (data) => {
            let dates = data.data.dates;

            dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

            this.setState({
              availableDates: dates,
            });
          },
          (e) => {
            this.setState({
              availableDates: [],
            });

            console.error(e);
          }
        )
        .finally(() => {
          resolve();
        });
    });
  }

  _rerunStatus(reportID, jobID, callback) {
    APIV2.checkRerunSubscriptionFulfillmentReportStatus(reportID, jobID).then(
      (data) => {
        console.log(data);

        callback(data);
      },
      (e) => {
        console.log(e);

        callback(null, e);
      }
    );
  }

  checkRerunStatus(reportID, jobID) {
    let self = this;

    let load = (dateString) => {
      this.loadSummary(dateString);
    };

    return new Promise((resolve, reject) => {
      this._rerunStatus(reportID, jobID, function callback(data, error) {
        if (error) {
          resolve();

          return; // Don't continue after error
        }

        if (data?.data?.job?.progress == 100) {
          resolve();

          load(self.state.fulfillmentDateString);

          return;
        }

        setTimeout(() => {
          self._rerunStatus(reportID, jobID, callback);
        }, 5000);
      });
    });
  }

  rerunReport(reportID) {
    APIV2.rerunSubscriptionFulfillmentReport(reportID).then(
      (data) => {
        this.setState({
          rerunning: true,
          rerunJob: data.data.job,
        });

        this.checkRerunStatus(reportID, data.data.job.id).then(() => {
          console.log("DONE");
        });
      },
      (e) => {
        window.alert("Unable to rerun report. Try again.");
      }
    );
  }

  loadOrders(page = 1, count = 25, filters = null, orderBy = null) {
    this.setState({
      loadingOrders: true,
      selectedOrders: [],
    });

    if (!this.state.report) {
      this.setState({
        loadingOrders: false,
      });

      return;
    }

    APIV2.getOrdersForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (data.data.orders.length) {
              orders = data.data.orders;
            }

            console.log(orders[0]?.createdAt);

            this.setState({
              orders: orders,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
        });
      });
  }

  searchOrders(page = 1, count = 25, filters = null, orderBy = null) {
    this.setState({
      loadingOrders: true,
      searchResults: true,
      searchPage: page,
      selectedOrders: [],
    });

    if (filters) {
      this.setState({
        searchFilters: filters,
      });
    }

    APIV2.getOrdersForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (data.data.orders.length) {
              orders = data.data.orders;
            }

            this.setState({
              searchResultOrders: orders,
              searchPagination: data.data.pagination,
              searchResultOut: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
          searchLoading: false,
        });
      });
  }

  performSearch() {
    let filters = {
      $or: [
        { "customer.name": { $regex: this.state.search, $options: "i" } },
        { "customer.email": { $regex: this.state.search, $options: "i" } },
        { "customer.phone": { $regex: this.state.search, $options: "i" } },

        { status: { $regex: this.state.search, $options: "i" } },
      ],
      fulfillmentDateString: {
        $regex: this.state.fulfillmentDateString,
        $options: "i",
      },
    };

    if (this.state.active?.id == "All") {
      filters.$or.push({
        "subscription.plan.name": {
          $regex: this.state.search,
          $options: "i",
        },
      });
    } else if (this.state.active?.id) {
      filters["subscription.plan.planType.name"] = this.state.active.id;
    }

    this.setState({
      searchFilters: filters,
    });

    this.searchOrders(1, 25, filters, this.state.orderBy);
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
      searchResults: false,
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadSummary(dateString) {
    if (!dateString) {
      return;
    }

    let strMoment = moment(dateString)
      .hours(0)
      .minutes(0)
      .second(0)
      .millisecond(0);

    let now = moment();

    if (now.toDate() < strMoment.toDate()) {
      let secondaryActions = [
        {
          id: "printLabels",
          name: (
            <>
              {this.state.selectedOrders?.length ? (
                <>Print {this.state.selectedOrders?.length} Selected</>
              ) : (
                "Print Labels"
              )}
            </>
          ),
          onClick: () => {
            LinkUtils.openInNewTab(
              "/storefront-print/" +
                this.props.match?.params?.storeID +
                "/fulfillment/orders/labels?date=" +
                this.state.fulfillmentDateString,
              true
            );
          },
        },
        {
          id: "printLabels",
          name: <>Download Boxing List</>,
          onClick: () => {
            LinkUtils.openInNewTab(
              "/storefront-print/" +
                this.props.match?.params?.storeID +
                "/fulfillment/orders/boxing-list/csv?date=" +
                this.state.fulfillmentDateString,
              true
            );
          },
        },
      ];

      let user = LocalStorage.get(Constant.CACHE.USER);

      console.log(user);

      if (user?.globalPermissions?.length) {
        secondaryActions.push({
          name: <>Re-Run Fulfillment Report</>,
          onClick: () => {
            swal({
              title: `Re-Run Fulfillment For ${this.state.fulfillmentMoment?.format(
                "MMM Do YYYY"
              )}`,
              text: "Are you sure you want to re-run this fulfillment report? This will delete all existing orders and invoices.",
              icon: "warning",
              buttons: ["Nevermind", "Re-Run"],
              dangerMode: true,
            }).then((rerun) => {
              if (!rerun) {
                return;
              }

              this.rerunReport(this.state.report._id);
            });
          },
        });
      }

      this.setState({
        secondaryActions: secondaryActions,
      });
    } else {
      this.setState({
        secondaryActions: [
          {
            id: "printLabels",
            name: (
              <>
                {this.state.selectedOrders?.length ? (
                  <>Print {this.state.selectedOrders?.length} Selected</>
                ) : (
                  "Print Labels"
                )}
              </>
            ),
            onClick: () => {
              LinkUtils.openInNewTab(
                "/storefront-print/" +
                  this.props.match?.params?.storeID +
                  "/fulfillment/orders/labels?date=" +
                  this.state.fulfillmentDateString,
                true
              );
            },
          },
          {
            id: "printLabels",
            name: <>Download Boxing List</>,
            onClick: () => {
              LinkUtils.openInNewTab(
                "/storefront-print/" +
                  this.props.match?.params?.storeID +
                  "/fulfillment/orders/boxing-list/csv?date=" +
                  this.state.fulfillmentDateString,
                true
              );
            },
          },
        ],
      });
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState({
      loading: true,
      rerunning: false,
      noReport: false,
    });

    APIV2.getStoreSubscriptionFulfillmentSummaryForDate(
      this.props.match?.params?.storeID,
      dateString
    )
      .then(
        (data) => {
          let payload = {};

          payload.report = data.data.report;

          if (!data.data.report) {
            payload.noReport = true;
          }

          console.log(this.state.filters);

          if (!this.state.filters || !_.keys(this.state.filters)?.length) {
            this.setState(
              {
                filters: {
                  status: { $ne: "CANCELLED" },
                  fulfillmentDateString: {
                    $regex: this.state.fulfillmentDateString,
                    $options: "i",
                  },
                },
              },
              () => {
                console.log(this.state.filters);

                this.setState(payload, () => {
                  this.loadOrders(
                    1,
                    this.state.count,
                    this.state.filters,
                    this.state.orderBy
                  );
                });
              }
            );
          } else {
            this.setState(payload, () => {
              this.loadOrders(
                1,
                this.state.count,
                this.state.filters,
                this.state.orderBy
              );
            });
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (this.state.searchResults) {
      page = this.state.searchPage ? this.state.searchPage + count : 1 + count;
    }

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    if (this.state.searchResults) {
      this.searchOrders(
        page,
        this.state.count,
        this.state.searchFilters,
        this.state.orderBy
      );
    } else {
      this.loadOrders(
        page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
      },
      () => {
        this.getAvailableDates().then(() => {
          this.loadSummary(this.state.fulfillmentDateString);
        });
      }
    );

    PubSub.subscribe(Event.ORDER.MODIFIED, (order) => {
      let orderIdx = _.findIndex(this.state.orders, { _id: order._id });
      let searchIdx = _.findIndex(this.state.searchResultOrders, {
        _id: order._id,
      });

      if (orderIdx >= 0) {
        let orders = this.state.orders;

        orders[orderIdx] = order;

        this.setState({
          orders,
        });
      }

      if (searchIdx >= 0) {
        let searchResultOrders = this.state.searchResultOrders;

        searchResultOrders[searchIdx] = order;

        this.setState({
          searchResultOrders,
        });
      }
    });

    PubSub.subscribe(Event.ORDER.OPEN, (order) => {
      this.setState({
        drawer: true,
        viewOrder: order,
      });
    });
  }

  toggleSelected(order, val) {
    let selected = this.state.selectedOrders;

    if (!val) {
      selected = _.filter(selected, (o) => {
        return o._id != order._id;
      });
    } else {
      if (!this.isSelected(order)) {
        selected.push(_.clone(order));
      }
    }

    this.setState({ selectedOrders: selected }, () => {
      let secondaryActions = this.state.secondaryActions;

      secondaryActions = _.filter(secondaryActions, (a) => {
        return a.id != "printLabels";
      });

      secondaryActions = [
        {
          id: "printLabels",
          name: (
            <>
              {this.state.selectedOrders?.length ? (
                <>
                  Print Labels For {this.state.selectedOrders?.length} Selected
                </>
              ) : (
                "Print Labels"
              )}
            </>
          ),
          onClick: () => {
            LinkUtils.openInNewTab(
              "/storefront-print/" +
                this.props.match?.params?.storeID +
                "/fulfillment/orders/labels?date=" +
                this.state.fulfillmentDateString +
                "&orders=" +
                this.state.selectedOrders
                  .map((v) => {
                    return v._id;
                  })
                  .join(","),
              true
            );
          },
        },
      ].concat(secondaryActions);

      this.setState({ secondaryActions });
    });
  }

  toggleAllSelected(val) {
    this.setState(
      {
        selectedOrders: [],
      },
      () => {
        if (this.state.searchResults) {
          for (let i = 0; i < this.state.searchResultOrders.length; i++) {
            this.toggleSelected(this.state.searchResultOrders[i], val);
          }
        } else {
          for (let i = 0; i < this.state.orders.length; i++) {
            this.toggleSelected(this.state.orders[i], val);
          }
        }
      }
    );
  }

  isSelected(order) {
    return _.findWhere(this.state.selectedOrders, { _id: order?._id })
      ? true
      : false;
  }

  allSelected() {
    let all = true;

    if (this.state.searchResults) {
      for (let i = 0; i < this.state.searchResultOrders.length; i++) {
        if (!this.isSelected(this.state.searchResultOrders[i])) {
          all = false;
        }
      }
    } else {
      for (let i = 0; i < this.state.orders.length; i++) {
        if (!this.isSelected(this.state.orders[i])) {
          all = false;
        }
      }
    }

    return all;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            forceSecondaryDropdown={true}
            title="All Orders"
            showBackButton={false}
            secondaryActions={
              this.state.secondaryActions?.length
                ? this.state.secondaryActions
                : null
            }
          />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-4">
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="">
                <h3 className="m-0">
                  {this.state.fulfillmentMoment?.format("ddd, MMM Do YYYY")}
                </h3>
              </Col>
              <Col xs="12" sm="6" md="auto">
                <DatePicker
                  oneTap
                  value={this.state.fulfillmentDate}
                  onChange={(v) => {
                    let mom = moment(v.toISOString());

                    mom.set("hours", 0).set("minutes", 0).set("seconds", 0);

                    this.setState(
                      {
                        fulfillmentDate: mom.toDate(),
                        fulfillmentMoment: mom,
                        fulfillmentDateString: mom.format("YYYY-MM-DD"),
                        orders: [],
                      },
                      () => {
                        this.loadSummary(
                          this.state.fulfillmentDateString,
                          this.state.comparisonValue
                        );
                      }
                    );
                  }}
                  className="mt-3 mt-md-0"
                  placeholder="Select Date"
                  style={{
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "800px",
                  }}
                  placement="autoVerticalEnd"
                  block
                  cleanable={false}
                  disabledDate={(date) => {
                    let mom = moment(date.toISOString());

                    if (!this.state.availableDates) {
                      return false;
                    }

                    return this.state.availableDates.indexOf(
                      mom.format("YYYY-MM-DD")
                    ) >= 0
                      ? false
                      : true;
                  }}
                />
              </Col>
            </Row>
          </div>

          {this.state.loading ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Loading Orders</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your orders for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : null}
          {this.state.rerunning ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Re-Running Report</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight, your fulfillment report is being created for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : null}
          {this.state.noReport ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">Order List Not Ready</h3>
                </CardHeader>
                <CardBody>
                  {moment().isBefore(this.state.fulfillmentMoment) ? (
                    <>
                      <p className="mb-0">
                        Check back on Thursday to see a list of this week's
                        orders.
                      </p>
                    </>
                  ) : (
                    <>
                      There are no orders available for fulfillment on{" "}
                      {this.state.fulfillmentMoment?.format(
                        "dddd, MMM Do YYYY"
                      )}
                      .
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading &&
          !this.state.rerunning &&
          !this.state.noReport ? (
            <>
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All",
                      id: "All",
                    },
                    {
                      name: "Lifestyle",
                      id: "Lifestyle",
                    },
                    {
                      name: "Athlete",
                      id: "Athlete",
                    },
                    {
                      name: "Cancelled",
                      id: "Cancelled",
                    },
                  ]}
                  onChange={(option) => {
                    let filters = null;

                    if (option.id == "All") {
                      filters = {
                        status: { $ne: "CANCELLED" },
                        fulfillmentDateString: {
                          $regex: this.state.fulfillmentDateString,
                          $options: "i",
                        },
                      };
                    } else if (option.id == "Lifestyle") {
                      filters = {
                        $or: [
                          {
                            "subscription.plan.planType.name": {
                              $regex: "Lifestyle",
                              $options: "i",
                            },
                          },
                          {
                            "subscription.plan.name": {
                              $regex: "Lifestyle",
                              $options: "i",
                            },
                          },
                        ],
                        status: { $ne: "CANCELLED" },
                        fulfillmentDateString: {
                          $regex: this.state.fulfillmentDateString,
                          $options: "i",
                        },
                      };
                    } else if (option.id == "Athlete") {
                      filters = {
                        $or: [
                          {
                            "subscription.plan.planType.name": {
                              $regex: "Athlete",
                              $options: "i",
                            },
                          },
                          {
                            "subscription.plan.name": {
                              $regex: "Athlete",
                              $options: "i",
                            },
                          },
                        ],
                        status: { $ne: "CANCELLED" },
                        fulfillmentDateString: {
                          $regex: this.state.fulfillmentDateString,
                          $options: "i",
                        },
                      };
                    } else if (option.id == "Cancelled") {
                      filters = {
                        status: { $eq: "CANCELLED" },
                        fulfillmentDateString: {
                          $regex: this.state.fulfillmentDateString,
                          $options: "i",
                        },
                      };
                    }

                    this.setState({
                      filters,
                      active: option,
                    });

                    this.setState({
                      searchResults: false,
                      search: "",
                    });

                    this.searchOrders(
                      1,
                      this.state.count,
                      filters,
                      this.state.orderBy
                    );
                  }}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search orders..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                    {/*<Col xs="6" sm="auto" className="mt-3 pr-0 mt-sm-0">
                      <SelectPicker
                        searchable={false}
                        size="sm"
                        placeholder="Sort By"
                        disabled={true}
                        data={[]}
                        block
                        placement="autoVerticalEnd"
                      />
                    </Col>
                    <Col xs="6" sm="auto" className="mt-3 mt-sm-0">
                      <Button
                        color="secondary"
                        size="sm"
                        block
                        outline
                        onClick={() => {
                          PubSub.publish(Event.FILTERS.CUSTOMERS_OPEN);
                        }}
                        disabled
                      >
                        Filter
                      </Button>
                    </Col>*/}
                  </Row>
                  <Row className="align-items-center mt-2">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      <div>
                        {this.state.searchResults ? (
                          <>
                            {this.state.searchResultOut?.start &&
                            this.state.searchResultOut?.end ? (
                              <>
                                Showing{" "}
                                {StringUtils.numberFormat(
                                  this.state.searchResultOut?.start
                                )}
                                -
                                {StringUtils.numberFormat(
                                  this.state.searchResultOut?.end
                                )}
                                {this.state.searchResultOut?.total ? (
                                  <>
                                    &nbsp;of{" "}
                                    {StringUtils.numberFormat(
                                      this.state.searchResultOut?.total
                                    )}{" "}
                                    orders
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <>---</>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.result?.start &&
                            this.state.result?.end ? (
                              <>
                                Showing{" "}
                                {StringUtils.numberFormat(
                                  this.state.result?.start
                                )}
                                -
                                {StringUtils.numberFormat(
                                  this.state.result?.end
                                )}
                                {this.state.result?.total ? (
                                  <>
                                    &nbsp;of{" "}
                                    {StringUtils.numberFormat(
                                      this.state.result?.total
                                    )}{" "}
                                    orders
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <>---</>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      {this.state.searchResults ? (
                        <>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            className="btn-icon-only"
                            outline
                            disabled={
                              this.state.searchPage == 1 ||
                              !this.state.searchPagination?.hasPrevious
                            }
                            onClick={() => {
                              this.incrementPage(-1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-left"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            disabled={!this.state.searchPagination?.hasNext}
                            className="btn-icon-only"
                            outline
                            onClick={() => {
                              this.incrementPage(1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-right"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            className="btn-icon-only"
                            outline
                            disabled={
                              this.state.page == 1 ||
                              !this.state.pagination?.hasPrevious
                            }
                            onClick={() => {
                              this.incrementPage(-1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-left"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                          <Button
                            style={{ height: "28px" }}
                            size="sm"
                            color="secondary"
                            disabled={!this.state.pagination?.hasNext}
                            className="btn-icon-only"
                            outline
                            onClick={() => {
                              this.incrementPage(1);
                            }}
                          >
                            <i
                              className="mdi mdi-chevron-right"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="auto" sm="auto" md="auto">
                        <Checkbox
                          checked={this.allSelected()}
                          indeterminate={
                            !this.allSelected() &&
                            this.state.selectedOrders?.length
                          }
                          onChange={(v, c) => {
                            this.toggleAllSelected(c);
                          }}
                        />
                      </Col>
                      <Col xs="" md="">
                        <Row>
                          <Col xs="" sm="" md="4" className="pl-0">
                            <h4 className="mb-0">Customer</h4>
                          </Col>
                          <Col
                            xs="6"
                            sm="6"
                            md="3"
                            className="d-none d-md-block"
                          >
                            <h4 className="mb-0">Plan</h4>
                          </Col>
                          <Col
                            xs="6"
                            sm="6"
                            md="2"
                            className="d-none d-md-block"
                          >
                            <h4 className="mb-0">Status</h4>
                          </Col>
                          <Col
                            xs="6"
                            sm="6"
                            md="3"
                            className="d-none d-md-block"
                          >
                            <h4
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                let dir = "ascending";

                                if (this.state.orderByOption == "createdAt") {
                                  if (
                                    this.state.orderByDirection == "descending"
                                  ) {
                                    return this.setState(
                                      {
                                        orderByOption: "",
                                        orderByDirection: "",
                                        orderBy: null,
                                      },
                                      () => {
                                        if (this.state.searchResults) {
                                          this.searchOrders(
                                            1,
                                            this.state.count,
                                            this.state.filters,
                                            this.state.orderBy
                                          );
                                        } else {
                                          this.loadOrders(
                                            1,
                                            this.state.count,
                                            this.state.filters,
                                            this.state.orderBy
                                          );
                                        }
                                      }
                                    );
                                  } else if (
                                    this.state.orderByDirection == "ascending"
                                  ) {
                                    dir = "descending";
                                  }
                                }

                                this.setState(
                                  {
                                    orderByOption: "createdAt",
                                    orderByDirection: dir,
                                    orderBy: {
                                      createdAt: dir == "descending" ? -1 : 1,
                                    },
                                  },
                                  () => {
                                    if (this.state.searchResults) {
                                      this.searchOrders(
                                        1,
                                        this.state.count,
                                        this.state.filters,
                                        this.state.orderBy
                                      );
                                    } else {
                                      this.loadOrders(
                                        1,
                                        this.state.count,
                                        this.state.filters,
                                        this.state.orderBy
                                      );
                                    }
                                  }
                                );
                              }}
                            >
                              Created
                              {this.state.orderByOption == "createdAt" ? (
                                <>
                                  <i
                                    className={`mdi mdi-sort-${this.state.orderByDirection} pl-1`}
                                  ></i>
                                </>
                              ) : (
                                <>
                                  <i className="mdi mdi-sort pl-1"></i>
                                </>
                              )}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingOrders || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    {this.state.searchResults ? (
                      <div>
                        {!this.state.searchResultOrders?.length &&
                        !this.state.searchLoading ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>
                                No orders found matching your search "
                                {this.state.search}"
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.searchResultOrders?.map((c, i) => (
                          <div
                            className={`${
                              i != this.state.orders.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            <CustomerOrderRow
                              onSelect={(v) => {
                                this.toggleSelected(v);
                              }}
                              selected={this.isSelected(c)}
                              order={c}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {!this.state.orders?.length ? (
                          <div className="px-3 py-2">
                            <Row>
                              <Col>No orders found.</Col>
                            </Row>
                          </div>
                        ) : null}

                        {this.state.orders?.map((c, i) => (
                          <div
                            className={`${
                              i != this.state.orders.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i}
                          >
                            <CustomerOrderRow
                              order={c}
                              onSelect={(v) => {
                                this.toggleSelected(c, v);
                              }}
                              selected={this.isSelected(c)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </>
          ) : null}
        </Container>
        <CustomerOrderDrawer
          order={this.state.viewOrder}
          onClose={() => {
            this.setState({ drawer: false });
          }}
          open={this.state.drawer}
        ></CustomerOrderDrawer>
      </>
    );
  }
}

export default StoreFulfillmentOrderListView;
