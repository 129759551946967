import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import AdminNavbarV2 from "components/Navbars/AdminNavbarV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import MealList from "views/examples/MealList";
import routesv2 from "routesv2";
import { Capacitor } from "@capacitor/core";
import LibraryHome from "views/university/LibraryHome";
import LibraryModule from "views/university/LibraryModule";
import LibraryTopic from "views/university/LibraryTopic";
import FranDevSupportTicketListView from "views/frandev/FranDevSupportTicketListView";
import FranDevTaskListView from "views/frandev/FranDevTaskListView";
import StoreView from "views/examples/StoreView";
import BillingAccountList from "views/examples/BillingAccountList";
import StoreList from "views/examples/StoreList";

class FranchiseDevelopment extends React.Component {
  componentDidUpdate(e) {
    if (e.history?.location?.state?.noScroll) {
      return;
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  componentWillMount() {
    if (Capacitor.getPlatform() == "ios") {
      document.body.className += " ios-padding";
    }

    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      API.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2._wsConnect();
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      window[Constant.CACHE.USER] = User._fromData(
        LocalStorage.get(Constant.CACHE.USER)
      );
      PubSub.publish(Event.USER_LOADED, window[Constant.CACHE.USER]);

      if (!this.isGlobal()) {
        window.location.href = "/storefront";
      }
    }

    APIV2.getAccount().then(
      (data) => {
        if (data && data.data && data.data.user) {
          data.data.user.id = data.data.user._id;

          let user = data.data.user;

          window[Constant.CACHE.USER] = user;
          LocalStorage.set(Constant.CACHE.USER, user);

          console.log("fetched user");

          PubSub.publish(Event.USER_LOADED, user);

          if (!this.isGlobal()) {
            window.location.href = "/storefront";
          }
        }
      },
      (error) => {
        console.error(error);

        window.location.href = "/storefront";
      }
    );
  }

  render() {
    if (!LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/auth");
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      if (!this.isGlobal()) {
        window.location.href = "/storefront";
      }
    }

    return (
      <>
        <SidebarV2
          {...this.props}
          routes={routesv2.frandevRoutes}
          basePath={"/frandev"}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbarV2
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            searchMode="frandev"
            searchPlaceholder="Search Stores..."
          />
          <Switch>
            <Route
              path="/frandev/tasks"
              component={FranDevTaskListView}
              exact
            />
            <Route path="/frandev/stores" component={StoreList} exact />
            <Route
              path="/frandev/stores/details/:storeID/:activeTab"
              component={StoreView}
            />
            <Route
              path="/frandev/stores/billing-accounts"
              component={BillingAccountList}
              exact
            />
            <Route
              path="/frandev/tickets"
              component={FranDevSupportTicketListView}
              exact
            />

            <Redirect from="*" to="/frandev/stores" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default FranchiseDevelopment;
