import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { DatePicker, SelectPicker, Modal, Drawer } from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";

class CustomerPriceOverrideWidget extends React.Component {
  state = {};

  toggleHistoryModal() {
    this.setState({
      openHistory: !this.state.openHistory,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  formatAccountBalance(balance) {
    if (!balance) {
      return "$0.00";
    }

    return StringUtils.centsToCurrency(balance);
  }

  handleBalanceChange(val) {
    this.setState({
      priceOverride: val,
    });
  }

  submit() {
    let cents = Math.abs(this.state.priceOverride?.floatValue * 100);

    this.setState({
      submitting: true,
    });

    APIV2.updateCustomerPriceOverride(
      this.props.customer?.storeID,
      this.props.customer?._id,
      cents
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({
            modify: false,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  remove() {
    this.setState({
      submitting: true,
    });

    APIV2.updateCustomerPriceOverride(
      this.props.customer?.storeID,
      this.props.customer?._id,
      null
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({
            modify: false,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  calculateAnRenderHistoryChange(history) {
    let diff = history.to - history.from;

    if (diff < 0) {
      return (
        <span className="text-danger">
          -{StringUtils.centsToCurrency(Math.abs(diff))}
        </span>
      );
    } else if (diff == 0) {
      return (
        <span className="text-dark">+{StringUtils.centsToCurrency(diff)}</span>
      );
    } else {
      return (
        <span className="text-success">
          +{StringUtils.centsToCurrency(diff)}
        </span>
      );
    }
  }

  render() {
    return (
      <>
        <Card className="mb-0 border shadow" style={{ height: "100%" }}>
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0 text-dark">Subscription Price Override</h3>
                <small>
                  Manually set the price for the member's subscription.
                </small>
              </div>
              <Col xs="auto">
                {!this.state.modify ? (
                  <>
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modify: true,
                          priceOverride: {
                            value:
                              this.props.customer?.subscription?.priceOverride >
                              0
                                ? (
                                    this.props.customer?.subscription
                                      ?.priceOverride / 100
                                  ).toString()
                                : (0.0).toString(),
                            floatValue:
                              this.props.customer?.subscription?.priceOverride >
                              0
                                ? this.props.customer?.subscription
                                    ?.priceOverride / 100
                                : 0.0,
                          },
                        });
                      }}
                    >
                      Edit
                    </Button>
                    {this?.props?.customer?.subscription?.priceOverride !==
                      null &&
                      this.props.customer?.subscription?.priceOverride !==
                        undefined && (
                        <Button
                          size="sm"
                          color="danger"
                          outline
                          disabled={this.state.submitting}
                          onClick={() => {
                            this.remove();
                          }}
                        >
                          {this.state.submitting ? (
                            <Spinner color="white" size="sm" />
                          ) : (
                            "Remove"
                          )}
                        </Button>
                      )}
                  </>
                ) : (
                  <>
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modify: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      disabled={
                        this.state.priceOverride?.floatValue === undefined ||
                        this.state.priceOverride?.floatValue < 0 ||
                        this.state.submitting
                      }
                      onClick={() => this.submit()}
                    >
                      {this.state.submitting ? (
                        <Spinner color="white" size="sm" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="">
            <Row className="align-items-center">
              {this.state.modify ? (
                <Col xs="12">
                  <h5>Price</h5>
                  <NumberFormat
                    className="form-control mb-3"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    required={true}
                    value={this.state.priceOverride.value}
                    onValueChange={this.handleBalanceChange.bind(this)}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Col>
              ) : (
                <Col xs="12">
                  <h3 className="mb-0 text-dark" style={{ lineHeight: 1.2 }}>
                    {this.props.customer?.subscription?.priceOverride !==
                      null &&
                    this.props.customer?.subscription?.priceOverride !==
                      undefined
                      ? this.formatAccountBalance(
                          this.props.customer?.subscription?.priceOverride
                        )
                      : "--"}
                  </h3>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerPriceOverrideWidget);
