import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";
import Fuse from "fuse.js";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import CustomerOrderDrawer from "./CustomerOrderDrawer";

class CustomerAllOrdersDrawer extends React.Component {
  state = {
    fulfillmentDate: moment().day(6).toDate(),
    order: [],
    loading: false,
  };

  openOrderDrawer(order) {
    this.setState({
      openOrder: order,
      orderDrawer: true,
    });
  }

  getAllOrders(customer) {
    this.setState({
      loading: true,
    });

    let query = {
      "customer.id": customer?._id,
    };

    let sortBy = { fulfillmentDate: -1 };

    APIV2.getOrdersForStore(
      this.props?.match?.params?.storeID,
      1,
      10000,
      query,
      sortBy
    )
      .then(
        (data) => {
          this.setState({ orders: data?.data?.orders ?? [] });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.customer != this.props.customer && this.props.customer) {
      this.getAllOrders(this.props.customer);
    }
  }

  componentDidMount() {
    if (this.props.customer) {
      this.getAllOrders(this.props.customer);
    }

    PubSub.subscribe(Event.ORDER.CREATED, () => {
      this.getAllOrders(this.props.customer);
    });

    PubSub.subscribe(Event.ORDER.MODIFIED, (order) => {
      if (this.state?.orders?.length) {
        let idx = _.findIndex(this.state.orders, { _id: order?.id });

        if (idx >= 0) {
          let od = this.state.orders;
          od[idx] = order;

          this.setState({
            orders: od,
          });
        }
      }
    });
  }

  toggleModal() {
    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              All Orders
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0">
            {this.state?.loading && (
              <div className="px-3 py-5 text-center">
                <Spinner size="sm" color="dark"></Spinner>
              </div>
            )}
            {!this.state?.orders?.length && !this.state.loading && (
              <div className="px-3 py-5">
                <Row>
                  <Col xs="12">
                    <h3 className="m-0">No orders available.</h3>
                    <p>
                      {this.props?.customer?.name
                        ? StringUtils.getFirstName(this.props?.customer?.name)
                        : "This customer"}{" "}
                      does not have any orders.
                    </p>
                  </Col>
                </Row>
              </div>
            )}
            {this.state?.orders?.length > 0 && (
              <div className="px-4 py-2 bg-superlight border-bottom">
                <Row>
                  <Col xs="12" sm="5">
                    <h4 className="m-0">Order</h4>
                  </Col>
                  <Col xs="12" sm="2" className="d-none d-sm-flex">
                    <h4 className="m-0">Status</h4>
                  </Col>
                  <Col xs="12" sm="3" className="d-none d-sm-flex">
                    <h4 className="m-0">Invoice Status</h4>
                  </Col>
                </Row>
              </div>
            )}
            {this.state?.orders?.map((order, i) => (
              <div
                key={order?._id}
                onClick={() => {
                  this.openOrderDrawer(order);
                }}
              >
                <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                  <Row className="align-items-center">
                    <Col xs="12" sm="5">
                      <h4 className="mb-0">
                        {order?.subscription?.plan?.name}
                      </h4>
                      <p className="m-0">
                        {order?.fulfillmentDateString
                          ? moment(order?.fulfillmentDateString).format(
                              "ddd, MMMM Do YYYY"
                            )
                          : "--"}
                      </p>
                    </Col>
                    <Col xs="12" sm="2">
                      <h3 className="m-0">
                        <Badge color="medium" className="mr-2">
                          {order?.status}
                        </Badge>
                      </h3>
                    </Col>
                    <Col xs="12" sm="3">
                      <h3 className="m-0">
                        {order?.status == "CANCELLED" &&
                        order?.invoice?.status == "CANCELLED" ? (
                          "--"
                        ) : (
                          <Badge
                            color={
                              order?.invoice?.status == "UNPAID"
                                ? "warning"
                                : "medium"
                            }
                          >
                            {order?.invoice?.status}
                          </Badge>
                        )}
                      </h3>
                    </Col>
                    <Col xs="12" sm="" className="text-right">
                      <h3 className="m-0">
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: 22 }}
                        ></i>
                      </h3>
                    </Col>
                  </Row>
                </div>
                {i != this.state?.recentOrders?.length - 1 && (
                  <hr className="m-0"></hr>
                )}
              </div>
            ))}
          </Drawer.Body>
        </Drawer>
        <CustomerOrderDrawer
          order={this.state.openOrder}
          open={this.state.orderDrawer}
          onClose={() => {
            this.setState({ orderDrawer: false });
          }}
        ></CustomerOrderDrawer>
      </>
    );
  }
}

export default withRouter(CustomerAllOrdersDrawer);
