import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CreateStoreDrawer from "components/Drawers/CreateStoreDrawer";
import _ from "underscore";

class StoreList extends React.Component {
  state = {
    stores: [],
    allStores: [],
    options: [
      { name: "All", id: "All", _id: "All" },
      { name: "Live", id: "Live", _id: "Live" },
      { name: "Prelaunch", id: "Prelaunch", _id: "Prelaunch" },
      { name: "Buildout", id: "Buildout", _id: "Buildout" },
      { name: "Site Selection", id: "Site Selection", _id: "Site Selection" },
      { name: "Funding", id: "Funding", _id: "Funding" },
      { name: "Discovery", id: "Discovery", _id: "Discovery" },
      { name: "Lead", id: "Lead", _id: "Lead" },
      { name: "Cold", id: "Cold", _id: "Cold" },
    ],
    active: {
      name: "All",
      id: "All",
    },
    loading: true,
  };

  handleTabChange(option, force = false) {
    if (!option || (option?.id == this.state.active?.id && !force)) {
      return;
    }

    this.setState({
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    if (option?.id != "All") {
      this.setState({
        stores: _.filter(this.state.allStores, (st) => {
          return st.nsoStage == option.id;
        }),
      });
    } else {
      this.setState({
        stores: this.state.allStores?.map((st) => st),
      });
    }
  }

  loadStores() {
    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data && data.data.stores) {
            this.setState(
              {
                stores: data.data.stores,
                allStores: data.data.stores,
              },
              () => {
                this.handleTabChange(this.state.active, true);
              }
            );

            let options = this.state.options;

            const allIdx = _.findIndex(options, { id: "All" });

            options[allIdx].count = data?.data?.stores?.length;

            for (let i = 0; i < data?.data?.stores?.length; i++) {
              const st = data.data.stores[i];

              if (st?.nsoStage) {
                const stageIdx = _.findIndex(options, { id: st.nsoStage });

                if (stageIdx >= 0) {
                  options[stageIdx].count = options[stageIdx].count
                    ? options[stageIdx].count + 1
                    : 1;
                }
              }
            }

            this.setState({
              options,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.setState(
        {
          active: {
            id: decodeURIComponent(tab),
            name: decodeURIComponent(tab),
          },
        },
        () => {
          this.loadStores();
        }
      );
    } else {
      this.loadStores();
    }

    PubSub.subscribe(Event.STORE.DELETED, (data) => {
      this.loadStores();
    });

    PubSub.subscribe(Event.STORE.CREATED, (s) => {
      this.loadStores();
    });

    PubSub.subscribe(Event.STORE.MODIFIED, (store) => {
      this.loadStores();
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeaderV2
            sticky={true}
            title="Stores"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                sticky={false}
                value={this.state.active}
                options={this.state.options}
                showCount={true}
                compact={true}
                onChange={(v) => {
                  this.handleTabChange(v);
                }}
              ></CardSelectorHeader>
            }
          />
          {/* Table */}
          <div
            style={{ marginLeft: -15, marginRight: -15 }}
            className="bg-white mt--4 border-bottom"
          >
            <Table
              className="align-items-center table-flush table-hover"
              responsive
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ minWidth: "180px" }}>
                    Name
                  </th>
                  <th scope="col">NSO Stage</th>
                  <th scope="col">Location</th>
                  <th scope="col">Website Launch</th>
                  <th scope="col">Prelaunch</th>
                  <th scope="col">First Bill Date</th>
                  <th scope="col">Retail Opening</th>
                  <th scope="col">Prelaunch NSO Ready</th>
                  <th scope="col">Retail NSO Ready</th>
                  <th scope="col">FBD NSO Ready</th>
                  <th scope="col">Franchise Partners</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loading ? (
                  <tr>
                    <td colSpan="2">Loading...</td>
                  </tr>
                ) : null}
                {!this.state.loading && !this.state.stores.length ? (
                  <tr>
                    <td colSpan="2">No stores.</td>
                  </tr>
                ) : null}
                {this.state.stores.map((s, i) => (
                  <StoreRow key={i} store={s}></StoreRow>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
        <CreateStoreDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
        ></CreateStoreDrawer>
      </>
    );
  }
}

export default StoreList;
