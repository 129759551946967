/**
 * Event constants for the app.
 */

class Constant {
  static IOS_APP_URL =
    "https://apps.apple.com/us/app/pln-storefront/id1598920273";

  static ENV_HOST = {
    DEV: "localhost",
    STAGING: "admin-staging.projectleannation.com",
    PRODUCTION: "admin.projectleannation.com",
  };

  static CACHE = {
    TOKEN: "cache_token",
    USER: "cache_user",
  };

  static TONES = {
    MESSAGE_NOTIFICATION:
      "https://s3.amazonaws.com/cdn.projectleannation.com/assets/ringtone-1-46486.mp3",
  };

  static HEATING_INSTRUCTIONS = {
    MICROWAVE:
      "<p>Heat for 90 seconds. Open container and stir contents. Heat for another 15-30 seconds.</p>",
    AIR_FRY:
      "<p>Transfer meal to oven-save dish. Heat for 8-12 minutes or until warm.</p>",
    OVEN: "<p>Transfer meal to oven-save dish. Heat for 7 minutes or until warm.</p>",
  };

  static AFFILIATE = {
    TYPES: [
      {
        value: "Gym",
        label: "Gym",
      },
      {
        value: "Crossfit",
        label: "Crossfit",
      },
      {
        value: "Yoga",
        label: "Yoga",
      },
      {
        value: "Spin",
        label: "Spin",
      },
      {
        value: "Fitness",
        label: "Fitness",
      },
      {
        value: "Corporate",
        label: "Corporate",
      },
      {
        value: "Individual",
        label: "Individual",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  };

  static SELECT_PICKER = {
    LEAD_STATUSES: [
      {
        label: "Not Contacted",
        value: "Not Contacted",
      },
      {
        label: "Attempted",
        value: "Attempted",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Converted",
        value: "closedwon",
      },
    ],
    LEAD_SOURCES: [
      {
        label: "Store Visit",
        value: "Store Visit",
        type: "In-Store",
      },
      {
        label: "Free InBody Scan",
        value: "Free InBody Scan",
        type: "In-Store",
      },
      {
        label: "Nutritional Consultation",
        value: "Nutritional Consultation",
        type: "In-Store",
      },
      {
        label: "Website: Visit",
        value: "Website: Visit",
        type: "Online",
      },

      {
        label: "Website: InBody",
        value: "Website: InBody",
        type: "Online",
      },
      {
        label: "Website: Macro Calculator",
        value: "Website: Macro Calculator",
        type: "Online",
      },
      {
        label: "Website: SMS Popup",
        value: "Website: SMS Popup",
        type: "Online",
      },
      {
        label: "Website: Lead Funnel",
        value: "Website: Lead Funnel",
        type: "Online",
      },
      {
        label: "Social Media",
        value: "Social Media",
        type: "Online",
      },
      {
        label: "Outreach",
        value: "Outreach",
        type: "Other",
      },
      {
        label: "Affiliate",
        value: "Affiliate",
        type: "Other",
      },
      {
        label: "Member Referral",
        value: "Client Referral",
        type: "Other",
      },
      {
        label: "SMS Conversation",
        value: "SMS Conversation",
        type: "Online",
      },
    ],
    CLIENT_GOALS: [
      {
        value: "Weight Management",
        label: "Weight Management",
      },
      {
        value: "Muscle Gain",
        label: "Muscle Gain",
      },
      {
        value: "Convenience",
        label: "Convenience",
      },
      {
        value: "Optimize Health",
        label: "Optimize Health",
      },
      {
        value: "Referred by Physician",
        label: "Referred by Physician",
      },
      {
        value: "Other Goals",
        label: "Other Goals",
      },
    ],
  };

  static TWILIO_MESSAGE_COST = 0.0079;

  static BROADCAST_LISTS = [
    {
      value: "dynamic:all_actives",
      label: "All Active Members",
      group: "Members",
    },
    {
      value: `dynamic:all_actives_this_week`,
      label: `Active This Week`,
      group: "Members",
    },
    {
      value: `dynamic:delivery_actives_this_week`,
      label: `Active This Week - Delivery List`,
      group: "Members",
    },
    {
      value: `dynamic:pickup_actives_this_week`,
      label: `Active This Week - Pickup List`,
      group: "Members",
    },
    {
      value: "dynamic:all_hfns",
      label: "All HFNs",
      group: "HFNs",
    },
    {
      value: "dynamic:all_leads",
      label: "All Leads",
      group: "Leads",
    },
  ];

  static APPOINTMENT_TYPES = {
    "ec-consult": {
      APPOINTMENT_TYPE: "ec-consult",
      NAME: "Existing Member Follow-Up & Inbody Scan",
      DESCRIPTION:
        "Meet with your coach to take an InBody scan, assess progress towards your goals, and review your plan.",
      DURATION_SECONDS: 20 * 60,
      DURATION_READABLE: "20 min",
      LISTED: false,
      EXTRA_FIELDS: ["notes"],
    },
    "nc-consult": {
      APPOINTMENT_TYPE: "nc-consult",
      NAME: "New Member Consultation & FREE Inbody Scan",
      DESCRIPTION:
        "Meet one-on-one with a coach to get a FREE InBody scan, discuss your goals, and develop a strategy to achieve success.",
      DURATION_SECONDS: 20 * 60,
      DURATION_READABLE: "20 min",
      LISTED: true,
      EXTRA_FIELDS: ["notes", "goal"],
    },
  };

  static FULFILLMENT_STRATEGIES = {
    IN_STORE: "In-Store Fulfillment",
    LLM_BOXED: "LeanLife Fulfillment",
  };

  static MEAL_PROTEINS = [
    {
      label: "Beef - Ground",
      value: "Beef - Ground",
      category: "Beef",
    },
    {
      label: "Beef - Shredded",
      value: "Beef - Shredded",
      category: "Beef",
    },
    {
      label: "Pork - Pulled",
      value: "Pork - Pulled",
      category: "Pork",
    },
    {
      label: "Chicken - Diced",
      value: "Chicken - Diced",
      category: "Chicken",
    },
    {
      label: "Chicken - Strips",
      value: "Chicken - Strips",
      category: "Chicken",
    },
    {
      label: "Turkey - Sausage",
      value: "Turkey - Sausage",
      category: "Turkey",
    },
    {
      label: "Chicken - Pulled Thigh",
      value: "Chicken - Pulled Thigh",
      category: "Chicken",
    },
    {
      label: "Chicken - Breaded Chunks",
      value: "Chicken - Breaded Chunks",
      category: "Chicken",
    },
    {
      label: "Chicken - Braised Thigh",
      value: "Chicken - Braised Thigh",
      category: "Chicken",
    },
    {
      label: "Chicken - Breaded Strips",
      value: "Chicken - Breaded Strips",
      category: "Chicken",
    },
    {
      label: "Egg",
      value: "Egg",
      category: "Egg",
    },
    {
      label: "Chicken - Breaded",
      value: "Chicken - Breaded",
      category: "Chicken",
    },
    {
      label: "Turkey - Barbacoa",
      value: "Turkey - Barbacoa",
      category: "Turkey",
    },
    {
      label: "Turkey - Ground",
      value: "Turkey - Ground",
      category: "Turkey",
    },
    {
      label: "Beef - Burger",
      value: "Beef - Burger",
      category: "Beef",
    },
    {
      label: "Plant Based",
      value: "Plant Based",
      category: "Plant Based",
    },
    {
      label: "Fish - Salmon",
      value: "Fish - Salmon",
      category: "Fish",
    },
    {
      label: "Chicken - Grilled Chunk",
      value: "Chicken - Grilled Chunk",
      category: "Chicken",
    },
    {
      label: "Chicken - Breast",
      value: "Chicken - Breast",
      category: "Chicken",
    },
    {
      label: "Beef - Barbacoa",
      value: "Beef - Barbacoa",
      category: "Beef",
    },
    {
      label: "Pork - Sausage",
      value: "Pork - Sausage",
      category: "Pork",
    },
    {
      label: "Pork - Bacon",
      value: "Pork - Bacon",
      category: "Pork",
    },
    {
      label: "Chicken - Sausage",
      value: "Chicken - Sausage",
      category: "Chicken",
    },
    {
      label: "Lean Cheat",
      value: "Lean Cheat",
      category: "Lean Cheat",
    },
  ];
}

export default Constant;
