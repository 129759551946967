var globalRoutes = [
  /*{
    path: "/users",
    name: "Users",
    icon: "mdi mdi-account",
    dropdown: false,
  },*/
  {
    path: "/conversations/templates",
    name: "Conversations",
    icon: "mdi mdi-forum",
    featureFlag: "",
    dataRequirement: "twilioNumber",
    dropdown: true,
    dropdownItems: [
      /*{
        name: "Broadcasts",
        path: "/conversations/broadcasts",
      },*/
      {
        name: "Templates",
        path: "/conversations/templates",
      },
      /*{
        name: "Suppressed Contacts",
        path: "/conversations/suppressions",
      },*/
    ],
  },
  /*{
    path: "/discounts",
    name: "Discounts",
    icon: "mdi mdi-ticket-percent",
  },*/
  {
    path: "/product-categories",
    name: "Product Categories",
    icon: "mdi mdi-select-group",
  },
  {
    path: "/url-creator",
    name: "Link Generator",
    icon: "mdi mdi-link",
  },
];

var reportRoutes = [
  {
    path: "/scorecard",
    name: "Scorecard",
    icon: "mdi mdi-counter",
  },
  {
    path: "/sales",
    name: "Sales Summary",
    icon: "mdi mdi-cash-fast",
  },
  {
    path: "/fulfillment",
    name: "Fulfillment",
    icon: "mdi mdi-shipping-pallet",
    dropdown: true,
    dropdownItems: [
      {
        name: "Summary",
        path: "/fulfillment",
      },
      {
        name: "Projections",
        path: "/fulfillment/projections",
      },
      /*{
        name: "All Stores",
        path: "/fulfillment/stores",
      },
      {
        name: "Test Reports",
        path: "/fulfillment/testing",
      },*/
    ],
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "mdi mdi-account-filter",
    dropdown: true,
    dropdownItems: [
      {
        name: "Summary",
        path: "/leads",
      },
      /*{
        name: "All Stores",
        path: "/fulfillment/stores",
      },
      {
        name: "Test Reports",
        path: "/fulfillment/testing",
      },*/
    ],
  },
];

var accountRoutes = [
  {
    path: "/information",
    name: "Account Information",
    icon: "mdi mdi-account",
  },
];

var productionRoutes = [
  /*{
    path: "/home",
    name: "Home",
    icon: "mdi mdi-home",
  },*/
  {
    path: "/orders",
    name: "Orders",
    icon: "mdi mdi-shipping-pallet",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Orders",
        path: "/orders",
      },
      {
        name: "Pricing & Quantities",
        path: "/orders/pricing",
      },
    ],
  },
  {
    path: "/fulfillment",
    name: "Fulfillment Reports",
    icon: "mdi mdi-shipping-pallet",
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: "mdi mdi-format-list-checks",
  },
  {
    path: "/tickets",
    name: "Support Tickets",
    icon: "mdi mdi-lifebuoy",
  },
  {
    path: "/meals",
    name: "Meals",
    icon: "mdi mdi-food",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Meals",
        path: "/meals",
      },
      {
        name: "Categories",
        path: "/meals/categories",
      },
      {
        name: "Allergens",
        path: "/meals/allergens",
      },
      {
        path: "/meals/menus",
        name: "Menus",
        icon: "mdi mdi-format-list-text",
      },
    ],
  },
];

var universityRoutes = [
  {
    path: "/library",
    name: "Library",
    icon: "mdi mdi-library",
  },
  {
    path: "https://projectleannation.talentlms.com/index",
    name: "Courses",
    icon: "mdi mdi-school",
    external: true,
  },
];

var marketingRoutes = [
  {
    path: "/brand",
    name: "Brand Central",
    icon: "mdi mdi-star-circle",
    dropdown: true,
    dropdownItems: [
      {
        name: "Definition",
        path: "/brand",
      },
      {
        name: "Positioning",
        path: "/brand/positioning",
      },
      {
        name: "Visual Guides & Assets",
        path: "/brand/visuals",
      },
    ],
  },
  {
    path: "/assets",
    name: "Digital Assets",
    icon: "mdi mdi-play-box",
    dropdown: true,
    dropdownItems: [
      {
        name: "Content Calendar",
        path: "/assets",
      },
      {
        name: "Social Media Content",
        path: "/assets/social",
      },
    ],
  },
];

var frandevRoutes = [
  {
    path: "/stores",
    name: "Stores",
    icon: "mdi mdi-store",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Stores",
        path: "/stores",
      },
      {
        name: "Billing Accounts",
        path: "/stores/billing-accounts",
      },
    ],
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: "mdi mdi-format-list-checks",
  },
  {
    path: "/tickets",
    name: "Support Tickets",
    icon: "mdi mdi-lifebuoy",
  },
];

var storefrontRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: "mdi mdi-home",
  },
  {
    path: "/members",
    name: "Members",
    icon: "mdi mdi-account-star",
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "mdi  mdi-account-filter",
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: "mdi  mdi-format-list-checks",
  },
  {
    path: "/conversations",
    name: "Conversations",
    icon: "mdi mdi-forum",
    featureFlag: "",
    dataRequirement: "twilioNumber",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Conversations",
        path: "/conversations",
      },
      {
        name: "Broadcasts",
        path: "/conversations/broadcasts",
      },
      {
        name: "Templates",
        path: "/conversations/templates",
      },
      {
        name: "Suppressed Contacts",
        path: "/conversations/suppressions",
      },
    ],
  },
  {
    path: "/affiliates",
    name: "Affiliates",
    icon: "mdi  mdi-account-heart",
    dropdown: false,
  },
  {
    path: "/fulfillment",
    name: "Fulfillment",
    icon: "mdi mdi-shipping-pallet",
    dropdown: true,
    dropdownItems: [
      {
        name: "Summary",
        path: "/fulfillment",
      },
      {
        name: "All Orders",
        path: "/fulfillment/orders",
      },
      {
        name: "Pickup List",
        path: "/fulfillment/pickup",
      },
      {
        name: "Delivery List",
        path: "/fulfillment/delivery",
      },
      {
        name: "Live Order Usage",
        path: "/fulfillment/projections",
      },
    ],
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: "mdi mdi-receipt",
  },
  {
    path: "/products",
    name: "Products",
    icon: "mdi mdi-tag",
    dropdown: true,

    dropdownItems: [
      {
        name: "Inventory",
        path: "/products",
      },
      {
        name: "Meal Menus",
        path: "/products/menus/meals",
      },
      {
        name: "Orders",
        path: "/products/orders",
      },
    ],
  },
  /*{
    path: "/products",
    name: "Products",
    icon: "mdi mdi-format-list-text",
    dropdown: true,
    dropdownItems: [
      {
        name: "Meals",
        path: "/products/meals",
      },
      {
        name: "Retail",
        path: "/products/retail",
      },
    ],
  },
  {
    path: "/discounts",
    name: "Discounts",
    icon: "mdi mdi-sale",
    dropdown: true,
    dropdownItems: [
      {
        name: "Discount Codes",
        path: "/discounts",
      },
      {
        name: "Automatic Discounts",
        path: "/discounts/automatic",
      },
    ],
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "mdi mdi-chart-box",
    dropdown: true,
    dropdownItems: [
      {
        name: "Dashboard",
        path: "/analytics",
      },
      {
        name: "Reports",
        path: "/analytics/reports",
      },
    ],
  },

  {
    path: "/finances",
    name: "Finances",
    icon: "mdi mdi-cash-multiple",
    dropdown: true,
    dropdownItems: [
      {
        name: "Overview",
        path: "/finances",
      },
      {
        name: "Fees",
        path: "/finances/fees",
      },
    ],
  },
  */

  {
    path: "/settings",
    name: "Settings",
    icon: "mdi mdi-cog",
    dropdown: true,
    dropdownItems: [
      {
        name: "Online Presence",
        path: "/settings",
      },
      {
        name: "Staff Members",
        path: "/settings/staff",
      },
      {
        name: "Coupons",
        path: "/settings/coupons",
      },
      {
        name: "Franchise Territories",
        path: "/settings/territories",
      },
      {
        name: "Manage Users",
        path: "/settings/users",
      },
    ],
  },
];

export default {
  globalRoutes,
  reportRoutes,
  productionRoutes,
  storefrontRoutes,
  universityRoutes,
  frandevRoutes,
  accountRoutes,
  marketingRoutes,
};
