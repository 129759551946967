import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import CustomerCreateOrderDrawer from "components/Drawers/CustomerCreateOrderDrawer";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import CustomerAllOrdersDrawer from "components/Drawers/CustomerAllOrdersDrawer";
import _ from "underscore";

class CustomerFulfillmentWidget extends React.Component {
  state = {
    expand: false,
  };

  fetchRecentOrders() {
    this.setState({
      loading: true,
    });

    let query = {
      "customer.id": this?.props?.match?.params?.customerID,
    };

    let sortBy = { fulfillmentDate: -1 };

    APIV2.getOrdersForStore(
      this.props?.match?.params?.storeID,
      1,
      6,
      query,
      sortBy
    )
      .then(
        (data) => {
          this.setState({ recentOrders: data?.data?.orders ?? [] });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchRecentOrders();

    PubSub.subscribe(Event.ORDER.CREATED, () => {
      this.fetchRecentOrders();
    });

    PubSub.subscribe(Event.ORDER.MODIFIED, (order) => {
      if (this.state?.recentOrders?.length) {
        let idx = _.findIndex(this.state.recentOrders, { _id: order?.id });

        if (idx >= 0) {
          let od = this.state.recentOrders;
          od[idx] = order;

          this.setState({
            recentOrders: od,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.customerID !=
        this.props?.match?.params?.customerID ||
      prevProps?.match?.params?.storeID != this.props?.match?.params?.storeID
    ) {
      this.fetchRecentOrders();
    }
  }

  openOrderDrawer(order) {
    this.setState({
      openOrder: order,
      orderDrawer: true,
    });
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="text-dark mb-0">Recent Orders</h3>
                  </Col>
                  <Col xs="auto">
                    {/*<Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          modified: true,
                          modifyImage: true,
                          imageURL: "",
                        });
                      }}
                    >
                      Mark All <i className="mdi mdi-chevron-down"></i>
                    </Button>*/}
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ openCreate: true });
                      }}
                    >
                      New Order
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {this.state?.loading && (
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                )}
                {!this.state?.recentOrders?.length && !this.state.loading && (
                  <div className="px-3 py-5">
                    <Row>
                      <Col xs="12">
                        <h3 className="m-0">No orders available.</h3>
                        <p>
                          {this.props?.customer?.name
                            ? StringUtils.getFirstName(
                                this.props?.customer?.name
                              )
                            : "This customer"}{" "}
                          does not have any orders.
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}

                {this.state?.recentOrders?.length > 0 && (
                  <div className="px-4 py-2 bg-superlight border-bottom">
                    <Row>
                      <Col xs="12" sm="5">
                        <h4 className="m-0">Order</h4>
                      </Col>
                      <Col xs="12" sm="2" className="d-none d-sm-flex">
                        <h4 className="m-0">Status</h4>
                      </Col>
                      <Col xs="12" sm="3" className="d-none d-sm-flex">
                        <h4 className="m-0">Invoice Status</h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {this.state?.recentOrders?.map((order, i) => (
                  <div
                    key={order?._id}
                    onClick={() => {
                      this.openOrderDrawer(order);
                    }}
                  >
                    <div className="px-4 cursor-pointer py-3 bg-superlight--hover">
                      <Row className="align-items-center">
                        <Col xs="12" sm="5">
                          <h4 className="mb-0">
                            {order?.subscription?.plan?.name}
                          </h4>
                          <p className="m-0">
                            {order?.fulfillmentDateString
                              ? moment(order?.fulfillmentDateString).format(
                                  "ddd, MMMM Do YYYY"
                                )
                              : "--"}
                          </p>
                        </Col>
                        <Col xs="12" sm="2">
                          <h3 className="m-0">
                            <Badge color="medium" className="mr-2">
                              {order?.status}
                            </Badge>
                          </h3>
                        </Col>
                        <Col xs="12" sm="3">
                          <h3 className="m-0">
                            {order?.status == "CANCELLED" &&
                            order?.invoice?.status == "CANCELLED" ? (
                              "--"
                            ) : (
                              <Badge
                                color={
                                  order?.invoice?.status == "UNPAID"
                                    ? "warning"
                                    : "medium"
                                }
                              >
                                {order?.invoice?.status}
                              </Badge>
                            )}
                          </h3>
                        </Col>
                        <Col xs="12" sm="" className="text-right">
                          <h3 className="m-0">
                            <i
                              className="mdi mdi-chevron-right"
                              style={{ fontSize: 22 }}
                            ></i>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                    {i != this.state?.recentOrders?.length - 1 && (
                      <hr className="m-0"></hr>
                    )}
                  </div>
                ))}
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => {
                    this.setState({ openAll: true });
                  }}
                >
                  View All <ArrowText></ArrowText>
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
        <CustomerOrderDrawer
          order={this.state.openOrder}
          open={this.state.orderDrawer}
          onClose={() => {
            this.setState({ orderDrawer: false });
          }}
        ></CustomerOrderDrawer>
        <CustomerCreateOrderDrawer
          customer={this.props?.customer}
          open={this.state.openCreate}
          onClose={() => {
            this.setState({ openCreate: "" });
          }}
        ></CustomerCreateOrderDrawer>
        <CustomerAllOrdersDrawer
          customer={this.props?.customer}
          open={this.state.openAll}
          onClose={() => {
            this.setState({ openAll: "" });
          }}
        ></CustomerAllOrdersDrawer>
      </>
    );
  }
}

export default withRouter(CustomerFulfillmentWidget);
