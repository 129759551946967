import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, isNumber } from "underscore";
import { withRouter } from "react-router-dom";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import _ from "underscore";
import swal from "sweetalert";
import Spinner from "reactstrap/lib/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import { TagPicker, Uploader } from "rsuite";
import moment from "moment";
import StringUtils from "lib/StringUtils";
import ArrowText from "components/ArrowText";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import AffiliateInformationWidget from "components/Widgets/AffiliateInformationWidget";
import { Capacitor } from "@capacitor/core";
import AffiliateSummaryWidget from "components/Widgets/AffiliateSummaryWidget";
import AffiliateAllTasksWidget from "components/Widgets/AffiliateAllTasksWidget";

class AffiliateView extends React.Component {
  state = {
    store: null,
    loading: true,
    descriptionState: EditorState.createEmpty(),
    loadingCategories: false,
    categories: [],
    categoryTags: [],
    allergens: [],
    tab: { name: "Summary", id: "summary" },
    allTabs: [
      {
        name: "Summary",
        id: "summary",
      },
      {
        name: "Information",
        id: "information",
      },
      {
        name: "Tasks",
        id: "tasks",
      },
      /*{
        name: "Timeline",
        id: "timeline",
      },*/
    ],
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
  }

  onDescriptionStateChange(editorState) {
    this.setState({
      descriptionState: editorState,
      modified: true,
    });
  }

  _loadAffiliateState(affiliate) {
    if (!affiliate) {
      return;
    }

    if (!affiliate.details) {
      affiliate.details = "";
    }

    this.setState({
      affiliate: affiliate,
    });
  }

  async loadAffiliate() {
    const {
      match: { params },
    } = this.props;

    APIV2.getAffiliateByID(params.storeID, params.affiliateID)
      .then(
        (data) => {
          console.log(data.data);

          if (data && data.data && data.data.affiliate) {
            this._loadAffiliateState(data.data.affiliate);
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  delete() {
    swal({
      title: "Delete Affiliate?",
      text: "Are you sure you want to delete this affiliate? This cannot be undone.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (!willDelete) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteAffiliate(
        this.state.affiliate?.storeID,
        this.state.affiliate._id
      )
        .then(
          (data) => {
            PubSub.publish(Event.AFFILIATE.DELETED, {
              id: this.state.affiliate._id,
            });

            swal("affiliate successfully deleted", {
              icon: "success",
            });

            this.props.history.push(
              "/storefront/" +
                this?.props?.match?.params?.storeID +
                "/affiliates"
            );
          },
          (e) => {
            console.error(e);
            window.alert(
              "We're having trouble removing this affiliate. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  checkStickyHeader(e) {
    if (this.headerRef.current) {
      const stickyElm = this.headerRef.current;

      var rect = stickyElm.getBoundingClientRect();

      if (rect.top <= (Capacitor.getPlatform() == "ios" ? 110 : 80)) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }

      if (rect.top == 0) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  }

  componentDidMount() {
    this.loadAffiliate();

    PubSub.subscribe(Event.AFFILIATE.MODIFIED, (c) => {
      this.setState({ affiliate: c, affiliateDetails: c?.details });
    });

    if (this.props.match?.params?.tabID) {
      this.setTab(this.props.match?.params?.tabID);
    } else {
      this.setTab("summary");
    }

    document.addEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  setTab(tab) {
    if (!tab) {
      return;
    }

    let t = _.findWhere(this.state.allTabs, { id: tab });

    if (t) {
      this.setState({ tab: t });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.affiliateID !=
        prevProps.match?.params?.affiliateID ||
      this.props.match?.params?.storeID != prevProps.match?.params?.storeID
    ) {
      this.loadaffiliate();
    }

    if (this.props.match?.params?.tabID !== prevProps.match?.params?.tabID) {
      if (this.props.match?.params?.tabID) {
        this.setTab(this.props.match?.params?.tabID);
      } else {
        this.setTab("summary");
      }
    }
  }

  /**
   * Sets the affiliate's details
   */
  setaffiliateDetails() {
    this.setState({
      submittingaffiliateDetails: true,
    });

    APIV2.setaffiliateVariable(
      this.props.match.params.storeID,
      this.state.affiliate._id,
      "details",
      this.state.affiliateDetails
    )
      .then(
        (data) => {
          this._loadaffiliateState(data.data.affiliate);
        },
        (err) => {}
      )
      .finally(() => {
        this.setState({ submittingaffiliateDetails: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value,
    });
  }

  handleInputNoSpaceChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/\s/g, ""),
    });
  }

  handleNumberInputChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    this.setState({
      [name]: value.replace(/[^\d.]/g, ""),
    });
  }

  categoryChange(v) {
    this.setState({ categories: v, modified: true });
  }

  allergenChange(v) {
    this.setState({ allergens: v, modified: true });
  }

  sinceDateFormat(d) {
    let date = moment(d);

    return date.format("MMMM YYYY");
  }

  renderNextBillDate(d) {
    let mom = moment(d);

    let now = moment();

    if (mom.format("MMMM Do YYYY") == now.format("MMMM Do YYYY")) {
      return "Today";
    }

    return mom.format("M/D/YY");
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            backOverride={`/storefront/${this?.props?.match?.params?.storeID}/affiliates`}
            title={
              this.state.affiliate?.name
                ? this.state.affiliate.name
                : "Loading..."
            }
            showBackButton={true}
          />
          {/* Table */}
          <Row>
            <Col xs="12" md="12">
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-top">
                    <Col xs="12" sm="6">
                      <h3 className=" mb-1">
                        {this.state.affiliate?.name
                          ? this.state.affiliate?.name
                          : this.state.affiliate?.email}
                      </h3>
                      <p className="mb-0">
                        {this.state.affiliate?.phone
                          ? StringUtils.formatPhoneNumber(
                              this.state.affiliate?.phone
                            )
                          : this.state.affiliate?.email}
                      </p>
                    </Col>
                    <Col xs="12" sm="6" className="text-left text-sm-right">
                      <div>
                        <small className="mb-0" style={{ lineHeight: 1.4 }}>
                          {this.state.affiliate?.type}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              {/** Page navigation */}
              <div
                ref={this.headerRef}
                style={{ position: "sticky", top: "80px" }}
                className={`stickable ${this.state.sticky ? "stuck" : ""}`}
              >
                <Card className="mb-4 shadow border">
                  <CardSelectorHeader
                    id="stickyHeader"
                    value={this.state.tab}
                    options={this.state.allTabs}
                    onChange={(v) => {
                      if (v.id != this.state.tab.id) {
                        this.props.history.push(
                          "/storefront/" +
                            this.props.match?.params?.storeID +
                            "/affiliates/details/" +
                            this.props.match?.params?.affiliateID +
                            "/" +
                            v.id,
                          { noScroll: true }
                        );
                      }
                    }}
                  ></CardSelectorHeader>
                </Card>
              </div>
              {this.state.tab.id == "summary" ? (
                <>
                  <AffiliateSummaryWidget
                    loading={this.state.loading}
                    affiliate={this.state.affiliate}
                  />
                </>
              ) : null}
              {/**Primary  Widgets */}
              {this.state.tab.id == "information" ? (
                <>
                  <AffiliateInformationWidget
                    loading={this.state.loading}
                    affiliate={this.state.affiliate}
                  />
                </>
              ) : null}
              {this.state.tab.id == "tasks" ? (
                <>
                  <AffiliateAllTasksWidget
                    affiliate={this.state.affiliate}
                    store={{ _id: this.props.match.params.storeID }}
                    loading={this.state.loading}
                  ></AffiliateAllTasksWidget>
                </>
              ) : null}
            </Col>
            <Col xs="12" md="4">
              {/*<Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Insights</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody></CardBody>
    </Card>*/}
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
          <Row>
            <Col xs="auto">
              <Button
                color="danger"
                onClick={() => this.delete()}
                outline
                disabled={this.state.deleting}
              >
                {this.state.deleting ? (
                  <>
                    <Spinner size="sm" color="danger" />
                  </>
                ) : (
                  <>Delete Affiliate</>
                )}
              </Button>
            </Col>
            <Col xs=""></Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(AffiliateView);
