import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";

class CustomerContactInformationWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateInformation() {
    this.setState({ submitting: true });

    let phone = "";

    if (this.state.phone?.value.length == 10) {
      phone = `+1${this.state.phone?.value}`;
    }

    APIV2.updateCustomerInformation(
      this.props?.match?.params?.storeID,
      this.props?.customer?._id,
      this.state.name,
      this.state.email,
      phone
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  render() {
    return (
      <>
        <Card
          className={`mb-4 ${
            this.props.flat ? "shadow-none" : "shadow"
          } border`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Contact</h3>
              </div>
              {this.props.modifiable ? (
                <Col xs="auto">
                  {!this.state.openModify ? (
                    <Button
                      size="sm"
                      color="secondary"
                      outline
                      onClick={() => {
                        this.setState({
                          openModify: true,
                          name: this.props?.customer?.name,
                          email: this.props?.customer?.email,
                          phone: {
                            value: this.props?.customer?.phone
                              ? this.props?.customer?.phone.replace("+1", "")
                              : "",
                          },
                        });
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            openModify: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        disabled={
                          this.state.submitting ||
                          !this.state.name ||
                          (this.state.phone?.value &&
                            this.state.phone?.value?.length != 10)
                        }
                        onClick={() => {
                          this.updateInformation();
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner color="white" size="sm" />
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody className="p-0">
              {this.state.openModify ? (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Name</h5>
                        <Input
                          type="text"
                          value={this.state.name}
                          name="name"
                          placeholder="Name"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Email</h5>
                        <Input
                          type="email"
                          value={this.state.email}
                          name="email"
                          placeholder="Email"
                          onChange={this.handleInputChange.bind(this)}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase">Phone</h5>

                        <NumberFormat
                          className="form-control mb-3"
                          format="+1 (###) ###-####"
                          mask=""
                          name="phoneNumberInput"
                          placeholder="Phone Number"
                          onValueChange={(e) => {
                            console.log(e);

                            this.setState({ phone: e });
                          }}
                          value={this.state.phone.value}
                          type="tel"
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Name:</Col>
                      <Col xs="" className="pl-0">
                        {this.props.customer?.name}
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Email:</Col>
                      <Col xs="" className="pl-0">
                        <a href={`mailto:${this.props.customer?.email}`}>
                          {this.props.customer?.email}
                        </a>
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 py-3">
                    <Row className="align-items-center">
                      <Col xs="auto">Phone:</Col>
                      <Col xs="" className="pl-0">
                        <div>
                          <a href={`tel:${this.props.customer?.phone}`}>
                            {StringUtils.formatPhoneNumber(
                              this.props.customer?.phone
                            )}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {this.state.error ? (
                <Alert className="mt-3 mb-0" color="danger">
                  {this.state.error}
                </Alert>
              ) : null}
            </CardBody>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(CustomerContactInformationWidget);
