import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import { Capacitor } from "@capacitor/core";
import LinkUtils from "lib/LinkUtils";

class BrandPositioningView extends React.Component {
  openFile(url) {
    LinkUtils.openInNewTab(url);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  copyToClipboard(val) {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(val);
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div
          className="bg-white border-bottom px-4 py-4 py-md-5 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="auto">
              <h1 className="display-1 text-primary m-0">
                <i className="mdi mdi-star-circle"></i>
              </h1>
            </Col>
            <Col xs="">
              <h1 className="m-0 display-3 text-dark">Brand Positioning</h1>
            </Col>
          </Row>
        </div>
        <Container fluid>
          {/* Table */}
          <div className="mb-4">
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Voice</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  At Project LeanNation, our brand voice is that of a supportive
                  coach, a trusted friend, and a partner on your health journey.
                  We believe in the power of structured nutrition, on-brand
                  support, and education on positive lifestyle choices to
                  transform lives and create a healthier, happier community.
                </p>
                <p>
                  Our voice is passionate and empathetic. We understand the pain
                  points and struggles that come with making lifestyle changes,
                  but we also know that with the right tools and support,
                  anything is possible. We believe in meeting our members where
                  they are and helping them take steps towards their health
                  goals at their own pace.
                </p>
                <p>
                  Project LeanNation is approachable and inviting. We are a
                  community that welcomes everyone, regardless of their
                  background or experience level. We believe in creating a safe
                  space where our members can share their struggles and
                  successes and feel supported every step of the way.
                </p>
                <p>
                  Our voice is informative and educational. We believe in
                  empowering our members with the knowledge and tools they need
                  to make informed decisions about their health and wellness. We
                  provide expert guidance and resources to help our members
                  understand the science of nutrition and make choices that will
                  lead to long-term success.
                </p>
                <p className="m-0">
                  At Project LeanNation, our brand voice is one of inclusivity,
                  support, and empowerment. We believe that by building
                  relationships with our members and providing a comprehensive,
                  supportive experience, we can help our community transform
                  their lives and become the best version of themselves.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Tone</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  The Project LeanNation brand tone is focused on promoting a
                  healthy and active lifestyle, emphasizing the importance of
                  eating well-balanced meals to achieve optimal health and
                  fitness goals. It is carefully crafted to resonate with
                  health-conscious consumers who are looking to improve their
                  overall well-being.
                </p>
                <p>
                  The brand tone is focused on inspiring and motivating your
                  community to adopt healthy habits and make positive changes in
                  their lives. Project LeanNation's marketing materials should
                  use language that is upbeat, enthusiastic, and goal-oriented,
                  encouraging members to reach their optimal health and fitness
                  goals through healthy eating &amp; activities. When speaking
                  about Project LeanNation, focus on using the following three
                  characteristics:
                </p>

                <h3 className="text-dark font-weight-bold mt-4">
                  1) Motivational / Inspring:
                </h3>
                <p>
                  Characterized by language that is positive and enthusiastic,
                  with the intention of driving the audience to take action
                  towards achievement.
                </p>
                <p>
                  To use a motivational tone, it is important to understand the
                  audience's desires and aspirations, and use language that
                  resonates with them. This involves using words that evoke
                  positive emotions, such as "empower," "inspire," and
                  "achieve," to create a sense of excitement and enthusiasm
                  around the message.
                </p>

                <h3 className="text-dark mt-4 font-weight-bold">
                  2) Goal-Oriented:
                </h3>
                <p>
                  Characterized by language that is clear, concise, and direct,
                  with the intention of inspiring action towards a specific
                  objective or outcome.
                </p>
                <p>
                  To use a goal-oriented tone, it is important to clearly define
                  the objective or outcome that you want to achieve, and use
                  language that is specific and measurable. This involves using
                  action-oriented verbs and phrases that communicate a sense of
                  urgency and focus on achieving the desired outcome.
                </p>
                <p>
                  Additionally, it is essential to provide a clear plan of
                  action that outlines the steps necessary to achieve the goal.
                  This approach helps to create a sense of direction and
                  purpose, which can be highly motivating for the audience.
                </p>
                <h3 className="text-dark mt-4 font-weight-bold">
                  3) Encouraging / Supportive:
                </h3>
                <p>
                  Characterized by language that is empathetic, caring, and
                  understanding, showing concern for the audience and a desire
                  to provide assistance or comfort.
                </p>
                <p>
                  To use an encouraging / supportive tone, it is important to
                  understand the audience's needs and emotions, and use language
                  that shows empathy and understanding. This involves
                  acknowledging their feelings and concerns, and providing
                  reassurance or guidance where needed.
                </p>
                <p className="mb-0">
                  Additionally, it is essential to use positive language that
                  focuses on solutions and support. This approach helps to
                  create a sense of comfort and trust, which can be highly
                  comforting for the audience.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Language: Words Matter</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  As a brand that is committed to empowering our members to
                  achieve their health and wellness goals, it's essential that
                  we are mindful of the language we use when speaking about
                  Project LeanNation. While the term "meal prep" may seem like a
                  simple way to describe our product offerings, it does not
                  accurately convey the full scope of what we bring to the
                  table.
                </p>
                <p>
                  At Project LeanNation, we are in the business of change. We
                  provide our valued members with a range of tools and resources
                  to help them optimize their health, and our healthy prepared
                  meals are just one aspect of our comprehensive approach. By
                  focusing solely on the meals themselves, we risk devaluing the
                  other crucial components of our brand, including support,
                  technology, and education.
                </p>
                <p>
                  Moreover, there is a perception of what people envision when
                  they hear the term "meal prep." For many, it evokes images of
                  plain, unappetizing food that is cooked in bulk and lacks
                  flavor. This perception is not reflective of what we offer at
                  Project LeanNation. Our meals are prepared with high-quality
                  ingredients, flavorful spices, and careful attention to
                  nutrition and portion control. By using the term "meal prep,"
                  we risk sacrificing the best things about our brand and
                  failing to communicate the full extent of what we offer.
                </p>
                <p>
                  Instead, we must emphasize that Project LeanNation is more
                  than just meal prep. We are a supportive community that is
                  committed to helping our members achieve their health goals.
                  We leverage technology to create a data-driven experience that
                  is tailored to each member's needs, and we provide educational
                  resources to help our members make informed decisions about
                  their health and wellness. Our healthy prepared meals are an
                  important component of this comprehensive approach, but they
                  are just one piece of the puzzle.
                </p>
                <p className="m-0">
                  By choosing the right language when speaking about Project
                  LeanNation, we can effectively communicate the value of our
                  brand and the measurable impact that we make on the health of
                  each community we serve.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Brand Differentiator</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  At Project LeanNation, our brand differentiator lies in our
                  results-driven approach that prioritizes our members' progress
                  and holds us accountable for their success. We have developed
                  a comprehensive approach that sets us apart from other brands
                  in the industry.
                </p>
                <p>
                  We hire exceptional people who are passionate about supporting
                  others, leverage technology to create a data-driven
                  experience, provide educational tools and communications, and
                  offer exceptional support throughout the process. These
                  channels work together to provide a seamless experience that
                  is focused on delivering results and driving member
                  satisfaction.
                </p>
                <p>
                  We understand the importance of building strong relationships
                  with our members, which is why our "people first" model
                  emphasizes the importance of establishing trust and creating a
                  loyal community that is invested in our brand's success. We
                  believe that by prioritizing the relationship with our
                  members, we can differentiate ourselves from other brands in
                  the industry and build a loyal following.
                </p>
                <p>
                  At Project LeanNation, we believe in the principle that
                  "nobody cares what you know until they know how much you
                  care." This statement reflects our commitment to our members
                  and underscores the importance of building relationships with
                  them. We strive to make every member feel valued and supported
                  throughout their journey with us.
                </p>
                <p>
                  Our brand is more than just a product or service – it's a
                  community that is committed to helping our members achieve
                  their goals and become the best version of themselves.
                </p>
                <p>
                  We will continue to prioritize our members' results and ensure
                  that we are providing a comprehensive, supportive experience
                  that meets their needs and exceeds their expectations. Our
                  brand differentiator is our commitment to delivering results,
                  building strong relationships, and empowering our members to
                  achieve their health and wellness goals.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Key Benefits</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h2 className="text-dark font-weight-500 mb-3">
                  Concise Summary
                </h2>
                <ul>
                  <li>
                    Supportive community and expert coaching to help you achieve
                    your weight loss and health goals.
                  </li>
                  <li>
                    Convenient and delicious prepared meals, made with
                    high-quality ingredients.
                  </li>
                  <li>
                    Easy-to-use technology for tracking your progress and
                    staying on track.
                  </li>
                  <li>
                    Educational resources to help you make informed decisions
                    about your health and wellness.
                  </li>
                  <li>
                    A welcoming and inclusive environment where everyone can
                    thrive.
                  </li>
                </ul>
                <hr className="my-3"></hr>
                <h2 className="text-dark font-weight-500 mb-3 mt-3">
                  Long-Form
                </h2>
                <p>
                  At Project LeanNation, we use support, technology, education,
                  and prepared healthy meals to transform communities into
                  healthier versions of themselves. Our expert coaches provide
                  the guidance and support you need to reach your weight loss
                  and health goals, while our technology makes it easy to
                  measure your progress and stay on track.
                </p>
                <p>
                  We also provide educational resources to help you make
                  informed decisions about your health and wellness. And with
                  our prepared meals, you can enjoy delicious and nutritious
                  food without the hassle of meal planning and preparation.
                </p>
                <p>
                  Our brand is built on the principles of creating a supportive
                  community and providing expert coaching to help our members
                  achieve their weight loss and health goals. We understand that
                  embarking on a journey to better health can be challenging,
                  which is why we are committed to providing the tools and
                  support necessary to help our members succeed.
                </p>
                <p>
                  Our approach includes convenient and delicious prepared meals
                  made with high-quality ingredients, which are designed to make
                  healthy eating easy and enjoyable. Additionally, we provide
                  easy-to-use technology that allows our members to track their
                  progress and stay on track with their goals.
                </p>
                <p>
                  We believe that education is essential to making informed
                  decisions about health and wellness. That's why we offer a
                  variety of educational resources to our members, including
                  articles, videos, and webinars, that provide the knowledge and
                  skills necessary to achieve long-term success.
                </p>
                <p>
                  Our brand is founded on the principles of inclusivity and
                  support. We strive to create a welcoming and inclusive
                  environment where everyone can thrive, regardless of their
                  background or experience level. By fostering a community that
                  is built on support and encouragement, we empower our members
                  to achieve their health and wellness goals and live their best
                  lives.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">What We Solve</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  Our brand understands that many of our prospects come to us
                  seeking convenience, but stay for the appeal of seeing what
                  they can transform into with structured nutrition, on-brand
                  support, and education on positive lifestyle choices. We
                  believe that it's important to understand the potential pain
                  points and consequences that our prospects may be facing in
                  order to better serve their needs.
                </p>
                <p>
                  Some of the pain points that our prospects may experience
                  include a lack of knowledge about how to eat healthy, a lack
                  of time to prepare for their week, a lack of accountability in
                  their life, and having tried all the quick fixes without
                  success.
                </p>
                <p>
                  The consequences of these pain points can be significant.
                  Continued weight gain from high sugar processed foods can lead
                  to long-term health issues and lower confidence and
                  self-esteem, which is often medicated by eating poorly. In
                  addition, not taking control of one's health can lead to a
                  lack of energy and focus, which can impact their ability to
                  show up for others in their lives.
                </p>
                <p>
                  As a brand, it is our responsibility to provide the tools and
                  support necessary to help our prospects overcome these pain
                  points and achieve their goals. By providing expert guidance
                  and education on positive lifestyle choices, convenient meal
                  options made with high-quality ingredients, and a supportive
                  community, we can help our prospects transform their lives and
                  achieve long-term success.
                </p>
                <p className="m-0">
                  At Project LeanNation, we understand that these pain points
                  can be a significant barrier to achieving optimal health and
                  wellness. That's why we are committed to providing our members
                  with the tools and support they need to overcome these
                  challenges and achieve their goals. By offering expert
                  guidance on nutrition and positive lifestyle choices,
                  convenient and delicious meal options made with high-quality
                  ingredients, and a supportive community, we empower our
                  members to take control of their health and transform their
                  lives for the better. With Project LeanNation, our members can
                  break free from the constraints of their pain points and avoid
                  the potential consequences of unhealthy habits, ultimately
                  achieving long-term success and a happier, healthier life.
                </p>
              </CardBody>
            </Card>
            <Card className="shadow border mb-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h2 className="text-dark mb-0">Product Positioning</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p>
                  Our product line is built around the concept of providing
                  healthy and delicious meals that fit our members' unique
                  lifestyles and dietary needs. Our Lifestyle Meals are designed
                  to help our members reach their health goals by providing
                  whole foods that are perfectly portioned and packed with
                  nutrients. Our meals are also gluten-free, providing a
                  convenient option for those with dietary restrictions.
                </p>
                <p>
                  For those with a heartier appetite or who engage in
                  high-intensity workouts, we offer our Athlete Meals. These
                  meals are 1.5 times the size of a Lifestyle Meal and provide
                  even more fuel and recovery to help our members perform at
                  their best.
                </p>
                <p>
                  In addition to our meal options, we also offer Protein Shakes
                  that are packed with high-quality protein to help our members
                  meet their nutritional needs. These shakes are a convenient
                  option for those on-the-go or looking to supplement their
                  meals with additional protein.
                </p>
                <p>
                  We also offer Lean Cheats, which are indulgent snacks made
                  with high-quality ingredients and prepared in a way that fits
                  our members' health goals. These snacks provide a delicious
                  option for those looking to indulge without sacrificing their
                  progress towards their health goals.
                </p>
                <p className="m-0">
                  At the core of our product position is the belief that healthy
                  eating should be delicious, convenient, and tailored to fit
                  our members' unique lifestyles and needs. By providing a range
                  of meal options and supplements, we are committed to helping
                  our members achieve their health goals and live their best
                  lives.
                </p>
              </CardBody>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

export default BrandPositioningView;
