import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _ from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import CalendarWeekView from "../CalendarWeekView";
import AppointmentCard from "../AppointmentCard";
import ConsultationDetailDrawer from "../Drawers/ConsultationDetailDrawer";

class StoreUpcomingAppointmentsWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    appointments: [],
    loading: false,
  };

  componentDidMount() {
    //this.handleTabChange(this.state.active, true);

    PubSub.subscribe(Event.APPOINTMENT.CREATED, () => {
      this.loadAppointments(this.state.dateRange);
    });

    PubSub.subscribe(Event.APPOINTMENT.MODIFIED, (appointment) => {
      this.loadAppointments(this.state.dateRange);

      if (this.state?.consultation?._id == appointment?._id) {
        this.setState({
          consultation: appointment,
        });
      }
    });

    PubSub.subscribe(Event.APPOINTMENT.DELETED, () => {
      this.loadAppointments(this.state.dateRange);
    });

    // Check for new appointment updates every 15 seconds
    let interval = setInterval(() => {
      if (this.state.dateRange) {
        this.getAppointments(
          this.state?.dateRange?.startDate,
          this.state.dateRange?.endDate,
          true
        );
      }
    }, 60000);

    this.setState({
      backgroundRefresh: interval,
    });
  }

  componentWillUnmount() {
    if (this.state.backgroundRefresh) {
      clearInterval(this.state.backgroundRefresh);
    }
  }

  getAppointments(startDate, endDate, background = false) {
    if (!background) {
      this.setState({
        loading: true,
      });
    }

    let start = moment(startDate?.toISOString())
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();
    let end = moment(endDate?.toISOString())
      .hour(23)
      .minutes(59)
      .second(59)
      .millisecond(999)
      .toDate();

    let filters = {
      storeID: this.props.match?.params?.storeID,
      status: { $ne: "CANCELLED" },
      startsAt: {
        $gte: { $date: start },
        $lte: { $date: end },
      },
    };

    let orderBy = {
      startsAt: -1,
    };

    if (this.props.match?.params?.storeID == "select") {
      return;
    }

    APIV2.getAppointmentsForStore(
      this.props.match?.params?.storeID,
      1,
      500,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let appointments = [];

            if (data.data.appointments.length) {
              appointments = data.data.appointments;
            }

            let existing = JSON.stringify(this.state.appointments);

            if (JSON.stringify(appointments) != existing) {
              this.setState({
                appointments,
                pagination: data.data.pagination,
                result: data.data.result,
              });
            }
          }
        },
        (e) => {
          console.error(e);

          if (!background) {
            window.alert(
              "There was an error loading the upcoming appointments. Please reload and try again."
            );
          }
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.store != this.props?.store) {
      this.loadAppointments(this.state.dateRange);
    }
  }

  taskDetailDrawer(task) {
    this.setState({
      taskDetail: task,
      detailOpen: true,
    });
  }

  loadAppointments(dateRange) {
    if (!dateRange) {
      return;
    }

    this.getAppointments(dateRange?.startDate, dateRange?.endDate);
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardBody className="p-0">
                <CalendarWeekView
                  loading={this.state.loading}
                  timeslotComponent={(data) => {
                    return (
                      <>
                        <AppointmentCard
                          onClick={() => {
                            this.setState({
                              consultation: data,
                              detailOpen: true,
                            });
                          }}
                          appointment={data}
                        ></AppointmentCard>
                      </>
                    );
                  }}
                  data={this.state.appointments}
                  onChange={(v) => {
                    this.loadAppointments(v);
                    this.setState({
                      dateRange: v,
                    });
                  }}
                  onRefresh={(v) => {
                    this.getAppointments(v?.startDate, v?.endDate);
                  }}
                  compareKey="startsAt"
                  emptyString={"No appointments scheduled."}
                ></CalendarWeekView>
              </CardBody>
            </Card>
          </>
        )}
        <ConsultationDetailDrawer
          consultation={this.state.consultation}
          open={this.state.detailOpen}
          onClose={() => {
            this.setState({
              consultation: null,
              detailOpen: false,
            });
          }}
          store={this.props.store}
        ></ConsultationDetailDrawer>
      </>
    );
  }
}

export default withRouter(StoreUpcomingAppointmentsWidget);
