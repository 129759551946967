import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Table,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import NumberFormat from "react-number-format";
import ModifyStoreModal from "./ModifyStoreModal";
import CreatePlanModal from "./CreatePlanModal";
import PlanRow from "./PlanRow";
import APIV2 from "lib/APIV2";
import { withRouter } from "react-router";
import CardSelectorHeader from "./Headers/CardSelectorHeader";
import { filter, sortBy } from "underscore";
import CsvDownload from "react-json-to-csv";
import moment from "moment";

class StoreViewPlansTab extends React.Component {
  state = {
    open: false,
    tabs: [],
    activeTab: {
      id: "All",
      name: "All",
    },
    filteredPlans: [],
    planExport: [],
  };

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });

            let planExport = data.data.store?.products?.mealPlans?.map(
              (plan, i) => {
                return {
                  _id: plan._id,
                  "Plan Code": plan.code,
                  Name: plan.name,
                  Type: plan.planType?.name,
                  Quantity: plan.quantity,
                  Frequency: plan.frequency,
                  Procurement: plan.procurement,
                  "Unit Price": "$" + plan.unitPrice / 100,
                  "Available To New Members": plan.availableToNewClients
                    ? "Yes"
                    : "No",
                };
              }
            );

            planExport = sortBy(planExport, "Plan Code");

            this.setState({ planExport: planExport });

            this.filterPlans(this.state.activeTab);
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getPlanTypes() {
    this.setState({
      planTypesLoading: true,
    });

    APIV2.getProductGroups()
      .then(
        (data) => {
          if (data.data && data.data.planTypes) {
            let tabs = [
              {
                _id: "All",
                id: "All",
                name: "All",
              },
            ];

            for (let i = 0; i < data.data.planTypes.length; i++) {
              tabs.push({
                name: data.data.planTypes[i].name,
                id: data.data.planTypes[i]._id,
                _id: data.data.planTypes[i]._id,
              });
            }

            this.setState({
              productGroups: data.data.planTypes,
              tabs,
            });
          }
        },
        () => {}
      )
      .finally(() => {
        this.setState({
          planTypesLoading: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();
    this.getPlanTypes();

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.activeTab?.id) {
      console.log(tab);

      this.filterPlans({
        id: decodeURIComponent(tab),
      });
    }

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });

      let planExport = s?.products?.mealPlans?.map((plan, i) => {
        return {
          _id: plan._id,
          "Plan Code": plan.code,
          Name: plan.name,
          Type: plan.planType?.name,
          Quantity: plan.quantity,
          Frequency: plan.frequency,
          Procurement: plan.procurement,
          "Unit Price": "$" + plan.unitPrice / 100,
          "Available To New Members": plan.availableToNewClients ? "Yes" : "No",
        };
      });

      planExport = sortBy(planExport, "Plan Code");

      this.setState({ planExport: planExport });

      this.filterPlans(this.state.activeTab);
    });
  }

  filterPlans(option) {
    this.setState({ activeTab: option });

    console.log(option);

    if (!this.state.store?.products?.mealPlans) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    if (option.id == "All") {
      this.setState({
        filteredPlans: this.state.store?.products?.mealPlans,
      });

      return;
    }

    let filtered = filter(this.state.store?.products?.mealPlans, (p) => {
      return p.planTypeID == option.id;
    });

    console.log(filtered);

    this.setState({
      filteredPlans: filtered,
    });
  }

  render() {
    return (
      <>
        <Card className=" shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h3 className=" mb-0">Plans</h3>
              </div>
              <div className="col-auto text-right">
                <CsvDownload
                  className="btn-outline-secondary btn-sm btn"
                  data={this.state.planExport}
                  filename={
                    this.state.store?.name +
                    " Plan Export " +
                    moment().format() +
                    ".csv"
                  }
                >
                  Export
                </CsvDownload>
                <Button
                  onClick={() => {
                    PubSub.publish(Event.PLAN.CREATE_OPEN, this.state.store);
                  }}
                  className="btn-sm btn"
                  color="primary"
                >
                  Add
                </Button>
              </div>
            </Row>
          </CardHeader>
          <CardSelectorHeader
            loading={this.state.planTypesLoading}
            options={this.state.tabs}
            value={this.state.activeTab}
            onChange={(e) => this.filterPlans(e)}
          ></CardSelectorHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Total Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Available</th>
                <th scope="col">Manage</th>
              </tr>
            </thead>
            <tbody>
              {!this.state.store ||
              !this.state.store.products ||
              !this.state.store.products.mealPlans ||
              !this.state.store.products.mealPlans.length ? (
                <tr>
                  <td colSpan="7">No plans.</td>
                </tr>
              ) : null}
              {this.state.store &&
              this.state.store.products &&
              this.state.store.products.mealPlans
                ? this.state.filteredPlans.map((s, i) => (
                    <PlanRow
                      key={i}
                      plan={s}
                      store={this.state.store ? this.state.store : null}
                    ></PlanRow>
                  ))
                : null}
            </tbody>
          </Table>
        </Card>
        <CreatePlanModal
          store={this.state.store ? this.state.store : null}
        ></CreatePlanModal>
      </>
    );
  }
}

export default withRouter(StoreViewPlansTab);
