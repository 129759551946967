import ArrowText from "components/ArrowText";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import moment from "moment";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import _, { flatten } from "underscore";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { P } from "@antv/g2plot";
import { Drawer } from "rsuite";
import ConversationMessagePane from "components/Conversation/ConversationMessagePane";

class ConversationWidget extends React.Component {
  state = {
    expand: false,
    taskDetail: null,
    count: 200,
  };

  componentDidMount() {
    PubSub.subscribe(`ws_conversations:modified`, (data) => {
      console.log("Conversation Modified: ", data);

      if (data?._id == this.state.conversation?._id) {
        this.setState({
          conversation: data,
        });
      }
    });

    PubSub.subscribe(Event.CUSTOMER.CREATED, (c) => {
      if (c?.phone == this.props.lead?.phone) {
        this.props.history.push(
          `/storefront/${this.props.store?._id}/members/details/${c?._id}/communications`
        );
      }
    });

    this.getConversation();
  }

  openConversation() {
    if (this.state.conversation?.unreadCount > 0) {
      APIV2.markConversationAsRead(this.state.conversation?._id).then(
        (data) => {},
        (e) => {}
      );
    }

    this.setState({
      viewConversation: true,
    });
  }

  setError(message) {
    if (!message) {
      this.setState({
        error: "",
      });

      return;
    }

    this.setState({
      error: message,
    });

    setTimeout(() => {
      this.setError("");
    }, 4000);
  }

  createConversation() {
    let recipientName = "";
    let recipientPhone = "";

    if (this.props.customer) {
      recipientName = this.props.customer?.name
        ? this.props.customer?.name
        : this.props.customer?.phone;
      recipientPhone = this.props.customer?.phone;
    } else if (this.props.lead) {
      recipientName = this.props.lead?.name
        ? this.props.lead?.name
        : this.props.lead?.phone;
      recipientPhone = this.props.lead?.phone;
    }

    let payload = {
      unreadCount: 0,
      latestMessageID: null,
      latestMessageTimestamp: new Date(),
      customerID: this.props.customer ? this.props.customer?._id : null,
      leadID: this.props.lead ? this.props.lead?._id : null,
      storeID: this.props.store?._id?.toString(),
      status: "OPEN",
      recipientName,
      recipientPhone,
    };

    this.setState({
      submitting: true,
    });

    APIV2.createConversation(payload)
      .then(
        (data) => {
          this.setState({
            conversation: data.data.conversation,
            viewConversation: true,
          });
        },
        (e) => {
          this.setError(
            e?.response?.body?.message ??
              "Unable to create new conversation - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  getConversation() {
    if (!this.props.customer && !this.props.lead) {
      return;
    }

    this.setState({
      conversationLoading: true,
    });

    let filters = {};

    if (this.props.customer?._id) {
      filters = {
        customerID: this.props.customer?._id,
      };
    } else if (this.props.lead?._id) {
      filters = {
        leadID: this.props.lead?._id,
      };
    }

    APIV2.getConversations(1, 1, filters)
      .then(
        (data) => {
          console.log(data);

          if (data?.data?.conversations?.length) {
            this.setState({
              conversation: data?.data?.conversations[0],
            });
          } else {
            this.setState({
              conversation: null,
            });
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          conversationLoading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.lead != this.props?.lead ||
      prevProps?.customer != this.props?.customer ||
      prevProps?.storeID != this.props?.storeID
    ) {
      this.getConversation();
    }
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <>
            <Card className="mb-4 shadow border">
              <CardBody className="bg-transparent py-5 text-center">
                <Spinner size="lg" color="medium"></Spinner>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-4 border shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="text-dark mb-0">Conversation</h3>
                    {this.state.conversationLoading ? (
                      <p className="m-0 small">Loading conversation...</p>
                    ) : (
                      <p className="m-0 small">
                        {this.state.conversation
                          ? `View this
                      ${this.props.lead ? " lead" : " member"}'s conversation`
                          : `${
                              !this.props.customer?.phone &&
                              !this.props.lead?.phone
                                ? "Add a phone number to start"
                                : "Start"
                            } a new conversation with this
                      ${this.props.lead ? " lead" : " member"}`}
                      </p>
                    )}
                  </Col>
                  <Col xs="auto">
                    {this.props.store?.twilioNumber ? (
                      <Button
                        size="sm"
                        color="primary"
                        disabled={
                          this.state.submitting ||
                          !this.props.store?.twilioNumber
                        }
                        onClick={() => {
                          if (
                            !this.props.customer?.phone &&
                            !this.props.lead?.phone
                          ) {
                            let url = `/storefront/${this.props.store?._id}`;

                            if (this.props.customer) {
                              url += `/members/details/${this.props.customer?._id}/information`;
                            } else {
                              url += `/leads/details/${this.props.customer?._id}/information`;
                            }

                            return this.props.history.push(url);
                          }

                          if (this.state.conversation) {
                            this.openConversation();

                            return;
                          }

                          this.createConversation();
                        }}
                      >
                        {this.state.conversation
                          ? "View"
                          : !this.props.customer?.phone &&
                            !this.props.lead?.phone
                          ? "Add Phone"
                          : "Start"}
                      </Button>
                    ) : (
                      <Button size="sm" color="light" disabled={true}>
                        Coming Soon
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              {this.state?.conversationLoading && (
                <CardBody className="p-0">
                  <div className="px-3 py-5 text-center">
                    <Spinner size="sm" color="dark"></Spinner>
                  </div>
                </CardBody>
              )}
              {this.state?.error && (
                <CardBody className="p-4">
                  <Alert color="danger" className="m-0">
                    {this.state.error}
                  </Alert>
                </CardBody>
              )}
              {!this.state.conversationLoading &&
              this.state.conversation?.latestMessage ? (
                <CardBody
                  className="p-0 cursor-pointer bg-superlight--hover"
                  onClick={() => {
                    this.openConversation();
                  }}
                >
                  <div className="p-4">
                    <Row className="align-items-center">
                      <Col
                        xs=""
                        className={`list-item-body ${
                          this.state.conversation?.unreadCount > 0
                            ? "short"
                            : ""
                        }`}
                      >
                        <div
                          style={{ fontSize: 16 }}
                          className={`text-dark text-truncate ${
                            this.state.conversation?.unreadCount > 0
                              ? "font-weight-bold"
                              : ""
                          }`}
                        >
                          {this.state.conversation?.latestMessage
                            ?.storeSent && (
                            <i
                              className="mdi mdi-share pr-1"
                              style={{ position: "relative", top: 1 }}
                            ></i>
                          )}
                          {this.state.conversation?.latestMessage?.body
                            ? this.state.conversation?.latestMessage?.body
                            : "(empty message)"}
                        </div>
                      </Col>

                      {this.state.conversation?.unreadCount > 0 ? (
                        <Col xs="auto" className="pl-0">
                          <i
                            className="mdi mdi-circle text-primary unread-icon"
                            style={{
                              fontSize: 12,
                            }}
                          ></i>
                        </Col>
                      ) : null}
                    </Row>
                    {this.state.conversation?.latestMessage?.mediaUrl ? (
                      <div style={{ fontSize: 12 }} className="mt-1">
                        1 attachment
                      </div>
                    ) : null}
                    <div style={{ fontSize: 12 }} className="mt-2">
                      {moment(
                        this.state.conversation?.latestMessage?.messageTimestamp
                      ).format("MM/DD/YY hh:mm A")}
                    </div>
                  </div>
                </CardBody>
              ) : null}
            </Card>
          </>
        )}
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.state.viewConversation}
          onClose={() => {
            this.setState({
              viewConversation: false,
            });
          }}
          keyboard={false}
        >
          <Drawer.Body className="p-0 conversation-drawer bg-superlight">
            <div className="conversation-content">
              <ConversationMessagePane
                conversation={this.state.conversation}
                loading={this.state.conversationLoading}
                newConversation={false}
                emptyState={false}
                onCancel={() => {
                  this.setState({
                    viewConversation: false,
                  });
                }}
                store={this.props.store}
                drawerMode={true}
                drawerOpen={this.state.viewConversation}
              ></ConversationMessagePane>
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(ConversationWidget);
