import ReactDOM from "react-dom";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import React from "react";
import { Chart, Axis, Tooltip, Geom, PieChart } from "bizcharts";
import { useDeepCompareMemo } from "use-deep-compare";
import { Row, Col } from "reactstrap";
import { Statistic, Table } from "antd";
import DataAPI from "lib/DataAPI";

const stackedChartData = (resultSet) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};

const LineChartRenderer = ({ resultSet }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet]
  );
  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom type="line" position="x*measure" size={2} color="color" />
    </Chart>
  );
};

const BarChartRenderer = ({ resultSet, pivotConfig }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet.serialize()]
  );
  const stacked = !(pivotConfig.x || []).includes("measures");
  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip />
      <Geom
        type="interval"
        position="x*measure"
        color="color"
        adjust={stacked ? "stack" : "dodge"}
      />
    </Chart>
  );
};

const AreaChartRenderer = ({ resultSet }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet.serialize()]
  );
  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom
        type="area"
        adjust="stack"
        position="x*measure"
        size={2}
        color="color"
      />
    </Chart>
  );
};

const PieChartRenderer = ({ resultSet }) => {
  const [data, angleField] = useDeepCompareMemo(() => {
    return [resultSet.chartPivot(), resultSet.series()];
  }, [resultSet]);
  if (!angleField?.length || !angleField[0]?.key) {
    return <div className="py-3 text-center">No data available.</div>;
  }

  return (
    <PieChart
      data={data}
      radius={0.8}
      angleField={angleField[0].key}
      colorField="x"
      label={{
        visible: true,
        offset: 20,
      }}
      legend={{
        position: "bottom",
      }}
    />
  );
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }

    if (type === "boolean") {
      return Boolean(value).toString();
    }

    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }

    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const TableRenderer = ({ resultSet, pivotConfig }) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);
  return (
    <Table pagination={false} columns={tableColumns} dataSource={dataSource} />
  );
};

const cubejsApi = cubejs(DataAPI.getAuthToken(), {
  apiUrl: DataAPI.getEnvironment(),
});

const renderChart = ({
  type,
  label = "",
  resultSet,
  error,
  pivotConfig,
  format,
}) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <Spin />;
  }

  if (type == "bar") {
    return <BarChartRenderer resultSet={resultSet} pivotConfig={pivotConfig} />;
  } else if (type == "line") {
    return (
      <LineChartRenderer resultSet={resultSet} pivotConfig={pivotConfig} />
    );
  } else if (type == "table") {
    return (
      <TableRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
      ></TableRenderer>
    );
  } else if (type == "pie") {
    return (
      <PieChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
      ></PieChartRenderer>
    );
  } else if (type == "area") {
    return (
      <AreaChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
      ></AreaChartRenderer>
    );
  } else if (type == "number") {
    return (
      <Row>
        <Col xs="12" className="text-center">
          {resultSet.seriesNames().map((s) => (
            <>
              {s?.key && (
                <Statistic
                  value={resultSet.totalRow()[s.key]}
                  valueRender={(v) => {
                    let val = v?.props?.value;

                    if (
                      format == "percent" &&
                      val !== null &&
                      val !== undefined
                    ) {
                      return <>{v.props.value.toFixed(2)}%</>;
                    }

                    return v;
                  }}
                />
              )}
              {}
              {label ? (
                <span className="text-uppercase">
                  <small>{label}</small>
                </span>
              ) : (
                ""
              )}
            </>
          ))}
        </Col>
      </Row>
    );
  }
};

class ChartRenderer extends React.Component {
  render() {
    return (
      <QueryRenderer
        query={this.props.query}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={false}
        render={(props) =>
          renderChart({
            ...props,
            label: this.props.label,
            type: this.props.type,
            chartType: this.props.type,
            pivotConfig: this.props.pivotConfig,
            format: this.props.format,
          })
        }
      />
    );
  }
}

export default ChartRenderer;
