import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { CheckboxGroup, DatePicker, SelectPicker, Checkbox } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Input from "reactstrap/lib/Input";
import swal from "sweetalert";
import CustomerPickupListRow from "components/CustomerPickupListRow";
import CustomerOrderDrawer from "components/Drawers/CustomerOrderDrawer";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import LinkUtils from "lib/LinkUtils";

class GlobalFulfillmentProjectionView extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    orders: [],
    compareToOptions: [],
    count: 25,
    page: 1,
    selectedOrders: [],
    searchResultOrders: [],
    reports: [],
    active: {
      id: "All",
    },
    filters: {
      status: { $ne: "CANCELLED" },
      "subscription.plan.procurement": { $eq: "pickup" },
    },
  };

  loadOrders() {
    this.setState({
      loadingOrders: true,
      selectedOrders: [],
    });

    APIV2.getProjections(this.state.dateString)
      .then(
        (data) => {
          if (data && data.data) {
            let projections = data?.data?.projection;

            let reports = [
              {
                statistics: {
                  totalBOMQtyByMeal: projections,
                },
              },
            ];

            this.setState({
              projections,
              reports,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the projections. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
          loading: false,
        });
      });
  }

  loadSummary(dateString) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState({
      loading: true,
      rerunning: false,
      noReport: false,
    });

    let filter = this.state.filters;

    filter.fulfillmentDateString = dateString;

    this.setState(
      {
        filters: filter,
        dateString,
      },
      () => {
        this.loadOrders();
      }
    );
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (this.state.searchResults) {
      page = this.state.searchPage ? this.state.searchPage + count : 1 + count;
    }

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadOrders();
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
      },
      () => {
        this.loadSummary(this.state.fulfillmentDateString);
      }
    );

    PubSub.subscribe(Event.ORDER.MODIFIED, (order) => {
      let orderIdx = _.findIndex(this.state.orders, { _id: order._id });
      let searchIdx = _.findIndex(this.state.searchResultOrders, {
        _id: order._id,
      });

      if (orderIdx >= 0) {
        let orders = this.state.orders;

        orders[orderIdx] = order;

        this.setState({
          orders,
        });
      }

      if (searchIdx >= 0) {
        let searchResultOrders = this.state.searchResultOrders;

        searchResultOrders[searchIdx] = order;

        this.setState({
          searchResultOrders,
        });
      }
    });

    PubSub.subscribe(Event.ORDER.OPEN, (order) => {
      this.setState({
        drawer: true,
        viewOrder: order,
      });
    });
  }

  toggleSelected(order, val) {
    let selected = this.state.selectedOrders;

    if (!val) {
      selected = _.filter(selected, (o) => {
        return o._id != order._id;
      });
    } else {
      if (!this.isSelected(order)) {
        selected.push(_.clone(order));
      }
    }

    this.setState({ selectedOrders: selected }, () => {});
  }

  toggleAllSelected(val) {
    this.setState(
      {
        selectedOrders: [],
      },
      () => {
        if (this.state.searchResults) {
          for (let i = 0; i < this.state.searchResultOrders.length; i++) {
            this.toggleSelected(this.state.searchResultOrders[i], val);
          }
        } else {
          for (let i = 0; i < this.state.orders.length; i++) {
            this.toggleSelected(this.state.orders[i], val);
          }
        }
      }
    );
  }

  isSelected(order) {
    return _.findWhere(this.state.selectedOrders, { _id: order?._id })
      ? true
      : false;
  }

  allSelected() {
    let all = true;

    if (this.state.searchResults) {
      for (let i = 0; i < this.state.searchResultOrders.length; i++) {
        if (!this.isSelected(this.state.searchResultOrders[i])) {
          all = false;
        }
      }
    } else {
      for (let i = 0; i < this.state.orders.length; i++) {
        if (!this.isSelected(this.state.orders[i])) {
          all = false;
        }
      }
    }

    return all;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            forceSecondaryDropdown={true}
            title="Projections"
            showBackButton={false}
            secondaryActions={[
              {
                id: "printPickupList",
                name: <>Download Projections</>,
                onClick: () => {
                  LinkUtils.openInNewTab(
                    "/storefront-print/fulfillment/projections/csv?date=" +
                      this.state.fulfillmentDateString,
                    true
                  );
                },
              },
            ]}
          />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-4">
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="">
                <h3 className="m-0">
                  {this.state.fulfillmentMoment?.format("ddd, MMM Do YYYY")}
                </h3>
              </Col>
              <Col xs="12" sm="6" md="auto">
                <DatePicker
                  oneTap
                  value={this.state.fulfillmentDate}
                  onChange={(v) => {
                    let mom = moment(v.toISOString());

                    mom.set("hours", 0).set("minutes", 0).set("seconds", 0);

                    this.setState(
                      {
                        fulfillmentDate: mom.toDate(),
                        fulfillmentMoment: mom,
                        fulfillmentDateString: mom.format("YYYY-MM-DD"),
                      },
                      () => {
                        this.loadSummary(
                          this.state.fulfillmentDateString,
                          this.state.comparisonValue
                        );
                      }
                    );
                  }}
                  className="mt-3 mt-md-0"
                  placeholder="Select Date"
                  style={{
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "800px",
                  }}
                  placement="autoVerticalEnd"
                  block
                  cleanable={false}
                  disabledDate={(date) => {
                    let mom = moment(date.toISOString());

                    return mom.toDate() < moment().toDate() || mom.day() !== 6;
                  }}
                />
              </Col>
            </Row>
          </div>

          {this.state.loading ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Loading Projections</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your projections for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : (
            <MealsOrderedWidget
              reports={this.state.reports}
            ></MealsOrderedWidget>
          )}
        </Container>
      </>
    );
  }
}

export default GlobalFulfillmentProjectionView;
